import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Cross from "../../../images/cross.png";
import axios from "axios";
import Swal from "sweetalert2";
import icon_3 from "../../../images/ae2.svg";
import { useNavigate } from "react-router-dom";
function StudentJoinClassroomDialogue(props) {
  const [classCode, setClassCode] = useState("");
  const navigate = useNavigate();
  const handleClickContinue = () => {
    if (classCode.trim() === "") {
      alert("Please enter class code");
      return;
    }

    const token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/join_class", {
        method: "POST",
        class_code: classCode,
      })
      .then((response) => {
        // console.log(response.data.data);
        // console.log("new data here is", response.data);
        if (response.data.data === "INCORRECT CLASS CODE") {
          Swal.fire({
            icon: "error",
            title: "<span style='font-size: 18px; font-weight: bold; color: black;'>INCORRECT CLASS CODE</span>",
            html: "<p style='font-size: 14px; color: #6c757d;'>Please double-check the code and try again.</p>",
            confirmButtonText: "<span style='font-size: 14px; font-weight: bold;'>Try again</span>",
            confirmButtonColor: "#6750A4", // Custom button color
            showCloseButton: true, // Enable the close button in the top-right corner
            closeButtonHtml: "<span style='font-size: 30px; font-weight: bold;'>×</span>", // Optional: custom close button style (you can use '×' or any HTML)
            customClass: {
              popup: "swal2-custom-popup",
              closeButton: "swal2-close-button", // Optional: Apply custom styling to the close button if needed
            },
          });
        } else if (response.data.data === "Classroom Already joined") {
          Swal.fire({
            imageUrl: icon_3, // Replace with the path to your custom icon
            imageWidth: 58.5, // Matches the dimensions of your provided icon
            imageHeight: 58.5,
            title: "<span style='font-size: 18px; font-weight: bold; color: black;'>Classroom Already Joined!</span>",
            html: "<p style='font-size: 14px; color: #6c757d;'>You’re all set! You’ve already joined this classroom.</p>",
            confirmButtonText: "<span style='font-size: 14px; font-weight: bold;padding: 10px 20px;'>OK</span>",
            confirmButtonColor: "#6750A4", // Custom button color
            showCloseButton: true, // Enable the close button in the top-right corner
            closeButtonHtml: "<span style='font-size: 30px; font-weight: bold;'>×</span>", // Optional: custom close button style (you can use '×' or any HTML)
            customClass: {
              popup: "swal2-custom-popup",
              closeButton: "swal2-close-button", // Optional: Apply custom styling to the close button if needed
            },
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "<span style='font-size: 18px; font-weight: bold; color: black;'>Class Joined</span>",
            html: "<p style='font-size: 14px; color: #6c757d;'>You've successfully enrolled in this class.</p>",
            confirmButtonText: "<span style='font-size: 14px; font-weight: bold; padding: 10px 20px;'>OK</span>",
            confirmButtonColor: "#6750A4", // Custom button color
            showCloseButton: true, // Enable the close button in the top-right corner
            closeButtonHtml: "<span style='font-size: 30px; font-weight: bold;'>×</span>", // Optional: custom close button style (you can use '×' or any HTML)
            customClass: {
              popup: "swal2-custom-popup",
              closeButton: "swal2-close-button", // Optional: Apply custom styling to the close button if needed
            },
          }).then((result) => {
            if (result.isConfirmed) {
              // Close the alert and reload the page
              Swal.close();
              navigate("/student-view-classroom", {
                state: {
                  studentClassroomData: {
                    classroom_id: response.data.class_id, // Sending classroom_id inside studentClassroomData object
                  },
                },
              });
            }
          });
        }
        props.handleClickCloseJoinClassroomDialogue();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Something went wrong. Please try again!",
        });
        console.error(error);
      });
  };
  useEffect(() => {
    if (props.openJoinClassroomDialogue) {
      setClassCode(""); // Reset class code to empty when dialog opens
    }
  }, [props.openJoinClassroomDialogue]); // Dependency array ensures effect runs when dialog is opened
  return (
    <Dialog
      PaperProps={{
        sx: {
          width: "500px",

          borderRadius: "12px",
          padding: "36px",
          paddingTop: "0px",
        },
      }}
      open={props.openJoinClassroomDialogue}
      onClose={props.handleClickCloseJoinClassroomDialogue}
      aria-labelledby="join-class-dialog-title"
    >
      {/* Header */}
      <DialogTitle>
        <div className="flex justify-end items-end">
          <button onClick={props.handleClickCloseJoinClassroomDialogue}>
            <img src={Cross} alt="Close" className="w-5 h-5" />
          </button>
        </div>
      </DialogTitle>

      {/* Body */}
      <DialogContent>
        <div className="text-lg font-semibold text-gray-900 text-center  ">Join Your Class</div>
        <p className="text-sm text-gray-600 text-center">Please enter the code provided by the teacher</p>
        <div className="mt-4">
          <label htmlFor="class_code" className="block text-sm font-medium text-gray-700">
            Enter your class code
          </label>
          <input
            type="text"
            id="class_code"
            value={classCode}
            onChange={(e) => setClassCode(e.target.value)}
            className="mt-2 w-full border-b border-gray-300 px-3 py-2 focus:ring-0 focus:border-purple-500 focus:outline-none"
            placeholder="Enter the classcode"
          />
        </div>
      </DialogContent>

      {/* Actions */}
      <DialogActions className="flex justify-center">
        <button
          onClick={handleClickContinue}
          disabled={classCode.trim() === ""}
          className={`mt-4 w-[80%] mx-auto px-4 py-2 text-sm font-semibold rounded-md ${
            classCode.trim() === "" ? "bg-gray-300 text-gray-500 cursor-not-allowed" : "bg-purple-600 text-white hover:bg-purple-700"
          }`}
        >
          Join Your Class
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default StudentJoinClassroomDialogue;
