import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@material-tailwind/react";
// Bringing in the GoogleOAuthProvider from the package
import { GoogleOAuthProvider } from "@react-oauth/google";
import amplitude from "amplitude-js";
import AppState from "./context/AppState";
import { BrowserRouter } from "react-router-dom";
import { thunk } from "redux-thunk";

import { createStore, applyMiddleware, combineReducers } from "redux";
import * as reducers from "./Componenets/Calendar/store/rootReducer";
import { Provider } from "react-redux";
import { TourProvider } from "./context/TourContext";
const store = createStore(combineReducers(reducers), applyMiddleware(thunk));
const root = ReactDOM.createRoot(document.getElementById("root"));
amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY, null, {
  // optional configuration options
  includeUtm: true,
  includeGclid: true,
  includeReferrer: true,
});

root.render(
  <GoogleOAuthProvider clientId="370779595431-ocjpvgpprq0nhdvrc8h3plm647jo6nbb.apps.googleusercontent.com">
    <Provider store={store}>
      <BrowserRouter>
        <TourProvider>
          <AppState>
            <App />
          </AppState>
        </TourProvider>
      </BrowserRouter>
    </Provider>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
