import React, { useState, useEffect } from "react";
import CodeEditor from "../../widgets/CodeEditor";
import FeedbackDisplay from "../../widgets/FeedbackDisplay";
import LoadingIndicator from "../../Student/widget/mockbot/LoadingIndicator";

function SummarySessionCard({ email, sessionSummary }) {
  const [isLoading, setIsLoading] = useState(true);
  function stripHTMLTags(str) {
    return str.replace(/<p>/g, "");
  }
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  }, []);

  return (
    <div className="flex bg-white justify-center items-center mt-8">
      {!isLoading ? (
        <div className="bg-white max-w-2xl mx-auto p-4 rounded-[12px] shadow mt-4 mb-8">
          <h2 className="text-xl font-semibold text-center">Session Summary</h2>
          <br />
          <br />
          {sessionSummary.length > 0 ? (
            sessionSummary.map((attempt, index) => (
              <div key={index} className="my-2 p-2 border-b">
                <div style={{ display: index === 0 ? "" : "none" }} className="flex flex-row  justify-between items-center">
                  {" "}
                  <p>
                    <b>Your Email:</b> {email}
                  </p>
                  <p className="float-right">
                    <b>Marks Obtained :</b> {sessionSummary[0].total_obtained_marks}/
                    {sessionSummary.reduce((acc, atmpt) => acc + atmpt.question_marks, 0)}
                  </p>
                </div>
                <div className="w-full mt-[24px]" style={{ display: "flex", alignItems: "flex-start" }}>
                  <div style={{ flex: 1 }}>
                    {attempt.have_code === 1 ? (
                      <p
                        className="answer-paragraph md:whitespace-break-spaces "
                        style={{
                          fontSize: "16px",
                          fontFamily: "Roboto",
                          color: "#49454F",
                        }}
                      >
                        <p
                          dangerouslySetInnerHTML={{
                            __html: `<div><span class="question" style=" color: #212529"><b>Question</b>&nbsp;${index + 1}:</span> ${stripHTMLTags(
                              attempt.question.split("##code")[0]
                            )}</div>`,
                          }}
                        />
                      </p>
                    ) : (
                      <p style={{ margin: 0, whiteSpace: "pre-line" }}>
                        <b>Question {index + 1}: </b>
                        <span style={{ display: "inline" }} dangerouslySetInnerHTML={{ __html: attempt.question }} />
                      </p>
                    )}
                  </div>
                  <div style={{ marginLeft: "20px", alignSelf: "start" }}>
                    <span className="">
                      <b>[</b> <b>Marks :</b> {attempt.obtained_marks}/{attempt.question_marks} <b>]</b>
                    </span>
                  </div>
                </div>

                <p className="mt-[4px]">
                  <b>Your Answer:</b>{" "}
                  {attempt.have_code === 1 ? (
                    <CodeEditor
                      language="javascript" // Replace with the language you want or leave empty for generic text
                      theme="monokai"
                      id={"" + index}
                      readOnly={true}
                      name="code"
                      value={attempt.answer} // Pass the current value for controlled input
                    />
                  ) : (
                    attempt.answer
                  )}
                </p>
                <br />
                <p>
                  <b>Feedback:</b> {attempt.have_code === 1 ? <FeedbackDisplay feedback={JSON.parse(attempt.ai_feedback)} /> : attempt.ai_feedback}
                </p>
                <br />
              </div>
            ))
          ) : (
            <p className="text-center">No questions were attempted.</p>
          )}
        </div>
      ) : (
        <div className="w-full h-full">
          {" "}
          <LoadingIndicator text="Loading..." />
        </div>
      )}
    </div>
  );
}

export default SummarySessionCard;
