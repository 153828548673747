import React, { useState } from "react";
import StudentDoTheseCard from "./StudentDoTheseCard";
import StudentDoneThemCard from "./StudentDoneThemCard";
import task_icon from "../../../images/No_icon.svg";
import image1 from "../../../images/CS.png";
import image2 from "../../../images/GEO.png";
import image3 from "../../../images/PSY.png";
import trialCalendar from "../../../images/trial_expire_calendar.svg";

import { useNavigate } from "react-router-dom";

function StudentDashbaordTabBody(props) {
  const navigate = useNavigate();
  const doThese = props.doThese;
  const doneThem = props.doneThem;
  const subjectName = props.subjectName;
  const subjectId = props.subjectId;
  const showDialogue = props.showDialogue;
  const images = [image1, image2, image3];

  const [showTrialDialogue, setShowTrialDialogue] = useState(false);

  const getImageIndex = (index) => {
    const pattern = [0, 1, 2, 1, 2, 0];
    return pattern[index % pattern.length];
  };

  const handleSelectSubject = async () => {
    if (showDialogue) {
      navigate("/select-topic", { state: { subjectId: subjectId, subjectName: subjectName, classroomId: props.classroomId } });
    } else {
      setShowTrialDialogue(true);
    }
  };

  return (
    <div className="mt-[-25px]">
      <div style={{ display: props.activeTab === "do" ? "" : "none" }} className="classroom_heading">
        <div>
          <label className="dashboard-label text-[#000000]" style={{ fontSize: "20px", color: "#000000" }}>
            Tasks
          </label>
        </div>
      </div>
      <>
        {props.activeTab === "do" && (
          <>
            {doThese && doThese.length > 0 ? (
              <StudentDoTheseCard doThese={doThese} viewQuiz={props.viewQuiz} quizType={1} />
            ) : (
              <div>
                <center>
                  <img src={task_icon} alt="no" />
                </center>
                <div className="flex justify-center font-bold">No tasks available!</div>
                <div className="flex justify-center ">we'll inform you when new tasks are on the way!</div>
              </div>
            )}
            <div className="classroom_heading">
              <div>
                <label className="dashboard-label text-[#000000]" style={{ fontSize: "20px", color: "#000000" }}>
                  Retrieval Practice
                </label>
              </div>
            </div>
            {props.doTheseRetrieval && props.doTheseRetrieval.length > 0 ? (
              <StudentDoTheseCard doThese={props.doTheseRetrieval} viewQuiz={props.viewQuizRetrieval} quizType={2} />
            ) : (
              <div>
                <center>
                  <img src={task_icon} alt="no" />
                </center>
                <div className="flex justify-center font-bold">No retrieval quizzes available!</div>{" "}
                <div className="flex justify-center ">we'll inform you when new quizzes are on the way!</div>
              </div>
            )}
            <div className="classroom_heading">
              <div>
                <label className="dashboard-label text-[#000000]" style={{ fontSize: "20px", color: "#000000", lineHeight: "44px" }}>
                  Practice on Your Own
                </label>
              </div>
            </div>
            <p className="text-[#000000] mt-[-4px]" style={{ fontSize: "16px", color: "#000000", lineHeight: "24px", letterSpacing: "0.15px" }}>
              Select a subject, choose a topic, and start practising exam questions at your own pace. Build your confidence and sharpen your skills
              for success!
            </p>
            <div
              className={"relative rounded-lg overflow-hidden flex justify-center items-center cursor-pointer mt-3"}
              onClick={handleSelectSubject}
              style={{
                backgroundImage: `url(${images[getImageIndex(0)]})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "250px", // Set fixed height
                width: "480px", // Set fixed width
              }}
            >
              <div className="z-8 mb-[160px] text-center font-bold text-2xl text-[#F2F2F2]">{subjectName}</div>
            </div>
            {showTrialDialogue && (
              <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-10">
                <div className="bg-white rounded-lg w-96">
                  <div className="flex flex-col items-center p-4">
                    <div className="flex items-center w-full justify-center mb-2">
                      <div className="flex flex-col items-center gap-2">
                        <img className="mt-1 mx-auto" src={trialCalendar} alt="tdr" />

                        <div className="text-center font-bold" style={{ fontSize: "24px", color: "#000000" }}>
                          Trial Expired
                        </div>
                        <div className="text-center">Your trial has ended. Let your teacher know so they can help you continue practising.</div>
                      </div>
                    </div>
                    <div className="flex justify-center w-full">
                      <button
                        className="px-4 py-2 text-sm text-white bg-[#6750A4] rounded-lg hover:opacity-55 focus:outline-none"
                        onClick={() => {
                          setShowTrialDialogue(false);
                        }}
                      >
                        OK
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </>

      {props.activeTab === "done" && (
        <>
          <div className="classroom_heading mt-[30px]">
            <p className="font-bold sm:ml-[25px] md:!ml-0 xs:ml-[25px] xs:text-[22px]">Your Completed Tasks and Quizzes</p>
          </div>
        </>
      )}

      {doneThem && props.activeTab === "done" && props.doTheseRetrieval && (doneThem.length > 0 || props.doneThemRetrieval.length > 0) ? (
        <StudentDoneThemCard
          handleOnClickDoneThemQuiz={props.handleOnClickDoneThemQuiz}
          doneThem={doneThem}
          doneThemRetrieval={props.doneThemRetrieval}
          viewQuizRetrieval={props.viewQuizRetrieval}
        />
      ) : (
        <div style={{ display: props.activeTab === "done" ? "" : "none" }} className="flex justify-center mt-4">
          Completed Quizzes will be displayed here.
        </div>
      )}
    </div>
  );
}

export default StudentDashbaordTabBody;
