import React, { useState } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled as styleds } from "@mui/material/styles";

const WelcomeStepsCompletedCard = ({ title, subtitle, description, buttonText, icon, buttonStyle, onClick, tooltipContent }) => {
  const [open, setOpen] = useState(false);
  const HtmlTooltip = styleds(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        color: "#49454F",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "20px",
        borderRadius: "8px",
        opacity: "0.8",
        backgroundColor: "#FFF",
        padding: "12px 16px",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 330,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
      },
      [`& .${tooltipClasses.arrow}`]: {
        color: "#FFF",
        "&:before": {
          border: "1px solid #dadde9",
        },
      },
    })
  );

  const handleClick = () => {
    setOpen(false); // Close tooltip
    if (onClick) {
      onClick(); // Call the passed onClick function
    }
  };
  return (
    <div className="border rounded-lg px-[1.5rem] py-[1rem] max-w-[23.625rem]">
      <div className="flex justify-between items-center">
        <h3 className="text-[20px] leading-[28px] font-semibold">{title}</h3>
        {icon && <img src={icon} />}
      </div>
      <p className=" text-[14px] leading-[20px] tracking-[0.1px] text-[#49454F]">{subtitle}</p>
      <HtmlTooltip
        enterTouchDelay={0}
        leaveTouchDelay={10}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        title={<p className="font-medium text-[14px] leading-5 tracking-[0.014rem]">{tooltipContent}</p>}
      >
        <button
          className={`w-full  py-2 mt-[3.25rem] mb-[0.5rem] rounded-lg ${buttonStyle} transition`}
          onClick={handleClick}
          onMouseLeave={() => setOpen(false)}
        >
          {buttonText}
        </button>
      </HtmlTooltip>
      <p className="text-[#49454F] text-[12px] leading-[16px] tracking-[0.1px]">{description}</p>
    </div>
  );
};

export default WelcomeStepsCompletedCard;
