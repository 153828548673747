import React from "react";

const SetQuestionSetCardCard = ({ handleOnClick }) => {
  return (
    <>
      <div className="home sm:ml[1.5rem] md:ml-[3.75rem]">
        <div>
          <label className="dashboard-label">Teacher Question Bank </label>
          <div className="teepee-divider"></div>
        </div>
      </div>
      <div className="sm:ml[1.5rem] md:ml-[3.75rem] w-80 p-6 rounded-lg bg-gradient-to-br from-purple-100 to-white text-center mt-8 ">
        <div className="flex justify-center items-center w-10 h-10 rounded-full bg-[#6750A4] mx-auto mb-3">
          <span className="text-white text-xl font-bold">+</span>
        </div>
        <h2 className="text-lg font-semibold text-gray-800">Set Up Your First Question</h2>
        <p className="text-sm text-gray-600 mt-2">Add a question, upload an image, define marks, and set a rubric—all in a few clicks!</p>
        <button
          className="mt-4 px-4 py-2 rounded-md bg-[#6750A4] text-white text-sm font-medium transition hover:bg-indigo-700"
          onClick={handleOnClick}
        >
          Add a Question
        </button>
      </div>
    </>
  );
};

export default SetQuestionSetCardCard;
