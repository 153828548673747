import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../Sidebar";
import PractiseAttemptTable from "./PractiseAttemptTable";
import PractiseSessions from "./PractiseSessions";
import SummarySessionCard from "./SummarySessionCard";

function PracticeAttemptDetailsPage() {
  const location = useLocation();
  const { subjectId, subjectName } = location.state;
  // const { subjectId, subjectName, accountStatus } = location.state;
  const [currentView, setCurrentView] = useState("attempts"); // attempts, sessions, summary
  const [sessionList, setSessionsList] = useState([]);
  const [sessionSummary, setSessionSummary] = useState([]);
  const navigate = useNavigate();
  const [email, setEmail] = useState();

  const [dataList, setDataList] = useState([
    { id: "01", student_name: "Sohiab", student_email: "sohaib@highersummit.com" },
    { id: "02", student_name: "Sohiab", student_email: "sohaib@highersummit.com" },
  ]);

  useEffect(() => {
    getSubjectStudents();
  }, []);

  const getSubjectStudents = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_all_students",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: JSON.stringify({
          subject_id: subjectId,
          // account_status: accountStatus,
          trial_start_date: moment().format("Y-MM-DD HH:mm:ss"),
        }),
      })
      .then((res) => {
        setDataList(res.data.data);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const getStudentPracticeSessiosn = async (studentEmail) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    await axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_student_sessions",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: JSON.stringify({
          subject_id: subjectId,
          student_email: studentEmail,
        }),
      })
      .then((res) => {
        setSessionsList(res.data.sessions);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const getSessionSummary = async (studentResponseId) => {
    // var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    // axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    await axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_session_summary",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: JSON.stringify({
          student_response_id: studentResponseId,
        }),
      })
      .then((res) => {
        console.log("RESPONSE Session Summary: ", res.data);
        setSessionSummary(res.data.summary);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const handleBackNavigation = () => {
    // getSessionSummary(168);

    if (currentView === "sessions") {
      setCurrentView("attempts");
    } else if (currentView === "summary") {
      setCurrentView("sessions");
    } else {
      navigate(-1);
    }
  };

  const handleViewPracticeSession = (studentEmail) => {
    getStudentPracticeSessiosn(studentEmail);
    setEmail(studentEmail);
    setCurrentView("sessions");
  };

  const handleViewSummary = (studentResponseId) => {
    window.scrollTo(0, 0);
    getSessionSummary(studentResponseId);
    setCurrentView("summary");
  };

  return (
    <div>
      <div>
        <Sidebar />
      </div>
      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px]">
        <div>
          <div className={`w-full p-[1.5rem] bg-[#F7F2FA] font-medium text-[32px] text-center tracking-[0.15px]`}>{subjectName}</div>
        </div>

        {/* {trialObject !== undefined && Object.keys(trialObject).length && (
          <SubscriptionExpirationBanner setOpen={state.setOpenBuyVoucherPopUp} bannerObject={trialObject} subjectData={subjectData.subject} />
        )} */}

        <div className="home !text-[#6750A4] sm:ml[25px] md:ml-[60px] underline teacher_navigation_text mt-[1.5vw]">
          <button className="" onClick={handleBackNavigation}>
            {"Back"}
          </button>
        </div>
        {currentView === "attempts" ? (
          <PractiseAttemptTable dataList={dataList} handleViewPracticeSession={handleViewPracticeSession} />
        ) : currentView === "sessions" ? (
          <PractiseSessions sessionList={sessionList} handleViewSummary={handleViewSummary} />
        ) : (
          currentView === "summary" && (
            <div className="w-full bg-white">
              <SummarySessionCard email={email} sessionSummary={sessionSummary} />
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default PracticeAttemptDetailsPage;
