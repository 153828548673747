import React, { useState } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled as styleds } from "@mui/material/styles";

const WelcomeStepsIncompletedCard = ({ title, subtitle, buttonText, icon, buttonStyle, onClick, tooltipContent }) => {
  const [open, setOpen] = useState(false);
  const HtmlTooltip = styleds(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        color: "#49454F",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "20px",
        borderRadius: "8px",
        opacity: "0.8",
        backgroundColor: "#FFF",
        padding: "12px 16px",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 330,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
      },
      [`& .${tooltipClasses.arrow}`]: {
        color: "#FFF",
        "&:before": {
          border: "1px solid #dadde9",
        },
      },
    })
  );

  const handleClick = () => {
    setOpen(false); // Close tooltip
    if (onClick) {
      onClick(); // Call the passed onClick function
    }
  };

  return (
    <div className="py-[1rem] w-[24.375rem]">
      <div className="flex">
        <div className="justify-start pr-[0.65rem] pt-[0.15rem] ">
          <img src={icon} className="max-w-fit" />
        </div>
        <div>
          <h3 className="text-[20px] leading-[28px] font-semibold">{title}</h3>
          <p className="text-[14px] leading-[20px] tracking-[0.1px] text-[#49454F]">{subtitle}</p>
        </div>
      </div>
      <HtmlTooltip
        enterTouchDelay={0}
        leaveTouchDelay={10}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        title={<p className="font-medium text-[14px] leading-5 tracking-[0.014rem]">{tooltipContent}</p>}
      >
        <button
          className={`w-full py-2 mt-[1.5rem] mb-[1rem] rounded-lg ${buttonStyle} transition create-quiz-btn`}
          onClick={handleClick}
          onMouseLeave={() => setOpen(false)}
        >
          {buttonText}
        </button>
      </HtmlTooltip>
    </div>
  );
};

export default WelcomeStepsIncompletedCard;
