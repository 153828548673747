import axios from "axios";
import { REACT_APP_REST_API_BASE_URL, REACT_APP_CREATE_PRICING_END_POINT } from "../../Constants/ApiConstant";

const useCreatePricing = () => {
  const createPricing = (number_of_students, price_per_student, isSubmitting, setShow) => {
    const access_token = "Bearer " + localStorage.getItem("access_token");

    axios
      .post(
        REACT_APP_REST_API_BASE_URL + REACT_APP_CREATE_PRICING_END_POINT,
        {
          number_of_students: number_of_students,
          price_per_student: price_per_student,
        },
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: access_token,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        // console.log("data here ", response.data);
        isSubmitting.current = false;
        setShow(true);
      })
      .catch((error) => {
        console.log(error);
        isSubmitting.current = false;
        alert("not created");
      });
  };

  return createPricing;
};

export default useCreatePricing;
