import React, { useState, useRef } from "react";
import FeedbackDisplay from "./FeedbackDisplay";
import Lottie from "react-lottie";
import * as animationData from "../../../../images/loading2.json";
import CodeEditor from "../../../widgets/CodeEditor";
import extractQuestionText, { ExtractTableData } from "../../../Helper/RemoveHTMLTags";
import EditableTable from "../../../Teacher/widgets/EditableTable";

const QuestionDisplay = ({
  question,
  questionNumber,
  answer,
  setAnswer,
  analyzing,
  onSubmit,
  feedback,
  marks,
  onEnd,
  prompts,
  onNext,
  mockbotStudentId,
  setQuestion,
  topic,
  tableRef,
  tableDataRef,
  //   onSkip,
  //   modelAnswer,
}) => {
  const [showFeedback, setShowFeedback] = useState(false);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef(null);

  function removeHtmlTags2(str) {
    if (str === null || str === "") return "";
    str = str.toString();

    // Step 1: Replace <br> and <p> with newlines to preserve line breaks
    str = str.replace(/<br\s*\/?>|<\/p>/g, "\n").replace(/<p[^>]*>/g, "");

    // Remove other HTML tags
    str = str.replace(/<\/?[^>]+(>|$)/g, "");

    // Wrap text between ##code markers in <pre><code> tags
    str = str.replace(/##code([\s\S]*?)##code/g, "<pre><code>$1</code></pre>");

    return str;
  }

  const handleSubmit = async () => {
    await onSubmit();
    setShowFeedback(true);
    // amplitude.getInstance().logEvent("Question Attempted");
    // ReactGA.event("Submit button", {
    //   submit_question: "Q" + questionNumber + " " + "Submit", // 'subject_name' as a custom parameter
    //   // Include any other custom parameters here
    // });
  };

  const handleNextQuestion = () => {
    setLoading(true);
    // // Hide feedback before moving to next question
    // amplitude.getInstance().logEvent("Viewed Next Question");
    // ReactGA.event("Next button", {
    //   next_question: "Q" + questionNumber + " " + "next", // 'subject_name' as a custom parameter
    //   // Include any other custom parameters here
    // });
    setTimeout(() => {
      setLoading(false);
      setShowFeedback(false);
      setAnswer("");
      onNext();
    }, 2000);
  };

  const handleSkip = () => {
    // onSkip();
    // ReactGA.event("Skipped button", {
    //   Skip_button: "Q" + questionNumber + " " + "skipped", // 'subject_name' as a custom parameter
    //   // Include any other custom parameters here
    // });
  };

  const handleEnd = () => {
    onEnd();
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  function stripPTags(str) {
    return str?.replace(/<p>/g, "");
  }

  const onChange2 = (newValue) => {
    setQuestion((prevState) => prevState.map((item, index) => (index === prevState.length - 1 ? { ...item, answer: newValue.trim() } : item)));
    setAnswer(newValue);
  };

  return (
    <div ref={containerRef} className="w-full flex flex-col items-center justify-center mt-[80px] mb-12">
      {question && (
        <div className="w-full p-2" style={{ maxWidth: "800px" }}>
          <div className="w-full mb-[20px]" style={{ display: "flex", alignItems: "flex-start" }}>
            <div style={{ flex: 1 }}>
              <p style={{ margin: 0, whiteSpace: "pre-line" }}>
                <b>Question {questionNumber}:</b>{" "}
                <span
                  dangerouslySetInnerHTML={{
                    // __html: stripPTags(question?.question.split("##code")[0]),
                    __html: extractQuestionText(question?.question),
                  }}
                ></span>
              </p>
            </div>
            <div style={{ marginLeft: "20px", alignSelf: "start" }}>
              <span className="font-bold">[ Marks : {question?.question_marks} ]</span>
            </div>
          </div>
          {question.image_file_name && (
            <div className="flex items-center justify-start mb-[20px]">
              <img className="images" alt="Question" src={process.env.REACT_APP_CDN_URL_FOR_QUESTION_IMAGES + question.image_file_name} />
            </div>
          )}

          {question.have_table && question.have_table === 1 ? (
            <EditableTable
              ref={tableRef}
              columnList={
                tableDataRef.current && tableDataRef.current.columns ? tableDataRef.current.columns : ExtractTableData(question.question).columnList
              }
              rowList={tableDataRef.current && tableDataRef.current.rows ? tableDataRef.current.rows : ExtractTableData(question.question).rowList}
              isEditable={true}
            />
          ) : question.have_code && question.have_code === 1 ? (
            <CodeEditor
              language="javascript" // Replace with the language you want or leave empty for generic text
              theme="monokai"
              name="code"
              initialCode={question.have_code === 0 ? "" : true}
              onChange={(newValue) => onChange2(newValue)}
              value={removeHtmlTags2(question.question.split("##code")[1])}
            />
          ) : (
            <textarea
              className="w-full text-black p-2 border rounded resize-none"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              placeholder="Enter your answer here"
              rows={6}
              disabled={showFeedback}
            ></textarea>
          )}
          <div className="flex justify-end my-2 gap-2">
            {!showFeedback && (
              <>
                <button
                  className="bg-[#6750A4] hover:bg-[#444780] hover:opacity-[0.7]  text-white font-bold py-2 px-4 rounded disabled:opacity-50 w-full"
                  onClick={handleSubmit}
                  disabled={analyzing || (answer === "" && !question.have_table)}
                >
                  Submit
                </button>
                {/* <button className="bg-[#322E33] bg-opacity-30 text-[purple]  py-2 px-4 rounded" style={{ fontFamily: "Roboto" }} onClick={handleSkip}>Skip</button> */}
                <button
                  className={`py-2 px-4 rounded font-medium text-sm ${
                    questionNumber === 1 ? "bg-gray-400 text-gray-700 opacity-50 cursor-not-allowed" : "bg-[#1C1B1F] bg-opacity-30 text-[#1C1B1F] "
                  }`}
                  disabled={questionNumber === 1}
                  onClick={handleEnd}
                >
                  End
                </button>
              </>
            )}
          </div>
          {analyzing && (
            <div className="flex justify-center items-center ">
              <div className="text-center">
                <div role="status">
                  <Lottie options={defaultOptions} height={256} width={256} />
                </div>
                <br />
                <div>“Please wait while we analyze your response...{analyzing}”</div>
              </div>
            </div>
          )}
          {showFeedback && !analyzing && (
            <FeedbackDisplay
              question={question.question}
              question_marks={question.question_marks}
              rubric={question.rubric}
              answer={answer}
              feedback={feedback}
              modelAnswer={question.answer}
              loading={loading}
              onEnd={handleEnd}
              onNext={handleNextQuestion}
              prompts={prompts}
              marks={marks}
              mockbotStudentId={mockbotStudentId}
              have_code={question.have_code}
              topic={topic}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default QuestionDisplay;
