import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import SubAccordions from "./SubAccordian";
import EdiText from "react-editext";
import Button from "@mui/material/Button";
import axios from "axios";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import "@material/react-radio/dist/radio.css";
import Radio from "@mui/material/Radio";
const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

// const AccordionSummary = styled((props) => (
//   <MuiAccordionSummary
//     expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
//     {...props}
//   />
// ))(({ theme }) => ({
//   backgroundColor:
//     theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "white",
//   flexDirection: "row-reverse",
//   "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
//     transform: "rotate(90deg)",
//   },
//   "& .MuiAccordionSummary-content": {
//     marginLeft: theme.spacing(1),
//   },
// }));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const onSaveChapter = (value, chapter, quiz_type) => {
  console.log("sdadad", quiz_type);
  chapter.chapter_name = value;
  var token = "Bearer " + localStorage.getItem("access_token");
  axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
  axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.post["authorization"] = token;
  axios
    .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_chapter", {
      method: "POST",
      chapter: chapter,
      quiz_type: quiz_type,
    })

    .then((response) => {
      // var subjects_list = JSON.parse(
      //   response.data["subject"]
      // );
      //console.log(subjects_list);
      // setSubjectData(subjects_list)
    })
    .catch((error) => {
      console.log(error);
      alert("unable to update subject");
    });
};

const onChangeAiEnabledDisabledChapter = (value, chapter, addTodo, quiz_type) => {
  chapter.ai_enabled = parseInt(value);
  var token = "Bearer " + localStorage.getItem("access_token");
  axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
  axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.post["authorization"] = token;
  axios
    .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_chapter", {
      method: "POST",
      chapter: chapter,
      quiz_type: quiz_type,
    })

    .then((response) => {
      addTodo();
      // var subjects_list = JSON.parse(
      //   response.data["subject"]
      // );

      //console.log(subjects_list);

      // setSubjectData(subjects_list)
    })
    .catch((error) => {
      console.log(error);
      alert("unable to update subject");
    });
};

const addNewTopic = (chapter, addTodo) => {
  // alert(subject.subject_name);

  var token = "Bearer " + localStorage.getItem("access_token");
  axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
  axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.post["authorization"] = token;
  axios
    .post(process.env.REACT_APP_REST_API_BASE_URL + "/create_new_topic", {
      method: "POST",
      chapter_id: chapter.chapter_id,
    })

    .then((response) => {
      addTodo();
    })
    .catch((error) => {
      console.log(error);
      alert("unable to add new topic");
    });
};

export default function TopicAccordion(props) {
  // const [expanded, setExpanded] = React.useState("");

  // const handleChange = (panel) => (event, newExpanded) => {
  //   setExpanded(newExpanded ? panel : false);
  // };

  const [expand, setExpand] = React.useState(false);
  const toggleAcordion = () => {
    setExpand((prev) => !prev);
  };

  const [selectedValue, setSelectedValue] = React.useState(props.title.ai_enabled);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    onChangeAiEnabledDisabledChapter(event.target.value, props.title, props.addTodo, props.predefined_question_answer_status);
  };

  return (
    <div className="">
      <Accordion
        expanded={expand}
        // expanded={expanded === "panel1"}
        // onChange={handleChange("panel1")}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          IconButtonProps={{
            onClick: toggleAcordion,
          }}
          style={{
            backgroundColor: "rgba(255, 255, 255, .05)",
            flexDirection: "row-reverse",
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              transform: "rotate(90deg)",
            },
            "& .MuiAccordionSummary-content": {
              marginLeft: 0,
            },
            padding: 0,
          }}
        >
          <Typography className="w-full">
            {" "}
            <EdiText
              className="w-full"
              type="text"
              onSave={(value) => onSaveChapter(value, props.title, props.predefined_question_answer_status)}
              value={props.title.chapter_name}
            />
          </Typography>

          {/* <table className="w-full"><tr><td>
                                  <p className="mt-[10px]" style={{ fontSize: "16px", fontFamily: "Roboto", color: "#49454F", }}>
                                      <b>AI Based Question</b>
                                  </p>
                                  
                                  </td><td>
                                  
                                  <ReactSwitch
                                  // className="float-right"
                                    checked={props.title.ai_enabled}
                                    onChange={(value)=>onChangeAiEnabledDisabledChapter(value, props.title, props.addTodo)}
                                  />
                                  </td><td>
                                  <p className="mt-[10px]" style={{ fontSize: "16px", fontFamily: "Roboto", color: "#49454F", }}>
                                      <b>Predefined Question</b>
                                  </p>
                                  
                                  </td></tr></table> */}
          {/* <Radio label='AI Based Question' key='ai_based'>
                                    <NativeRadioControl
                                      
                                      // checked={props.title.ai_enabled}
                                      name='mode'
                                      value='1'
                                      id='ai_based_radio'
                                      onChange={(e) => {onChangeAiEnabledDisabledChapter(e.target.value, props.title, props.addTodo); }}
                                    />
                                  </Radio>
                                  <Radio label='Predefined Question' key='predefined'>
                                    <NativeRadioControl
                                      name='mode'
                                      value='0'
                                      // checked={props.title.ai_enabled}
                                      id='predefined_radio'
                                      onChange={(e) => {onChangeAiEnabledDisabledChapter(e.target.value, props.title, props.addTodo); }}
                                    />
                                  </Radio> */}

          {!props.teacherFlow && (
            <>
              <Radio label="AI Based Question" checked={props.title.ai_enabled === 1} onChange={handleChange} id="a" value={1} name="radio-buttons" />
              <div className="w-[240px] mt-[12px]">AI Based Question</div>

              <Radio
                label="Predefined Question"
                inputProps={{
                  "aria-label": "Radio A",
                }}
                id="b"
                checked={props.title.ai_enabled === 0}
                onChange={handleChange}
                value={0}
                name="radio-buttons"
              />
              <div className="w-[240px] mt-[12px]">Predefined Question</div>
            </>
          )}

          <Button style={{ float: "right" }} onClick={() => props.onDeleteClick(props.title, process.env.REACT_APP_DELETING_OBJECT_TYPE_CHAPTER)}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              <b>Subtopic: </b>
              {JSON.parse(props.topics_list)?.map((topic, subIopicIndex) => (
                <Fragment key={subIopicIndex}>
                  <div>
                    <p
                      style={{
                        fontSize: "14px",
                        fontFamily: "Roboto",
                        color: "#49454F",
                      }}
                    >
                      {
                        <SubAccordions
                          addTodo={props.addTodo}
                          title={topic}
                          chapter={props.title}
                          subject={props.subject}
                          has_sub_topics={props.has_sub_topics}
                          onDeleteClick={props.onDeleteClick}
                          sub_topics_list={topic["sub_topics_list"]}
                          predefined_question_answer_status={props.predefined_question_answer_status}
                          predefinedQuestionsPromptList={props.predefinedQuestionsPromptList}
                        />
                      }
                    </p>
                  </div>
                </Fragment>
              ))}
              <Button
                className="delete-button"
                style={{ color: "#1976d2", padding: "6px 8px" }}
                onClick={() => addNewTopic(props.title, props.addTodo)}
              >
                Add New Subtopic
              </Button>
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
