import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import Sidebar from "../Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import SubscriptionExpirationBanner from "../Teacher/widgets/SubscriptionExpirationBanner";
import ClassroomDetailBanner from "../Teacher/widgets/ClassroomDetailBanner";
import AnswerAccordian from "../AnswerAccordian";
import Button from "@mui/material/Button";
import BackArrow from "../../images/icon.svg";
import magicianImage from "../../images/magician.gif";
import infoLogo from "../../images/info.png";
import ReplaceLogo from "../../images/replace.png";
import { FiEdit, FiEye, FiShare2 } from "react-icons/fi";
import axios from "axios";
import { useForm } from "react-hook-form";
import amplitude from "amplitude-js";
import ReplaceDialogue from "./ReplaceDialogue";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import QuizSetting from "./QuizSetting";
import ConfirmationDialogue from "../Teacher/confirmationdialogue/ConfirmationDialogue";
import AcknowledgementDialogue from "../Teacher/confirmationdialogue/AcknowledgementDialogue";
import QuizRawResponseAccordian from "../QuizRawResponseAccordian";
import DashboardLabel from "../Teacher/widgets/DashboardLabel";
import Setting from "../../images/settings.png";
import UpgradePlan from "../Subscription/UpgradePlan";
import CodeEditor from "./CodeEditor";
import moment from "moment";
import appContext from "../../context/appContext";
import TrialPeriodExpiredPopup from "../Teacher/outsideClassQuizGeneration/TrialExpiredPopUp";
import SubscriptionPeriodExpiredPopup from "../Teacher/outsideClassQuizGeneration/SubscriptionPeriodExpire";
import ShareQuizUrlDialogue from "../Teacher/outsideClassQuizGeneration/ShareQuizUrlDialogue";
import TrialPeriodAcknowledgmentPopupOutsideClass from "../Teacher/outsideClassQuizGeneration/TrialPeriodAcknowledgmentPopupOutsideClass";
import TaskLimitReachedPopup from "../Teacher/TrialPeriod/TaskLimitReachedPopUp";
import OnboardingTour from "./onboarding_tour/OnboardingTour";
import OnboardingTourLastSlide from "./onboarding_tour/OnboardingTourLastSlide";
import EditableTable from "../Teacher/widgets/EditableTable";
import extractQuestionText, { ExtractTableData } from "../Helper/RemoveHTMLTags";
const QuestionConfigurator = () => {
  const location = useLocation();
  const state = useContext(appContext);
  const [counts, setCounts] = useState([0, 0, 0, 0]);
  const [open2, setOpen2] = useState(false);
  const [buttonEvent, setButtonEvent] = useState();
  const [questionsCount, setQuestionsCount] = useState([]);
  const [response_data, setResponseData] = useState([]);
  const [replaceQuestionIndex, setReplaceQuestionIndex] = useState();
  const [openReplaceDialogue, setOpenReplaceDialogue] = React.useState(false);
  const [openViewLogsDialog, setOpenViewLogsDialog] = React.useState(false);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [quizTiming, setQuizTiming] = useState(0);
  const [openShareQuizConfirmationDialogue, setOpenShareQuizConfirmationDialogue] = useState(false);
  const [openShareQuizConfirmationDialogueOutsideClassroom, setOpenShareQuizConfirmationDialogueOutsideClassroom] = useState(false);
  const [isSubmittingShare, setIsSubmittingShare] = useState(false);
  const [isPreviewingQuiz, setIsPreviewingQuiz] = useState(false);
  const isPreviewButtonPressed = useRef(false);
  const [isQuizSaved, setIsQuizSaved] = useState(false);
  const [quizSettingDialogue, setQuizSettingsDialogue] = useState(false);
  const [configureToggle, setConfigureToggle] = useState(false);
  const [updateQuizSettingPopUp, setUpdateQuizSettingPopUp] = useState(false);
  const [quizDurationSetting, setQuizDurationSetting] = useState(false);
  const [graceDuration, setGraeDuration] = useState(0);
  const [graceDurationToggle, setGraceDurationToggle] = useState(false);
  const [autoSubmissionToggle, setAutoSubmitionToggle] = useState(false);
  const [hideAnswerToggle, setHideAnswerToggle] = useState(false);
  const [shuffleQuestionToggle, setShuffleQuestionToggle] = useState(false);
  const [AIQuestionToggle, setAIQuestionToggle] = useState(false);
  const [openShareQuizAcknowledgementDialogue, setOpenShareQuizAcknowledgementDialogue] = useState(false);
  const [errorOnShareQuiz, setErrorOnShareQuiz] = React.useState(false);
  const [openDeleteAcknowledgementDialgoue, setOpenDeleteAcknowledgementDialgoue] = useState();
  const [isAnswerRubric, setIsAnswerRubric] = useState();
  const [openTrialPeriodAcknowledgmentPopupOutsideClass, setTrialPeriodAcknowledgmentPopupOutsideClass] = useState(false);
  const alternativeShareQuizDialogFlow = useRef(false);
  let { formativeQuizTypeStatus } = location.state !== null ? location.state : "";
  let { hasMockExams } = location.state !== null ? location.state : "";
  let { hasExamStylePrompt } = location.state !== null ? location.state : "";
  const { chapters } = location?.state;
  const [navigatedFromOutsideClassroom, setNavigatedFromOutsideClassroom] = useState();
  const isOwnDBSelected = useRef();
  const [bannerObject, setBannerObject] = useState({});

  const [quizDetails, setQuizDetails] = useState({
    chapter_id: -1,
    topic_id: [],
    subTopic: [],
    numOfQuestions: 0,
    quizType: formativeQuizTypeStatus ? 1 : hasMockExams ? 2 : hasExamStylePrompt ? 3 : 3,
  });
  const [mock_exam, setMockExam] = useState(null);
  const [quizDuration, setQuizDuration] = useState(1);
  const [selectedQuizType, setSelectedQuizType] = useState(formativeQuizTypeStatus ? 1 : hasExamStylePrompt ? 3 : hasMockExams ? 2 : 3);
  const [selectedPaperCategory, setSelectedPaperCategory] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openDeleteQuizConfirmationDialogue, setOpenDeleteQuizConfirmationDialogue] = useState(false);
  const [selectedQuestionForReplacement, setSelectedQuestionForReplacement] = useState();

  const question_answers = useRef([]);
  const creation_tokens = useRef(0);
  const quiz_generation_ids_list = useRef([]);
  const lockFields = useRef(false);
  const blackListedQuestionArray = useRef([]);
  const blackListedQuestionsToAvoid = useRef([]);
  const parentRef = useRef();
  const grandchildRef = useRef();
  const replacedQuestionIndexStatus = useRef([]);
  const saveQuizDataBody = useRef();

  const userType = localStorage.getItem("user_type");
  const { sub_topic_ids_csv } = location?.state;
  const navigate = useNavigate();
  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;
  let { selectedSubject } = location.state !== null ? location.state : "";
  const selectedQuizTypeId = useRef(formativeQuizTypeStatus.current ? 1 : hasMockExams ? 2 : hasExamStylePrompt ? 3 : null);
  let { data } = location.state !== null ? location.state : "";
  const secretKey = useRef();
  const [trialPeriodTrackingId, setTrialPeriodTrackingId] = useState(null);
  const haltTourFlow = useRef(true);

  let { topic_id } = location?.state;
  const AccountStatus = {
    TRIAL_ACTIVE: 1,
    TRIAL_EXPIRED: 2,
    SUBSCRIPTION_ACTIVE: 3,
    SUBSCRIPTION_EXPIRED: 4,
    SPECIAL_CONDITION: 5,
  };

  const handleAccontStatus = (status, object) => {
    if (AccountStatus.TRIAL_ACTIVE === status) {
      return 1;
    } else if (AccountStatus.TRIAL_EXPIRED === status) {
      return 1;
    } else if (AccountStatus.SUBSCRIPTION_ACTIVE === status) {
      return 3;
    } else if (AccountStatus.SPECIAL_CONDITION === status) {
      if (object.subscription_days_left) {
        return 3;
      } else {
        return 1;
      }
    }
  };

  const search = window.location.search;
  const params = new URLSearchParams(search);
  var id = params.get("id");

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setBannerObject(location.state && location.state.bannerObject && location.state.bannerObject);
    if (location?.state?.navigatedFromOutsideClassroom) {
      setNavigatedFromOutsideClassroom(location.state.navigatedFromOutsideClassroom);
      isOwnDBSelected.current = location.state.isOwnDBSelected;
    }
    scrollToTop();
    getQuestionCategoryCount();
  }, []);

  const getQuestionCategoryCount = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    const subTopicIdsCsv = sub_topic_ids_csv.join(",");
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + (isOwnDBSelected.current ? "get_question_category_web_user" : "/get_question_category"), {
        method: "POST",
        area_ids_csv: subTopicIdsCsv,
        code_status: location.state?.is_code,
        trial_start_date: moment().format("Y-MM-DD HH:mm:ss"),
        subject_id: location.state.subjectData.subject_id,
      })

      .then((response) => {
        // console.log("API RESPONSE: ", response.data);
        setQuestionsCount(response.data);
        // console.log("RESONSE from /get_question_category: ", response.data);
        // console.log("RESONSE trial Data from /get_question_category: ", response.data.subject_trial.trial_data);
        setBannerObject(response.data.subject_trial && response.data.subject_trial.trial_data && response.data.subject_trial.trial_data);
        setTrialPeriodTrackingId(response.data.subject_trial.trial_data.trial_period_tracking_id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCountChange = (index, delta, type) => {
    const newCounts = [...counts];
    newCounts[index] = Math.max(0, Math.min(handleMaxQuestionValue(type), newCounts[index] + delta)); // Ensure the count is within bounds
    setCounts(newCounts);
  };

  const questionTypes = [
    // { label: "Quiz: multiple choice select questions", color: "bg-[#15906E]", type: 1 },
    { label: `Exam-Style: ${location.state?.is_code === 0 ? "1-3 Marks" : "7 Marks"} `, color: "bg-[#1F5490]", type: 2 },
    { label: `Exam-Style: ${location.state?.is_code === 0 ? "4-7 Marks" : "8 Marks"} `, color: "bg-[#5E2750]", type: 3 },
    { label: `Exam-Style: ${location.state?.is_code === 0 ? "8 Marks" : "15 Marks"}`, color: "bg-[#CD1E41]", type: 4 },
  ];

  const handleOnClick = () => {
    if (location.state) {
      if (location.state.url) {
        console.log("BUTTON EVENT", buttonEvent);
        if (buttonEvent === "2") {
          localStorage.setItem("teacher_table_state", 2);
        } else {
          localStorage.setItem("teacher_table_state", 1);
        }
        window.location = location.state.url;
      }
    }
  };

  const handleMaxQuestionValue = (type) => {
    // console.log("TYPE: ", type);
    if (type === 1) {
      return 172;
    } else if (type === 2) {
      return questionsCount.one_to_three;
    } else if (type === 3) {
      return questionsCount.four_to_seven;
    } else if (type === 4) {
      return questionsCount.eight_plus;
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleClickScroll = () => {
    const element = document.getElementById("quiz-section");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  // function removeHtmlTags(str) {
  //   if (str === null || str === "") return false;
  //   else str = str.toString();

  //   // Regular expression to identify HTML tags in the string
  //   return str.replace(/<p>/g, "");
  // }

  function removeHtmlTags2(str) {
    if (str === null || str === "") return "";
    str = str.toString();

    // Step 1: Replace <br> and <p> with newlines to preserve line breaks
    str = str.replace(/<br\s*\/?>|<\/p>/g, "\n").replace(/<p[^>]*>/g, "");

    // Remove other HTML tags
    str = str.replace(/<\/?[^>]+(>|$)/g, "");

    // Wrap text between ##code markers in <pre><code> tags
    str = str.replace(/##code([\s\S]*?)##code/g, "<pre><code>$1</code></pre>");

    return str;
  }

  const removeQuestion = (index) => {
    var indexArray = [...replacedQuestionIndexStatus.current];
    if (index !== -1) {
      indexArray.splice(index, 1);
      replacedQuestionIndexStatus.current = indexArray;
    }
    var array = [...response_data]; // make a separate copy of the array

    //var index = e.target.value;
    if (index !== -1) {
      array.splice(index, 1);
      setResponseData(array);
    }
  };
  const handleClickOpen = (index, selectedQuestion) => {
    setSelectedQuestionForReplacement(selectedQuestion);
    setReplaceQuestionIndex(index);
    setOpenReplaceDialogue(true);
  };
  const handleClose = () => {
    setOpenReplaceDialogue(false);
  };

  const replaceQuestion = (index, newQuestion) => {
    if (replacedQuestionIndexStatus.current.length === 0) {
      for (let index = 0; index < response_data.length; index++) {
        replacedQuestionIndexStatus.current.push(0);
      }
    }
    replacedQuestionIndexStatus.current[index] = 1;
    var array = [...response_data];
    if (index !== -1) {
      array[index] = newQuestion;
      setResponseData(array);
    }
  };

  const handleRawResponseClick = (secret_key) => {
    setOpenViewLogsDialog(true);
  };

  const handleOpenShareQuizConfirmationDialogue = (e) => {
    setButtonEvent(e);
    if (navigatedFromOutsideClassroom) {
      isPreviewButtonPressed.current = false;
      handleClickOnShareWithStudentBtn(e);
    } else {
      setOpenShareQuizConfirmationDialogue(true);
    }
  };

  const handlePreviewQuiz = (e) => {
    setButtonEvent(e);
    isPreviewButtonPressed.current = true;
    handleClickOnShareWithStudentBtn(e);
  };

  const handleOpenDeleteQuizConfirmationDialgue = () => {
    setOpenDeleteQuizConfirmationDialogue(true);
  };

  const handleCloseQuizSettingDialogue = () => {
    setQuizSettingsDialogue(false);
    setConfigureToggle(false);
    if (id !== null) {
      handleOpenUpdateQuizSetting();
    }
  };

  const handleOpenUpdateQuizSetting = () => {
    setUpdateQuizSettingPopUp(true);
  };

  const handleChangeSetting = (swictType, value) => {
    if (swictType === 1) {
      if (value) {
        amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
        amplitude.getInstance().logEvent("QuizDuration");
      }
      setQuizDurationSetting(value);
      setQuizTiming(5);
      if (value === false) {
        setQuizTiming(0);
        setGraeDuration(0);
        setGraceDurationToggle(false);
        setAutoSubmitionToggle(false);
      }
      // changeIndividualQuizSettings()
    }
    if (swictType === 2) {
      if (value) {
        amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
        amplitude.getInstance().logEvent("SubmissionGracePeriod");
      }
      setGraceDurationToggle(value);
      setGraeDuration(1);
      if (value === false) {
        setGraeDuration(0);
      }
      // changeIndividualQuizSettings()
    }
    if (swictType === 3) {
      if (value) {
        amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
        amplitude.getInstance().logEvent("AutoSubmission");
      }
      setAutoSubmitionToggle(value);
      // changeIndividualQuizSettings()
    }
    if (swictType === 4) {
      if (value) {
        amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
        amplitude.getInstance().logEvent("HideAnswers");
      }
      setHideAnswerToggle(value);
      // changeIndividualQuizSettings()
    }
    if (swictType === 5) {
      setConfigureToggle(value);
      if (value) {
        axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
        axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
        axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
        var token = "Bearer " + localStorage.getItem("access_token");
        axios.defaults.headers.post["authorization"] = token;

        axios
          .post(process.env.REACT_APP_REST_API_BASE_URL + "/set_class_quiz_setting", {
            method: "POST",
            class_id: location.state.classroomData ? location.state.classroomData.classroom_id : null,
            quiz_type: selectedQuizType,
            quiz_time_duration: quizDurationSetting ? quizTiming : 0,
            quiz_grace_time: graceDurationToggle ? graceDuration : 0,
            quiz_auto_submission: autoSubmissionToggle,
            quiz_instant_feedback_status: hideAnswerToggle,
            shuffle_quiz_questions: shuffleQuestionToggle,
          })

          .then((response) => {
            console.log(response);
            amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
            amplitude.getInstance().logEvent("SaveDefaultSettings");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    if (swictType === 6) {
      if (value) {
        amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
        amplitude.getInstance().logEvent("ShuffleQuestions");
      }
      setShuffleQuestionToggle(value);
    }
    if (swictType === 7) {
      if (value) {
        amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
        amplitude.getInstance().logEvent("ShuffleQuestions");
      }
      setAIQuestionToggle(value);
    }
  };

  const onIncDecClickQuizDuration = (value) => {
    console.log("value is: ", value);
    setQuizTiming(value);
  };
  const onIncDecClickQuizGraceDuration = (value) => {
    setGraeDuration(value);
  };
  const handleCloseShareQuizConfirmationDialogueWithCancel = () => {
    setOpenShareQuizConfirmationDialogue(false);
  };

  const handleCloseShareQuizConfirmationDialogueWithConfirm = () => {
    handleClickOnShareWithStudentBtn(buttonEvent);
    setOpenShareQuizConfirmationDialogue(false);
  };

  const handleOpenShareQuizAcknowledgementDialogue = () => {
    setOpenShareQuizAcknowledgementDialogue(true);
  };
  const handleCloseShareQuizAcknowledgementDialogue = () => {
    setOpenShareQuizAcknowledgementDialogue(false);
    handleOnClick();
  };
  const handleCloseDeleteQuizConfirmationDialgueWithCancel = () => {
    setOpenDeleteQuizConfirmationDialogue(false);
  };
  const handleCloseDeleteQuizConfirmationDialgueWithConfirm = () => {
    deleteDraftQuiz();
    setOpenDeleteQuizConfirmationDialogue(false);
  };

  const handleClickOnShareWithStudentBtn = async (e) => {
    if (isPreviewButtonPressed.current) {
      setIsPreviewingQuiz(true);
    } else {
      setIsSubmittingShare(true);
    }

    if (id != null && id.length > 0 && location.state) {
      axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
      axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
      axios
        .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_status_of_quiz", {
          method: "POST",
          quiz_id: response_data[0].quiz_id,
        })
        .then((response) => {
          console.log("RESPONSE IS: ", response.data);
          localStorage.setItem("teacher_table_state", e);
          handleOpenShareQuizAcknowledgementDialogue();
        })
        .catch((error) => {
          console.log(error);
        });
      return;
    }

    // e.preventDefault();
    if (id != null && id.length > 0) {
      navigate("/share?id=" + id);
      return;
    }

    let dt;

    if (selectedQuizType === 1 || selectedQuizType === 3) {
      console.log("SUBJECT ID: ", location.state.subjectData);
      dt = {
        quiz_name: data.quizName,
        topic_id: topic_id,
        question_answers: response_data,
        sub_topic_ids_csv: sub_topic_ids_csv.current,
        creation_tokens_count: creation_tokens.current,
        quiz_generation_ids_list: quiz_generation_ids_list.current,
        sso_required: false,
        quiz_type: 3,
        folder_id: data.folder_id,
        class_id: location.state.classroomData ? location.state.classroomData.classroom_id : null,
        quiz_status: e ? e : 1,
        quiz_instant_feedback_status: hideAnswerToggle,
        quiz_duration: quizTiming,
        quiz_grace_time: graceDuration,
        quiz_auto_submission: autoSubmissionToggle,
        shuffle_quiz_questions: shuffleQuestionToggle,
        subject_id: location.state.subjectData.subject_id,
        account_status: bannerObject ? handleAccontStatus(bannerObject.account_status, bannerObject) : null,
      };
    } else if (selectedQuizType === 2) {
      dt = {
        quiz_name: data.quizName,
        // subject_id: localStorage.getItem("selected_subject_id"),
        mock_exam_id: mock_exam.mock_exam_id,
        sso_required: false,
        quiz_type: 3,
        quiz_generation_ids_list: quiz_generation_ids_list.current,
        quiz_duration: quizDuration,
        folder_id: data.folder_id,
        class_id: location.state.classroomData ? location.state.classroomData.classroom_id : null,
        quiz_status: e ? e : 1,
        quiz_instant_feedback_status: hideAnswerToggle,
        quiz_duration: quizTiming === 0 ? mock_exam.mock_exam_duration : quizTiming,
        quiz_grace_time: graceDuration,
        quiz_auto_submission: autoSubmissionToggle,
        shuffle_quiz_questions: shuffleQuestionToggle,
        subject_id: location.state.subjectData
          ? location.state.subjectData.subject_id
          : localStorage.getItem("selected_subject_id")
          ? localStorage.getItem("selected_subject_id")
          : null,
        account_status: bannerObject ? handleAccontStatus(bannerObject.account_status, bannerObject) : null,
      };
    }

    if (!navigatedFromOutsideClassroom) {
      callSaveQuizApi(e, dt);
    } else {
      // Calling start_trial_period_quiz
      const responseData = await callStartTrialPeriodQuizApi();

      // Handle multiple cases to tackle wheather to show banners or not.
      if (responseData) {
        dt = { ...dt, account_status: handleAccontStatus(responseData.trial_data.account_status, responseData.trial_data) };
        localStorage.setItem("daysLeft", responseData.trial_data.days_left);
        saveQuizDataBody.current = dt;
        setTrialPeriodTrackingId(responseData.trial_data.trial_period_tracking_id);
        // const canShare =

        handleConditionsOfStartTrial(responseData);
      }
    }
    setIsPreviewingQuiz(false);
    setIsSubmittingShare(false);
  };

  const handleConditionsOfStartTrial = (responseData) => {
    const quizzesLeft =
      responseData.trial_data.account_status === AccountStatus.TRIAL_EXPIRED ||
      (!responseData.trial_data.subscription_days_left &&
        responseData.trial_data.account_status === AccountStatus.SPECIAL_CONDITION &&
        responseData.trial_data.days_left <= 0) ||
      responseData.trial_data.account_status === AccountStatus.SUBSCRIPTION_EXPIRED
        ? 0
        : responseData.trial_data.account_status === AccountStatus.TRIAL_ACTIVE ||
          (!responseData.trial_data.subscription_days_left &&
            responseData.trial_data.account_status === AccountStatus.SPECIAL_CONDITION &&
            responseData.trial_data.days_left > 0)
        ? process.env.REACT_APP_TRIAL_QUIZ - responseData.trial_data.quiz_count
        : process.env.REACT_APP_SUBSCRIPTION_QUIZ - responseData.trial_data.quiz_count_subscribed;

    if (responseData.state === 1) {
      alternativeShareQuizDialogFlow.current = true;
      callSaveQuizApi("1", saveQuizDataBody.current);
      handleOpenTrialPeriodAcknowledgmentPopupOutsideClass();
    } else {
      if (
        (responseData.trial_data.account_status === AccountStatus.TRIAL_ACTIVE && responseData.trial_data.days_left > 0) ||
        (responseData.trial_data.account_status === AccountStatus.SPECIAL_CONDITION &&
          !responseData.trial_data.subscription_days_left &&
          responseData.trial_data.days_left > 0)
      ) {
        if (quizzesLeft > 0) {
          alternativeShareQuizDialogFlow.current = false;
          callSaveQuizApi("1", saveQuizDataBody.current);
        } else {
          state.handleOpenTaskLimitReachedPopup();
        }
        console.log(`Your trial for  will expire in ${responseData.trial_data.days_left} days.`);
      } else if (
        responseData.trial_data.account_status === AccountStatus.TRIAL_EXPIRED ||
        (!responseData.trial_data.subscription_days_left &&
          responseData.trial_data.account_status === AccountStatus.SPECIAL_CONDITION &&
          responseData.trial_data.days_left <= 0) ||
        (!responseData.trial_data.subscription_days_left && responseData.trial_data.quiz_count >= process.env.REACT_APP_TRIAL_QUIZ)
      ) {
        state.handleOpenTrialExpiredPopup();
        console.log(`Your trial for  has expired!`);
      } else if (responseData.trial_data.account_status === AccountStatus.SUBSCRIPTION_EXPIRED) {
        state.handleOpenSubscriptionExpiredPopup();
        console.log(`Your subscription for  has expired!`);
      } else if (
        responseData.trial_data.account_status === AccountStatus.SUBSCRIPTION_ACTIVE ||
        (responseData.trial_data.subscription_days_left &&
          responseData.trial_data.account_status === AccountStatus.SPECIAL_CONDITION &&
          responseData.trial_data.subscription_days_left > 0) ||
        responseData.trial_data.quiz_count_subscribed >= process.env.REACT_APP_SUBSCRIPTION_QUIZ
      ) {
        if (responseData.trial_data.quiz_count_subscribed <= process.env.REACT_APP_SUBSCRIPTION_QUIZ) {
          alternativeShareQuizDialogFlow.current = false;
          callSaveQuizApi("1", saveQuizDataBody.current);
        } else {
          state.handleOpenTaskLimitReachedPopup();
        }
      }
    }
  };

  const callStartTrialPeriodQuizApi = async () => {
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.headers.post["authorization"] = token;

    const response = await axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/start_trial_period_quiz", {
        method: "POST",
        subject_id: location.state.subjectData.subject_id,
        trial_start_date: moment().format("Y-MM-DD HH:mm:ss"),
        trial_end_date: moment().format("Y-MM-DD HH:mm:ss"),
        request_user: 1,
      })
      // .then((response) => {
      //   console.log("RESPONSE /start_trial_period_quiz: ", response);
      //   return response;
      // })
      .catch((err) => console.log("ERROR in /start_trial_period_quiz", err));
    return response.data;
  };

  const callSaveQuizApi = (e, body) => {
    if (!isQuizSaved) {
      axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
      axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
      var token = "Bearer " + localStorage.getItem("access_token");
      axios.defaults.headers.post["authorization"] = token;

      axios
        .post(process.env.REACT_APP_REST_API_BASE_URL + "/save_quiz", {
          method: "POST",
          body: body,
        })

        .then((response) => {
          console.log("RESPONSE OF /save_quiz: ", response);
          var id = response.data["secret_key"];
          secretKey.current = id;

          setIsSubmittingShare(false);
          setIsPreviewingQuiz(false);

          if (location.state.classroomData) {
            amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
            amplitude.getInstance().logEvent("ICSharetheQuizWithStudents", "User has pressed the share quiz button for quiz key: {" + id + "}");
          } else {
            amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
            amplitude.getInstance().logEvent("OCSharetheQuizWithStudents", "User has pressed the share quiz button for quiz key: {" + id + "}");
          }

          if (location.state.classroomData) {
            if (e === "2") {
              handleOpenShareQuizAcknowledgementDialogue();
              return;
            }
            handleOpenShareQuizAcknowledgementDialogue();
            return;
          }
          if (!navigatedFromOutsideClassroom) {
            secretKey.current = id;
            navigate("/share?id=" + id);
          } else {
            secretKey.current = id;
            setIsQuizSaved(true);
            if (!alternativeShareQuizDialogFlow.current && !isPreviewButtonPressed.current) {
              setOpenShareQuizConfirmationDialogueOutsideClassroom(true);
            } else if (!alternativeShareQuizDialogFlow.current && isPreviewButtonPressed.current) {
              window.open(`${window.location.origin + "/quiz?id=" + secretKey.current}`, "_blank");
            }
          }
        })
        .catch((error) => {
          console.log(error);
          setIsPreviewingQuiz(false);
          setIsSubmittingShare(false);
          setErrorOnShareQuiz(true);
        });
    } else {
      if (location.state.classroomData) {
        if (e === "2") {
          handleOpenShareQuizAcknowledgementDialogue();
          return;
        }
        handleOpenShareQuizAcknowledgementDialogue();
        return;
      }
      if (!navigatedFromOutsideClassroom) {
        navigate("/share?id=" + secretKey.current);
      } else {
        if (!alternativeShareQuizDialogFlow.current && !isPreviewButtonPressed.current) {
          setOpenShareQuizConfirmationDialogueOutsideClassroom(true);
        } else if (!alternativeShareQuizDialogFlow.current && isPreviewButtonPressed.current) {
          window.open(`${window.location.origin + "/quiz?id=" + secretKey.current}`, "_blank");
        }
      }
    }
  };

  const deleteDraftQuiz = () => {
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/delete_quiz_from_draft", {
        method: "POST",
        quiz_id: response_data[0].quiz_id,
      })
      .then((response) => {
        handleOpenDeleteAcknowledgementDialgoue();
        console.log("RESPONSE IS: ", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOpenDeleteAcknowledgementDialgoue = () => {
    setOpenDeleteAcknowledgementDialgoue(true);
  };
  const handleCloseDeleteAcknowledgementDialogue = () => {
    setOpenDeleteAcknowledgementDialgoue(false);
    window.location = location.state.url;
  };

  function submitForm(data) {
    if (counts[0] > 0 || counts[1] > 0 || counts[2] > 0) {
    } else {
      haltTourFlow.current = true;
      alert("Select at least one question to proceed");
      return;
    }
    haltTourFlow.current = false;
    question_answers.current = [];
    sub_topic_ids_csv.current = "";
    creation_tokens.current = "";
    quiz_generation_ids_list.current = [];
    setResponseData(question_answers.current);
    setMockExam(null);
    handleClickScroll();
    return new Promise((resolve) => {
      // if (selectedSubject.exam_paper_quiz_status === 0) {
      //   if (quizDetails.subTopic.length > 0 && quizDetails.numOfQuestions > 0 && quizDetails.numOfQuestions <= 10) {
      //   } else {
      //     if (selectedQuizType === 1 || selectedQuizType === 3) {
      //       var msg = "Please enter all details";
      //       if (quizDetails.numOfQuestions > 10) {
      //         msg = "Number of questions must be less than or equal to 10 ";
      //       }
      //       alert(msg);
      //       resolve();
      //       return;
      //     } else if (selectedQuizType === 2) {
      //       if (selectedPaperCategory === null) {
      //         alert("Please enter all details");
      //         resolve();
      //         return;
      //       }
      //     }
      //   }
      // }

      //const sub_topic_id_list = [1, 2]
      var token = "Bearer " + localStorage.getItem("access_token");
      axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
      axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.post["authorization"] = token;
      lockFields.current = true;
      axios
        .post(process.env.REACT_APP_REST_API_BASE_URL + "/generate_quiz", {
          method: "POST",
          subject_id: localStorage.getItem("selected_subject_id"),
          sub_topic_id_list: sub_topic_ids_csv,
          number_of_questions: counts[0] + counts[1] + counts[2],
          quiz_type: 3,
          question_ids: blackListedQuestionArray.current,
          quiz_status: location.state.classroomData ? 1 : 0,
          exam_paper_quiz_status: selectedSubject.exam_paper_quiz_status,
          category: selectedQuizType === 2 && selectedPaperCategory[0].paper_id,
          account_status: bannerObject ? handleAccontStatus(bannerObject.account_status, bannerObject) : null,
          is_task: true,
          one_to_three: counts[0],
          four_to_seven: counts[1],
          eight_plus: counts[2],
          code_status: location.state?.is_code,
          is_db: isOwnDBSelected.current ? 1 : 0,
        })

        .then((response) => {
          lockFields.current = true;
          console.log(response);
          if (location.state.classroomData) {
            amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
            amplitude
              .getInstance()
              .logEvent(
                "ICGenerate Quiz [" + (quizDetails.quizType === 2 ? "mini-mocks (beta)]" : quizDetails.quizType === 3 ? "Exam Style]" : "Formative]")
              );
          } else {
            amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
            amplitude
              .getInstance()
              .logEvent(
                "OCGenerateQuiz [" + (quizDetails.quizType === 2 ? "mini-mocks (beta)]" : quizDetails.quizType === 3 ? "Exam Style]" : "Formative]")
              );
          }

          if (selectedQuizType === 2) {
            var mock_exam = JSON.parse(response.data["mock_exam"]);
            setMockExam(mock_exam);
            setQuizDuration(mock_exam.mock_exam_duration);
            setResponseData([]);
            setIsAnswerRubric(response.data["show_answer_rubric"]);
          } else if (selectedQuizType === 1 || selectedQuizType === 3) {
            var question_answers_list = JSON.parse(response.data["question_answers"]);

            var sub_topics = response.data["sub_topic_ids_csv"];
            console.log(question_answers_list);
            question_answers.current = question_answers_list;
            blackListedQuestionArray.current = question_answers.current;
            console.log("BLACK LISTED QUESTION ADD QUIZ: ", blackListedQuestionArray.current);
            blackListedQuestionsToAvoid.current = blackListedQuestionArray.current;
            sub_topic_ids_csv.current = sub_topics;
            creation_tokens.current = response.data["creation_tokens"];
            quiz_generation_ids_list.current = JSON.parse(response.data["quiz_generation_ids_list"]);
            setIsAnswerRubric(response.data["show_answer_rubric"]);
            // sub_topic_name.current = response.data["sub_topic_name"];
            // console.log("SUBTOPIC NAMES ARE: ", sub_topic_name.current);

            setResponseData(question_answers.current);
          }

          //quizType.current=quizDetails.quizType;

          //question_answers.current = response.data['question_answers'];
          //setResponseData(question_answers.current)

          console.log("Question Response: ", question_answers.current);
          resolve();
          handleClickScroll();
          // lockFields.current = false;
        })
        .catch((error) => {
          resolve();
          lockFields.current = false;
          console.log(error);
          if (error.code === "ERR_BAD_RESPONSE") {
            setOpen(true);
          }
        });
    });
  }

  const handleViewLogDialogCloseWithCancel = () => {
    setOpenViewLogsDialog(false);
  };

  const handleCloseWithCancel = () => {
    setOpen(false);
    if (document.getElementById("sub_topics_container")) {
      if (document.getElementById("sub_topics_container").style) {
        document.getElementById("sub_topics_container").style.borderColor = "#474747";
      }
    }
  };

  const handleCloseWithCancelShareError = () => {
    setErrorOnShareQuiz(false);
  };

  const handleCloseUpdateQuizSettingWithConfirm = () => {
    changeIndividualQuizSettings();
  };

  const changeIndividualQuizSettings = () => {
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.headers.post["authorization"] = token;

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_individual_quiz_settings", {
        method: "POST",
        quiz_secret_key: id,
        class_id: location.state.classroomData ? location.state.classroomData.classroom_id : null,
        quiz_type: 3,
        quiz_time_duration: quizDurationSetting ? quizTiming : 0,
        quiz_grace_time: graceDurationToggle ? graceDuration : 0,
        quiz_auto_submission: autoSubmissionToggle,
        quiz_instant_feedback_status: hideAnswerToggle,
        shuffle_quiz_questions: shuffleQuestionToggle,
      })

      .then((response) => {
        console.log(response);
        setUpdateQuizSettingPopUp(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCloseUpdateQuizSetting = () => {
    setUpdateQuizSettingPopUp(false);
  };

  const handleQuizSettingDialogueOpen = () => {
    amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
    amplitude.getInstance().logEvent("Settings");
    setQuizSettingsDialogue(true);
  };
  // console.log("SELECTED QUIZ TYPE: ", selectedQuizType);

  const handleOpenTrialPeriodAcknowledgmentPopupOutsideClass = () => {
    setTrialPeriodAcknowledgmentPopupOutsideClass(true);
  };
  const handleCloseTrialPeriodAcknowledgmentPopupOutsideClass = () => {
    setTrialPeriodAcknowledgmentPopupOutsideClass(false);
    if (isPreviewButtonPressed.current) {
      window.open(`${window.location.origin + "/quiz?id=" + secretKey.current}`, "_blank");
      return;
    }
    setOpenShareQuizConfirmationDialogueOutsideClassroom(true);
  };

  const handleBannerCondition = () => {
    if (location.state.subjectData) {
      if (Array.isArray(bannerObject) && bannerObject.length > 0) {
        return true;
      } else if (!Array.isArray(bannerObject) && bannerObject) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <>
      {bannerObject && response_data.length === 0 && location.state.tour_status === 0 ? (
        <OnboardingTour handleSubmit={handleSubmit(submitForm)} haltTourFlow={haltTourFlow.current} />
      ) : (
        location.state.tour_status === 0 && <OnboardingTourLastSlide />
      )}
      <ReplaceDialogue
        is_code={location.state?.is_code}
        openReplaceDialogue={openReplaceDialogue}
        handleClose={handleClose}
        chapterList={chapters}
        questionIndex={replaceQuestionIndex}
        replaceQuestion={replaceQuestion}
        quizType={3}
        blackListedQuestionArray={blackListedQuestionArray}
        handleRawResponseClick={handleRawResponseClick}
        numberOfQuestions={numberOfQuestions}
        blackListedQuestionsToAvoid={blackListedQuestionsToAvoid.current}
        bannerObject={location.state !== null && bannerObject && bannerObject}
        one_to_three={counts[0]}
        four_to_seven={counts[1]}
        eight_plus={counts[2]}
        selectedQuestionForReplacement={selectedQuestionForReplacement}
        isAnswerRubric={isAnswerRubric}
      />
      <Dialog
        fullWidth
        maxWidth="md"
        className="overflow-y-hidden"
        open={quizSettingDialogue}
        onClose={handleCloseQuizSettingDialogue}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <b>Task Settings</b>
        </DialogTitle>
        <DialogContent>
          <QuizSetting
            quizType={selectedQuizType}
            quizDuration={quizDurationSetting}
            handleChange={handleChangeSetting}
            quizTiming={quizTiming}
            onIncDecClickQuizDuration={onIncDecClickQuizDuration}
            graceDurationToggle={graceDurationToggle}
            graceDuration={graceDuration}
            onIncDecClickQuizGraceDuration={onIncDecClickQuizGraceDuration}
            autoSubmissionToggle={autoSubmissionToggle}
            hideAnswerToggle={hideAnswerToggle}
            configureToggle={configureToggle}
            shuffleQuestionToggle={shuffleQuestionToggle}
            AIQuestionToggle={AIQuestionToggle}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseQuizSettingDialogue}>Close</Button>
        </DialogActions>
      </Dialog>
      {navigatedFromOutsideClassroom && secretKey.current ? (
        <ShareQuizUrlDialogue
          title={"Share This Task with Your Students"}
          quizUrl={window.location.origin + "/quiz?id=" + secretKey.current}
          handleCopy={() => {
            navigator.clipboard.writeText(window.location.origin + "/quiz?id=" + secretKey.current);
            setOpenShareQuizConfirmationDialogueOutsideClassroom(false);
            setIsSubmittingShare(true);
            // setIsPreviewingQuiz(true);
            setTimeout(() => {
              navigate(-2);
            }, 2500);
          }}
          // handleClose={() => {
          //   setOpenShareQuizConfirmationDialogueOutsideClassroom(false);
          //   setIsSubmittingShare(true);
          //   setIsPreviewingQuiz(true);
          //   setTimeout(() => {
          //     navigate(-2);
          //   }, 2500);
          // }}
          open={openShareQuizConfirmationDialogueOutsideClassroom}
        />
      ) : (
        <ConfirmationDialogue
          title={buttonEvent === "1" ? "Share Task" : "Add to drafts"}
          text={buttonEvent === "1" ? "Are you sure you want to share this task with students?" : "Are you sure you want to add this task to drafts?"}
          handleCloseWithConfirm={handleCloseShareQuizConfirmationDialogueWithConfirm}
          handleCloseWithCancel={handleCloseShareQuizConfirmationDialogueWithCancel}
          open={openShareQuizConfirmationDialogue}
        />
      )}
      <AcknowledgementDialogue
        title={buttonEvent === "1" ? "Task assigned!" : "Task Saved as Draft!"}
        text={
          buttonEvent === "1"
            ? "You can find it under the ‘Assigned’ tab on your dashboard."
            : "You can find it under the ‘Drafts’ tab on your dashboard."
        }
        open={openShareQuizAcknowledgementDialogue}
        handleCloseWithConfirm={handleCloseShareQuizAcknowledgementDialogue}
        showNewDialogue={true}
        isDraft={buttonEvent !== "1"}
        handlePreviewClicked={() => {
          sessionStorage.setItem("is_preview", 1);
          window.open(`${window.location.origin}/quiz?id=${secretKey.current}`, "_blank");
        }}
      />

      <ConfirmationDialogue
        open={openDeleteQuizConfirmationDialogue}
        title="Delete"
        text="Are you sure you want to delete this task from drafts?"
        handleCloseWithConfirm={handleCloseDeleteQuizConfirmationDialgueWithConfirm}
        handleCloseWithCancel={handleCloseDeleteQuizConfirmationDialgueWithCancel}
      />
      <AcknowledgementDialogue
        title="Task deleted from drafts!"
        text="The task has been deleted"
        handleCloseWithConfirm={handleCloseDeleteAcknowledgementDialogue}
        open={openDeleteAcknowledgementDialgoue}
      />
      {navigatedFromOutsideClassroom && (
        <>
          <TrialPeriodExpiredPopup
            subjectName={location.state.subjectData.subject_name}
            openTrialExpiredPopup={state.openTrialExpiredPopup}
            handleCloseTrialExpiredPopup={state.handleCloseTrialExpiredPopup}
            setOpen={setOpen2}
          />
          <SubscriptionPeriodExpiredPopup
            subjectName={location.state.subjectData.subject_name}
            openSubscriptionExpiredPopup={state.openSubscriptionExpiredPopup}
            handleCloseSubscriptionExpiredPopup={state.handleCloseSubscriptionExpiredPopup}
            setOpen={setOpen2}
          />

          <TaskLimitReachedPopup
            openTaskLimitReachedPopup={state.openTaskLimitReachedPopup}
            handleCloseTaskLimitReachedPopup={state.handleCloseTaskLimitReachedPopup}
            setOpen={setOpen2}
          />

          <TrialPeriodAcknowledgmentPopupOutsideClass
            navigatedFromOutsideClassroom={navigatedFromOutsideClassroom}
            subjectName={location.state.subjectData.subject_name}
            openTrialAcknowledgePopup={openTrialPeriodAcknowledgmentPopupOutsideClass}
            handleCloseTrialAcknowledgePopup={handleCloseTrialPeriodAcknowledgmentPopupOutsideClass}
            handleUpgrade={() => {
              setTrialPeriodAcknowledgmentPopupOutsideClass(false);
            }}
            setOpen={setOpen2}
          />
        </>
      )}
      <Dialog
        className="overflow-y-hidden"
        open={openViewLogsDialog}
        onClose={handleViewLogDialogCloseWithCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <b>Raw Response</b>
        </DialogTitle>
        <DialogContent className="overflow-y-hidden">
          <QuizRawResponseAccordian quizEvaluationLog={quiz_generation_ids_list.current} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewLogDialogCloseWithCancel}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={handleCloseWithCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ fontSize: "20px" }}>
            Hello <span class="wave">👋</span> It looks like you've chosen a lot of subtopics. To get the best results, we recommend selecting fewer
            subtopics.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="delete-button" onClick={handleCloseWithCancel} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={errorOnShareQuiz}
        onClose={handleCloseWithCancelShareError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ fontSize: "20px" }}>
            Unable to share quiz with students at this moment. Please try again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="delete-button" onClick={handleCloseWithCancelShareError} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmationDialogue
        open={updateQuizSettingPopUp}
        title="Update"
        text={`Do you want to update the Task settings?`}
        handleCloseWithConfirm={handleCloseUpdateQuizSettingWithConfirm}
        handleCloseWithCancel={handleCloseUpdateQuizSetting}
      />
      <UpgradePlan
        subjectName={location.state.subjectData.subject_name}
        subject={location.state.subjectData}
        open={open2}
        handleClose={() => {
          setOpen2(false);
          if (!isPreviewButtonPressed) {
            setOpenShareQuizConfirmationDialogueOutsideClassroom(true);
          }
        }}
        trialPeriodTrackingId={trialPeriodTrackingId}
      />

      <Sidebar item_id="quiz" />
      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px]">
        {location.state ? (
          <>
            {handleBannerCondition() && (
              <SubscriptionExpirationBanner setOpen={setOpen2} bannerObject={bannerObject} subjectData={location.state.subjectData} />
            )}
            {/* {navigatedFromOutsideClassroom && (
              <div className="home !text-[#6750A4] sm:ml[25px] md:ml-[60px] underline teacher_navigation_text mt-[1.5vw]">
                <button className="" onClick={() => navigate(-1)}>
                  {"Back"}
                </button>
              </div>
            )} */}
            {location.state.classroomData ? (
              [
                <>
                  <ClassroomDetailBanner classroomData={location.state.classroomData} />

                  {id !== null ? (
                    <div className="home sm:ml[25px] md:ml-[60px] underline teacher_navigation_text mt-[1.5vw]">
                      <button className="" onClick={() => navigate(-1)}>
                        {/* {props.text} */}
                        {"Classroom "}
                      </button>
                      <button>{" " + " > View Task"}</button>
                    </div>
                  ) : (
                    <div className="home sm:ml[25px] md:ml-[60px] underline teacher_navigation_text mt-[1.5vw]">
                      <button className="" onClick={() => navigate(-2)}>
                        {/* {props.text} */}
                        {"Classroom "}
                      </button>
                      {/* <button onClick={() => navigate(-1)}>{" " + " > Create Quiz"}</button> */}
                      <button>{" " + " > Create Task"}</button>
                    </div>
                  )}
                </>,
              ]
            ) : (
              <div></div>
            )}
          </>
        ) : null}

        <div className="home sm:ml[25px] md:ml-[4.219vw] mt-[2.083vw] w-[65%] justify-between">
          <div className="flex">
            <div className="md:pl-10 w-fit">
              <label className="disabled-label">Step 1: Task Setup</label>
            </div>
            <DashboardLabel text="Step 2: Question Selection & Review" />
          </div>
          {location.state && (
            <>
              <div className="float-right flex">
                <img src={Setting} style={{ width: "20px", height: "20px", marginRight: "7px", alignSelf: "center" }}></img>
                <button onClick={() => handleQuizSettingDialogueOpen()} className="disabled-label">
                  Settings
                </button>
              </div>
            </>
          )}
        </div>

        {/* <div className="home sm:mx[25px] md:ml-[6.771vw] mt-[1.667vw] sm:gap-x-[1rem] md:gap-x-[14rem] lg:gap-x-[35rem]"> */}
        <div className="home sm:mx[25px] md:ml-[6.771vw] mt-[1.667vw]">
          <div className="text-[16px]">• Choose the number and type of questions to include:</div>
        </div>

        <div className="form-div">
          <div className="sm:w-[100%] md:w-[60vw]">
            <div className="font-roboto p-6 bg-white ">
              <div className="py-3 md:w-[60%] sm:-[100%] mx-auto">
                <div className="flex justify-between py-3">
                  <div className="text-base font-semibold text-[0.729vw] ml-1">Question type</div>
                  <div className="text-base font-semibold text-[0.729vw] mr-10">Number of Questions</div>
                </div>
                <div>
                  <div className=" border-t-[2px]    border-gray-300 mb-4 ">
                    <div className="question-selection">
                      {questionTypes.map((type, index) => (
                        <div
                          key={index}
                          className={`flex justify-between items-center pl-5 pr-5 pt-3 pb-2  ${index === 0 && "mt-4"} rounded`}
                          style={{
                            backgroundColor: index % 2 === 0 ? "#F7F2F9" : "white",
                          }}
                        >
                          <span className="text-sm text-[0.833vw]">
                            {" "}
                            <span className={`${type.color} pt-[0.042vw] pb-[0.042vw] pl-[10px] pr-[10px] mr-2 rounded-sm`}></span>
                            {type.label}
                          </span>
                          <div className="">
                            <div className="flex items-center">
                              <button
                                disabled={isSubmitting || lockFields.current}
                                className="text-gray-700 bg-[#D8D2E9] px-3 rounded-l md:w-[2.5vw] md:h-[2.083vw] sm:w-[8vw] sm:h-[6vw] flex items-center justify-center"
                                onClick={() => handleCountChange(index, -1, type.type)}
                              >
                                -
                              </button>
                              <input
                                disabled={isSubmitting || lockFields.current}
                                className="text-center md:w-[3.333vw] md:h-[2.083vw] md:text-[0.833vw] sm:w-[20vw] sm:h-[6vw] sm:text-[4vw]"
                                value={counts[index] ? counts[index] : 0}
                                onChange={(e) => {
                                  if (e.target.value === "") {
                                  }
                                  e.target.value <= handleMaxQuestionValue(type.type) && e.target.value !== ""
                                    ? setCounts([...counts.slice(0, index), parseInt(e.target.value), ...counts.slice(index + 1)])
                                    : setCounts([...counts.slice(0, index), parseInt(0), ...counts.slice(index + 1)]);
                                }}
                              />
                              <button
                                disabled={isSubmitting || lockFields.current}
                                className="text-gray-700 bg-[#D8D2E9] px-3 rounded-r md:w-[2.5vw] md:h-[2.083vw] sm:w-[8vw] sm:h-[6vw] flex items-center justify-center"
                                onClick={() => handleCountChange(index, 1, type.type)}
                              >
                                +
                              </button>
                            </div>

                            <span className="text-sm ml-2 float-right italic mt-1">(Max. {handleMaxQuestionValue(type.type)})</span>
                          </div>
                        </div>
                      ))}
                      <div style={{ display: id != null ? "none" : "block" }}>
                        <div className="dashboard_buttons mt-3">
                          <button
                            className={userType === "2" ? "teepee-back-button sm:w-[30%] md:w-[46%]" : "teepee-back-button w-[46%]"}
                            onClick={goBack}
                          >
                            <div style={{ display: "flex", justifyContent: "center" }}>
                              <img alt="" src={BackArrow} className="pr-4"></img>
                              <div>Go Back</div>
                            </div>
                          </button>

                          <button
                            style={{ display: userType === "2" ? "" : "none" }}
                            onClick={() => {
                              // handleRawResponseClick();
                            }}
                            className="teepee-button md:ml-12 hover:bg-black sm:w-[30%] md:w-[30%]"
                          >
                            View Logs
                          </button>

                          <button
                            id="button"
                            onClick={handleSubmit(submitForm)}
                            disabled={isSubmitting || lockFields.current}
                            className={
                              userType === "2"
                                ? "teepee-button md:ml-12 hover:bg-black sm:w-[30%] md:w-[46%]"
                                : "teepee-button md:ml-12 hover:bg-black md:w-[46%]"
                            }
                          >
                            {isSubmitting && (
                              <div style={{ height: "26px", width: "25px" }} className="spinner-border spinner-border-m float-left" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            )}
                            Generate Task
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isSubmitting && (
                <div id="quiz-section" className="w-[100%] sm:pt-[30px] ">
                  <div
                    style={{
                      flex: 1,
                      height: "1px",
                      backgroundColor: "#B3B3B3",
                      marginTop: "0px",
                    }}
                  />
                  <img alt="" src={magicianImage} style={{ margin: "auto", width: "180px" }} className="sm:pt-[30px] md:pt-0"></img>
                  <p
                    style={{
                      fontSize: "21px",
                      fontFamily: "Roboto",
                      fontStyle: "Bold",
                      fontWeight: "500",
                      textAlign: "center",
                      marginTop: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    Magically generating Task
                  </p>

                  <p
                    style={{
                      fontSize: "16px",
                      marginBottom: "100px",
                      fontFamily: "Roboto",
                      textAlign: "center",
                      marginTop: "1px",
                    }}
                  >
                    Our AI can take up to 60 seconds…
                  </p>
                </div>
              )}
              <div
                id="quiz-section"
                style={{
                  display: question_answers.current.length === 0 && mock_exam === null ? "none" : "",
                }}
                className="sm:ml-[3px] sm:w-[98%] md:w-[100%] sm:pt-[20px]"
              >
                <div
                  style={{
                    flex: 1,
                    height: "1px",
                    backgroundColor: "#B3B3B3",
                    marginTop: "50px",
                  }}
                />
                <p
                  style={{
                    marginBottom: "50px",
                    fontFamily: "Roboto",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  {selectedQuizType === 1 || selectedQuizType === 3 ? `Here is your Task 👇` : "Your mini-mock exam paper 👇"}
                </p>

                {(selectedQuizType === 1 || selectedQuizType === 3 || mock_exam === null) &&
                  response_data?.map((rowData, index) => {
                    const tableData = ExtractTableData(rowData.question);
                    return (
                      <Fragment key={index}>
                        <div
                          ref={parentRef}
                          className={
                            replacedQuestionIndexStatus.current.length > 0 && replacedQuestionIndexStatus.current[index] === 1
                              ? "w-[102%]   relative bg-[#CEF2CE] "
                              : ""
                          }
                        >
                          <div
                            className={
                              replacedQuestionIndexStatus.current.length > 0 && replacedQuestionIndexStatus.current[index] === 1 ? "relative " : ""
                            }
                          >
                            <div
                              ref={grandchildRef}
                              // style={{
                              //   background: "#E1E1E1",
                              //   padding: "10px",
                              //   borderRadius: "5px",
                              // }}

                              className={
                                replacedQuestionIndexStatus.current.length > 0 && replacedQuestionIndexStatus.current[index] === 1
                                  ? " p-[10px] pt-[20px] pl-[8px] md:pb-[70px] sm:pb-[130px] 		"
                                  : ""
                              }
                            >
                              <div className="md:w-auto ">
                                <div
                                  style={{
                                    padding: "10px ",
                                    borderRadius: "5px",
                                    width: "100%",
                                    marginBottom: "20px",
                                    backgroundColor: "#F0F0F0",
                                  }}
                                >
                                  <p
                                    className="answer-paragraph !whitespace-normal	"
                                    style={{
                                      fontSize: "16px",
                                      fontFamily: "Roboto",
                                      color: "#49454F",
                                      display: "flex",
                                    }}
                                  >
                                    <span className="w-[87%]">
                                      <b>Question {index + 1}: </b>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: extractQuestionText(rowData.question),
                                        }}
                                      ></span>
                                    </span>

                                    <b className="sm:w-[35%] md:w-auto"> [Marks: {rowData.question_marks}]</b>
                                  </p>
                                  {rowData.image_file_name !== null && (
                                    <div className="flex justify-center mb-[20px] mt-[20px]">
                                      <img
                                        alt=""
                                        className=""
                                        src={process.env.REACT_APP_CDN_URL_FOR_QUESTION_IMAGES + rowData.image_file_name}
                                      ></img>
                                    </div>
                                  )}
                                  {rowData.have_table === 1 && (
                                    <EditableTable columnList={tableData.columnList} rowList={tableData.rowList} isEditable={false} />
                                  )}
                                  {rowData.have_code === 1 && (
                                    <CodeEditor readOnly={true} value={removeHtmlTags2(rowData.question?.split("##code")[1])} />
                                  )}
                                  <p
                                    className="answer-paragraph"
                                    style={{
                                      fontSize: "16px",
                                      fontFamily: "Roboto",
                                      color: "#49454F",
                                      display: isAnswerRubric === 1 ? "none" : "",
                                    }}
                                  >
                                    <b>Answer: </b>
                                    {rowData.answer.split("Rubric:")[0]}
                                  </p>
                                  {selectedQuizType !== 1 && rowData.answer.split("Rubric:").length > 1 && (
                                    <p
                                      className="answer-paragraph"
                                      style={{
                                        fontSize: "16px",
                                        fontFamily: "Roboto",
                                        color: "#49454F",
                                        display: isAnswerRubric === 1 ? "none" : "",
                                      }}
                                    >
                                      <b>Rubric: </b>
                                      {rowData.answer.split("Rubric:")[1]}
                                    </p>
                                  )}
                                </div>
                                <div className="flex md:flex-row sm:flex-col justify-center items-center">
                                  <div></div>
                                  <div
                                    className="close bg-[white] text-[black]  px-4  pt-[12px] flex  sm:mb-[20px] md:mr-auto sm:mr-0"
                                    aria-label="Close"
                                    style={{
                                      display: rowData.sub_topic_name ? "flex" : "none",
                                      height: "50px",
                                      fontSize: "16px",
                                      borderRadius: "5px",
                                      width: "250px",
                                      border: "1px solid black",
                                    }}
                                    // className=" "
                                  >
                                    <span className="pr-[10px] text-[#6750A4] subtopic_name" aria-hidden="true" style={{ width: "170px" }}>
                                      {rowData.sub_topic_name}
                                    </span>
                                    <div className="mt-[3px]">
                                      <img src={infoLogo}></img>
                                    </div>{" "}
                                    {/* {sub_topic_name.current[index]} */}
                                  </div>
                                  <div>
                                    <button
                                      type="button"
                                      className="close bg-[#edeaf4] text-[#6750A4]  px-4"
                                      aria-label="Close"
                                      onClick={() => removeQuestion(index)}
                                      value={index}
                                      style={{
                                        display: id != null ? "none" : "block",
                                        height: "50px",
                                        float: "right",
                                        fontSize: "16px",
                                        borderRadius: "5px",
                                      }}
                                      // className=" "
                                    >
                                      <span className="pr-[10px]" aria-hidden="true">
                                        &times;
                                      </span>
                                      Remove
                                    </button>
                                    <button
                                      data-modal-target="defaultModal"
                                      type="button"
                                      className="close bg-[#edeaf4] text-[#6750A4]  px-4 mr-[10px]"
                                      aria-label="Close"
                                      // onClick={() => <ReplaceDialogue />}
                                      onClick={() => handleClickOpen(index, rowData)}
                                      value={index}
                                      style={{
                                        display: id != null ? "none" : "flex",
                                        height: "50px",
                                        float: "right",
                                        fontSize: "16px",
                                        borderRadius: "5px",
                                        alignItems: "center",
                                      }}
                                      // className=" "
                                    >
                                      <span className="pr-[10px]" aria-hidden="true">
                                        <img src={ReplaceLogo}></img>
                                      </span>
                                      Replace
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className={
                            replacedQuestionIndexStatus.current.length > 0 && replacedQuestionIndexStatus.current[index] === 1
                              ? "sm:mt-[80px] md:mt-[20px] mb-[2.25rem]"
                              : "sm:mt-[150px] md:mt-[30px] mb-[2.25rem]"
                          }
                          style={{
                            flex: 1,
                            height: "1px",
                            backgroundColor: "#B3B3B3",
                            // marginTop:
                            //   replacedQuestionIndexStatus.current.length > 0 &&
                            //   replacedQuestionIndexStatus.current[index] === 1
                            //     ? "20px"
                            //     : "100px",
                          }}
                        />
                      </Fragment>
                    );
                  })}

                {selectedQuizType === 2 && mock_exam !== null && (
                  <b style={{ fontSize: "16px" }}>Exam Duration: {quizTiming === 0 ? mock_exam.mock_exam_duration : quizTiming} Minutes</b>
                )}

                {selectedQuizType === 2 &&
                  mock_exam !== null &&
                  JSON.parse(mock_exam.question_context_list)?.map((question_context, index) => (
                    <Fragment key={index}>
                      <div style={{}}>
                        <div
                          style={{
                            padding: "10px",
                            borderRadius: "5px",
                            width: "100%",
                            marginBottom: "30px",
                            backgroundColor: "#F0F0F0",
                          }}
                        >
                          <p
                            className="answer-paragraph !whitespace-normal	"
                            style={{
                              display: "flex",
                              fontSize: "16px",
                              fontFamily: "Roboto",
                              color: "#49454F",
                            }}
                          >
                            <span className="w-[87%]">
                              <b>Questions {index + 1}: </b>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: extractQuestionText(question_context.question_context_name),
                                }}
                              ></span>
                            </span>
                          </p>
                          {JSON.parse(question_context.predefined_question_answer_list)?.map((predefined_question_answer, index) => (
                            <div className="m-[30px] mb-[30px]">
                              <p
                                style={{
                                  display: "flex",
                                  fontSize: "16px",
                                  fontFamily: "Roboto",
                                  color: "#49454F",
                                }}
                              >
                                <b className="mr-[5px]">({String.fromCharCode(index + "A".charCodeAt(0)).toLowerCase()})</b>
                                <p
                                  className="w-[87%]"
                                  dangerouslySetInnerHTML={{
                                    __html: predefined_question_answer.question,
                                  }}
                                ></p>
                                {selectedQuizTypeId.current === 2 && (
                                  <b className="sm:w-[40%] md:w-[20%]">[Marks: {predefined_question_answer.question_marks}]</b>
                                )}
                              </p>
                              {predefined_question_answer.image_file_name !== null && (
                                <div className="flex justify-center mb-[20px] mt-[20px]">
                                  <img
                                    alt=""
                                    className=""
                                    src={process.env.REACT_APP_CDN_URL_FOR_QUESTION_IMAGES + predefined_question_answer.image_file_name}
                                  ></img>
                                </div>
                              )}
                              <AnswerAccordian
                                display_rubric={isAnswerRubric === 1 ? false : true}
                                title="Answer"
                                predefined_question_answer={predefined_question_answer}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </Fragment>
                  ))}
                {navigatedFromOutsideClassroom && (
                  <div className="text-[1.0rem] font-bold mb-[1rem] tracking-[0.009rem] justify-center text-center">
                    Review the questions, then share with students or experience AI marking & feedback yourself.
                  </div>
                )}
                <div className="share-task">
                  <button
                    style={{
                      display: location.state ? (location.state.selectedQuizType === 1 ? "none" : "block") : "block",
                    }}
                    value={1}
                    onClick={(e) => handleOpenShareQuizConfirmationDialogue("1")}
                    disabled={isSubmittingShare || isPreviewingQuiz}
                    className="teepee-button flex items-center gap-2 !float-none hover:bg-black w-full mb-[8px] sm:w-[100%]"
                  >
                    {isSubmittingShare && (
                      <div className="spinner-border spinner-border-m float-left" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                    <span className="flex items-center justify-center gap-2">
                      <FiShare2 size={20} />
                      Share This Task with Students
                    </span>
                  </button>

                  {/* Adding Preview Button Under Sshare Task */}
                  {navigatedFromOutsideClassroom && (
                    <button
                      style={{
                        display: location.state ? (location.state.selectedQuizType === 1 ? "none" : "block") : "block",
                      }}
                      value={1}
                      onClick={(e) => handlePreviewQuiz(e.target.value)}
                      disabled={isSubmittingShare || isPreviewingQuiz}
                      className="teepee-back-button hover:bg-black hover:text-white w-[full] mb-[40px]  sm:w-[100%] !float-none"
                    >
                      {isPreviewingQuiz && (
                        <div className="spinner-border spinner-border-m float-left" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      )}
                      <span className="flex items-center justify-center gap-2">
                        <FiEye size={20} /> {/* Add share icon */}
                        Preview Task as a Student
                      </span>
                    </button>
                  )}
                </div>
                <button
                  style={{
                    display: location.state
                      ? location.state.selectedQuizType
                        ? location.state.selectedQuizType === 2
                          ? "block"
                          : "none"
                        : "none"
                      : "none",
                  }}
                  onClick={(e) => handleOpenDeleteQuizConfirmationDialgue(e)}
                  disabled={isSubmittingShare}
                  className="teepee-back-button hover:bg-black hover:text-white w-[full] mb-[40px]  sm:w-[100%]"
                >
                  {isSubmittingShare && <div></div>}
                  Delete from drafts
                </button>
                {location.state ? (
                  <>
                    {location.state.classroomData && (
                      <button
                        style={{
                          display: location.state
                            ? location.state.selectedQuizType === 2
                              ? "none"
                              : location.state.selectedQuizType === 1 && "none"
                            : "block",
                        }}
                        value={2}
                        onClick={(e) => {
                          amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
                          amplitude.getInstance().logEvent("AddtoDrafts");
                          handleOpenShareQuizConfirmationDialogue("2");
                        }}
                        disabled={isSubmittingShare}
                        className="teepee-back-button hover:bg-black hover:text-white w-[full] mb-[8px]  sm:w-[100%]"
                      >
                        {isSubmittingShare && <div></div>}
                        <span className="flex items-center justify-center gap-2">
                          <FiEdit size={20} />
                          Add to drafts
                        </span>
                      </button>
                    )}
                    {/* {location.state.classroomData && (
                      <button
                        style={{
                          display: location.state
                            ? location.state.selectedQuizType === 2
                              ? "none"
                              : location.state.selectedQuizType === 1 && "none"
                            : "block",
                        }}
                        value={2}
                        onClick={(e) => {
                          handleClickOnShareWithStudentBtn("3");
                        }}
                        disabled={isSubmittingShare}
                        className="teepee-back-button !text-[#949494] !border-0  w-[full] mb-[40px]  sm:w-[100%] flex justify-center"
                      >
                        {isSubmittingShare && <div></div>}
                        <img src={EyeIcon} className="mr-[0.3rem]"></img> Preview Task as a Student
                      </button>
                    )} */}
                  </>
                ) : null}

                <div className="app" style={{ textAlign: "center" }}></div>
                <div>
                  {/* <form onSubmit={handleSubmit(submitForm)}>
          <div className="mt-2">
              <button disabled={isSubmitting} className="btn btn-danger">
                {isSubmitting && (
                  <span className="spinner-grow spinner-grow-sm"></span>
                )}
                Submit
              </button>
          </div>
      </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionConfigurator;
