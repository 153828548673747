import React from "react";
import QuizzesTable from "./QuizzesTable";

function TaskDashboardTabBody({
  showAssignedQuizzesTable,
  assignedQuizzes,
  selectedTable,
  draftQuizzes,
  getClassData,
  bannerObject,
  classroomData,
  requiredUrl,
  is_code,
}) {
  return (
    <div>
      <div className="home md:ml-[20px]">
        <div className="flex assigned-task-and-draft">
          <div onClick={() => showAssignedQuizzesTable(1)}>
            <button className="dashboard-label">Assigned Tasks {"(" + `${assignedQuizzes.length}` + ")"} </button>
            <div className={selectedTable === 1 ? "teepee-divider" : ""}></div>
          </div>
          <div className="pl-10" onClick={() => showAssignedQuizzesTable(2)}>
            <button className="dashboard-label">Drafts {"(" + `${draftQuizzes.length}` + ")"}</button>
            <div className={selectedTable === 2 ? "teepee-divider" : ""}></div>
          </div>
        </div>
      </div>
      <div className="home md:ml-[20px] mt-[20px] ">
        <div class="relative overflow-x-auto w-[100%]">
          <QuizzesTable
            is_code={is_code}
            getClassData={getClassData}
            quizzes={selectedTable === 1 ? assignedQuizzes : draftQuizzes}
            classroomData={classroomData}
            selectedQuizType={selectedTable}
            url={requiredUrl}
            bannerObject={bannerObject}
          />
        </div>
      </div>
    </div>
  );
}

export default TaskDashboardTabBody;
