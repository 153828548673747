import axios from "axios";
import AppContext from "./appContext";
import React, { useRef, useState } from "react";
const AppState = (props) => {
  const [secretKey, setSecretKey] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [createClassroomDialogue, setCreateClassroomDialogue] = useState(false);
  const [openShareClassroomDialogue, setOpenShareClassroomDialogue] = useState(false);
  const [openJoinClassroomDialogue, setOpenJoinClassroomDialogue] = useState(false);
  const [classroomData, setClassroomData] = useState(null);
  const [loginState, setLoginState] = useState(2);
  const [DashboardTabsState, setDashboardTabsState] = useState(1);
  const [loadCount, setLoadCount] = useState(1);
  const [quizTabLoadCount, setQuizTabLoadCount] = useState(1);
  const [teacherQuizzesTableState, setTeacherQuizzesTableState] = useState(1);
  const [openTrialGetStartedPopup, setOpenTrialGetStartedPopup] = useState(false);
  const [openTrialAcknowledgePopup, setOpenTrialAcknowledgePopup] = useState(false);
  const [selectedSubjectForClass, setSelectedSubjectForClass] = useState(null);
  const [selectedSubjectIdForClass, setSelectedSubjectIdForClass] = useState(null);
  const [selectedSubjectForPopUp, setSelectedSubjectForPopUp] = useState(null);
  const [openBuyVoucherPopup, setOpenBuyVoucherPopUp] = useState(null);
  const [openTrialExpiredPopup, setOpenTrialExpiredPopup] = useState(false);
  const [openSubscriptionExpiredPopup, setOpenSubscriptionExpiredPopup] = useState(false);
  const [openTaskLimitReachedPopup, setOpenTaskLimitReachedPopup] = useState(false);
  const selectedSubjectObject = useRef(null);

  const handleOpenTrialAcknowledgePopup = () => {
    setOpenTrialAcknowledgePopup(true);
  };
  const handleCloseTrialAcknowledgePopup = () => {
    setOpenTrialAcknowledgePopup(false);
  };

  const handleOpenTrialGetStartedPopup = () => {
    setOpenTrialGetStartedPopup(true);
  };
  const handleCloseTrialGetStartedPopup = () => {
    setOpenTrialGetStartedPopup(false);
  };
  const handleClickOpenCreateClassroomDialogue = () => {
    setCreateClassroomDialogue(true);
  };

  const handleCloseCreateClassroomDialogue = () => {
    setSelectedSubjectForPopUp(null);
    setCreateClassroomDialogue(false);
  };
  const handleClickOpenShareClassCodeDialogue = () => {
    setOpenShareClassroomDialogue(true);
  };

  const handleClickCloseShareClassCodeDialogue = () => {
    setOpenShareClassroomDialogue(false);
    window.location.reload();
  };
  const handleClickOpenJoinClassroomDialogue = () => {
    setOpenJoinClassroomDialogue(true);
  };
  const handleClickCloseJoinClassroomDialogue = () => {
    setOpenJoinClassroomDialogue(false);
  };

  const handleOpenTrialExpiredPopup = () => {
    setOpenTrialExpiredPopup(true);
  };

  const handleCloseTrialExpiredPopup = () => {
    setOpenTrialExpiredPopup(false);
  };

  const handleOpenSubscriptionExpiredPopup = () => {
    setOpenSubscriptionExpiredPopup(true);
  };

  const handleCloseSubscriptionExpiredPopup = () => {
    setOpenSubscriptionExpiredPopup(false);
  };
  const handleOpenTaskLimitReachedPopup = () => {
    setOpenTaskLimitReachedPopup(true);
  };

  const handleCloseTaskLimitReachedPopup = () => {
    setOpenTaskLimitReachedPopup(false);
  };

  const updateTourState = (step) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_tour_status", {
        method: "POST",
        tour_status: 1,
        step: step,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <AppContext.Provider
      value={{
        secretKey,
        setSecretKey,

        selectedSubject,
        setSelectedSubject,

        createClassroomDialogue,
        setCreateClassroomDialogue,

        openShareClassroomDialogue,
        setOpenShareClassroomDialogue,
        handleClickOpenShareClassCodeDialogue,
        handleClickCloseShareClassCodeDialogue,

        handleClickOpenCreateClassroomDialogue,
        handleCloseCreateClassroomDialogue,

        openJoinClassroomDialogue,
        setOpenJoinClassroomDialogue,
        handleClickOpenJoinClassroomDialogue,
        handleClickCloseJoinClassroomDialogue,

        openTrialGetStartedPopup,
        setOpenTrialGetStartedPopup,
        handleOpenTrialGetStartedPopup,
        handleCloseTrialGetStartedPopup,

        openTrialAcknowledgePopup,
        setOpenTrialAcknowledgePopup,
        handleCloseTrialAcknowledgePopup,
        handleOpenTrialAcknowledgePopup,

        classroomData,
        setClassroomData,

        loginState,
        setLoginState,

        DashboardTabsState,
        setDashboardTabsState,

        loadCount,
        setLoadCount,

        quizTabLoadCount,
        setQuizTabLoadCount,

        teacherQuizzesTableState,
        setTeacherQuizzesTableState,

        selectedSubjectForClass,
        setSelectedSubjectForClass,

        selectedSubjectIdForClass,
        setSelectedSubjectIdForClass,

        selectedSubjectForPopUp,
        setSelectedSubjectForPopUp,

        openBuyVoucherPopup,
        setOpenBuyVoucherPopUp,

        openTrialExpiredPopup,
        handleOpenTrialExpiredPopup,
        handleCloseTrialExpiredPopup,

        openSubscriptionExpiredPopup,
        handleOpenSubscriptionExpiredPopup,
        handleCloseSubscriptionExpiredPopup,

        openTaskLimitReachedPopup,
        handleOpenTaskLimitReachedPopup,
        handleCloseTaskLimitReachedPopup,

        selectedSubjectObject,

        updateTourState,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppState;
