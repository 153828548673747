import React from "react";
import { MDBFooter, MDBContainer, MDBCol, MDBRow } from "mdb-react-ui-kit";

export default function Footer() {
  return (
    <MDBFooter className="text-white mb-0 flex justify-center items-center" style={{ backgroundColor: "white" }}>
      <MDBContainer className="absolute bottom-5 !pr-0 !pl-0 !w-[495px]">
        <div
          className="teepee-disclaimer"
          style={{
            fontSize: "12px",
          }}
        >
          <p>
            ⚠️ Please note: Teepee.ai is in early Beta. We’re refining our AI’s task generation and marking, so occasional inaccuracies may occur.
          </p>
        </div>
        <div className="flex">
          <div style={{ color: "black" }} className="mr-4">
            © Teepee.ai. All rights reserved
          </div>
          <div style={{ color: "black" }} className="mr-4">
            <a href="/about_us" target="_blank" rel="noopener noreferrer" className="text-black">
              About
            </a>
          </div>
          {/* <div style={{ color: "black" }}>
            <a href="https://teepee.ai/privacy-policy-for-teepee-ai/" target="_blank" className="text-black">
              Privacy Policy
            </a>
          </div> */}
        </div>
      </MDBContainer>
    </MDBFooter>
  );
}
