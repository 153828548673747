import React, { useEffect, useState } from "react";

import axios from "axios";
import Sidebar from "../Sidebar";
import { useNavigate } from "react-router-dom";
import SubjectCard from "./Widgets/SubjectCard";

function SelectSubjectForQuestionAddition() {
  const navigate = useNavigate();
  const [subjectData, setSubjectsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleClickOnSubject = (subjectId) => {
    navigate(`/teacher_can_add_own_question?id=${subjectId}`);
  };

  const getSubjectsList = () => {
    axios
      .request({
        method: "GET",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_subjects",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((res) => {
        console.log("Data inside Subject Selection: ", res.data);
        setSubjectsData(res.data);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getSubjectsList();
  }, []);

  return (
    <>
      <Sidebar />
      <div className="sm:ml-[0.3rem] md:ml-[19.75rem] flex flex-wrap gap-3 p-8 !pr-3">
        {isLoading ? (
          <div>I'm loading</div>
        ) : (
          subjectData.map((subject, index) => <SubjectCard index={index} subject={subject} handleOnClick={handleClickOnSubject} />)
        )}
      </div>
    </>
  );
}

export default SelectSubjectForQuestionAddition;
