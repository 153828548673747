import { useRef, useState, useEffect } from "react";
import subject_icon from "../../../../images/subject.png";
import topic_icon from "../../../../images/topic.png";
import Sidebar from "../../../Sidebar";
import QuestionDisplay from "./QuestionDisplay";
import { useLocation, useNavigate } from "react-router-dom";
import SessionSummary from "./SessionSummary";
import moment from "moment";
import animationData from "../../../../images/body_builder.json";
import Lottie from "react-lottie";
import left_icon from "../../../../images/arrow_left_alt.png";

function QuestionPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [analyzing, setAnalyzing] = useState(false);
  const [answer, setAnswer] = useState("");
  const [attempts, setAttempts] = useState([]);
  const [currentPage, setCurrentPage] = useState("question");
  // const [error, setError] = useState("");
  // const [isLimit, setIsLimit] = useState(false);
  const inputToken = useRef("");
  const outputToken = useRef;
  const [prmpt, setprmpt] = useState();
  const [attemptAdded, setAttemptAdded] = useState(false);
  const [questionSet, setQuestionSet] = useState([]);
  const [responseId, setResponseId] = useState(null);
  const [submissionResult, setSubmissionResult] = useState({});
  // const [loading, setLoading] = useState(true); // Indicates API loading state
  const { student, currentQuestion, subject, topic, classroomId } = location.state;

  // const { question, subjectName, topicNamesInCSV, payload } = location.state;
  const [question, setQuestion] = useState(location.state && currentQuestion);
  const [predefinedQuestionIds, setPredefinedQuestionIds] = useState([question[0].predefined_question_answer_id]);
  const [showBodyBuilder, setShowBodyBuilder] = useState(false);
  const containerRef = useRef(null);
  const tableRef = useRef();
  const tableData = useRef({});

  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  function getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  }

  const fetchQuestions = async () => {
    console.log("-=-=-=-=-=-=-Fetch Question-=-=-=-===-==-");
    const token = localStorage.getItem("access_token");
    const currentTime = getCurrentDateTime();

    // Add the current predefined question answer ID to the state
    setPredefinedQuestionIds((prev) => [...prev, question[question.length - 1].predefined_question_answer_id]);

    if (!subject.subjectId) {
      console.error("Subject ID is missing");
      // setError("No subject ID provided.");
      // setLoading(false);
      return;
    }

    try {
      // Convert predefinedQuestionIds to a CSV string
      const predefinedQuestionIdsCSV = predefinedQuestionIds.join(",");
      const response = await fetch(`${process.env.REACT_APP_MOCKBOT_IN_TEEPEE_BASE_URL}/fetch_questions_for_student_teepee`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          student_id: student.studentId,
          subject_id: subject.subjectId,
          chapter_id: topic.topicIdsInCSV,
          subject_name: subject.subjectName,
          country: "England",
          country_code: "GB",
          year: "Year 10",
          current_time: currentTime,
          current_date: moment().format("Y-MM-DD HH:mm:ss"),
          predefined_question_answer_ids_csv: predefinedQuestionIdsCSV, // Send CSV string here
        }),
      });

      if (!response.ok) throw new Error("Failed to fetch question");
      const text = await response.text();
      const data = text ? JSON.parse(text) : null;
      console.log("Here is latest Data:", data.question);

      // Update question state with the new question data
      if (data.question.length !== 0) {
        tableData.current = undefined;
        setQuestion((prev) => [...prev, data.question[0]]);
      } else {
        setShowBodyBuilder(true);
      }
    } catch (err) {
      console.error("Error fetching Questions:", err);
      // setError(err.message);
    } finally {
      // setLoading(false); // Mark as no longer loading
    }
  };

  const handleSubmit = async () => {
    console.log("-=-=-=-=-=-=-Handle Submit-=-=-=-===-==-");
    let _tableData;
    if (question[question.length - 1].have_table === 1) {
      _tableData = tableRef.current.getTableData();
      tableData.current = _tableData;
      let appendedValue = JSON.stringify(_tableData);
      _tableData = `##table ${appendedValue} ##table`;
    }

    setAnalyzing(true);
    var sbjID = localStorage.getItem("subject_id");
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
    try {
      const quiz_content = {
        question: question?.question,
        actual_answer: question.answer,
        rubric: question.rubric,
        answer:
          question[question.length - 1].have_table === 1
            ? _tableData
            : question[question.length - 1].have_code === 1
            ? question[question.length - 1].answer
            : answer,
      };

      const response = await fetch(`${process.env.REACT_APP_MOCKBOT_IN_TEEPEE_BASE_URL}/evaluate_question_for_student_teepee`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          question: quiz_content,
          subject_id: sbjID,
          question_marks: "1",
          question_id: question[question.length - 1].predefined_question_answer_id,
          have_code: question[question.length - 1].have_code,
        }),
      });

      if (!response.ok) throw new Error("Failed to submit answer");

      const data = await response.json();
      console.log("-=-=-=-=-=-=-evaluate_question_for_student_teepee-=-=-=-===-==-", data.response);

      setAnalyzing(false);

      setSubmissionResult(data.response);
      if (data.state === 0) {
        // setIsLimit(true);
        return;
      }
      setprmpt(JSON.stringify(data.message));
      inputToken.current = data.input_token;
      outputToken.current = data.output_token;
      setAttempts((prev) => [
        ...prev,
        {
          id: parseInt(question[question.length - 1].predefined_question_answer_id),
          question: question[question.length - 1].question,
          answer:
            question[question.length - 1].have_table === 1
              ? _tableData
              : question[question.length - 1].have_code === 0
              ? answer
              : question[question.length - 1].answer,
          rubric: question[question.length - 1].rubric,
          ai_feedback: data.response?.feedback,
          actual_answer: parseInt(question[question.length - 1].answer),
          question_marks: parseInt(question[question.length - 1].question_marks),
          obtained_marks: parseInt(data.response.score),
          question_context: 0,
          image_file_name: question[question.length - 1].image_file_name,
          prompt: JSON.stringify(data.message),
          have_code: question[question.length - 1].have_code,
          have_table: question[question.length - 1].have_table,
        },
      ]);
      setQuestionSet({
        question: question[question.length - 1].question,
        answer:
          question[question.length - 1].have_table === 1
            ? _tableData
            : question[question.length - 1].have_code === 0
            ? answer
            : question[question.length - 1].answer,
        rubric: question[question.length - 1].rubric,
        ai_feedback: data.response.feedback,
        actual_answer: question[question.length - 1].answer,
        question_marks: question[question.length - 1].question_marks,
        obtained_marks: parseInt(data.response.score),
        question_context: 0,
        image_name: question[question.length - 1].image_file_name,
        prompt: JSON.stringify(data.message),
        input_token: inputToken.current,
        output_token: outputToken.current,
      });
      // setQuestion((prevState) => prevState.map((item, index) => (index === prevState.length - 1 ? { ...item, answer: newValue.trim() } : item)));

      setTimeout(() => {
        // Stop showing quiz preparation loading once questions are fetched
        // setStage('feedback');
        setAnalyzing(false);
        setAttemptAdded(true);
      }, 4000);
    } catch (error) {
      // setError(error.message);
    } finally {
    }
  };

  const submitResponse = async () => {
    console.log("-=-=-=-=-=-=-Submit Response-=-=-=-===-==-");
    // var sbj_id = localStorage.getItem("subject_id");
    // var sbj_name = localStorage.getItem("subject_name");
    // var topic_id = localStorage.getItem("topic_id");
    // var studentId = localStorage.getItem("student_id");
    // const token = localStorage.getItem("access_token");
    var email = localStorage.getItem("email_address");

    try {
      const response = await fetch(`${process.env.REACT_APP_MOCKBOT_IN_TEEPEE_BASE_URL}/submit_response_authentic_student_teepee`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          student_id: student.mockbotStudentId,
          student_email: email,
          subject_id: subject.subjectId,
          subject_name: subject.subjectName,
          topic_id: topic.topicIdsInCSV,
          topic_name: topic.topicNamesInCSV,
          question_list: questionSet,
          response_id: responseId ? responseId : null,
          current_date: moment().format("Y-MM-DD HH:mm:ss"),
        }),
      });
      if (response.ok) {
        const data = await response.json();
        // console.log("data in response" + JSON.stringify(data.response_id));
        setResponseId(data.response_id);
      } else {
      }
    } catch (error) {
      alert(error.message);
    }
  };

  useEffect(() => {
    if (attemptAdded) {
      // Reset the flag
      setAttemptAdded(false);

      // Now that attempts have been updated, you can safely call submitResponse
      submitResponse();

      // If you need to do something after a certain delay, you can still use setTimeout here
      setTimeout(() => {
        setAnalyzing(false);
        // Any other cleanup or subsequent actions
      }, 4000);
    }
  }, [questionSet, attemptAdded]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [analyzing]);

  const handleEnd = () => {
    setCurrentPage("summary");
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  const handleNext = () => {
    fetchQuestions();
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  // console.log("QUESTION IS: ", question[0], question.length);

  return (
    <div>
      <Sidebar />
      {currentPage !== "summary" && (
        <div className=" py-4">
          <span
            onClick={() => {
              navigate(-1);
            }}
            className="md:ml-[68px] cursor-pointer flex sm:ml-[4px]  gap-2 font-semibold text-[#444780]"
            style={{
              fontFamily: "Roboto",
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            <img src={left_icon} alt="" /> Back
          </span>
        </div>
      )}
      <div ref={containerRef} className="overflow-y-scroll max-h-[90vh] w-full scrollbar-hidden">
        {currentPage === "summary" && (
          <SessionSummary attempts={attempts} mockbotStudentId={student.mockbotStudentId} subject={subject} classroomId={classroomId} />
        )}
        {currentPage !== "summary" && (
          <>
            <div className="flex gap-2 md:ml-[68px] sm:ml-[4px] my-2">
              <img className="h-[24px] w-[24px]" src={subject_icon} alt="" />
              <span
                className="text-[#322E33] opacity-80"
                style={{
                  fontFamily: "Roboto",
                  fontSize: "20px",
                  lineHeight: "24px",
                }}
              >
                {subject.subjectName}
              </span>
            </div>
            <div className="flex gap-2 md:ml-[68px] sm:ml-[4px] mt-2 relative group">
              <img className="h-[24px] w-[24px]" src={topic_icon} alt="" />
              <span
                className="text-[#322E33] opacity-80 overflow-hidden whitespace-nowrap text-ellipsis"
                style={{
                  fontFamily: "Roboto",
                  fontSize: "20px",
                  lineHeight: "24px",
                  maxWidth: "480px", // Set the desired max width
                }}
              >
                <b>Topic:</b> {topic.topicNamesInCSV === "undefined" ? "All Topics" : topic.topicNamesInCSV}
              </span>
              <div
                className="absolute hidden group-hover:block bg-white text-black text-sm font-medium p-2 rounded-md shadow-md z-50"
                style={{
                  // bottom: "calc(100% + 8px)", // Position above the cursor/element with a small gap
                  left: "calc(20% + 80px)", // Align horizontally with the parent element
                  whiteSpace: "pre-wrap", // Preserve the full text layout
                  maxWidth: "400px", // Restrict the tooltip width
                  border: "1px solid #ccc", // Optional: Add a border for styling
                }}
              >
                {topic.topicNamesInCSV === "undefined" ? "All Topics" : topic.topicNamesInCSV}
              </div>
            </div>

            {question &&
              (showBodyBuilder ? (
                <div className="flex justify-center items-center min-h-[80vh]">
                  <div className="text-center">
                    <div role="status">
                      <Lottie options={defaultOptions1} height={256} width={400} />
                    </div>
                    <br />
                    <div
                      className="text-[#322E33] font-semibold mb-[8px]"
                      style={{
                        fontSize: "22px",
                        fontFamily: "Roboto",
                        lineHeight: "28px",
                      }}
                    >
                      Oops, you caught me still prepping this one!
                    </div>
                    <div
                      className="text-[#322E33] font-regular"
                      style={{
                        fontSize: "16px",
                        fontFamily: "Roboto",
                        lineHeight: "24px",
                      }}
                    >
                      I'm piecing together some challenging questions. In the meantime, why not <br></br> practise other topics?
                    </div>
                    <div className="flex justify-center w-full mt-[16px]">
                      {/* <button
                      className="px-6 py-2 mr-3 w-[176px] text-sm text-[#444780] border border-[#444780] background-transparent rounded hover:bg-gray-200 focus:outline-none"
                      onClick={() => navigate(-1)}
                    >
                      Go back Home
                    </button> */}
                      <button
                        className="bg-[#6750A4] w-[200px] hover:bg-[#444780] hover:opacity-[0.7]  text-white  py-2 px-6 rounded transition duration-150 ease-in-out flex items-center justify-center"
                        onClick={() => navigate(-1)}
                      >
                        View Other Topics
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <QuestionDisplay
                  question={question[question.length - 1]}
                  questionNumber={question.length}
                  answer={answer}
                  setAnswer={setAnswer}
                  analyzing={analyzing}
                  onSubmit={handleSubmit}
                  feedback={submissionResult.feedback}
                  marks={submissionResult.score}
                  onEnd={handleEnd}
                  prompts={prmpt}
                  onNext={handleNext}
                  mockbotStudentId={student.mockbotStudentId}
                  setQuestion={setQuestion}
                  topic={topic}
                  tableRef={tableRef}
                  tableDataRef={tableData}
                />
              ))}
          </>
        )}
      </div>
    </div>
  );
}

export default QuestionPage;
