import React, { Fragment, useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import "bootstrap/dist/css/bootstrap.min.css";
import successGif from "../images/successful.gif";
import receivingFeedback from "../images/receiving-feedback.gif";
import opwnaiDownImg from "../images/welcome-hand.gif";
import axios from "axios";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextareaAutosize from "react-textarea-autosize";
import Button from "@mui/material/Button";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import GoogleButton from "react-google-button";
import QuizReviewCard from "./QuizReviewCard";
import amplitude from "amplitude-js";
import CountDownTimer from "./CountDownTimer";
import google_icon from "../images/ic_google.svg";
import { useMsal } from "@azure/msal-react";
import microsoft_icon from "../images/ic_microsoft.svg";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import AcknowledgementDialogue from "./Teacher/confirmationdialogue/AcknowledgementDialogue";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { PDFDownloadLink } from "@react-pdf/renderer";
import QuizReviewDocument from "./QuizReviewDocument";
import QuizReviewDocumentMinimock from "./QuizReviewDocumentMinimock";
import Download from "../images/download.png";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import Tick from "../images/green-tick.png";
import TrialMocks from "./QuizSettings/TrialMocks";
import { useLocation, useNavigate } from "react-router-dom";
import StudentEmail from "./QuizSettings/studentEmail";
import Transition from "./QuizSettings/Transition";
import CodeEditor from "./widgets/CodeEditor";
import TeacherDetailsDialogue from "./TeacherDetailsDialogue";
import TypewriterEffect from "./TypewriterEffect";
import QuizReviewCardTopicQuiz from "./QuizReviewCardTopicQuiz";
import Chatbot from "./TopicBasedQuizChatbot";
import moment from "moment";
function TopicBasedQuiz() {
  const { instance } = useMsal();
  const [response_data, setResponseData] = useState([]);
  const [sso_required, setSsoRequired] = useState(false);
  const [quizTotalMarks, setQuizTotalMarks] = useState(0);
  const [quizDuration, setQuizDuration] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const [mock_exam, setMockExam] = useState(null);
  const [outsideClassroomQuiz, setOutsideClassroomQuiz] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const minimock = location.state?.minimock;
  const [isAnswerRubric, setIsAnswerRubric] = useState();
  const [email, setEmail] = useState();
  const [code, setCode] = useState("");
  const student_name = useRef(localStorage.getItem("student_name") ? localStorage.getItem("student_name") : null);
  const student_email = useRef();
  let code_status = Number(localStorage.getItem("code_status"));
  const quiz_title = useRef("");
  const [notEligibleForQuiz, setNotEligibleForQuiz] = useState(false);
  const question_answers = useRef([]);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  var id = params.get("id");
  const prevCount = useRef(1);
  const [open, setOpen] = React.useState(false);
  const [quizData, setQuizData] = useState([]);
  const [quizType, setQuizType] = useState();
  const [quizStarted, setQuizStarted] = useState(false);
  const [responseSubmitted, setResponseSubmitted] = React.useState(false);
  const [quizResponseId, setQuizResponseId] = React.useState(null);
  const userType = localStorage.getItem("user_type");
  const teacherEmail = localStorage.getItem("email_address");
  const token = localStorage.getItem("access_token");
  const studentName = localStorage.getItem("student_name");
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState(null);
  const [enableCopyPaste, setEnableCopyPaste] = useState(false);
  const [isLoggedInByMicrosoft, setIsLoggedInByMicrosoft] = useState(false);
  const [quizfeedback, setQuizFeedback] = useState();
  const [graceTime, setGraceTime] = useState(0);
  const [autosubmission, setAutoSubmission] = useState(false);
  const graceTimeToggle = useRef(false);
  const [orignalArray, setOrignalArray] = useState([]);
  const [shuffleQuestion, setShuffleQuestion] = useState(0);
  const shuffledMock = useRef(null);
  const [graceTimeDialogue, setGraceTimeDialogue] = useState(false);
  const [autoSubmissionDialogue, setAutoSubmissionDialogue] = useState(false);
  const [extraTimeDialodue, setExtraTimeDialogue] = useState(false);
  const [quizSubmittedDialogue, setQuizSubmittedDialogue] = useState(false);
  const refreshIntervalId = useRef();
  const [percentage, setPercentage] = useState(null);
  const [shimmerPercentage, setShimmerPercentage] = useState(null);
  const [isPolling, setIsPolling] = useState(false);
  const [questionResponseList, setQuestionResponseList] = useState([]);
  const [spinnerVisibility, setSpinnerVisibility] = useState([]);
  const [show, setShow] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isOpenTeacherDetailDialog, setIsOpenTeacherDetailDialog] = useState(false);
  const [quizCreator, setQuizCreator] = useState();
  const [isCompleted, setIsCompleted] = useState(false);
  const [mockAttempterEmail, setMockAttempterEmail] = useState("");
  const [showChatbot, setShowChatbot] = useState(false);
  const [startTypeWritter, setStartTypeWriter] = useState(false);

  const handleToggleChatbot = () => {
    setShowChatbot(!showChatbot); // Toggle chatbot display
  };

  const toastMessage = useRef("");
  var emailFlag = useRef(false);
  const pollerIteration = useRef(1);

  const isGrace = useRef();
  var timeTaken = useRef(0);
  var startQuizCount = 0;

  const handleChangeGraceTime = () => {
    isGrace.current = true;
  };
  // const [quizTiming, seetQuizTiming] = useState();
  // const [remainingQuizTiming, setRemainingQuizTiming] = useState();

  const handleOpenGraceTimeDialogue = () => {
    setGraceTimeDialogue(true);
  };
  const handleCloseGraceTimeDialogue = () => {
    console.log("called");
    setGraceTimeDialogue(false);
  };

  const handleOpenAutoSubmissionDialogue = () => {
    setAutoSubmissionDialogue(true);
  };
  const handleCloseAutoSubmissionDialogue = () => {
    setAutoSubmissionDialogue(false);
  };
  const handleOpenExtraTimeDialogue = () => {
    setExtraTimeDialogue(true);
  };
  const handleCloseExtraTimeDialogue = () => {
    setExtraTimeDialogue(false);
  };

  const handleOpenQuizSubmittedDialogue = () => {
    setQuizSubmittedDialogue(true);
  };
  const handleCloseQuizSubmittedDialogue = () => {
    setQuizSubmittedDialogue(false);
  };
  const resetGraceTime = () => {
    setGraceTime(0);
  };

  const handleGraceTime = () => {
    graceTimeToggle.current = false;
  };
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });
  function stripPTags(str) {
    return str.replace(/<p>/g, "");
  }
  function removeHtmlTags2(str) {
    if (str === null || str === "") return "";
    str = str.toString();

    // Step 1: Replace <br> and <p> with newlines to preserve line breaks
    str = str.replace(/<br\s*\/?>|<\/p>/g, "\n").replace(/<p[^>]*>/g, "");

    // Remove other HTML tags
    str = str.replace(/<\/?[^>]+(>|$)/g, "");

    // Wrap text between ##code markers in <pre><code> tags
    str = str.replace(/##code([\s\S]*?)##code/g, "<pre><code>$1</code></pre>");

    return str;
  }

  const authHandler = (err, data) => {
    console.log(err, data);
    console.log(data.account.name);
    // setName(data.account.name);
    // setUserName(data.account.username);
    setProfile({ name: data.account.username, email: data.account.username });
    student_name.current = data.account.username;
    student_email.current = data.account.username;
  };

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    googleLogout();
    setProfile(null);
  };

  useEffect(() => {
    console.log("data here", location.state);

    if (userType != null && token != null && userType === "2") {
      setEnableCopyPaste(true);
    } else {
      setEnableCopyPaste(false);
    }
  }, [token, userType]);

  useEffect(() => {
    if (user) {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: "application/json",
          },
        })
        .then((res) => {
          // setProfile(res.data);
          checkUserQuizAttemptStatus(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  function checkUserQuizAttemptStatus(profile) {
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/check_user_quiz_attempt_status", {
        method: "POST",
        quiz_secret_key: id,
        email_address: profile.email,
      })

      .then((response) => {
        console.log(response);

        var user_quiz_attempt_status = response.data["user_quiz_attempt_status"];
        setNotEligibleForQuiz(user_quiz_attempt_status);
        if (user_quiz_attempt_status === false) {
          setProfile(profile);
          student_name.current = profile.name;
          student_email.current = profile.email;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (id.length > 0) {
      if (location.state?.exam_style) {
        if (location.state?.experience === "Teacher") {
          amplitude.getInstance().logEvent("TEQuizScreenView");
        } else {
          amplitude.getInstance().logEvent("VEQuizScreenView");
        }
      } else {
        if (location.state?.experience === "Teacher") {
          amplitude.getInstance().logEvent("TEMiniMockScreenView");
        } else {
          amplitude.getInstance().logEvent("VEMiniMockScreenView");
        }
      }
      var submittedQuizes = localStorage.getItem("submitted_quizes");

      if (
        submittedQuizes !== null &&
        submittedQuizes.split(",").find((element) => {
          return element === id;
        })
      ) {
        if (!(userType != null && token != null && userType === "2")) {
          setNotEligibleForQuiz(true);
          return;
        }
      }
      // handleClickScroll();
      axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
      axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

      axios
        .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_quiz_details", {
          method: "POST",
          quiz_secret_key: id,
          current_date: moment().format("Y-MM-DD HH:mm:ss"),
        })

        .then((response) => {
          console.log(response);
          setQuizCreator(response.data.web_user_id);
          var question_answers_list = JSON.parse(response.data["question_answers"]);

          quiz_title.current = response.data["quiz_title"];
          setSsoRequired(response.data["sso_required"]);
          console.log(question_answers_list);
          question_answers.current = question_answers_list;
          setQuizFeedback(response.data["quiz_instant_feedback_status"]);
          setShuffleQuestion(response.data["shuffle_quiz_questions"]);
          if (response.data["shuffle_quiz_questions"] === 1 && response.data["quiz_type"] !== 2) {
            setOrignalArray(JSON.parse(response.data["question_answers"]));
            setResponseData(shuffleArray(question_answers.current));
          } else {
            setResponseData(JSON.parse(response.data["question_answers"]));
          }
          if (response.data["mock_exam"].length > 0) {
            let mock_exam = JSON.parse(response.data["mock_exam"]);
            if (response.data["shuffle_quiz_questions"]) {
              shuffledMock.current = JSON.parse(response.data["mock_exam"]);
              shuffledMock.current.question_context_list = shuffleArray(JSON.parse(mock_exam.question_context_list));
              setMockExam(JSON.parse(response.data["mock_exam"]));
            } else {
              setMockExam(JSON.parse(response.data["mock_exam"]));
            }
          }
          setQuizTotalMarks(response.data["quiz_total_marks"]);

          if (location.state) {
            localStorage.removeItem("startQuizCount");
            localStorage.removeItem("quizzes");
            localStorage.removeItem("deadline");
            localStorage.removeItem("grace_deadline");
          }

          setQuizDuration(response.data["quiz_duration"]);
          if (response.data["quiz_duration"] === 0) {
            if (response.data["mock_exam"]) {
              setQuizDuration(JSON.parse(response.data["mock_exam"]).mock_exam_duration * 60);
            }
          }
          setGraceTime(response.data["quiz_grace_time"]);
          setAutoSubmission(response.data["quiz_auto_submission"] === 0 ? false : true);

          setQuizType(response.data["quiz_type"]);
          if (response.data["quiz_type"] === 1 || response.data["quiz_type"] === 3) {
            setQuizStarted(true);
          }
          if (location.state) {
            if (response.data["quiz_type"] === 2) {
              setQuizStarted(false);
            }
          }
          if (localStorage.getItem("startQuizCount") !== null) {
            setQuizStarted(true);
          }
          //question_answers.current = response.data['question_answers'];
          //setResponseData(question_answers.current)
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id]);
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const isSubmitting = useRef(false);
  const isUnleaching = useRef(false);

  function submitForm() {
    isSubmitting.current = true;
    return new Promise((resolve) => {
      if (student_name.current === undefined || student_name.current === "" || student_name.current === null) {
        if (location.state) {
          alert("Please enter Your name");
        } else {
          alert("Please enter student name");
        }

        resolve();
        isSubmitting.current = false;

        return;
      }

      handleDelateOptionClick(resolve);
    });
  }

  function onUnleashFeedbackClicked(quizResponseId) {
    document.getElementById("analyzing").style.display = "block";
    isUnleaching.current = true;
    var token = "Bearer " + localStorage.getItem("access_token");
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_question_response_list",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: token,
        },
        data: JSON.stringify({
          quiz_secret_key: id,
          quiz_response_id: quizResponseId,
          get_teacher_feedback: false,
        }),
      })
      // Modify the response handling
      .then((res) => {
        console.log(res.data);

        // Create a promise that resolves when typewriter is complete
        const waitForTypewriter = new Promise((resolve) => {
          // Start typewriter and pass the resolve function
          setResponseSubmitted(true); // This triggers TypewriterEffect to start

          // Create an interval to check if typewriter is completed
          const checkCompletion = setInterval(() => {
            if (isCompleted) {
              // isCompleted is the state we're already using in TypewriterEffect
              clearInterval(checkCompletion);
              resolve();
            }
          }, 100);
        });

        // Wait for typewriter to complete before continuing with evaluation
        waitForTypewriter.then(() => {
          setIsAnswerRubric(res.data["show_answer_rubric"]);
          setShimmerPercentage(res.data["percentage"]);
          const questionResponseList = JSON.parse(res.data["question_reponse_list"]);
          const totalScore = questionResponseList.reduce((accumulator, currentQuiz) => accumulator + currentQuiz.score, 0);
          let calculatedPerformance = totalScore ? totalScore : 0;

          // Set the quiz data and calculated performance
          setQuizData(questionResponseList);
          setPercentage(calculatedPerformance);
          setSpinnerVisibility(JSON.parse(res.data["question_reponse_list"]).map(() => true));
          if (spinnerVisibility.length !== 0) {
            setSpinnerVisibility(
              JSON.parse(res.data["question_reponse_list"]).map((value, index) => {
                if (spinnerVisibility[index]) {
                  return true;
                } else {
                  return false;
                }
              })
            );
          }

          pollerIteration.current = ++pollerIteration.current;
          JSON.parse(res.data["question_reponse_list"]).forEach((quiz, index) => {
            if (quiz.feedback !== null) {
              setSpinnerVisibility((prevState) => {
                const newState = [...prevState];
                newState[index] = false;
                const count = newState.filter((value) => !value).length;

                // Check if the count value has changed
                if (prevCount.current !== count) {
                  toastMessage.current = `Question ${count} of ${newState.length} evaluated`;
                  setShow(true);
                  prevCount.current = count; // Update the previous count
                }

                return newState;
              });
            }
          });

          setQuizType(res.data["quiz_type"]);
          isUnleaching.current = false;
          document.getElementById("analyzing").style.display = "none";
          pollerIteration.current = ++pollerIteration.current;
        });
      });
  }

  function startQuiz() {
    if (localStorage.getItem("startQuizCount") === null) {
      setQuizStarted(true);
      localStorage.setItem("startQuizCount", 1);
    }
  }

  const onChange = (selectedQuestionId, e) => {
    //setQuizDetails({ ...quizDetails, [e.target.name]: e.target.value });
    let index = e.target.id;

    var inputAnswer = e.target.value;
    //var count = e.target.value;
    // create array of words
    // const lines = inputAnswer.split('\n');
    // let lastWord = ""
    // let wordCount = 0;
    // lines.forEach((line) => {
    //   let words = line.split(' ');
    //   words.forEach((word) => {
    //       if (word.trim() !== '') {
    //         wordCount++;
    //         lastWord=word.trim();
    //       }
    // })});

    // if (count.length === 0) {
    //   wordCount=0;
    // } else {
    //   count = count.replace(/(^\s*)|(\s*$)/gi,"");
    //   count = count.replace(/[ ]{2,}/gi," ");
    //   count = count.replace(/\n /,"\n");
    //   wordCount = count.split(' ').length;
    // }

    // if(lastWord.length>45)
    // {
    //   document.getElementById("counter-"+index).innerText="One word cannot be more then 45 characters";
    //   document.getElementById(""+index).value=response_data[index].answer.trim();
    //   document.getElementById("counter-"+index).classList.add("words-limit-reached");
    //   return;
    // }

    if (inputAnswer.length > 1000) {
      // document.getElementById("counter-"+index).innerText="Answer cannot be inserted more then 1000 characters";
      document.getElementById("" + index).value = response_data[index].answer.trim();

      document.getElementById("counter-" + index).classList.add("words-limit-reached");
      document.getElementById("" + index).classList.add("words-limit-reached-input-field");
      return;
    }

    if (shuffleQuestion === 1 && quizType !== 2) {
      for (let indexloop = 0; indexloop < orignalArray.length; indexloop++) {
        const originalQuestion = orignalArray[indexloop];
        if (originalQuestion.quiz_question_id === response_data[index].quiz_question_id) {
          originalQuestion.answer = inputAnswer.trim();
        }
      }
    } else if (shuffleQuestion === 1 && quizType === 2) {
      console.log("dasdasd", selectedQuestionId);
      for (let indexloop = 0; indexloop < response_data.length; indexloop++) {
        const question = response_data[indexloop];
        if (question.predefined_question_answer_id === selectedQuestionId) {
          response_data[indexloop].answer = inputAnswer.trim();
        }
      }
      // var quiz_question = null;
      // for (let index = 0; index < shuffledMock.current.question_context_list.length; index++) {
      //   const question = shuffledMock.current.question_context_list[index];
      //   console.log("question we need",JSON.parse(question.predefined_question_answer_list));
      //   quiz_question = quiz_question.push(question.predefined_question_answer_list);
      //   console.log("object")
      // }
    } else {
      response_data[index].answer = inputAnswer.trim();
    }

    console.log("response data: ", response_data);

    document.getElementById("counter-" + index).innerText = "" + inputAnswer.trim().length + "/1000 characters";
    document.getElementById("counter-" + index).classList.remove("words-limit-reached");
    document.getElementById("" + index).classList.remove("words-limit-reached-input-field");
  };
  const onChange2 = (index, newValue) => {
    console.log("Input change detected for index:", index, "New Value:", newValue);

    // Update the response_data or state based on the index and new value
    response_data[index].answer = newValue.trim();

    // Additional conditions or validations can be added here
    if (newValue.length > 1000) {
      console.warn("Character limit exceeded for index:", index);
      // Display character limit warning or handle accordingly
      return;
    }

    // Update character count display if needed
    document.getElementById(`counter-${index}`).innerText = `${newValue.length}/1000 characters`;
  };

  const onChangeStudentName = (e) => {
    student_name.current = e.target.value;
  };

  const onChangeStudentEmail = (e) => {
    student_email.current = e.target.value;
  };

  const handleCloseWithCancel = () => {
    setOpen(false);
    isSubmitting.current = false;
  };

  function updateRemainingTime(time) {
    setRemainingTime(time);
  }

  useEffect(() => {
    if (shimmerPercentage === null) {
      if (isPolling) {
        var intervalTime;
        if (quizType === 2) {
          intervalTime = pollerIteration.current === 1 ? 2000 : 500;
        } else {
          intervalTime = pollerIteration.current === 1 ? 10000 : 5000;
        }
        const interval = setInterval(() => {
          onUnleashFeedbackClicked(quizResponseId);
        }, intervalTime);
        return () => clearInterval(interval);
      }
    } else {
      setIsPolling(false);
    }
  }, [isPolling, quizResponseId, onUnleashFeedbackClicked]);

  const evaluatesQuiz = (quizResponseId, responseQuestionList, evaluationList) => {
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/evaluates_quiz",

        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: token,
        },
        data: JSON.stringify({
          quiz_secret_key: id,
          quiz_response_id: quizResponseId,
          question_response_list: responseQuestionList,
          list_for_evaluation: evaluationList,
        }),
      })
      .then((res) => {
        console.log(res.data);
      });
  };

  const quizTakerInsideClassroom = () => {
    var submittedQuizes = localStorage.getItem("submitted_quizes");

    if (
      submittedQuizes !== null &&
      submittedQuizes.split(",").find((element) => {
        return element === id;
      })
    ) {
      if (!(userType != null && token != null && userType === "2")) {
        setNotEligibleForQuiz(true);
        return;
      }
    }
    setResponseSubmitted(true);

    var submittedQuizes = localStorage.getItem("submitted_quizes");

    if (submittedQuizes !== null || submittedQuizes !== "") {
      submittedQuizes += "," + id;
    } else {
      submittedQuizes = id;
    }

    let quiz_attempt_duration;

    if (isGrace.current) {
      console.log("GRACE TIME EXECUTED: ");
      console.log(quizDuration + graceTime - remainingTime);
      quiz_attempt_duration = quizDuration + graceTime - remainingTime;
    } else {
      console.log("WITHOUT GRACE TIME: ");
      console.log(quizDuration - remainingTime);
      quiz_attempt_duration = quizDuration - remainingTime;
    }

    localStorage.setItem("submitted_quizes", submittedQuizes);
    var quizList = [];
    quizList = localStorage.getItem("quizzes") !== null ? JSON.parse(localStorage.getItem("quizzes")) : [];
    quizList = quizList.filter((quiz) => quiz.secret_key !== id);
    localStorage.setItem("quizzes", JSON.stringify(quizList));
    setOpen(false);
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;

    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/save_quiz_answers", {
        method: "POST",
        student_name: student_name.current,
        student_email: student_email.current,
        question_answers: shuffleQuestion === 1 && quizType !== 2 ? orignalArray : response_data,
        mock_exam: mock_exam,
        quiz_attempt_duration: quiz_attempt_duration,
        quiz_secret_key: id,
        class_student: student_name.current ? " " : null,
        quiz_instant_feedback_status: quizfeedback,

        //   sub_topic_id_list: quizDetails.subTopic,
        //   number_of_questions: quizDetails.numOfQuestions,
      })

      .then((response) => {
        console.log("THIS RESPONSE", response);
        setQuizResponseId(response.data["quiz_response_id"]);

        // onUnleashFeedbackClicked(response.data["quiz_response_id"]);

        evaluatesQuiz(response.data["quiz_response_id"], response.data["question_response_list"], response.data["list_for_evaluation"]);
        setIsPolling(true);
        amplitude.getInstance().logEvent("QuizSubmit", "student has submitted the quiz");
      })
      .catch((error) => {
        console.log(error);

        document.getElementById("analyzing").style.display = "none";
        document.getElementById("openaidown").style.display = "block";
      });
  };

  const quizTakerOutsideClassroom = (email) => {
    if (location.state) {
      if (location.state?.experience === "Teacher") {
        amplitude.getInstance().logEvent("TEQuizEvalBtnClk");
      } else {
        amplitude.getInstance().logEvent("VEQuizEvalBtnClk");
      }
    }
    if (!location.state) {
      var submittedQuizes = localStorage.getItem("submitted_quizes");

      if (
        submittedQuizes !== null &&
        submittedQuizes.split(",").find((element) => {
          return element === id;
        })
      ) {
        if (!(userType != null && token != null && userType === "2")) {
          setNotEligibleForQuiz(true);
          return;
        }
      }
    }
    setResponseSubmitted(true);

    setOpen(false);
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";

    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/save_quiz_answers_outside_classroom", {
        method: "POST",
        student_name: student_name.current,
        subject_name: location.state?.subject_name ? location.state?.subject_name : null,
        student_email: email?.length > 0 ? email : teacherEmail,
        question_answers: shuffleQuestion === 1 ? orignalArray : response_data,
        mock_exam: mock_exam ? mock_exam : location.state?.minimock[0],
        quiz_attempt_duration: isGrace ? quizDuration + graceTime - remainingTime : quizDuration - remainingTime,
        quiz_secret_key: id,
        class_student: studentName ? " " : null,
        user_status: location.state?.experience ? 1 : 0,
        student_email: email,
        //   sub_topic_id_list: quizDetails.subTopic,
        //   number_of_questions: quizDetails.numOfQuestions,
      })

      .then((response) => {
        console.log(response);
        setIsSubmit(false);
        handleToggleChatbot();
        emailFlag.current = false;
        setQuizResponseId(response.data["quiz_response_id"]);
        if (response.data["quiz_response_id"] === null) {
          setOutsideClassroomQuiz(true);
        }

        setResponseSubmitted(true);
        setTimeout(() => {
          setStartTypeWriter(true);
        }, 4000);
        if (!location.state) {
          var submittedQuizes = localStorage.getItem("submitted_quizes");

          if (submittedQuizes !== null || submittedQuizes !== "") {
            submittedQuizes += "," + id;
          } else {
            submittedQuizes = id;
          }

          localStorage.setItem("submitted_quizes", submittedQuizes);
        }
        console.log("ALI LIST", response.data["question_response_list"], response.data["list_for_evaluation"]);
        evaluatesQuiz(response.data["quiz_response_id"], response.data["question_response_list"], response.data["list_for_evaluation"]);
        setIsPolling(true);
        if (location.state) {
        } else {
          amplitude.getInstance().logEvent("QuizSubmit", "student has submitted the quiz");
        }
      })
      .catch((error) => {
        console.log(error);

        document.getElementById("analyzing").style.display = "none";
        document.getElementById("openaidown").style.display = "block";
      });
  };

  const handleCloseWithYes = () => {
    if (location.state?.quiz_setting !== undefined) {
      clearInterval(refreshIntervalId.current);
      localStorage.removeItem("deadline");
      localStorage.removeItem("grace_deadline");
      teepeeExperinceFlow();
    } else {
      var quizTaker = localStorage.getItem("quiz_taker");
      clearInterval(refreshIntervalId.current);
      localStorage.removeItem("deadline");
      localStorage.removeItem("grace_deadline");
      localStorage.removeItem("startQuizCount");

      if (quizTaker === "1") {
        quizTakerInsideClassroom();
      } else {
        quizTakerOutsideClassroom();
      }
    }
  };

  const teepeeExperinceFlow = () => {
    if (location.state?.exam_style) {
      if (location.state?.experience === "Teacher") {
        amplitude.getInstance().logEvent("TEQuizSbmitBtn");
      } else {
        amplitude.getInstance().logEvent("VEQuizSbmitBtn");
      }
    } else {
      if (location.state?.experience === "Teacher") {
        amplitude.getInstance().logEvent("TEMockSbmitBtn");
      } else {
        amplitude.getInstance().logEvent("VEMockSbmitBtn");
      }
    }
    if (location.state?.experience === "Teacher") {
      quizTakerOutsideClassroom();
      setOpen(false);
    } else {
      emailFlag.current = true;

      amplitude.getInstance().logEvent("VEEnterEmailScreen");
      setOpen(false);
    }
  };

  const handleDelateOptionClick = (event) => {
    //setUserMessage(param1)
    //setPrompt(param2)
    setOpen(true);
  };

  const loginWithMicrosoft = () => {
    instance
      .loginPopup({
        scopes: ["email"],
      })
      .then((result) => {
        return result;
      })
      .then((result) => {
        //checkUserAccountAndThenLogin(result.account.username, 3)
        setProfile({
          name: result.account.username,
          email: result.account.username,
        });
        student_name.current = result.account.username;
        student_email.current = result.account.username;
        setIsLoggedInByMicrosoft(true);
      })
      .catch((err) => {
        console.log(err.errName, err.errType);
      });
  };

  let q_index = 0;
  let p_index = 0;

  const mapConditionForMock = (isShuffledArray) => {
    return isShuffledArray === null ? JSON.parse(mock_exam.question_context_list) : shuffledMock.current.question_context_list;
  };

  const handleTopicBasedFlow = (email) => {
    setMockAttempterEmail(email);
    setIsSubmit(true);
    setIsOpenTeacherDetailDialog(false);
    quizTakerOutsideClassroom(email);
  };

  const handleOpenTeacherDialogue = () => {
    setIsOpenTeacherDetailDialog(true);
  };

  return (
    <>
      <div style={{ display: emailFlag.current === false ? "block" : "none" }}>
        <ToastContainer className="p-3 position-fixed top-12 right-0">
          <Toast style={{ backgroundColor: "#f7f2f9" }} onClose={() => setShow(false)} show={show} delay={2000} autohide>
            <Toast.Body>
              <div className="flex">
                <img src={Tick} className="pr-[2%]"></img>
                {toastMessage.current}
              </div>
            </Toast.Body>
          </Toast>
        </ToastContainer>
        {!profile && !notEligibleForQuiz && sso_required ? (
          <div>
            <div className="form-div2">
              <div className="w-[800px]">
                <div>
                  <div className="w-full">
                    <p
                      style={{
                        display: quizData.length === 0 ? "" : "none",
                        marginBottom: "0px",
                        fontFamily: "Roboto",
                        fontSize: "24px",
                        textAlign: "center",
                        marginTop: "40px",
                      }}
                    >
                      Welcome, quiz taker! It's great to have you here.
                    </p>
                    <div className="w-full h-full bg-white centered ">
                      <div className="main-content-div ">
                        <div>
                          <div className="w-full">
                            <div
                              className="mt-[20px]"
                              style={{
                                position: "relative",
                                //  display:'flex',
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <button
                                type="submit"
                                className="create-new-account-button border-[1px] border-[#474747] rounded-md hover:bg-black text-[black] hover:text-white font-bold py-2 px-4 w-full h-[45px]"
                                onClick={() => login()}
                              >
                                <div style={{ display: "inherit" }}>
                                  <div className="flex">
                                    <img alt="" className="float-left w-[26px] mr-[10px]" src={google_icon}></img>
                                    Continue with Google
                                  </div>
                                </div>
                              </button>
                            </div>
                            <div
                              className="mt-[20px]"
                              style={{
                                position: "relative",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <button
                                type="submit"
                                className="create-new-account-button border-[1px] border-[#474747] rounded-md hover:bg-black text-[black] hover:text-white font-bold py-2 px-4 w-full h-[45px]"
                                onClick={() => loginWithMicrosoft()}
                              >
                                <div style={{ display: "inline-block" }}>
                                  <div className="flex">
                                    <img alt="" className="float-left w-[26px] mr-[10px]" src={microsoft_icon}></img>
                                    Continue with Microsoft
                                  </div>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : notEligibleForQuiz && !location.state ? (
          <div className="form-div2">
            <div className="w-[800px]">
              <div>
                <div className="w-full">
                  <p
                    style={{
                      display: quizData.length === 0 ? "" : "none",
                      marginBottom: "0px",
                      fontFamily: "Roboto",
                      fontSize: "24px",
                      textAlign: "center",
                      marginTop: "40px",
                    }}
                  >
                    Hey there! It looks like you've already filled out this form.
                    <br />
                    Unfortunately, you can only submit it once.
                  </p>
                  <p
                    style={{
                      display: quizData.length === 0 ? "" : "none",
                      marginBottom: "0px",
                      fontFamily: "Roboto",
                      fontSize: "24px",
                      textAlign: "center",
                      marginTop: "40px",
                    }}
                  >
                    If you think there's been a mistake, try reaching out to your teacher.
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : quizStarted === true ? (
          <div>
            <AcknowledgementDialogue
              title="Grace time alert!"
              // text={`Heads up! Your ${Math.floor(graceTime / 60)} minutes of extra time has started. Wrap up your answers soon.`}
              text={`Heads up! Your ${Math.floor(graceTime / 60)} ${
                Math.floor(graceTime / 60) === 1 ? "minute" : "minutes"
              }  of extra time has started. Wrap up your answers soon.`}
              handleCloseWithConfirm={handleCloseGraceTimeDialogue}
              open={graceTimeDialogue}
            />
            <AcknowledgementDialogue
              title="Heads up!"
              text={"Your task is about to auto-submit. Make sure you're all set!"}
              handleCloseWithConfirm={handleCloseAutoSubmissionDialogue}
              open={autoSubmissionDialogue}
            />
            <AcknowledgementDialogue
              title="Time's up!"
              text={"You're now using extra time. Please consider finishing soon."}
              handleCloseWithConfirm={handleCloseExtraTimeDialogue}
              open={extraTimeDialodue}
            />
            <AcknowledgementDialogue
              title="Time’s up!"
              text={"Your responses have been recorded and submitted. Appreciate your effort!"}
              handleCloseWithConfirm={handleCloseQuizSubmittedDialogue}
              open={quizSubmittedDialogue}
            />

            <Dialog open={open} onClose={handleCloseWithCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">
                <b>Confirm</b>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Please review your answer before submitting. Once submitted, you cannot change your answer.
                  <br />
                  <br />
                  Are you sure you want to submit task?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseWithCancel}>NO</Button>
                <Button className="delete-button" onClick={handleCloseWithYes} autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
            {isOpenTeacherDetailDialog && <TeacherDetailsDialogue onSubmitTeacherDetailsDialog={handleTopicBasedFlow} isSubmit={isSubmit} />}
            <div style={{ display: responseSubmitted ? "" : "none" }}>
              {/* <div className="home">
          <h1>Add Details</h1>
        </div> */}
              <div className="form-div2">
                <div className="w-[800px]">
                  <div className="sm:p-[10px]">
                    <div
                      id="thanku-section"
                      //   style={{
                      //     display: question_answers.current.length === 0 ? "none" : "",
                      //   }}
                      className="w-full "
                    >
                      {/* <div
                style={{
                  flex: 1,
                  height: "1px",
                  backgroundColor: "#B3B3B3",
                  marginTop: "50px",
                }}
              /> */}
                      <p
                        id="result"
                        style={{
                          display: quizData.length === 0 ? "" : "none",
                          marginBottom: "0px",
                          fontFamily: "Roboto",
                          fontSize: "20px",
                          textAlign: "center",
                          marginTop: "40px",
                          fontWeight: 700,
                          lineHeight: "28px",
                        }}
                      >
                        {outsideClassroomQuiz ? "Hey there! This task can't be evaluated " : "Quiz submitted! Teepee is analysing your responses!"}
                      </p>
                      <p
                        id="result"
                        style={{
                          display: quizData.length === 0 ? "" : "none",
                          marginBottom: "0px",
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          textAlign: "center",
                          fontWeight: 400,
                        }}
                      >
                        {outsideClassroomQuiz
                          ? "Hey there! This task can't be evaluated "
                          : "This could take up to 60 seconds, so please hang tight."}
                      </p>
                      <div
                        style={{
                          display: quizData.length === 0 ? "" : "none",
                        }}
                      >
                        <div id="analyzing">
                          <img alt="" src={receivingFeedback} style={{ margin: "auto", width: "180px" }}></img>
                        </div>

                        <div className="mt-[30px]">
                          {responseSubmitted && startTypeWritter && <TypewriterEffect isCompleted={isCompleted} setIsCompleted={setIsCompleted} />}
                        </div>

                        <div id="openaidown" style={{ display: "none" }}>
                          {/* <p
                  id="result"
                  style={{
                    marginBottom: "0px",
                    fontFamily: "Roboto",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                > */}
                          <p
                            style={{
                              fontSize: "21px",
                              fontFamily: "Roboto",
                              fontStyle: "Bold",
                              fontWeight: "500",
                              textAlign: "center",
                              marginTop: "10px",
                              marginBottom: "5px",
                            }}
                          >
                            Oops, it looks like I need a little more time to evaluate your quiz!
                          </p>

                          <p
                            style={{
                              fontSize: "16px",
                              marginBottom: "10px",
                              fontFamily: "Roboto",
                              textAlign: "center",
                              marginTop: "1px",
                            }}
                          >
                            Don't worry though, I'll email you the report as soon as I'm done. Thank you for your patience!
                          </p>

                          {/* </p> */}

                          <img alt="" src={opwnaiDownImg} style={{ margin: "auto", width: "180px" }}></img>
                        </div>
                      </div>

                      <div
                        style={{
                          display: quizData.length === 0 ? "none" : "block",
                        }}
                      >
                        <p
                          id="result"
                          style={{
                            marginBottom: "30px",
                            fontFamily: "Roboto",
                            fontSize: "24px",
                            fontWeight: "500",
                            textAlign: "center",
                            marginTop: "40px",
                          }}
                        >
                          Teepee's feedback 👇
                        </p>

                        <div
                          style={{
                            display: quizData.length === 0 ? "none" : "block",
                          }}
                        ></div>
                      </div>
                      <div>
                        <div
                          style={{
                            width: "fit-content",
                            marginLeft: "auto",
                            display: quizData.length === 0 ? "none" : "block",
                          }}
                        >
                          {quizType === 1 || quizType === 3 || (mock_exam === null && quizType === 2) ? (
                            <>
                              {/* <PDFViewer>
                  <QuizReviewDocument
                    quizData={quizData}
                    student_name={student_name}
                    marks_achieved={quiz_performace.current}
                  />
                </PDFViewer> */}
                              <PDFDownloadLink
                                document={
                                  <QuizReviewDocument
                                    is_code={code_status.toString()}
                                    quizData={quizData}
                                    student_name={student_name.current}
                                    marks_achieved={percentage}
                                    percentage={shimmerPercentage}
                                    quiz_total_marks={quizTotalMarks}
                                  />
                                }
                                fileName={`${quiz_title.current}` + "_" + `${student_name.current}` + ".pdf"}
                              >
                                <button className="flex downloadCSV-button items-center ml-auto">
                                  <img src={Download} className="mr-[8px]" />
                                  <div>Download PDF</div>
                                </button>
                              </PDFDownloadLink>
                            </>
                          ) : (
                            mock_exam !== null &&
                            quizData.length > 0 && (
                              <>
                                <PDFDownloadLink
                                  document={
                                    <QuizReviewDocumentMinimock
                                      mock_exam={mock_exam}
                                      quizData={quizData}
                                      display_rubric={false}
                                      student_name={student_name.current}
                                      marks_achieved={percentage}
                                      percentage={shimmerPercentage}
                                      quiz_total_marks={quizTotalMarks}
                                    />
                                  }
                                  fileName={`${quiz_title.current}` + "_" + `${student_name.current}` + ".pdf"}
                                >
                                  <button className="flex downloadCSV-button items-center ml-auto">
                                    <img src={Download} className="mr-[8px]" />
                                    <div>Download PDF</div>
                                  </button>
                                </PDFDownloadLink>
                              </>
                            )
                          )}
                        </div>
                      </div>

                      <div id="ifeedback" className=" md:ml-[60px] mt-[20px]">
                        <div
                          className="md:flex sm:block md:flex-row sm:flex-col"
                          style={{
                            display: quizData?.length > 0 ? "flex" : "none",
                          }}
                        >
                          <div className="student-info md:w-[60%]">
                            <label className="teepee-label mr-[10px]">{location.state ? "Your Name :" : "Student Name :"}</label>
                            <div>{student_name.current}</div>
                          </div>
                          <div className="teepee-label md:justify-end sm:justify-start md:pt-0 md:w-[40%]">
                            Marks Achieved:
                            <div className="ml-2"> {percentage + "/" + quizTotalMarks}</div>
                          </div>
                        </div>
                        <div className="mt-[65px]">
                          <QuizReviewCardTopicQuiz
                            readOnly={true}
                            is_code={code_status.toString()}
                            isAnswerRubric={isAnswerRubric}
                            student_name={student_name}
                            quiz_instant_feedback_status={quizfeedback}
                            display_rubric={false}
                            viewThumbsAction={false}
                            quizData={quizData}
                            mock_exam={mock_exam}
                            quizType={quizType}
                            percentage={percentage}
                            quiz_performance={percentage}
                            spinnerVisibility={spinnerVisibility}
                            setQuiz_performance={setPercentage}
                            quizSetting={location.state?.quiz_setting}
                            id={id}
                            email={mockAttempterEmail}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ display: responseSubmitted ? "none" : "" }}>
              <div className="w-full sticky top-0 bg-white">
                <p
                  style={{
                    marginBottom: "0px",
                    fontFamily: "Roboto",
                    textAlign: "center",
                    fontSize: "25px",
                    fontWeight: "700px",
                    marginTop: "10px",
                  }}
                >
                  Task Name: {quiz_title.current}
                  <p
                    style={{
                      marginBottom: "0px",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      textAlign: "center",
                      marginTop: "10px",
                    }}
                  >
                    {quizType === 2 && (
                      <CountDownTimer
                        quiz_secret_key={id}
                        handleChangeGraceTime={handleChangeGraceTime}
                        timeTaken={timeTaken}
                        refreshIntervalId={refreshIntervalId}
                        handleOpenQuizSubmittedDialogue={handleOpenQuizSubmittedDialogue}
                        handleOpenExtraTimeDialogue={handleOpenExtraTimeDialogue}
                        handleOpenAutoSubmissionDialogue={handleOpenAutoSubmissionDialogue}
                        handleOpenGraceTimeDialogue={handleOpenGraceTimeDialogue}
                        submitForm={handleCloseWithYes}
                        updateRemainingTime={updateRemainingTime}
                        start_time={quizDuration}
                        autosubmission={autosubmission}
                        grace_time={graceTime}
                        resetGraceTime={resetGraceTime}
                      />
                    )}
                    {quizDuration > 0 && (
                      <>
                        {quizType !== 2 && (
                          <CountDownTimer
                            quiz_secret_key={id}
                            handleChangeGraceTime={handleChangeGraceTime}
                            timeTaken={timeTaken}
                            refreshIntervalId={refreshIntervalId}
                            handleOpenQuizSubmittedDialogue={handleOpenQuizSubmittedDialogue}
                            handleOpenExtraTimeDialogue={handleOpenExtraTimeDialogue}
                            handleOpenAutoSubmissionDialogue={handleOpenAutoSubmissionDialogue}
                            handleOpenGraceTimeDialogue={handleOpenGraceTimeDialogue}
                            submitForm={handleCloseWithYes}
                            autosubmission={autosubmission}
                            grace_time={graceTime}
                            updateRemainingTime={updateRemainingTime}
                            start_time={quizDuration}
                            resetGraceTime={resetGraceTime}
                          />
                        )}
                      </>
                    )}
                  </p>
                </p>
                <p
                  style={{
                    marginBottom: "0px",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  {quizType === 2 && (
                    <>
                      {quizDuration / 60} min. Total Marks {quizTotalMarks}
                    </>
                  )}
                </p>

                <hr></hr>
              </div>
              <div className="form-div mb-[30px]">
                <div className="w-full ml-[150px] mr-[150px]">
                  <div>
                    <div id="quiz-section" className="w-full">
                      <div
                        style={{
                          padding: "10px",
                          borderRadius: "5px",
                          width: "100%",
                          marginBottom: "30px",
                          backgroundColor: "#FFFFFF",
                        }}
                      >
                        <div
                          style={{
                            display: isLoggedInByMicrosoft ? "none" : "block",
                          }}
                        >
                          <label className="student-name-label" htmlFor="student_name">
                            {location.state ? "Your Name :" : "Student Name :"}
                          </label>

                          {sso_required ? (
                            <input
                              name="student_name"
                              maxLength={45}
                              readOnly={sso_required}
                              value={profile.name}
                              onChange={onChangeStudentName}
                              className="p-2.5 bg-white shadow-sm outline-none rounded-[5px] border-[1px] border-[black]  h-[50px] text-[#474747] leading-[30px] text-[20px] font-[500] px-4"
                            ></input>
                          ) : (
                            <input
                              readOnly={studentName !== null ? true : false}
                              value={studentName ? studentName : null}
                              maxLength={45}
                              name="student_name"
                              id="student_name"
                              onChange={onChangeStudentName}
                              className="p-2.5 bg-white shadow-sm outline-none rounded-[5px] border-[1px] border-[black]  h-[50px] text-[#474747] leading-[30px] text-[20px] font-[500] px-4"
                            ></input>
                          )}
                        </div>
                        <label className="character-length-label md:pl-[352px] sm:pb-[10px]">
                          {isLoggedInByMicrosoft || login !== null ? "" : "Max. 45 characters"}
                        </label>

                        {sso_required ? (
                          <div style={{ display: "block" }}>
                            <label className="email-name-label w-[112px]" htmlFor="student_email">
                              Email:
                            </label>
                            <label
                              name="student_email"
                              className="p-2.5 bg-white shadow-sm outline-none rounded-[5px] border-[1px] border-[black]  h-[50px] text-[#474747] leading-[30px] text-[20px] font-[500] px-4"
                            >
                              {profile.email}
                            </label>
                            <label className="email-name-label ml-[10px]" htmlFor="student_email">
                              (We will send your quiz results and personalized feedback to this email.)
                            </label>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      {quizType === 1 || quizType === 3 ? (
                        <div>
                          {response_data?.map((rowData, index) => (
                            <Fragment key={index}>
                              <div className="sm:p-[10px] md:p-0">
                                <div className="">
                                  {" "}
                                  <p
                                    className="mt-[20px] answer-paragraph flex justify-between"
                                    style={{
                                      fontSize: "16px",
                                      fontFamily: "Roboto",
                                      color: "#49454F",
                                    }}
                                  >
                                    <div style={{ marginBottom: "8px", width: "87%" }}>
                                      <b className="mr-[4px]">Question {index + 1}:</b>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: stripPTags(rowData.question.split("##code")[0]),
                                        }}
                                      ></span>
                                      {/* {rowData.have_code === 1 && <CodeEditor value={removeHtmlTags2(rowData.question.split("##code")[1])} />} */}
                                    </div>
                                    <b> [Marks :{rowData.question_marks}]</b>
                                  </p>
                                </div>

                                {rowData.image_file_name !== null && (
                                  <div className="flex justify-center mb-[20px] mt-[20px]">
                                    <img alt="" className="" src={process.env.REACT_APP_CDN_URL_FOR_QUESTION_IMAGES + rowData.image_file_name}></img>
                                  </div>
                                )}

                                {code_status === 0 && rowData.have_code === 0 ? (
                                  <TextareaAutosize
                                    minRows={6}
                                    id={"" + index}
                                    name="answer"
                                    onPaste={(e) => {
                                      if (!enableCopyPaste) {
                                        e.preventDefault();
                                      }
                                      return enableCopyPaste;
                                    }}
                                    onCopy={(e) => {
                                      if (!enableCopyPaste) {
                                        e.preventDefault();
                                      }
                                      return enableCopyPaste;
                                    }}
                                    onChange={(e) => onChange("", e)}
                                    style={{
                                      width: "100%",
                                      fontSize: "18px",
                                      fontFamily: "Roboto",
                                      color: "#49454F",
                                    }}
                                    className="shadow-sm outline-none rounded-[5px] border-[1px] border-[black] text-[#474747] text-[20px] font-[500] px-2"
                                  />
                                ) : (
                                  <CodeEditor
                                    language="javascript" // Replace with the language you want or leave empty for generic text
                                    theme="monokai"
                                    id={"" + index}
                                    name="code"
                                    initialCode={rowData.have_code === 0 ? "" : removeHtmlTags2(rowData.question.split("##code")[1])}
                                    onChange={(newValue) => onChange2(index, newValue)}
                                    value={code} // Pass the current value for controlled input
                                  />
                                )}

                                <div id={"counter-" + index} style={{ float: "right", fontSize: "12px" }}>
                                  0/1000 characters
                                </div>
                              </div>
                            </Fragment>
                          ))}
                        </div>
                      ) : (
                        <>
                          {mock_exam !== null &&
                            mapConditionForMock(shuffledMock.current)?.map((question_context, index) => (
                              <Fragment key={index}>
                                <div style={{ display: "none" }}>{(p_index -= p_index)}</div>
                                <div style={{}}>
                                  <div
                                    style={{
                                      padding: "10px",
                                      borderRadius: "5px",
                                      width: "100%",
                                      marginBottom: "30px",
                                      backgroundColor: "#FFFFFF",
                                    }}
                                  >
                                    <p
                                      className=" answer-paragraph"
                                      style={{
                                        fontSize: "16px",
                                        fontFamily: "Roboto",
                                        color: "#49454F",
                                      }}
                                    >
                                      <b className="mr-[5px]">Question {index + 1}:</b>{" "}
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: stripPTags(question_context.question_context_name),
                                        }}
                                      ></span>
                                    </p>

                                    {JSON.parse(question_context.predefined_question_answer_list)?.map((predefined_question_answer, index) => (
                                      <div className="m-[30px] mb-[30px]">
                                        <p
                                          style={{
                                            display: "flex",
                                            fontSize: "16px",
                                            fontFamily: "Roboto",
                                            color: "#49454F",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div className=" flex">
                                            <b className="mr-[4px]">({String.fromCharCode(p_index++ + "A".charCodeAt(0)).toLowerCase()}) </b>
                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html: predefined_question_answer.question,
                                              }}
                                            ></p>
                                          </div>

                                          {quizType === 2 && (
                                            <b
                                              style={{
                                                fontSize: "16px",
                                                fontFamily: "Roboto",
                                                color: "#49454F",
                                              }}
                                            >
                                              {" "}
                                              [Marks:
                                              {predefined_question_answer.question_marks}]
                                            </b>
                                          )}
                                        </p>
                                        {predefined_question_answer.image_file_name !== null && (
                                          <div className="flex justify-center mb-[20px] mt-[20px]">
                                            <img
                                              alt=""
                                              className=""
                                              src={process.env.REACT_APP_CDN_URL_FOR_QUESTION_IMAGES + predefined_question_answer.image_file_name}
                                            ></img>
                                          </div>
                                        )}
                                        <TextareaAutosize
                                          minRows={6}
                                          id={"" + q_index}
                                          name="anwser"
                                          onPaste={(e) => {
                                            if (!enableCopyPaste) {
                                              e.preventDefault();
                                            }
                                            return enableCopyPaste;
                                          }}
                                          onCopy={(e) => {
                                            if (!enableCopyPaste) {
                                              e.preventDefault();
                                            }
                                            return enableCopyPaste;
                                          }}
                                          onChange={(e) => onChange(predefined_question_answer.predefined_question_answer_id, e)}
                                          style={{
                                            width: "100%",
                                            fontSize: "18px",
                                            fontFamily: "Roboto",
                                            color: "#49454F",
                                          }}
                                          className="shadow-sm outline-none rounded-[5px] border-[1px] border-[black]  text-[#474747] px-2"
                                        />
                                        <div
                                          id={"counter-" + q_index++}
                                          style={{
                                            float: "right",
                                            fontSize: "12px",
                                          }}
                                        >
                                          0/1000 characters
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </Fragment>
                            ))}
                        </>
                      )}

                      <div className={location.state?.minimock || !mock_exam ? "sm:m-[10px] md:m-0 " : "sm:m-[10px] md:m-10  "}>
                        {" "}
                        <button
                          onClick={
                            process.env.REACT_APP_TOPIC_BASED_USER.toString() === quizCreator.toString() ? handleOpenTeacherDialogue : submitForm
                          }
                          disabled={isSubmitting.current}
                          className="teepee-button w-full   mb-[8px]"
                        >
                          {isSubmitting.current && (
                            <div className="spinner-border spinner-border-m float-left" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {location.state
                            ? "Submit my Answers to the AI"
                            : process.env.REACT_APP_TOPIC_BASED_USER.toString() === quizCreator.toString()
                            ? "Submit & Get AI Feedback Now"
                            : "Submit"}
                        </button>
                      </div>
                    </div>
                    <div className="sm:m-[10px] mb-[10px] text-center h-[10%]">
                      {minimock && minimock.length > 0 && (
                        <TrialMocks
                          minimocks={location.state?.minimock}
                          subject={location.state?.subject_name}
                          experience={location.state?.experience}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          quizType === 2 &&
          localStorage.getItem("startQuizCount") === null && (
            <div className="form-div2">
              <div className="w-[600px]">
                <div>
                  <div id="thanku-section" className="w-full sm:pr-[20px] sm:pl-[20px] md:pr-0 md:pl-0">
                    <p
                      id="result"
                      style={{
                        display: quizData.length === 0 ? "" : "none",
                        marginBottom: "0px",
                        fontFamily: "Roboto",
                        fontSize: "24px",
                        textAlign: "left",
                        marginTop: "40px",
                      }}
                    >
                      {quiz_title.current}
                    </p>
                    <p
                      id="result"
                      style={{
                        display: quizData.length === 0 ? "" : "none",
                        marginBottom: "0px",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        textAlign: "left",
                        marginTop: "0px",
                      }}
                    >
                      Time allowed: {quizDuration / 60} minutes
                    </p>

                    <p
                      id="result"
                      style={{
                        display: quizData.length === 0 ? "" : "none",
                        marginBottom: "0px",
                        fontFamily: "Roboto",
                        fontSize: "24px",
                        textAlign: "left",
                        marginTop: "40px",
                      }}
                    >
                      INSTRUCTIONS
                    </p>

                    <p
                      id="result"
                      style={{
                        display: quizData.length === 0 ? "" : "none",
                        marginBottom: "0px",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        textAlign: "left",
                        marginTop: "20px",
                      }}
                    >
                      • The total marks for this paper is {quizTotalMarks}
                    </p>

                    <p
                      id="result"
                      style={{
                        display: quizData.length === 0 ? "" : "none",
                        marginBottom: "0px",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        textAlign: "left",
                        marginTop: "10px",
                      }}
                    >
                      • The marks for each question are shown in brackets [ ].
                    </p>

                    <p
                      id="result"
                      style={{
                        display: quizData.length === 0 ? "" : "none",
                        marginBottom: "0px",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        textAlign: "left",
                        marginTop: "10px",
                      }}
                    >
                      • Write your answer to each question in the space provided.
                    </p>

                    <p
                      id="result"
                      style={{
                        display: quizData.length === 0 ? "" : "none",
                        marginBottom: "0px",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        textAlign: "left",
                        marginTop: "10px",
                      }}
                    >
                      • Answer all the questions.
                    </p>

                    <p
                      id="result"
                      style={{
                        display: quizData.length === 0 ? "" : "none",
                        marginBottom: "0px",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        textAlign: "left",
                        marginTop: "10px",
                      }}
                    >
                      • You will only be allowed to submit your exam once.
                    </p>

                    <p
                      id="result"
                      style={{
                        display: quizData.length === 0 ? "" : "none",
                        marginBottom: "0px",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        textAlign: "left",
                        marginTop: "10px",
                      }}
                    >
                      • Read each question carefully before you start to answer.
                    </p>

                    <button onClick={startQuiz} disabled={isSubmitting.current} className="teepee-button w-full mt-[20px] ">
                      {isSubmitting.current && (
                        <div className="spinner-border spinner-border-m float-left" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      )}
                      Start Quiz
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
      <div style={{ display: emailFlag.current === true ? "block" : "none" }}>
        <StudentEmail email={email} setEmail={setEmail} quizTakerOutsideClassroom={quizTakerOutsideClassroom} />
      </div>
      {showChatbot && <Chatbot condition={quizData.length > 0} />}
      {quizData.length > 0 && (
        <div className=" bottom-0 left-0 right-0 z-40 bg-[#0A1C38] py-3 flex items-center justify-center text-sm text-gray-400">
          <span className="text-[#8C96A3] ml-[5%]">Powered by </span>
          <a href="https://teepee.ai" target="_blank" rel="noopener noreferrer" className="text-[#55A5EE] transition-colors duration-200 ml-1">
            Teepee.ai
          </a>{" "}
        </div>
      )}
    </>
  );
}

export default TopicBasedQuiz;
