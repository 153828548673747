import React, { createContext, useContext, useState } from "react";

// Create Context
const TabContext = createContext();

// Custom hook for easier access
export const useTab = () => useContext(TabContext);

// Provider Component
export const TabProvider = ({ children }) => {
  const [tabState, setTabState] = useState("1");
  return <TabContext.Provider value={{ tabState, setTabState }}>{children}</TabContext.Provider>;
};
