import React, { useContext, useEffect, useState } from "react";
import Joyride from "react-joyride";
import Modal from "react-modal";
import "../../../../src/OnboardingTour.css";
import SlideOneBullets from "./widgets/SlideOneBullets";
import Cross from "../../../images/cross.png";
import CheckCircle from "../../../images/check_circle.svg";
import VideoPlayer from "../VideoPlayer";
import { useNavigate } from "react-router-dom";
import TaskDetailsVideo from "../../../images/videos/AddTask.mp4";
import QuestionSelector from "../../../images/videos/questions.mp4";
import appContext from "../../../context/appContext";

Modal.setAppElement("#root"); // Required for accessibility

const OnboardingTour = ({ onCloseStep1, onClickNextStepTwo, disableTour, handleClickChooseQuestionType, handleSubmit, haltTourFlow }) => {
  const [modalIsOpen, setModalIsOpen] = useState(
    sessionStorage.getItem("tourStarted") === null && sessionStorage.getItem("tourStarted") !== "false" ? true : false
  );
  const [runTour, setRunTour] = useState(false);
  const navigate = useNavigate();

  const startTour = () => {
    sessionStorage.setItem("tourStarted", true);
    setModalIsOpen(false);
    setRunTour(true);
  };
  const state = useContext(appContext);

  useEffect(() => {
    setTimeout(() => {
      if (sessionStorage.getItem("tourStarted") && sessionStorage.getItem("tourStarted") !== "false") {
        setRunTour(true);
      }
    }, 1000);
  }, []);

  const onClickNextStepTwoInside = () => {
    onClickNextStepTwo();
  };

  const onClickNextStepThree = () => {
    handleClickChooseQuestionType();
    if (haltTourFlow) {
      return;
    }
    setRunTour(false);
  };
  const onClickNextStepFour = async () => {
    await handleSubmit();
    if (haltTourFlow) {
      return;
    }
    setRunTour(false);
  };

  const onClickRestart = () => {
    if (window.location.pathname === "/") {
      setModalIsOpen(true);
      setRunTour(false);
    }
    sessionStorage.removeItem("tourStarted");
    navigate("/");
  };

  const steps = [
    {
      content: "🚀 Welcome to Teepee.ai! Let’s walk you through the key features.",
      placement: "center",
      disableBeacon: true,
    },
    {
      target: ".create-quiz-btn",
      title: (
        <div className="flex text-[1rem] leading-[1.75rem] tracking-[0.009rem] font-semibold">
          <img src={CheckCircle} className="w-[5%] mr-[0.375rem]" />
          <div>Step 1: Click "Create Task"</div>
        </div>
      ),
      content: (
        <div>
          <div className="pr-[1.5rem] pl-[1.5rem] pb-[1.25rem] text-start leading-[1.5rem] tracking-[0.006rem] text-[0.875rem]">
            💡 <strong>Tip:</strong> Tasks can be quizzes, homework, or exam-style practice. Click <strong>"Create Task"</strong> to get started.
          </div>
          <hr className="!m-0"></hr>
          <div className="modal-buttons pl-[1.5rem] pr-[1.5rem] pt-[0.5rem]  mt-0">
            <button className="skip-btn" onClick={() => onClickRestart()}>
              Restart
            </button>
            <p className="step-count">2 of 5</p>
            <button className="teepee-button !font-medium !text-[0.875rem]" onClick={() => onClickNextStepTwoInside()}>
              Next
            </button>
          </div>
        </div>
      ),
      placement: "top",
      disableBeacon: true,
      spotlightClicks: true,
      styles: {
        options: {
          width: "25vw", // Set the width of this specific step
        },
      },
    },
    {
      target: ".task-name-input",
      title: "",
      content: (
        <div>
          <div className="pt-[2.375rem] pr-[0.75rem] pl-[0.75rem]">
            <div>
              <VideoPlayer source={TaskDetailsVideo} />
            </div>
            <div className="my-[1.625rem]">
              <div className="flex text-[1rem] leading-[1.75rem] tracking-[0.009rem] font-semibold">
                <img src={CheckCircle} className="w-[5%] mr-[0.375rem]" />
                <div>Step 2: Set Up Your Task</div>
              </div>
              <div className="text-start pl-[1.25rem] pr-[1.25rem]">
                <div className="mt-[0.25rem] leading-[1.5rem] tracking-[0.006rem] text-[0.875rem]">💡 Follow these steps to set up your task:</div>
                <div className="flex mt-[1.25rem]">
                  <div className="mr-2">&#x2022;</div>
                  <div className="leading-[1.25rem] tracking-[0.006rem] text-[0.875rem]">
                    <strong>Enter a Task Name </strong> – Example: Algebra Quiz - Week 2
                  </div>
                </div>
                <div className="flex mt-[1.25rem]">
                  <div className="mr-2">&#x2022;</div>
                  <div className="leading-[1.25rem] tracking-[0.006rem] text-[0.875rem]">
                    <strong>Select Subject </strong> –Choose the relevant subject for your task
                  </div>
                </div>
                <div className="flex mt-[1.25rem]">
                  <div className="mr-2">&#x2022;</div>
                  <div className="leading-[1.25rem] tracking-[0.006rem] text-[0.875rem]">
                    <strong>Pick Topic & Subtopic </strong> –This helps in generating focused questions
                  </div>
                </div>
              </div>
              <div className="text-start mt-[1.375rem] ml-[0.5rem] leading-[1.5rem] tracking-[0.006rem] text-[0.875rem]">
                👉 <strong>Action:</strong> Press the <strong>"Choose Question Types"</strong> button.
              </div>
            </div>
          </div>
          <hr className="!m-0"></hr>
          <div className="modal-buttons pl-[1.5rem] pr-[1.5rem] pt-[0.5rem]  mt-0">
            <button className="skip-btn" onClick={() => onClickRestart()}>
              Restart
            </button>
            <p className="step-count">3 of 5</p>
            <button
              disabled={disableTour}
              className={`${
                disableTour
                  ? "text-[#1C1B1F] text-opacity-50 !font-medium !text-[0.875rem] pt-[0.5rem] pb-[0.5rem] pl-[1rem] pr-[1rem] rounded-[5px] bg-[rgba(28,27,31,0.12)]"
                  : "teepee-button !font-medium !text-[0.875rem]"
              }`}
              onClick={() => onClickNextStepThree()}
            >
              Next
            </button>
          </div>
        </div>
      ),
      placement: "bottom",
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".question-selection", // Ensure your question selection has this class
      title: "",
      content: (
        <div>
          <div className="pt-[2.375rem] pr-[0.75rem] pl-[0.75rem]">
            <div>
              <VideoPlayer source={QuestionSelector} />
            </div>
            <div className="my-[1.625rem]">
              <div className="flex text-[1rem] leading-[1.75rem] tracking-[0.009rem] font-semibold">
                <img src={CheckCircle} className="w-[5%] mr-[0.375rem]" />
                <div>Step 3: Select Number of Questions</div>
              </div>
              <div className="text-start pl-[1.25rem] pr-[1.25rem]">
                <div className=" mt-[1.25rem] leading-[1.25rem] tracking-[0.006rem] text-[0.875rem]">
                  🔹 Use the "+" icon to <strong>add questions</strong> against each <strong>mark category</strong> (e.g., 1-3 marks, 4-7 marks, 8+
                  marks).
                </div>

                <div className="mt-[1.25rem] leading-[1.25rem] tracking-[0.006rem] text-[0.875rem]">
                  <strong>👉 Action: </strong> Once you've selected the number of questions, press<strong> "Generate Task".</strong>
                </div>
              </div>
            </div>
          </div>
          <hr className="!m-0"></hr>
          <div className="modal-buttons pl-[1.5rem] pr-[1.5rem] pt-[0.5rem]  mt-0">
            <button className="skip-btn" onClick={() => onClickRestart()}>
              Restart
            </button>
            <p className="step-count">4 of 5</p>
            <button className="teepee-button !font-medium !text-[0.875rem]" onClick={() => onClickNextStepFour()}>
              Next
            </button>
          </div>
        </div>
      ),
      disableBeacon: true,
      placement: "right",
    },
  ];

  const bullets = [
    { text: "Create topic-based, exam-style quizzes in seconds" },
    { text: "Share instantly with students" },
    { text: "AI auto-marks & gives instant feedback" },
  ];

  const handleJoyrideCallback = (data) => {
    const { action, step } = data;

    if (action === "close") {
      let stepIndex = steps.findIndex((stepIndex) => stepIndex.target === step.target);
      state.updateTourState(stepIndex + 1);
      sessionStorage.setItem("tourStarted", false);
    }
  };

  return (
    <>
      {/* Welcome Modal (Step 1) */}
      <Modal isOpen={modalIsOpen} className="custom-modal !p-0 !rounded-lg !w-[37vw]" overlayClassName="modal-overlay">
        <div className="modal-content text-justify relative">
          <button
            className="absolute top-5 right-6"
            onClick={() => {
              state.updateTourState(1);
              setModalIsOpen(false);
              onCloseStep1 && onCloseStep1();
            }}
          >
            <img src={Cross} alt="Close" />
          </button>
          <div className="pl-[1.5rem] pr-[1.5rem] pt-[1.5rem] pb-[1rem]">
            <div className="font-bold text-[1.375rem] leading-[1.75rem] mt-[0.688rem] mb-[1.063rem]">🚀 Welcome to Teepee.ai!</div>
            <p className="text-[1rem] leading-[1.75rem] tracking-[0.009rem]">
              Want a quick tour on how to <strong>create a task?</strong> We’ll guide you step by step!
            </p>
            <ul className="ml-1">
              {bullets.map((bullet, index) => (
                <SlideOneBullets key={index} text={bullet.text} />
              ))}
            </ul>
          </div>
          <hr className="mt-0 mb-0"></hr>
          <div className="modal-buttons pl-[1.5rem] pr-[1.5rem] pt-[0.5rem] pb-[1rem] mt-0">
            <button
              className="skip-btn"
              onClick={() => {
                state.updateTourState(1);
                sessionStorage.setItem("tourStarted", false);
                setModalIsOpen(false);
              }}
            >
              Skip for Now
            </button>
            <p className="step-count">1 of 5</p>
            <button className="teepee-button !font-medium !text-[0.875rem]" onClick={startTour}>
              Take the Tour
            </button>
          </div>
        </div>
      </Modal>

      <Joyride
        steps={steps}
        run={runTour}
        continuous={true}
        showProgress={false}
        showSkipButton={false}
        hideBackButton={true}
        disableOverlayClose={true}
        disableBeacon={true}
        hideFooter={true}
        spotlightClicks={true}
        callback={handleJoyrideCallback}
        styles={{
          buttonClose: { width: "70%" },
          tooltipTitle: { padding: "1.5rem", textAlign: "start" },
          tooltipContent: { padding: "0px" },
          buttonNext: {
            display: "none",
          },
          options: {
            zIndex: 10000,
          },
          overlay: {
            pointerEvents: "none", // ✅ Allows clicking on the shaded area
            background: "#0000005C", // ✅ Removes the shading effect
          },
        }}
      />
    </>
  );
};

export default OnboardingTour;
