import axios from "axios";
import { REACT_APP_REST_API_BASE_URL, REACT_APP_GET_PRICING_END_POINT } from "../../Constants/ApiConstant";

const useGetPricing = () => {
  const getPricing = (callback1, callback2, id) => {
    const accessToken = localStorage.getItem("access_token");
    const config = {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    // Using GET request instead of POST
    axios
      .get(`${REACT_APP_REST_API_BASE_URL}${REACT_APP_GET_PRICING_END_POINT}`, config)
      .then((response) => {
        callback1(response.data.pricing_data[0].number_of_students);
        callback2(response.data.pricing_data[0].price_per_student);
        id(response.data.pricing_data[0].pricing_id);
      })
      .catch((error) => {
        console.error("Failed to fetch notifications:", error);
        // Optionally handle errors in state or pass error up to calling component
      });
  };

  return getPricing;
};

export default useGetPricing;
