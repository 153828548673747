import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import AddExamPaper from "./AddExamPaper";
import Sidebar from "./Sidebar";
import EdiText from "react-editext";
import ViewSubjectDetails from "./ViewSubjectDetails";
import ViewSubjectDetialsRetrieval from "./Admin/Retrival/Content/ViewSubjectDetialsRetrieval";

function SubjectsContentContainer() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get("id");
  const [subjectData, setSubjectData] = useState(null);
  const [subjectDataFormative, setSubjectDataFormative] = useState(null);
  const [predefinedQuestionsPromptList, setPredefinedQuestionsPromptList] = useState([]);
  const [formativePredefinedQuestionsPromptList, setFormativePredefinedQuestionsPromptList] = useState([]);
  const [minimockPredefinedQuestionsPromptList, setMinimockPredefinedQuestionsPromptList] = useState([]);
  const [subjectDataRetrieval, setSubjectDataRetrieval] = useState(null);

  useEffect(() => {
    getSubjectDetailsFormative(id);
    getSubjectDetails(id);
    getPredefinedQuestionsPrompts(id);
    getSubjectDetailsRetrieval(id);
  }, [id]);

  function getPredefinedQuestionsPrompts(id) {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_predefined_questions_prompt_list_updated", {
        method: "POST",
        subject_id: id,
        show_disabled: false,
      })

      .then((response) => {
        var predefinedQuestionsPromptList = JSON.parse(response.data["predefined_questions_prompt_list"]);
        var formativePredefinedQuestionsPrompt = JSON.parse(response.data["formative_predefined_questions_prompt_list"]);
        var minimockPredefinedQuestionsPrompt = JSON.parse(response.data["mini_mock_predefined_questions_prompt_list"]);
        setPredefinedQuestionsPromptList(predefinedQuestionsPromptList);
        setFormativePredefinedQuestionsPromptList(formativePredefinedQuestionsPrompt);
        setMinimockPredefinedQuestionsPromptList(minimockPredefinedQuestionsPrompt);
        //generation_model
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getSubjectDetailsFormative = (subject_id) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_subjects_data", {
        method: "POST",
        subject_id: subject_id,
        predefined_question_answer_status: 1,
      })

      .then((response) => {
        var subjects_list = JSON.parse(response.data["subject"]);

        console.log("subject list", subjects_list);

        setSubjectDataFormative(subjects_list);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function getSubjectDetailsRetrieval(subject_id) {
    var statuss = localStorage.getItem("lesson_status");
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_subjects_data_complete", {
        method: "POST",
        subject_id: subject_id,
        subject_status_have_lesson: parseInt(statuss),
      })

      .then((response) => {
        console.log("DATA IN THE JSON: ", response.data["subject"]);
        var subjects_list = response.data["subject"];

        console.log("EXAM STYLE: ", subjects_list);

        setSubjectDataRetrieval(subjects_list);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getSubjectDetails(subject_id) {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_subjects_data", {
        method: "POST",
        subject_id: subject_id,
        predefined_question_answer_status: 2,
      })

      .then((response) => {
        var subjects_list = JSON.parse(response.data["subject"]);

        console.log("EXAM STYLE: ", subjects_list);

        setSubjectData(subjects_list);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const addTodo = () => {
    getSubjectDetails(id);
  };

  const addTodoFormative = () => {
    getSubjectDetailsFormative(id);
  };

  const addTodoRetrieval = () => {
    getSubjectDetailsRetrieval(id);
  };

  const onSaveSubject = (value, subject) => {
    // alert(subject.subject_name);
    subject.subject_name = value;
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    const subjectHeader = { subject_id: subject.subject_id, subject_name: subject.subject_name };
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_subject", {
        method: "POST",
        subject: subjectHeader,
      })

      .then((response) => {
        // var subjects_list = JSON.parse(
        //   response.data["subject"]
        // );
        //console.log(subjects_list);
        // setSubjectData(subjects_list)
      })
      .catch((error) => {
        console.log(error);
        alert("unable to update subject");
      });
  };
  console.log("NEW DATA: ", subjectDataRetrieval);

  return (
    <>
      <Sidebar item_id="view_subject" />
      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px] ">
        <div className="home sm:ml[25px] md:ml-[60px]">
          <div>
            <label className="dashboard-label">Subject Name</label>
            <div className="teepee-divider"></div>
          </div>
        </div>
        <div className=" flex-row justify-left items-center sm:pl-[10px] sm:pr-[10px] md:pl-[60px] md:pr-[40px] mt-[20px]">
          {subjectData?.map((subject, subjectIndex) => (
            <div>
              <p
                style={{
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  color: "#49454F",
                }}
              >
                <p
                  style={{
                    fontSize: "18px",
                    fontFamily: "Roboto",
                    color: "#49454F",
                  }}
                >
                  <EdiText submitOnEnter={false} type="text" value={subject.subject_name} onSave={(value) => onSaveSubject(value, subject)} />
                </p>
              </p>
            </div>
          ))}
          {(subjectData || subjectDataFormative) && (
            <Tabs>
              <TabList>
                <Tab>Add Subject Content Formative</Tab>
                <Tab>Add Subject Content Exam Style</Tab>
                <Tab>Add Exam Paper</Tab>
                {subjectDataRetrieval && <Tab>Add Subject Content Retrieval </Tab>}
              </TabList>

              <TabPanel>
                <ViewSubjectDetails
                  addTodo={addTodoFormative}
                  subject_data={subjectDataFormative}
                  predefined_question_answer_status={1}
                  predefinedQuestionsPromptList={formativePredefinedQuestionsPromptList}
                  // addTodoRetrieval={addTodoRetrieval}
                />
              </TabPanel>
              <TabPanel>
                <ViewSubjectDetails
                  addTodo={addTodo}
                  subject_data={subjectData}
                  predefined_question_answer_status={2}
                  predefinedQuestionsPromptList={predefinedQuestionsPromptList}
                />
              </TabPanel>
              <TabPanel>
                <AddExamPaper
                  addTodoParent={addTodo}
                  subject_data={subjectDataFormative && subjectDataFormative[0]}
                  predefinedQuestionsPromptList={minimockPredefinedQuestionsPromptList}
                />
              </TabPanel>
              <TabPanel>
                {subjectDataRetrieval && (
                  <ViewSubjectDetialsRetrieval
                    addTodo={addTodoRetrieval}
                    subject_data={subjectDataRetrieval}
                    predefined_question_answer_status={1}
                    addTodoOld={addTodo}
                  />
                )}
              </TabPanel>
            </Tabs>
          )}
        </div>
      </div>
    </>
  );
}

export default SubjectsContentContainer;
