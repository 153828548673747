import React from "react";
import Dialog from "@mui/material/Dialog";
import trialExpCalender from "../../../images/task-limit-reached.svg";
import appContext from "../../../context/appContext";
import { useContext } from "react";

function TaskLimitReachedPopup(props) {
  const state = useContext(appContext);

  const handleClose = () => {
    // state.setOpenShareClassroomDialogue(true);
    props.handleCloseTaskLimitReachedPopup();
  };

  const handleUpgradeNow = () => {
    props.handleCloseTaskLimitReachedPopup();
    props.setOpen(true);
    state.setOpenBuyVoucherPopUp(true);
  };

  return (
    <div>
      <Dialog
        PaperProps={{
          sx: {
            "@media (max-width: 768px)": {
              width: "90%", // Adjust for smaller screens
            },
          },
        }}
        open={props.openTaskLimitReachedPopup}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="relative flex flex-col items-center justify-center bg-white rounded-lg p-6 max-w-md w-full mx-auto shadow-lg">
          {/* Close Button */}
          <button className="absolute top-4 right-6 text-gray-600 text-xl hover:text-gray-800" onClick={() => handleClose()}>
            ✕
          </button>

          {/* Icon */}
          <div>
            <img src={trialExpCalender} alt="Acknowledge Icon" className="w-16 mb-4" />
          </div>

          {/* Title */}
          <div className="text-lg font-bold text-gray-900 text-center">Task Limit Reached</div>

          {/* Subtitle */}
          <div className="text-gray-600 text-center mt-2 text-base leading-6">You’ve hit your task limit. Upgrade now to continue access.</div>

          {/* Button */}
          <div className="mt-4 text-center">
            <button
              className="bg-[#6750A4] text-white rounded-md px-4 py-2 font-semibold text-base shadow-md hover:bg-purple-800 transition duration-300"
              onClick={() => handleUpgradeNow()}
            >
              ⚡ Upgrade Now
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default TaskLimitReachedPopup;
