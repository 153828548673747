import React, { useContext, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Views from "../.././../images/eye.svg";
import amplitude from "amplitude-js";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import QuizRawResponseAccordian from "../../QuizRawResponseAccordian";
import Copy from "../../../../src/images/content_copy.png";
import Edit from "../../../../src/images/edit_content.png";
import Delete from "../../../../src/images/delete_content.png";
import { Tooltip } from "@mui/material";

import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import appContext from "../../../context/appContext";
import moment from "moment";

const AccountStatus = {
  TRIAL_ACTIVE: 1,
  TRIAL_EXPIRED: 2,
  SUBSCRIPTION_ACTIVE: 3,
  SUBSCRIPTION_EXPIRED: 4,
  SPECIAL_CONDITION: 5,
};

function DashboardQuizCard(props) {
  var quizData = props.quizData;
  const navigate = useNavigate();
  const [quizGenerationLog, setQuizGenerationLog] = useState([]);
  const [selectedQuizzesList, setSelectedQuizzesList] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openMoveToFolderDialog, setOpenMoveToFolderDialog] = React.useState(false);
  const userType = localStorage.getItem("user_type");
  const [showHoverButton, setShowHoverButton] = useState(false);
  const [hoverDivIndex, setHoverDivIndex] = useState(-1);
  const [isEditing, setIsEditing] = useState(-1);
  const [editedValue, setEditedValue] = useState("");
  const [isActiveQuiz, setIsActiveQuiz] = useState(false);
  const [toasterValue, setToasterValue] = useState();
  const [show, setShow] = useState(false);
  const [quizCount, setQuizCount] = useState(0);
  const oldEditedQuizValue = useRef("");
  const editedQuizValue = useRef("");
  const editedQuizId = useRef("");
  const wrapperRef = useRef(null);
  const isEditingNew = useRef();
  const hoverDivIndexNew = useRef();
  const state = useContext(appContext);

  function useOutsideAlerter(ref) {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (oldEditedQuizValue.current.length === 0) {
          alert("please enter the quiz name");
          return;
        }
        if (
          editedQuizValue.current !== "" &&
          oldEditedQuizValue.current !== editedQuizValue.current &&
          hoverDivIndexNew.current === isEditingNew.current
        ) {
          var token = "Bearer " + localStorage.getItem("access_token");
          axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
          axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
          axios.defaults.headers.post["authorization"] = token;
          axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

          axios
            .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_quiz_name", {
              method: "POST",
              quiz_id: editedQuizId.current,
              quiz_name: editedQuizValue.current,
            })

            .then((response) => {
              amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
              amplitude.getInstance().logEvent("OCRenameQuiz");
              console.log(response);
              props.handleCancel();
              // props.editedQuizName(
              //   editedQuizId.current,
              //   editedQuizValue.current
              // );
              props.getQuizzesList(null);
              setShow(true);
              setToasterValue(3);
            })
            .catch((error) => {
              console.log(error);
            });
        }
        setIsEditing(-1);
        setShowHoverButton(false);
        setHoverDivIndex(-1);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }
  useOutsideAlerter(wrapperRef);

  const foldersList = props.folders_list.map((folder) => <option id={folder.folder_id}>{folder.folder_name}</option>);
  // const [moveQuizToFolder, setMoveQuizToFolder] = useState({
  //   quiz_id: "",
  //   folder_id: "",
  // });
  const handleViewResultClick = (quiz) => {
    console.log("QUIZ IS: ", quiz);
    // var secret_key = quiz.
    // var quiz_title = e.target.name;
    // var created_timestamp = e.target.created_timestamp
    // var secret_key = document.getElementById("button").value;
    // var quiz_title = document.getElementById("button").name;
    amplitude.getInstance().logEvent("OCViewResult");
    navigate(`/view-result?id=${quiz.secret_key}`, {
      state: {
        secret_key: quiz.secret_key,
        quiz_title: quiz.quiz_title,
        created_timestamp: quiz.created_timestamp,
        quiz_total_marks: quiz.quiz_total_marks,
        quiz_duration: quiz.quiz_duration,
        quiz_grace_time: quiz.quiz_grace_time,
      },
    });
  };

  const onQuizSelected = (e, quiz) => {
    var isFound = selectedQuizzesList.filter(function (item) {
      return item.quiz.quiz_id === quiz.quiz_id;
    });

    if (e.target.checked) {
      if (isFound.length <= 0) {
        var list = selectedQuizzesList;
        list.push({ quiz, e });
        setSelectedQuizzesList(list);
        setSelectedQuizzesList(
          selectedQuizzesList.filter(function (item) {
            return item.quiz.quiz_id !== 0;
          })
        );
      }
    } else {
      if (isFound.length > 0) {
        setSelectedQuizzesList(
          selectedQuizzesList.filter(function (item) {
            return item.quiz.quiz_id !== quiz.quiz_id;
          })
        );
      }
    }
  };

  const handleViewQuizClick = (secret_key, subjectName, subjectId) => {
    sessionStorage.setItem("is_parent", true);
    // var secret_key = e.target.value;
    amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
    amplitude.getInstance().logEvent("OCViewQuiz", "user has clicked on view quiz button");
    // var secret_key = document.getElementById("button").value;
    // var quiz_title = document.getElementById("button").name;
    navigate(`/add-details?id=${secret_key}`, { state: { isViewQuiz: true, subjectName: subjectName, subjectId: subjectId } });
  };

  const handleRawResponseClick = (secret_key) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_quiz_generation_log",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: token,
        },
        data: JSON.stringify({
          quiz_secret_key: secret_key,
        }),
      })
      .then((res) => {
        console.log(res.data);
        var quiz_generation_log_list = JSON.parse(res.data["quiz_generation_log_list"]);
        setQuizGenerationLog(quiz_generation_log_list);
        setOpen(true);
      });
  };
  const handleCloseWithCancel = () => {
    setOpen(false);
  };

  const closeMoveToFolderDialog = () => {
    setOpenMoveToFolderDialog(false);
  };

  const onFolderChange = (e) => {
    setSelectedFolder(props.folders_list[e.target.selectedIndex]);
  };

  const moveQuizToFolder = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/move_quiz_to_folder",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: token,
        },
        data: JSON.stringify({
          quiz_secret_key_csv: selectedQuizzesList
            .map((item) => {
              return "'" + item.quiz.secret_key + "'";
            })
            .join(","),
          folder_id: selectedFolder != null ? selectedFolder.folder_id : props.folders_list[0].folder_id,
        }),
      })
      .then((res) => {
        console.log(res.data);
        setOpenMoveToFolderDialog(false);

        for (var item in selectedQuizzesList) {
          selectedQuizzesList[item].e.target.checked = false;
        }
        setSelectedQuizzesList([]);

        props.refreshData();
      });
  };

  const handleMouseEnter = (index, value, quiz_id) => {
    if (isEditing === -1) {
      setShowHoverButton(true);
      setHoverDivIndex(index);
      hoverDivIndexNew.current = index;
      editedQuizId.current = quiz_id;
    }
  };

  const handleMouseLeave = () => {
    if (isEditing === -1) {
      setIsEditing(-1);
      setShowHoverButton(false);
      setHoverDivIndex(-1);
    }
  };

  const editQuizName = (index, value) => {
    setIsEditing(index);
    setEditedValue(value);
    isEditingNew.current = index;
    oldEditedQuizValue.current = value;
    editedQuizValue.current = "";
  };

  const onChange = (event) => {
    setEditedValue(event.target.value);
    editedQuizValue.current = event.target.value;
  };

  const handleCopyQuizIndividual = (quiz) => {
    console.log("QUIZ: ", quiz);
    props.setSubject(quiz.trial_data);
    props.setSubjectName(quiz.subject_name);
    handleConditionsOfStartTrial(quiz);
  };

  const handleConditionsOfStartTrial = (responseData) => {
    const quizzesLeft =
      responseData.trial_data.account_status === AccountStatus.TRIAL_EXPIRED ||
      (!responseData.trial_data.subscription_days_left &&
        responseData.trial_data.account_status === AccountStatus.SPECIAL_CONDITION &&
        responseData.trial_data.days_left <= 0) ||
      responseData.trial_data.account_status === AccountStatus.SUBSCRIPTION_EXPIRED
        ? 0
        : responseData.trial_data.account_status === AccountStatus.TRIAL_ACTIVE ||
          (!responseData.trial_data.subscription_days_left &&
            responseData.trial_data.account_status === AccountStatus.SPECIAL_CONDITION &&
            responseData.trial_data.days_left > 0)
        ? process.env.REACT_APP_TRIAL_QUIZ - responseData.trial_data.quiz_count
        : process.env.REACT_APP_SUBSCRIPTION_QUIZ - responseData.trial_data.quiz_count_subscribed;

    if (
      (responseData.trial_data.account_status === AccountStatus.TRIAL_ACTIVE && responseData.trial_data.days_left > 0) ||
      (responseData.trial_data.account_status === AccountStatus.SPECIAL_CONDITION &&
        !responseData.trial_data.subscription_days_left &&
        responseData.trial_data.days_left > 0)
    ) {
      // canShare = true;

      if (quizzesLeft > 0) {
        callGetQuizDataForCopy(responseData);
      } else {
        state.handleOpenTaskLimitReachedPopup();
      }
      console.log(`Your trial for  will expire in ${responseData.trial_data.days_left} days.`);
    } else if (
      responseData.trial_data.account_status === AccountStatus.TRIAL_EXPIRED ||
      (!responseData.trial_data.subscription_days_left &&
        responseData.trial_data.account_status === AccountStatus.SPECIAL_CONDITION &&
        responseData.trial_data.days_left <= 0) ||
      (!responseData.trial_data.subscription_days_left && responseData.trial_data.quiz_count >= process.env.REACT_APP_TRIAL_QUIZ)
    ) {
      state.handleOpenTrialExpiredPopup();
      console.log(`Your trial for  has expired!`);
    } else if (responseData.trial_data.account_status === AccountStatus.SUBSCRIPTION_EXPIRED) {
      state.handleOpenSubscriptionExpiredPopup();
      console.log(`Your subscription for  has expired!`);
    } else if (
      responseData.trial_data.account_status === AccountStatus.SUBSCRIPTION_ACTIVE ||
      (responseData.trial_data.subscription_days_left &&
        responseData.trial_data.account_status === AccountStatus.SPECIAL_CONDITION &&
        responseData.trial_data.subscription_days_left > 0)
    ) {
      if (responseData.trial_data.quiz_count_subscribed <= process.env.REACT_APP_SUBSCRIPTION_QUIZ) {
        callGetQuizDataForCopy(responseData);
      } else {
        state.handleOpenTaskLimitReachedPopup();
      }
      // canShare = true;
    }
    console.log("EXECUTED SUCCESSFULY ");
    // return canShare;
  };

  const callGetQuizDataForCopy = (quiz) => {
    setToasterValue(1);
    let quizList = [];
    quizList[0] = quiz.quiz_id;

    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_quiz_data_for_copy", {
        method: "POST",
        quiz_id_list: quizList,
        quiz_name: quiz.quiz_title + " copy",
        quiz_status: null,
        subject_id: quiz.subject_id,
        account_status: quiz.trial_data ? handleAccontStatus(quiz.trial_data.account_status, quiz.trial_data) : null,
        class_id: null,
        current_date: moment().format("Y-MM-DD HH:mm:ss"),

        // quiz_id_list: quizList,
        // quiz_name: inputText,
        // quiz_status: quiz_status,
        // class_id: class_id,
        // subject_id: classroomData.subject.subject_id,
        // account_status: props.bannerObject ? handleAccontStatus(props.bannerObject.account_status, props.bannerObject) : null,
      })

      .then((response) => {
        amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
        amplitude.getInstance().logEvent("OCCopyQuiz");
        console.log(response);
        props.getQuizzesList(null);
        setShow(true);
        setQuizCount(1);
        props.handleCancel();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteIndividualQuiz = (quiz_id) => {
    let quizList = [];
    quizList[0] = quiz_id;
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/check_student_data_in_quiz", {
        method: "POST",
        quiz_id_list: quizList,
        quiz_name: null,
        quiz_status: null,
      })
      .then((response) => {
        console.log(response);
        amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
        amplitude.getInstance().logEvent("OCDeleteQuiz");
        if (response.data.msg === 0) {
          // setDeleteQuizList(quizList);
          props.handleSetDeleteQuiz(quizList);
          props.handleOpenDeleteDialogue();
          // setOpenDeleteConfirmationDialogue(true);
          // setIsActiveQuiz(false);
          props.handleSetActive(false);
        } else {
          setIsActiveQuiz(true);
          props.handleSetDeleteQuiz(quizList);
          props.handleOpenDeleteDialogue();
          props.handleSetActive(true);
          // setDeleteQuizList(quizList);
          // setOpenDeleteConfirmationDialogue(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const AccountStatus = {
    TRIAL_ACTIVE: 1,
    TRIAL_EXPIRED: 2,
    SUBSCRIPTION_ACTIVE: 3,
    SUBSCRIPTION_EXPIRED: 4,
    SPECIAL_CONDITION: 5,
  };
  const handleAccontStatus = (status, object) => {
    if (AccountStatus.TRIAL_ACTIVE === status) {
      return 1;
    } else if (AccountStatus.TRIAL_EXPIRED === status) {
      return 1;
    } else if (AccountStatus.SUBSCRIPTION_ACTIVE === status) {
      return 3;
    } else if (AccountStatus.SPECIAL_CONDITION === status) {
      if (object.subscription_days_left) {
        return 3;
      } else {
        return 1;
      }
    }
  };

  return (
    <>
      <ToastContainer className="p-3 position-fixed top-12 right-0">
        <Toast style={{ backgroundColor: "#f7f2f9" }} onClose={() => setShow(false)} show={show} delay={5000} autohide>
          <Toast.Header>
            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
            <strong className="me-auto">
              {toasterValue === 1 || toasterValue === 2
                ? "Task Copied"
                : toasterValue === 3
                ? "Task Renamed!"
                : props.toasterValue === 4 && "Task Deleted!"}
            </strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>
            {toasterValue === 1 || toasterValue === 2
              ? quizCount === 1
                ? `${quizCount} Task copied successfully!`
                : `${quizCount} Tasks copied successfully!`
              : toasterValue === 3
              ? "Task name updated successfully!"
              : props.toasterValue === 4 && "Task deleted successfully!"}
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <div className="right-[20px] absolute mt-[-70px]">
        <div>
          <div className="float-left">
            <button
              style={{
                display: selectedQuizzesList.length > 0 ? "block" : "none",
              }}
              className="selected-quiz-option-button"
            >
              {"- Selected " + selectedQuizzesList.length}
            </button>
          </div>
          <div className="float-left ml-[10px] mr-[10px]">
            <button
              style={{
                display: selectedQuizzesList.length > 0 ? "block" : "none",
              }}
              className="selected-quiz-option-button"
            >
              Delete
            </button>
          </div>
          <div className="float-left">
            <button
              style={{
                display: selectedQuizzesList.length > 0 ? "block" : "none",
              }}
              onClick={() => {
                if (props.folders_list.length === 0) {
                  alert("You have not created any folder yet.");
                  return;
                }
                setSelectedFolder(props.folders_list[0]);
                setOpenMoveToFolderDialog(true);
              }}
              className="selected-quiz-option-button"
            >
              Move to Folder
            </button>
          </div>
        </div>
      </div>

      <Dialog
        className="overflow-y-hidden"
        open={open}
        onClose={handleCloseWithCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <b>Raw Response</b>
        </DialogTitle>
        <DialogContent className="overflow-y-hidden">
          <QuizRawResponseAccordian quizEvaluationLog={quizGenerationLog} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWithCancel}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        className="overflow-y-hidden"
        open={openMoveToFolderDialog}
        onClose={closeMoveToFolderDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <b>Move to Folder</b>
        </DialogTitle>
        <DialogContent className="overflow-y-hidden">
          <div className="mt-[2px]">
            <label className="create_quiz_label">Select Folder</label>
            <select
              id="folder_id"
              onChange={onFolderChange}
              name="folder_id"
              className=" teepee-select shadow-none leading-[30px] font-[300] px-4 w-[100%] h-[100%] bg-transparent"
            >
              {foldersList}
            </select>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeMoveToFolderDialog}>Close</Button>
          <Button onClick={moveQuizToFolder}>Move</Button>
        </DialogActions>
      </Dialog>

      {quizData.length === 0 ? (
        <div className="w-[100%]" style={{ display: "none" }}>
          <label className="no-quiz-label text-center w-[100%]">You haven't generated any Tasks yet.</label>
          <div className="text-center mt-[2vh]">
            <button type="button" className="create_quiz_button_dashboard-new " onClick={props.handleClick}>
              <div className="inline-flex">
                <span>👉 Create Your First Task Now!</span>
              </div>
            </button>
          </div>
        </div>
      ) : (
        <div className="relative">
          <div className="flex flex-col items-center pb-[50px]">
            <div className="grid xlg:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-0  w-full">
              {quizData.map((item, index) => (
                <div
                  key={index}
                  className="quiz-div w-full relative top-0"
                  style={{
                    left: "0px",
                    borderRadius: showHoverButton && index === hoverDivIndex && "4px",
                    border:
                      props.checked.includes(item.quiz_id.toString()) || (showHoverButton && index === hoverDivIndex) ? "1px solid #6750A4" : "",
                  }}
                  onMouseEnter={() => handleMouseEnter(index, item.quiz_title, item.quiz_id)}
                  onMouseLeave={() => handleMouseLeave(item.quiz_title, item.quiz_id, index)}
                >
                  <div
                    className="absolute top-[14px] left-5"
                    style={{
                      display: props.checked.includes(item.quiz_id.toString()) || (showHoverButton && index === hoverDivIndex) ? "block" : "none",
                    }}
                  >
                    <input name="quizzes" value={item.quiz_id} type="checkbox" onChange={(e) => props.handleCheck(e)} />
                  </div>
                  <div
                    className="absolute bottom-[120px] right-5"
                    style={{
                      display: showHoverButton && index === hoverDivIndex ? "block" : "none",
                    }}
                  >
                    <button
                      className="w-[24px] h-[24px] rounded-xl bg-[#E6E0E9] flex justify-center items-center"
                      onClick={() => handleCopyQuizIndividual(item)}
                    >
                      {showHoverButton && index === hoverDivIndex && (
                        <Tooltip key={index} placement="right" title={"Make a copy"}>
                          <img style={{ width: "12px", height: "12px" }} src={Copy}></img>
                        </Tooltip>
                      )}
                    </button>
                  </div>
                  <div
                    className="absolute bottom-[90px] right-5"
                    style={{
                      display: showHoverButton && index === hoverDivIndex ? "block" : "none",
                    }}
                  >
                    <button
                      className="w-[24px] h-[24px] rounded-xl bg-[#E6E0E9] flex justify-center items-center"
                      onClick={() => editQuizName(index, item.quiz_title)}
                    >
                      {showHoverButton && index === hoverDivIndex && (
                        <Tooltip placement="right" title={"Rename"}>
                          <img style={{ width: "12px", height: "12px" }} src={Edit}></img>
                        </Tooltip>
                      )}
                    </button>
                  </div>
                  <div
                    className="absolute bottom-16 right-5"
                    style={{
                      display: showHoverButton && index === hoverDivIndex ? "block" : "none",
                    }}
                  >
                    <button
                      className="w-[24px] h-[24px] rounded-xl bg-[#E6E0E9] flex justify-center items-center"
                      onClick={() => handleDeleteIndividualQuiz(item.quiz_id)}
                    >
                      {showHoverButton && index === hoverDivIndex && (
                        <Tooltip placement="right" title={"Delete"}>
                          <img style={{ width: "12px", height: "12px" }} src={Delete}></img>
                        </Tooltip>
                      )}
                    </button>
                  </div>

                  <div
                    className="inner-my-quizzes"
                    style={{
                      paddingLeft: props.checked.includes(item.quiz_id.toString()) || (showHoverButton && index === hoverDivIndex) ? "40px" : "",
                    }}
                  >
                    {isEditing === index ? (
                      <input autoFocus ref={wrapperRef} maxLength={45} value={editedValue} onChange={onChange}></input>
                    ) : (
                      <div className="dashboard-my-quizzes-name text-with-dots">{item.quiz_title}</div>
                    )}

                    <div className="dashboard-my-quizzes-date">
                      {item.created_timestamp}
                      <div className="flex ml-1">
                        <img src={Views} alt="views" className="pr-1"></img>
                        <div>{item.quiz_attempt_count}</div>
                      </div>
                    </div>
                  </div>
                  <div className="dashboard-my-quizzes-info ">
                    <div className="dashboard-my-quizzes-subject text-with-dots">{item.subject_name}</div>
                    <div className="dashboard-my-quizzes-topic text-with-dots mb-[40px]">
                      <div className="width-full">{item.quiz_type === 2 ? "Mini-mock exam" : item.topic_name}</div>
                      {/* <Checkbox onChange={(e)=>{onQuizSelected(e, item)}} className=" float-right" /> */}
                    </div>
                  </div>
                  <div className="my-quizzes-card_button">
                    <button
                      style={{ display: userType === "2" ? "" : "none" }}
                      value={item.secret_key}
                      onClick={() => {
                        handleRawResponseClick(item.secret_key);
                      }}
                      className="my-quizzes-card-buttons my-quizzes-card-view-quiz-button ml-[10px]"
                    >
                      View Logs
                    </button>
                    <button
                      value={item.secret_key}
                      name={item.quiz_title}
                      className="my-quizzes-card-buttons my-quizzes-card-view-quiz-button"
                      onClick={() => {
                        handleViewQuizClick(item.secret_key, item.subject_name, item.subject_id);
                      }}
                    >
                      View Task
                    </button>
                    <button
                      id="button"
                      value={item.secret_key}
                      name={item.quiz_title}
                      created_timestamp={item.created_timestamp}
                      onClick={() => handleViewResultClick(item)}
                      className="my-quizzes-card-buttons my-quizzes-card-view-result-button"
                    >
                      View Result
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DashboardQuizCard;
