import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import SetQuestionSetCardCard from "./Widgets/CreateQuestionSetCard";
import { useNavigate } from "react-router-dom";
import SubjectCard from "./Widgets/SubjectCard";
import axios from "axios";

function CreateQuestionSet() {
  const navigate = useNavigate();
  const [subjectData, setSubjectsData] = useState([]);
  const [isLoading, setIsLoading] = useState();

  const handleOnClickCreateQuestionSet = () => {
    navigate("/select-subject");
  };

  const handleClickOnSubject = () => {
    navigate("/view-self-created-question-bank");
  };

  const getSubjectsList = () => {
    axios
      .request({
        method: "GET",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_subjects",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((res) => {
        console.log("Data inside Subject Selection: ", res.data);
        setSubjectsData(res.data);
        setIsLoading(false);
      });
  };

  useEffect(() => {}, []);

  return (
    <>
      <div className="mt-1 mr-1 bg-white rounded-lg overflow-none">
        <Sidebar item_id="create_question_set" />
        <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[19.75rem] ">
          <SetQuestionSetCardCard handleOnClick={handleOnClickCreateQuestionSet} />
        </div>
        <div>
          {subjectData.length > 0 &&
            subjectData.map((subject, index) => <SubjectCard index={index} subject={subject} handleOnClick={handleClickOnSubject} />)}
        </div>
      </div>
    </>
  );
}

export default CreateQuestionSet;
