import React, { useEffect, useRef } from "react";
import MonacoEditor from "@monaco-editor/react";
import he from "he"; // Install with `npm install he`

function CodeEditor({ onChange, initialCode = "", value = "", readOnly, from, height = "18.75rem" }) {
  console.log("EDITOR", from);
  const combinedValue = `${value}`;
  const initialRender = useRef(true);

  const decodeHtmlEntities = (text) => he.decode(text || "");

  const decodedValue = decodeHtmlEntities(combinedValue);

  useEffect(() => {
    if (initialCode && initialRender.current) {
      onChange(value);
      initialRender.current = false;
    }
  }, [initialCode, onChange]);

  return (
    <MonacoEditor
      height={height}
      width="100%"
      language="python"
      theme="vs-dark"
      value={decodedValue} // Pass the decoded value
      options={{
        fontSize: 16,
        minimap: { enabled: false },
        lineNumbers: "on",
        scrollBeyondLastLine: false,
        automaticLayout: true,
        readOnly: readOnly,
      }}
      onChange={readOnly ? undefined : (newValue) => onChange && onChange(newValue)}
    />
  );
}

export default CodeEditor;
