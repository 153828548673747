export function removeHTMLTags(str) {
  if (str === null || str === "") return false;
  else str = str.toString();

  // Regular expression to identify HTML tags in the string
  return str.replace(/<p>/g, "");
}
export function extractQuestionAndCodeText(questionstr) {
  let simpleString = removeHTMLTags(questionstr?.split("##table")[0]);
  if (simpleString) {
    return simpleString.trim();
  } else {
    return "";
  }
}

export function ExtractTableData(str) {
  if (!str || typeof str !== "string") return { columnList: [], rowList: [] };

  const tableDataString = str.split("##table")[1];
  if (!tableDataString) return { columnList: [], rowList: [] };

  try {
    const tableData = JSON.parse(tableDataString.trim());
    return {
      columnList: tableData.columns || [],
      rowList: tableData.rows || [],
    };
  } catch (error) {
    return { columnList: [], rowList: [] };
  }
}

export default function extractQuestionText(questionstr) {
  let simpleString = removeHTMLTags(questionstr?.split("##table")[0].split("##code")[0]);
  if (simpleString) {
    return simpleString.trim();
  } else {
    return "";
  }
}
