import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import animation from "../../../images/bulb_lottie.json";
import moment from "moment";
import LoadingIndicator from "../../Student/widget/mockbot/LoadingIndicator";

function PractiseSessions({ sessionList, handleViewSummary }) {
  const [isLoading, setIsLoading] = useState(true);

  const changeFormat = (date) => {
    const dateString = date;
    const formattedDate = moment(dateString).format("DD/MM/YYYY");
    return formattedDate;
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  }, []);

  return (
    <div className="py-12 px-16">
      {!isLoading ? (
        sessionList && sessionList.length !== 0 ? (
          <table className="w-full text-sm text-left text-white">
            <thead className="text-xs text-white bg-[#6750A4]" style={{ height: "3.5rem" }}>
              <tr>
                <th scope="col" className="px-6" style={{ height: "3.5rem" }}>
                  S.No
                </th>
                <th scope="col" className="px-6" style={{ height: "3.5rem" }}>
                  Practise Sessions
                </th>
                <th scope="col" className="px-6" style={{ height: "3.5rem" }}>
                  Topics
                </th>
                <th scope="col" className="px-6" style={{ height: "3.5rem" }}>
                  Date
                </th>
                <th scope="col" className="" style={{ height: "3.5rem" }}>
                  Practise Attempts
                </th>
              </tr>
            </thead>
            <tbody>
              {/* Static data rows */}
              {sessionList &&
                sessionList.map((session, index) => (
                  <tr key={index} className="bg-white border-b text-black">
                    <td className="px-6 py-4 mx-8">{index + 1}</td>
                    <td className="px-6 py-4 mx-8">{`Practise Session ${index}`}</td>
                    <td className="px-6 py-4 mx-8">
                      {/* <div className="relative group w-[18rem] whitespace-nowrap text-ellipsis overflow-hidden">{session.topic_name}</div> */}
                      {/*  */}
                      <div className="flex gap-2 sm:ml-[4px] mt-2 relative group">
                        <div className="w-[20rem] whitespace-nowrap text-ellipsis overflow-hidden">{session.topic_name}</div>
                        <div
                          className="absolute hidden group-hover:block bg-white text-black text-sm font-medium p-2 rounded-md shadow-md z-50"
                          style={{
                            // bottom: "calc(100% + 8px)", // Position above the cursor/element with a small gap
                            left: "calc(20% + 80px)", // Align horizontally with the parent element
                            whiteSpace: "pre-wrap", // Preserve the full text layout
                            maxWidth: "400px", // Restrict the tooltip width
                            border: "1px solid #ccc", // Optional: Add a border for styling
                          }}
                        >
                          {session.topic_name}
                        </div>
                      </div>
                    </td>
                    <td className="pl-6 pr-12 py-4 mx-8">{changeFormat(session.creation_timestamp)}</td>
                    <td className="">
                      <button
                        className="border border-[#6750A4] text-[#6750A4] px-4 py-2 rounded hover:bg-[#6750A4] hover:text-white transition"
                        onClick={() => {
                          handleViewSummary(session.student_response_id);
                        }}
                      >
                        View Summary
                      </button>
                    </td>
                  </tr>
                ))}

              {/* END HERE */}
            </tbody>
          </table>
        ) : (
          <div className=" text-center md:mt-12 ">
            {" "}
            <Lottie options={defaultOptions} height={180} width={150} />
            <h3 class="title md:mt-12">Practice not Started!</h3>
            <p class="subtitle">Students haven’t attempted the practice session yet.</p>
          </div>
        )
      ) : (
        <LoadingIndicator text={"Loading..."} />
      )}
    </div>
  );
}

export default PractiseSessions;
