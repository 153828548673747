import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import delete_icon from "../../../images/delete.png";
import SuccessDialog from "../confirmationdialogue/SuccessDialog";
import DeleteTeacherDialog from "../confirmationdialogue/DeleteTeacherDialog";
import DeclineTeacherDialog from "../confirmationdialogue/DeclineTeacherDialogue";

const TeacherTable = ({ teacher_data, is_parent }) => {
  const [localTeacherData, setLocalTeacherData] = useState([...teacher_data]);
  const [openDialog, setOpenDialog] = useState(false); // State for confirmation dialog visibility
  const [openDialog2, setOpenDialog2] = useState(false); // State for confirmation dialog visibility
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false); // State for success dialog visibility
  const [selectedTeacher, setSelectedTeacher] = useState(null); // Store the teacher to delete
  const [isDeleted, setIsDeleted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleOpenDialog = (teacher, index) => {
    setSelectedTeacher({ teacher, index }); // Store selected teacher info
    setOpenDialog(true); // Open confirmation dialog
  };
  const handleOpenDialog2 = (teacher, index) => {
    setSelectedTeacher({ teacher, index }); // Store selected teacher info
    setOpenDialog2(true); // Open confirmation dialog
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); // Close confirmation dialog
    setSelectedTeacher(null); // Clear selection
  };
  const handleCloseDialog2 = () => {
    setOpenDialog2(false); // Close confirmation dialog
    setSelectedTeacher(null); // Clear selection
  };

  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false); // Close success dialog
  };

  const handleDeleteConfirm = () => {
    const { teacher, index } = selectedTeacher;
    const token = "Bearer " + localStorage.getItem("access_token");

    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_class_status", {
        method: "POST",
        web_id: teacher.web_user_id,
        classroom_id: teacher.classroom_id,
        status: 2, // Status 2 to decline/delete
      })
      .then((response) => {
        const updatedTeachers = localTeacherData.filter((_, i) => i !== index);
        setLocalTeacherData(updatedTeachers); // Update local state with the filtered array
        handleCloseDialog(); // Close confirmation dialog
        setOpenSuccessDialog(true); // Open success dialog
        setIsDeleted(true);
      })
      .catch((error) => {
        console.log(error);
        handleCloseDialog(); // Close dialog even on error
      });
  };
  const handleDeclineOrDelete = (class_id, web_id, is_decline) => {
    const { teacher, index } = selectedTeacher;
    const token = "Bearer " + localStorage.getItem("access_token");

    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_class_status", {
        method: "POST",
        web_id: teacher.web_user_id,
        classroom_id: teacher.classroom_id,
        status: 2, // Decline/Delete status
      })
      .then((response) => {
        const updatedTeachers = localTeacherData.filter((_, i) => i !== index);
        setLocalTeacherData(updatedTeachers); // Update local state with the filtered array
        setOpenDialog2(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleApprove = (index, class_id, web_id) => {
    setIsSubmitting(true);
    const token = "Bearer " + localStorage.getItem("access_token");

    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_class_status", {
        method: "POST",
        web_id: web_id,
        classroom_id: class_id,
        status: 1, // Approve status
      })
      .then((response) => {
        const updatedTeachers = [...localTeacherData];
        updatedTeachers[index].status = 1; // Set the status to 1 (Approved)
        setLocalTeacherData(updatedTeachers);
        setOpenSuccessDialog(true);
        setIsDeleted(false);
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {/* Instructions Section */}
      <div style={{ marginBottom: "20px", fontFamily: "Roboto" }}>
        {" "}
        {/* Adjust margin as needed */}
        <p style={{ fontWeight: "bold" }}>Adding Co-Teachers to Your Classroom</p>
        <ol className="list-decimal  space-y-2">
          <li>Share the class code with your co-teacher.</li>
          <li>
            The co-teacher will go to <strong>"Join Shared Class"</strong> and enter the class code.
          </li>
          <li>You’ll receive a request for approval. Once approved, the co-teacher will gain access to the classroom.</li>
        </ol>
      </div>
      <table className="w-full text-sm text-left text-white dark:text-white">
        <thead className="text-xs text-white bg-[#6750A4] dark:bg-white dark:text-white">
          <tr>
            <th scope="col" className="px-6 py-3">
              ID
            </th>
            <th scope="col" className="px-6 py-3">
              Name
            </th>
            <th scope="col" className="px-6 py-3">
              Email
            </th>
            <th scope="col" className="px-6 py-3 text-center">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {localTeacherData.map((teacher, index) => (
            <tr key={teacher.web_user_id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                {index + 1}
              </th>
              <td className="px-6 py-4">
                {teacher.first_name ? teacher.first_name : "n/a"} {teacher.last_name ? teacher.last_name : ""}
              </td>

              <td className="px-6 py-4">{teacher.email_address}</td>
              <td className="px-6 py-4">
                {teacher.status === null ? (
                  <div className="flex justify-center">
                    <div className="flex  items-center px-2 py-[6px] border border-[black] rounded-lg" style={{ width: "fit-content" }}>
                      <FontAwesomeIcon icon={faCrown} className="text-[#6750A4] mr-2" />
                      <span className="text-[#6750A4]">Owner</span>
                    </div>
                  </div>
                ) : teacher.status === 0 && is_parent ? (
                  <div className="flex justify-center">
                    <div className="flex space-x-4">
                      <button
                        className="text-green-600 border border-green-600 px-3 py-1 rounded-lg"
                        onClick={() => handleApprove(index, teacher.classroom_id, teacher.web_user_id)}
                      >
                        {isSubmitting && (
                          <div
                            style={{ height: "20px", width: "20px" }}
                            className="spinner-border spinner-border-m float-left mr-2 mt-[2px]"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                        Approve
                      </button>
                      <button className="text-red-600 border border-red-600 px-3 py-1 rounded-lg" onClick={() => handleOpenDialog2(teacher, index)}>
                        Decline
                      </button>
                    </div>
                  </div>
                ) : teacher.status === 1 && is_parent ? (
                  <Dropdown className="flex justify-center">
                    <Dropdown.Toggle variant="light" className="p-2">
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => handleOpenDialog(teacher, index)} // Open the delete confirmation dialog
                        className="flex items-center rounded-md transition-all duration-200 ease-in-out bg-[#FFFBFE]"
                      >
                        <span className="flex items-center">
                          <img src={delete_icon} className="mr-2 h-[16px] w-[16px]" alt="delete icon" />
                          <span className="text-sm font-normal">Delete Teacher</span>
                        </span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Use the separate DeleteTeacherDialog component for confirmation */}
      <DeleteTeacherDialog open={openDialog} onClose={handleCloseDialog} onConfirm={handleDeleteConfirm} />
      <DeclineTeacherDialog open={openDialog2} onClose={handleCloseDialog2} onConfirm={handleDeclineOrDelete} />
      {/* Use the separate SuccessDialog component for success notification */}
      <SuccessDialog
        open={openSuccessDialog}
        onClose={handleCloseSuccessDialog}
        message="Teacher has been successfully deleted."
        isDeleted={isDeleted}
      />
    </>
  );
};

export default TeacherTable;
