import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  IconButton,
  CircularProgress,
  TextareaAutosize,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import IncDecCounter from "../../IncDecCounter";
import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";
import axios from "axios";
import CodeEditor from "../../widgets/CodeEditor";
import moment from "moment";
import AcknowledgmentPopup from "./AddOwnQuestionDialogue";
import ShareQuizUrlDialogue from "../../Teacher/outsideClassQuizGeneration/ShareQuizUrlDialogue";
import EditableTable from "../../Teacher/widgets/EditableTable";
import { ExtractTableData } from "../../Helper/RemoveHTMLTags";

export default function QuestionForm({ subjectId }) {
  console.log("QuestionComponent");
  const [questions, setQuestions] = useState([
    { id: 0, question: "", question_marks: 1, rubric: "", haveCode: false,have_table: false, topic_id: "", sub_topic_id: "" },
  ]);
  const codeText = useRef([]);
  const [uploadedImages, setUploadedImages] = useState(questions.map(() => ""));
  const [topicsOptionList, setTopicsOptionList] = useState([]);
  const selectedTopic = useRef([]);
  const [subtopicsOptionList, setSubtopicsOptionList] = useState([]);
  const [isAnyFieldEmpty, setIsAnyFieldEmpty] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const noDuplicate = useRef();
  const [openDuplicatedDialogue, setOpenDuplicatedDialogue] = useState();
  const duplicateQuestionIdsList = useRef([]);
  const [quizData, setQuizData] = useState();
  const [openAskToShareQuiz, setOpenAskToShareQuiz] = useState();
  const secretKey = useRef();
  const [openCopyQuizUrl, setOpenCopyQuizUrl] = useState();

  const tableRefs = useRef({});

  const validateFields = () => {
    if (questions.length !== 0) {
      const isEmpty = questions.some(
        (questionObj) =>
          questionObj.question.trim() === "" ||
          questionObj.rubric.trim() === "" ||
          questionObj.topic_id.trim() === "" ||
          questionObj.sub_topic_id.trim() === ""
      );
      if (isAnyFieldEmpty !== isEmpty) {
        setIsAnyFieldEmpty(isEmpty);
      }
    }
  };

  useEffect(() => {
    getTopics();
  }, []);

  const getTopics = () => {
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_chapters",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: JSON.stringify({
          subject_id: subjectId,
        }),
      })
      .then((res) => {
        setTopicsOptionList(res.data);
      });
  };

  const getSubTopics = (index, topicId, topicName) => {
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_topics",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: JSON.stringify({
          chapterId: topicId,
          chapterName: topicName,
        }),
      })
      .then((res) => {
        // setSubtopicsOptionList(res.data);
        setSubtopicsOptionList((prev) => {
          var newList = [...prev];
          while (newList.length <= index) {
            newList.push(undefined);
          }
          newList[index] = res.data;
          return newList;
        });
      });
  };

  const onSelectTopic = (index, e) => {
    handleTopicChange(index, e.target.value);
    selectedTopic.current[index] = topicsOptionList.find((topic) => topic.chapter_id.toString() === e.target.value);
    getSubTopics(index, selectedTopic.current[index].chapter_id, selectedTopic.current[index].chapter_name);
  };

  const onSelectSubtopic = (index, e) => {
    handleSubtopicChange(index, e.target.value);
  };

  const addNewQuestion = () => {
    setQuestions([
      ...questions,
      { id: questions.length, question: "", question_marks: 1, rubric: "", haveCode: false, have_table:false, topic_id: "", sub_topic_id: "" },
    ]);
    setUploadedImages((prevImages) => [...prevImages, ""]);
  };

  const deleteQuestion = (id) => {
    setQuestions((prevQuestions) => {
      const indexToDelete = prevQuestions.findIndex((questionObj) => questionObj.id === id);
      setUploadedImages((prevImages) => prevImages.filter((_, idx) => idx !== indexToDelete));
      return prevQuestions.filter((questionObj) => questionObj.id !== id);
    });
  };

  const handleQuestionChange = (id, value) => {
    setQuestions(questions.map((questionObj) => (questionObj.id === id ? { ...questionObj, question: value } : questionObj)));
  };

  const handleMarksChange = (id, value) => {
    setQuestions(questions.map((questionObj) => (questionObj.id === id ? { ...questionObj, question_marks: value } : questionObj)));
  };

  const handleRubricChange = (id, value) => {
    setQuestions(questions.map((questionObj) => (questionObj.id === id ? { ...questionObj, rubric: value } : questionObj)));
  };

  const handleCodeToggle = (id) => {
    setQuestions(questions.map((questionObj) => (questionObj.id === id ? { ...questionObj, haveCode: !questionObj.haveCode, have_table: false } : questionObj)));
  };

  const handleTableToggle = (id) => {
    setQuestions(questions.map((questionObj) => (questionObj.id === id ? { ...questionObj, have_table: !questionObj.have_table, haveCode: false } : questionObj)));
  };

  const handleTopicChange = (id, value) => {
    setQuestions(questions.map((questionObj) => (questionObj.id === id ? { ...questionObj, topic_id: value, sub_topic_id: "" } : questionObj)));
  };

  const handleSubtopicChange = (id, value) => {
    setQuestions(questions.map((questionObj) => (questionObj.id === id ? { ...questionObj, sub_topic_id: value } : questionObj)));
  };

  const handleFileUpload = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedImages((prevImages) => {
        const newImages = [...prevImages];
        newImages[index] = file;
        return newImages;
      });
    }
  };

  const handleCodeChange = (id, value) => {
    const index = questions.findIndex((questionObj) => questionObj.id === id);
    codeText.current[index] = value;
  };

  const handleSaveTableData = (index) => {
    if (tableRefs.current[index]) {
      const data = tableRefs.current[index].getTableData();
      // setHaveTable((prev) => ({
      //   ...prev,
      //   [index]: { ...prev[index], tableData: data }, // Save table data inside state
      // }));
      return data;
    }
    return null;
  };

  const onSubmitForm = (questionList) => {
    setIsSubmitting(true);
    setIsAnyFieldEmpty(true);

    questionList = questionList.map((questionObj, index) => {
      let currentTableData = questionObj.have_table && handleSaveTableData(index);

      return questionObj.haveCode
        ? { ...questionObj, question: `${questionObj.question} \n##code\n ${codeText.current[index] ?? ""}\n##code` }
        : questionObj.have_table
        ? { ...questionObj, question: `${questionObj.question} \n##table\n ${JSON.stringify(currentTableData)} \n ##table` }
        : questionObj;
    });

    var token = "Bearer " + localStorage.getItem("access_token");

    const formData = new FormData();
    formData.append("subject_id", subjectId);
    formData.append("question_list", JSON.stringify(questionList));
    formData.append("trial_start_date", moment().format("Y-MM-DD HH:mm:ss"));
    formData.append("duplicate_question", noDuplicate.current);

    uploadedImages.forEach((file, index) => {
      if (file instanceof File) {
        formData.append("file", file);
      } else {
        formData.append(`file`, new Blob([""], { type: "text/plain" }));
      }
    });

    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/add_own_subject_questions",
        headers: {
          authorization: token, // Axios automatically sets 'Content-Type' for FormData
        },
        data: formData,
      })
      .then((res) => {
        if (res.data.is_duplicate) {
          handleDuplicateQuestionFound(res.data.question_list);
        } else {
          setQuizData(res.data.quiz_body);
          setOpenAskToShareQuiz(true);
          setIsSubmitting(false);
          noDuplicate.current = true;
        }
      })
      .catch((err) => {
        console.log("Error in /add_own_subject_questions: ", err);
        setIsSubmitting(false);
      });
  };

  const handleDuplicateQuestionFound = (duplicateQuestionIds) => {
    duplicateQuestionIdsList.current = duplicateQuestionIds.map((quesIndex) => quesIndex + 1);
    setOpenDuplicatedDialogue(true);
  };

  const handleKeepDuplicateQues = () => {
    noDuplicate.current = false;
    setOpenDuplicatedDialogue(false);
    onSubmitForm(questions);
  };

  const handleChangeDuplicateQues = () => {
    setOpenDuplicatedDialogue(false);
    setIsSubmitting(false);
  };

  const handleCloseWithShare = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/save_quiz",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: token,
        },
        data: JSON.stringify({
          body: quizData,
        }),
      })
      .then((res) => {
        secretKey.current = res.data.secret_key;
        setOpenCopyQuizUrl(true);
      })
      .catch((err) => {});
    setOpenAskToShareQuiz(false);
  };

  const handleCloseAskToSharePopup = () => {
    setOpenAskToShareQuiz(false);
    questionForm();
  };

  const questionForm = () => {
    setQuestions([]);
    setUploadedImages([]);
  };

  useEffect(() => {
    validateFields();
  }, [questions]);

  return (
    <div className="w-full p-5 bg-[#F0F0F0]">
      <AcknowledgmentPopup
        heading={`Quesion Already Exists`}
        description={`Question ${duplicateQuestionIdsList.current} already exists in your Repository. \n Do you want to change duplicate question or keep it?`}
        PrimaryBtnText="Keep it!"
        secondaryBtnText="Change"
        handlePrimaryBtnSelected={handleKeepDuplicateQues}
        handleSecondaryBtnSelected={handleChangeDuplicateQues}
        open={openDuplicatedDialogue}
      />
      <AcknowledgmentPopup
        heading="Data saved Successfully"
        description="Do you want to share quiz with students?"
        PrimaryBtnText="Yes"
        secondaryBtnText="No"
        handlePrimaryBtnSelected={handleCloseWithShare}
        handleSecondaryBtnSelected={handleCloseAskToSharePopup}
        open={openAskToShareQuiz}
      />
      <ShareQuizUrlDialogue
        // window.open(`${window.location.origin + "/quiz?id=" + secretKey.current}`, "_blank");
        title="Share Quiz"
        quizUrl={window.location.origin + "/quiz?id=" + secretKey.current}
        handleCopy={() => {
          navigator.clipboard.writeText(window.location.origin + "/quiz?id=" + secretKey.current);
          questionForm();
          setOpenCopyQuizUrl(false);
        }}
        open={openCopyQuizUrl}
        handleClose={() => {
          questionForm();
          setOpenCopyQuizUrl(false);
        }}
      />
      <Typography variant="h5" style={{ marginBottom: "10px" }}>
        Add Questions
      </Typography>

      {questions.map((questionObj, index) => {
        const tableData = ExtractTableData(questionObj.question);
        return (
          <Accordion key={questionObj.id} className="mb-[0.625rem]">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="flex items-center justify-between">
              <Typography>Question {index + 1}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ width: "100%" }}>
                <div className="flex items-center justify-end gap-3 mb-1">
                  <Typography variant="subtitle1">Marks:</Typography>
                  <IncDecCounter
                    label=""
                    uper_limit={15}
                    lower_limit={1}
                    marks={questionObj.question_marks}
                    onSaveSubTopicMarks={(value) => handleMarksChange(questionObj.id, value)}
                  />
                  <FormControlLabel
                    control={<Checkbox checked={questionObj.haveCode} onChange={() => handleCodeToggle(questionObj.id)} />}
                    label="Have Code"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={questionObj.have_table} onChange={() => handleTableToggle(questionObj.id)} />}
                    label="Have Table"
                  />
                  <IconButton onClick={() => deleteQuestion(questionObj.id)} color="error">
                    <DeleteIcon />
                  </IconButton>
                </div>
                <Typography variant="subtitle1" className="mb-1">
                  Question Text:
                </Typography>
                <ReactQuill
                  value={questionObj.question}
                  onChange={(value) => handleQuestionChange(questionObj.id, value)}
                  theme="snow"
                  style={{ height: questionObj.haveCode || questionObj.have_table ? "10rem" : "30rem", marginBottom: "4rem" }}
                />
                {questionObj.haveCode && (
                  <CodeEditor
                    language="javascript" // Replace with the language you want or leave empty for generic text
                    theme="monokai"
                    name="code"
                    value=""
                    onChange={(value) => {
                      handleCodeChange(questionObj.id, value);
                    }}
                    height="30rem"
                  />
                )}
                {questionObj.have_table && (
                  <EditableTable
                    ref={(el) => (tableRefs.current[index] = el)} // UPDATED: Use ref for multiple tables
                    columnList={tableData.columnList}
                    rowList={tableData.rowList}
                    isEditable={true}
                    isAdmin={true}
                  />
                )}
                <Typography variant="subtitle1" className="mt-2">
                  Rubric:
                </Typography>
                <TextareaAutosize
                  className="shadow-sm outline-none rounded-[5px] border-[1px] border-[#CCCCCC] text-[#474747] text-[0.825rem] font-[400] px-2 py-1"
                  fullWidth
                  multiline
                  rows={2}
                  value={questionObj.rubric}
                  onChange={(e) => handleRubricChange(questionObj.id, e.target.value)}
                  style={{ marginBottom: "20px", width: "100%" }}
                />
                <div className="flex justify-end gap-2.5">
                  <select
                    placeholder="select topic"
                    id="select topic"
                    onChange={(e) => {
                      onSelectTopic(questionObj.id, e);
                    }}
                    name="topic"
                    className="block w-full pr-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option hidden value="">
                      Select topic
                    </option>
                    {topicsOptionList.length > 0 &&
                      topicsOptionList.map((topic) => (
                        <option key={topic.chapter_id} value={topic.chapter_id}>
                          {topic.chapter_name}
                        </option>
                      ))}
                  </select>
                  <select
                    placeholder="select subtopic"
                    id="select subtopic"
                    onChange={(e) => {
                      onSelectSubtopic(questionObj.id, e);
                    }}
                    name="subtopic"
                    className="block w-full pr-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option hidden value="">
                      Select subtopic
                    </option>
                    {subtopicsOptionList[index] &&
                      subtopicsOptionList[index].map((subtopic) => (
                        <option key={subtopic.topic_id} value={subtopic.topic_id}>
                          {subtopic.topic_name}
                        </option>
                      ))}
                  </select>
                </div>
                {/* {uploadedImages[index] && (
                  <img src={uploadedImages[index]} alt="Preview" style={{ width: "100px", height: "100px", marginTop: "10px" }} />
                )} */}
                <input type="file" accept="image/*" onChange={(e) => handleFileUpload(e, index)} className="mt-3" />
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}

      <div className="flex justify-between mt-2.5">
        <Button variant="contained" color="primary" onClick={addNewQuestion}>
          Add Question
        </Button>
        <Button
          variant="contained"
          color="success"
          disabled={isAnyFieldEmpty}
          onClick={() => {
            onSubmitForm(questions);
          }}
          style={{ display: "flex", alignItems: "center", justifyContent: "center", minWidth: "8.5rem" }} // Ensures space for spinner
        >
          {isSubmitting ? <CircularProgress size={24} sx={{ color: "green", marginRight: "0.625rem" }} /> : "Submit"}
        </Button>
      </div>
    </div>
  );
}
