import React, { useCallback, useState, useEffect, useReducer, useRef } from "react";
import EdiText from "react-editext";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/material/Button";
import IncDecCounter from "./IncDecCounter";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import EditIcon from "../images/edit.png";
import Tick from "../images/tick.png";
import Cancel from "../images/cancel.png";
import YesNoDialog from "../Componenets/YesNoDialog";
import EditableTable from "./Teacher/widgets/EditableTable";
import extractQuestionText, { ExtractTableData } from "./Helper/RemoveHTMLTags";
import { extractQuestionAndCodeText } from "./Helper/RemoveHTMLTags";

export default function PrefinedQuestions(props) {
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [predefinedSelectedQuestionDeletionObject, setPredefinedSelectedQuestionDeletionObject] = useState();
  const [predefinedQuestionAnswerList, setPredefinedQuestionAnswerList] = useState(JSON.parse(props.predefined_question_answer_list));
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [haveTable, setHaveTable] = useState({});
  const tableRefs = useRef({});
  const isEditingRef = useRef(false);

  var modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }, { align: [] }],
      [
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
      ],
    ],
  };
  var formats = ["header", "height", "bold", "italic", "underline", "strike", "blockquote", "list", "color", "bullet", "indent", "align", "size"];
  var editValue;
  const handleProcedureContentChange = (content) => {
    editValue = content;
  };
  useEffect(() => {
    setPredefinedQuestionAnswerList(JSON.parse(props.predefined_question_answer_list));
  }, [props.predefined_question_answer_list]);

  const onSaveSubTopicQuestion = (value, predefined_question_answer, index, id) => {
    // For table dev.
    isEditingRef.current = false;
    setHaveTable((prev) => ({
      ...prev,
      [index]: { ...prev[index], editable: false },
    }));

    let tableData = "";
    if (haveTable[index]?.haveTable || predefined_question_answer.have_table === 1) {
      // TODO:
      tableData = handleSaveTableData(index);

      if (value !== undefined) {
        value = value + "\n ##table" + JSON.stringify(tableData) + " \n ##table";
      } else {
        value = extractQuestionText(predefined_question_answer.question) + "\n ##table" + JSON.stringify(tableData) + " \n ##table";
      }
    }

    // End Table dev.

    if (value === undefined) {
      if (props.is_mini_mock) {
        document.getElementById(`questionm${id}`).style.display = "none";
        document.getElementById(`qm${id}`).style.display = "flex";
        return;
      }
      document.getElementById(`question${id}`).style.display = "none";
      document.getElementById(`q${id}`).style.display = "flex";
      return;
    }

    predefined_question_answer.question = value;

    setPredefinedQuestionAnswerList((prevList) => {
      const updatedList = [...prevList];
      updatedList[index] = predefined_question_answer;
      return updatedList;
    });

    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_sub_topic_predefined_question_answer", {
        method: "POST",
        predefined_question_answer: predefined_question_answer,
      })

      .then((response) => {
        let newArray = [...predefinedQuestionAnswerList];
        newArray[index] = predefined_question_answer;
        setPredefinedQuestionAnswerList(newArray);
        if (props.is_mini_mock) {
          document.getElementById(`questionm${id}`).style.display = "none";
          document.getElementById(`qm${id}`).style.display = "flex";
          return;
        }
        document.getElementById(`question${id}`).style.display = "none";
        document.getElementById(`q${id}`).style.display = "flex";
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCloseEditor = (index, id) => {
    // For Tabular dev.
    isEditingRef.current = false;

    setHaveTable((prev) => {
      if (!prev[index]?.haveTable) return prev;
      return {
        ...prev,
        [index]: { haveTable: true, editable: false },
      };
    });

    if (props.is_mini_mock) {
      document.getElementById(`questionm${id}`).style.display = "none";
      document.getElementById(`qm${id}`).style.display = "flex";
      return;
    }
    document.getElementById(`question${id}`).style.display = "none";
    document.getElementById(`q${id}`).style.display = "flex";
  };

  const getPredefinedQuestionsPromptsTypesList = (predefinedQuestionAnswer) => {
    const predefinedQuestionsPromptList = props.predefinedQuestionsPromptList.map((predefinedQuestionsPrompt) => (
      <option
        id={predefinedQuestionsPrompt.predefined_questions_prompt_id}
        selected={predefinedQuestionAnswer.predefined_questions_prompt_id === predefinedQuestionsPrompt.predefined_questions_prompt_id}
      >
        {predefinedQuestionsPrompt.prompt_label}
      </option>
    ));

    return predefinedQuestionsPromptList;
  };

  //   const addTodo = useCallback(() => {
  //     getSubjectDetails(id);
  //   },[id]);

  const handleFileUpload = (event, predefined_question_answer, index) => {
    // get the selected file from the input
    const file = event.target.files[0];
    // create a new FormData object and append the file to it
    const formData = new FormData();
    formData.append("file", file);
    formData.append("predefined_question_answer_id", predefined_question_answer.predefined_question_answer_id);
    // make a POST request to the File Upload API with the FormData object and Rapid API headers
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/upload_file", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // handle the response
        console.log(response);
        alert("File uploaded successfully");

        predefined_question_answer.image_file_name = response.data["image_file_name"];
        predefinedQuestionAnswerList[index] = predefined_question_answer;
        props.addTodo();
        // 👇️ reset file input
        event.target.value = null;
      })
      .catch((error) => {
        // handle errors
        alert("Error while uploading file");
        console.log(error);
        // 👇️ reset file input
        event.target.value = null;
      });
  };

  const removePredefinedQuestionImageFileName = (predefined_question_answer_id, predefined_question_answer) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/remove_predefined_questions_image_file", {
        method: "POST",
        predefined_question_answer_id: predefined_question_answer_id,
      })

      .then((response) => {
        if (props.is_mini_mock) {
          props.addTodoParent();
        }
        props.addTodo();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSaveSubTopicMarks = useCallback((value, predefined_question_answer, index) => {
    predefined_question_answer.question_marks = value;
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_sub_topic_predefined_question_answer", {
        method: "POST",
        predefined_question_answer: predefined_question_answer,
      })

      .then((response) => {
        predefinedQuestionAnswerList[index] = predefined_question_answer;
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onSaveSubTopicAnswer = (value, predefined_question_answer, index) => {
    predefined_question_answer.answer = value;
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_sub_topic_predefined_question_answer", {
        method: "POST",
        predefined_question_answer: predefined_question_answer,
      })

      .then((response) => {
        predefinedQuestionAnswerList[index] = predefined_question_answer;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onChangeCodeStatus = useCallback((value, predefined_question_answer, index) => {
    // Update `have_code` directly
    predefined_question_answer.have_code = value === "0" ? 1 : 0;

    // Set up the token and API configuration
    const token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;

    // Make the API call
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_sub_topic_predefined_question_answer", {
        method: "POST",
        predefined_question_answer: predefined_question_answer,
      })
      .then((response) => {
        // Update the local list on success
        predefinedQuestionAnswerList[index] = predefined_question_answer;
        forceUpdate();
      })
      .catch((error) => {
        console.log(error);
      });
  }, []); // Include dependencies

  const onChangeTableStatus = useCallback(
    (value, predefined_question_answer, index) => {
      setHaveTable((prev) => {
        // const currentState = prev[index]?.haveTable  ?? false;
        const currentState = prev[index]?.haveTable ?? predefined_question_answer.have_table === 1;
        const newState = {
          ...prev,
          [index]: currentState ? undefined : { haveTable: true, editable: isEditingRef.current, tableData: [] },
        };
        predefined_question_answer.have_table = newState[index]?.haveTable ? 1 : 0;

        const token = "Bearer " + localStorage.getItem("access_token");
        axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
        axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
        axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.post["authorization"] = token;

        axios
          .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_sub_topic_predefined_question_answer", {
            method: "POST",
            predefined_question_answer: predefined_question_answer,
          })
          .then((response) => {
            predefinedQuestionAnswerList[index] = predefined_question_answer;
            forceUpdate();
          })
          .catch((error) => {
            console.log(error);
          });
        return newState;
      });
    },
    [haveTable]
  );

  const onSaveSubTopicRubric = (value, predefined_question_answer, index) => {
    predefined_question_answer.rubric = value;
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_sub_topic_predefined_question_answer", {
        method: "POST",
        predefined_question_answer: predefined_question_answer,
      })

      .then((response) => {
        predefinedQuestionAnswerList[index] = predefined_question_answer;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onChange = (e, predefined_question_answer, predinedQuestionAnswerIndex) => {
    // const predefinedQuestionsPrompt = props.predefinedQuestionsPromptList.filter(
    //(predefined_questions_prompt) => predefined_questions_prompt.predefined_questions_prompt_id === e.target.selectedOptions[0].id
    //);

    //console.log("Selected predefinedQuestionsPrompt :", predefinedQuestionsPrompt[0].predefined_questions_prompt_id);
    if (e.target.selectedOptions[0].index > 0) {
      var id = props.predefinedQuestionsPromptList[e.target.selectedOptions[0].index - 1].predefined_questions_prompt_id;
      predefined_question_answer.predefined_questions_prompt_id = id;
    } else {
      predefined_question_answer.predefined_questions_prompt_id = null;
    }
    onSaveSubTopicQuestion(predefined_question_answer.question, predefined_question_answer, predinedQuestionAnswerIndex);
  };

  const handleOpenEditor = (questionIndex, id, predefined_question_answer) => {
    if (props.is_mini_mock) {
      document.getElementById(`questionm${id}`).style.display = "flex";
      document.getElementById(`qm${id}`).style.display = "none";
      return;
    }
    document.getElementById(`question${id}`).style.display = "flex";
    document.getElementById(`q${id}`).style.display = "none";
    isEditingRef.current = true;

    // For table dev.
    setHaveTable((prev) => {
      if (!prev[questionIndex]?.haveTable && predefined_question_answer.have_table !== 1) return prev;
      return {
        ...prev,
        [questionIndex]: { haveTable: true, editable: true },
      };
    });
    console.log("Have Table: ", haveTable);
  };

  const onDeleteClick = (object) => {
    setOpenConfirmationDialog(true);
    setPredefinedSelectedQuestionDeletionObject(object);
  };

  const handleCloseWithNo = () => {
    setOpenConfirmationDialog(false);
  };

  const handleCloseWithYes = () => {
    setOpenConfirmationDialog(false);
    var id = predefinedSelectedQuestionDeletionObject.predefined_question_answer_id;
    props.removeQuestion(id);
  };

  const handleSaveTableData = (index) => {
    if (tableRefs.current[index]) {
      const data = tableRefs.current[index].getTableData();
      setHaveTable((prev) => ({
        ...prev,
        [index]: { ...prev[index], tableData: data }, // Save table data inside state
      }));
      return data;
    }
    return null;
  };

  return (
    <div>
      <YesNoDialog
        handleCloseWithNo={handleCloseWithNo}
        handleCloseWithYes={handleCloseWithYes}
        open={openConfirmationDialog}
        title={"Confirm"}
        description={"Are you sure you want to delete this record?"}
      />
      {predefinedQuestionAnswerList && (
        <div>
          <p>
            {/* <b style={{ fontSize: "16px" }}>Predefined Question Answers:</b> */}
            {predefinedQuestionAnswerList.map((predefined_question_answer, predinedQuestionAnswerIndex) => {
              const tableData = ExtractTableData(predefined_question_answer.question);
              return (
                <React.Fragment key={predinedQuestionAnswerIndex}>
                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        padding: "10px",
                        borderRadius: "5px",
                        width: "100%",
                        marginBottom: "30px",
                        backgroundColor: "#F0F0F0",
                      }}
                    >
                      <Button
                        style={{ float: "right", minWidth: "0px" }}
                        onClick={() =>
                          // props.removeQuestion(
                          //   predefined_question_answer.predefined_question_answer_id
                          // )
                          onDeleteClick(predefined_question_answer)
                        }
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                      <p
                        className="answer-paragraph"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Roboto",
                          color: "#49454F",
                        }}
                      >
                        {props.predefinedQuestionsPromptList.length > 0 && (
                          <table>
                            <tr>
                              <td style={{ width: "120px" }}>
                                <label className="">Select Prompt</label>
                              </td>
                              <td style={{ width: "280px" }}>
                                <select
                                  id="modules_option"
                                  onChange={(e) => {
                                    onChange(e, predefined_question_answer, predinedQuestionAnswerIndex);
                                  }}
                                  name="chapter"
                                  className=" teepee-select shadow-none leading-[30px] font-[300] px-4 w-[50%] h-[100%] bg-transparent"
                                >
                                  <option value="Select prompt" defaultValue={"Select prompt"}>
                                    Select prompt
                                  </option>
                                  {getPredefinedQuestionsPromptsTypesList(predefined_question_answer)}
                                </select>
                              </td>
                              <td style={{ width: "120px" }}>
                                <label className="ml-4">Have Code</label>
                              </td>
                              <td>
                                <label class="inline-flex items-center cursor-pointer">
                                  <input
                                    type="checkbox"
                                    value={predefined_question_answer.have_code}
                                    checked={predefined_question_answer.have_code === 1}
                                    onChange={(e) => onChangeCodeStatus(e.target.value, predefined_question_answer, predinedQuestionAnswerIndex)}
                                    class="sr-only peer"
                                  />
                                  <div class="relative w-11 h-6 bg-gray-700 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                </label>
                              </td>
                              {/* This is new code for Tabular data */}
                              <td style={{ width: "120px" }}>
                                <label className="ml-4">Have Table</label>
                              </td>
                              <td>
                                <label className="inline-flex items-center cursor-pointer">
                                  <input
                                    type="checkbox"
                                    value={predefined_question_answer.have_table}
                                    checked={haveTable[predinedQuestionAnswerIndex] === 1 || predefined_question_answer.have_table === 1}
                                    onChange={() =>
                                      onChangeTableStatus(
                                        predefined_question_answer.have_table,
                                        predefined_question_answer,
                                        predinedQuestionAnswerIndex
                                      )
                                    }
                                    className="sr-only peer"
                                  />
                                  <div className="relative w-11 h-6 bg-gray-700 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                </label>
                              </td>
                              {/* End */}
                            </tr>
                          </table>
                        )}
                        <table>
                          <tr>
                            <td style={{ width: "120px" }}>
                              {props.is_mini_mock ? (
                                <b>Part ({String.fromCharCode(predinedQuestionAnswerIndex + "A".charCodeAt(0)).toLowerCase()})</b>
                              ) : (
                                <b>Question: {predinedQuestionAnswerIndex + 1}</b>
                              )}
                            </td>
                            <td style={{ width: "60px" }}>Marks:</td>
                            <td>
                              <b>
                                <IncDecCounter
                                  label=""
                                  uper_limit={15}
                                  lower_limit={1}
                                  marks={predefined_question_answer.question_marks}
                                  onSaveSubTopicMarks={onSaveSubTopicMarks}
                                  predefined_question_answer={predefined_question_answer}
                                  predinedQuestionAnswerIndex={predinedQuestionAnswerIndex}
                                />
                              </b>
                            </td>
                          </tr>
                        </table>

                        <div
                          id={
                            props.is_mini_mock
                              ? `qm${predefined_question_answer.predefined_question_answer_id}`
                              : `q${predefined_question_answer.predefined_question_answer_id}`
                          }
                          className="flex mt-[2%]"
                        >
                          <div className="mr-[5%]">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: extractQuestionAndCodeText(predefined_question_answer.question),
                              }}
                            ></div>
                          </div>
                          <div>
                            <button
                              className="p-[4px]"
                              style={{
                                border: "1px solid",
                                borderColor: "rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186)",
                              }}
                              onClick={() => {
                                handleOpenEditor(
                                  predinedQuestionAnswerIndex,
                                  predefined_question_answer.predefined_question_answer_id,
                                  predefined_question_answer
                                );
                              }}
                            >
                              <img src={EditIcon}></img>
                            </button>
                          </div>
                        </div>

                        <div
                          id={
                            props.is_mini_mock
                              ? `questionm${predefined_question_answer.predefined_question_answer_id}`
                              : `question${predefined_question_answer.predefined_question_answer_id}`
                          }
                          style={{ display: "none", width: "95%" }}
                          className="mb-[5%] mt-[4%]"
                        >
                          <ReactQuill
                            id={`value${predefined_question_answer.predefined_question_answer_id}`}
                            value={predefined_question_answer.question.split("##table")[0]}
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            placeholder="write your content ...."
                            onChange={handleProcedureContentChange}
                            style={{ height: "220px", width: "100%" }}
                          />

                          <div className="self-center mt-[6%] ml-[2%]">
                            <button
                              onClick={() =>
                                onSaveSubTopicQuestion(
                                  editValue,
                                  predefined_question_answer,
                                  predinedQuestionAnswerIndex,
                                  predefined_question_answer.predefined_question_answer_id
                                )
                              }
                              className="p-[4px]"
                              style={{
                                border: "1px solid",
                                borderColor: "rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186)",
                              }}
                            >
                              <img src={Tick}></img>
                            </button>
                            <button
                              onClick={() => handleCloseEditor(predinedQuestionAnswerIndex, predefined_question_answer.predefined_question_answer_id)}
                              className="p-[4px]"
                              style={{
                                border: "1px solid",
                                borderColor: "rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186)",
                              }}
                            >
                              <img src={Cancel}></img>
                            </button>
                          </div>
                        </div>
                        {(haveTable[predinedQuestionAnswerIndex]?.haveTable || predefined_question_answer.have_table === 1) && (
                          <EditableTable
                            ref={(el) => (tableRefs.current[predinedQuestionAnswerIndex] = el)} // UPDATED: Use ref for multiple tables
                            columnList={tableData.columnList}
                            rowList={tableData.rowList}
                            isEditable={haveTable[predinedQuestionAnswerIndex]?.editable}
                            isAdmin={true}
                          />
                        )}

                        {/* <EdiText
                          editOnViewClick
                          className="w-full"
                          type="textarea"
                          inputProps={{
                            onChange: (e) => {
                              //   setCount(e.target.value.length)
                            },
                            className: "textarea",
                            placeholder: "Type your content here",
                            style: {
                              width: "100%",
                              height: "100%",
                              padding: "5px",
                            },
                            rows: 10,
                          }}
                          onSave={(value) =>
                            onSaveSubTopicQuestion(
                              value,
                              predefined_question_answer,
                              predinedQuestionAnswerIndex
                            )
                          }
                          value={predefined_question_answer.question}
                        /> */}
                      </p>
                      <p
                        className="answer-paragraph"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Roboto",
                          color: "#49454F",
                        }}
                      >
                        <div className="flex justify-center mb-[20px] mt-[20px]">
                          {predefined_question_answer.image_file_name !== null ? (
                            <div>
                              <Button
                                style={{ float: "right", minWidth: "0px" }}
                                onClick={() => removePredefinedQuestionImageFileName(predefined_question_answer.predefined_question_answer_id)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                              <img alt="" src={process.env.REACT_APP_CDN_URL_FOR_QUESTION_IMAGES + predefined_question_answer.image_file_name}></img>
                            </div>
                          ) : (
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => handleFileUpload(e, predefined_question_answer, predinedQuestionAnswerIndex)}
                            />
                          )}
                        </div>

                        <b>Answer: </b>

                        <EdiText
                          editOnViewClick
                          className="w-full"
                          type="textarea"
                          inputProps={{
                            onChange: (e) => {},
                            className: "textarea",
                            placeholder: "Type your content here",
                            style: {
                              width: "100%",
                              height: "100%",
                              padding: "5px",
                            },
                            rows: 10,
                          }}
                          onSave={(value) => onSaveSubTopicAnswer(value, predefined_question_answer, predinedQuestionAnswerIndex)}
                          value={predefined_question_answer.answer}
                        />
                      </p>

                      <p
                        className="answer-paragraph"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Roboto",
                          color: "#49454F",
                        }}
                      >
                        <b>Rubric: </b>

                        <EdiText
                          editOnViewClick
                          className="w-full"
                          type="textarea"
                          inputProps={{
                            onChange: (e) => {},
                            className: "textarea",
                            placeholder: "Type your content here",
                            style: {
                              width: "100%",
                              height: "100%",
                              padding: "5px",
                            },
                            rows: 10,
                          }}
                          onSave={(value) => onSaveSubTopicRubric(value, predefined_question_answer, predinedQuestionAnswerIndex)}
                          value={predefined_question_answer.rubric}
                        />
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      height: "1px",
                      backgroundColor: "#B3B3B3",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  />
                </React.Fragment>
              );
            })}
          </p>
        </div>
      )}
    </div>
  );
}
