import React, { Fragment, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import infoLogo from "../../images/info.png";
import BlacklistedQuestionDialogue from "./BlacklistedQuestionDialogue";
import Setting from "../../images/settings.png";
import BackArrow from "../../images/arrow_back_ios_new.png";
import EmptyBox from "../../images/emptybox.gif";
import AddToQuiz from "../../images/addquiz.png";
import Cross from "../../images/cross.png";
import Hand from "../../images/hand.png";
import QuizRawResponseAccordian from "../QuizRawResponseAccordian";
import EditableTable from "../Teacher/widgets/EditableTable";
import { ExtractTableData } from "../Helper/RemoveHTMLTags";

function ReplaceQuestionDialogue(props) {
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [openBlacklistedReplaceQuestionDialogue, setOpenBlacklistedReplaceQuestionDialogue] = React.useState(false);
  const userType = localStorage.getItem("user_type");
  const [openViewLogsDialog, setOpenViewLogsDialog] = React.useState(false);

  const handleClick = (index, rowData) => () => {
    console.log("CLICKED");
    // if(props)
    // if (props.selectedQuestionForReplacement.question_marks !== rowData.question_marks) {
    //   alert(`You can only replace with ${props.selectedQuestionForReplacement.question_marks} mark question`);
    //   return;
    // }
    setOpen(true);
    setRowData(rowData);
  };

  const handleClickOpenBlacklistedReplacementQuestionDialogue = () => {
    setOpenBlacklistedReplaceQuestionDialogue(true);
  };

  const handleCloseBlackListedReplacementQuestionDialogue = () => {
    setOpenBlacklistedReplaceQuestionDialogue(false);
  };

  const handleCloseWithCancel = () => {
    setOpen(false);
  };
  const handleCloseWithYes = () => {
    props.replaceQuestion(props.questionIndex, rowData);
    setOpen(false);
    props.handleClose();
    props.handleCloseParentDialogue();
  };

  const handleCrossWithCrossButton = () => {
    props.handleClose();
    props.handleCloseParentDialogue();
  };

  const handleViewLogDialogCloseWithCancel = () => {
    setOpenViewLogsDialog(false);
  };
  const handleRawResponseClick = () => {
    setOpenViewLogsDialog(true);
  };

  return (
    <div>
      <Dialog
        className="overflow-y-hidden"
        open={openViewLogsDialog}
        onClose={handleViewLogDialogCloseWithCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <b>Raw Response</b>
        </DialogTitle>
        <DialogContent className="overflow-y-hidden">
          <QuizRawResponseAccordian quizEvaluationLog={props.quiz_generation_ids_list.current} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewLogDialogCloseWithCancel}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={handleCloseWithCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <b>Confirm</b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to add this question?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWithCancel}>NO</Button>
          <Button className="delete-button" onClick={handleCloseWithYes} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        PaperProps={{
          sx: {
            width: "850px",
            maxWidth: "900px",
            height: "690px",
            maxHeight: "700px",
          },
        }}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="flex">
          <button className=" mt-1" onClick={props.handleClose}>
            <img src={BackArrow}></img>
          </button>
          <div className="text-center w-[100%] flex justify-center">
            {props.response_data.length !== 0 ? "Select your favourite question" : "Teepee Explorer!"}
            {props.response_data.length !== 0 ? <img src={Hand}></img> : ""}
          </div>

          <button className="sm:w-[20px]" onClick={() => handleCrossWithCrossButton()}>
            <img src={Cross}></img>
          </button>
        </DialogTitle>
        <hr className="mr-[30px] ml-[30px]"></hr>
        {props.response_data.length !== 0 ? (
          <DialogContent>
            {(props.selectedQuizType === 1 || props.selectedQuizType === 3 || props.mock_exam === null) &&
              props.response_data?.map((rowData, index) => {
                const tableData = ExtractTableData(rowData.question);
                return (
                  <Fragment key={index}>
                    <div className="md:w-[800px]">
                      <div
                        style={{
                          padding: "10px",
                          borderRadius: "5px",
                          width: "100%",
                          marginBottom: "20px",
                          backgroundColor: "#F0F0F0",
                        }}
                      >
                        <p
                          className=" flex answer-paragraph"
                          style={{
                            fontSize: "16px",
                            fontFamily: "Roboto",
                            color: "#49454F",
                          }}
                        >
                          <b>Question {index + 1}: </b>
                          <p className="md:w-[78%] sm:w-[30%]" dangerouslySetInnerHTML={{ __html: rowData.question.split("##table")[0] }}></p>
                          <b> [Marks: {rowData.question_marks}]</b>
                        </p>
                        {rowData.have_table === 1 && (
                          <EditableTable columnList={tableData.columnList} rowList={tableData.rowList} isEditable={false} />
                        )}
                        {rowData.image_file_name !== null && (
                          <div className="flex justify-center mb-[20px] mt-[20px]">
                            <img alt="" className="" src={process.env.REACT_APP_CDN_URL_FOR_QUESTION_IMAGES + rowData.image_file_name}></img>
                          </div>
                        )}
                        <p
                          className="answer-paragraph"
                          style={{
                            fontSize: "16px",
                            fontFamily: "Roboto",
                            color: "#49454F",
                            display: props.isAnswerRubric === 1 ? "none" : "",
                          }}
                        >
                          <b>Answer: </b>
                          {rowData.answer.split("Rubric:")[0]}
                        </p>

                        {props.selectedQuizType !== 1 && rowData.answer.split("Rubric:").length > 1 && (
                          <p
                            className="answer-paragraph"
                            style={{
                              fontSize: "16px",
                              fontFamily: "Roboto",
                              color: "#49454F",
                              display: props.isAnswerRubric === 1 ? "none" : "",
                            }}
                          >
                            <b>Rubric: </b>
                            {rowData.answer.split("Rubric:")[1]}
                          </p>
                        )}
                      </div>
                      {/* <button
                    onClick={removeQuestion}
                    value={index}
                    style={{ height: "50px", float: "right", fontSize: "14px" }}
                    className="bg-[#edeaf4] text-[#6750A4]  px-4 "
                  >
                     <span className="spinner-grow spinner-grow-sm"></span>
                    Remove
                  </button> */}

                      {/* <button
                        type="button"
                        className="close bg-[#edeaf4] text-[#6750A4]  px-4"
                        aria-label="Close"
                        onClick={() => removeQuestion(index)}
                        value={index}
                        style={{
                          display: id != null ? "none" : "block",
                          height: "50px",
                          float: "right",
                          fontSize: "16px",
                          borderRadius: "5px",
                        }}
                        // className=" "
                      >
                        <span className="pr-[10px]" aria-hidden="true">
                          &times;
                        </span>
                        Remove
                      </button> */}
                      <div>
                        <div
                          className="close bg-[white] text-[black]  px-4 mr-[100px] pt-[12px] flex sm:mb-[20px]"
                          aria-label="Close"
                          style={{
                            height: "50px",
                            float: "left",
                            fontSize: "16px",
                            borderRadius: "5px",
                            width: "250px",
                            border: "1px solid black",
                          }}
                          // className=" "
                        >
                          <span className="pr-[10px] text-[#6750A4] subtopic_name" aria-hidden="true" style={{ width: "170px" }}>
                            {rowData.sub_topic_name}
                          </span>
                          <div className="mt-[3px]">
                            <img src={infoLogo}></img>
                          </div>{" "}
                          {/* {sub_topic_name.current[index]} */}
                        </div>
                        <button
                          data-modal-target="defaultModal"
                          type="button"
                          className="close bg-[#edeaf4] text-[#6750A4]  px-4 mr-[10px]"
                          aria-label="Close"
                          // onClick={() => <ReplaceDialogue />}
                          onClick={handleClick(index, rowData)} // Pass index and rowData here                        value={index}
                          value={index}
                          style={{
                            height: "50px",
                            float: "right",
                            fontSize: "16px",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                          }}
                          // className=" "
                        >
                          <span className="pr-[10px]" aria-hidden="true">
                            <img src={AddToQuiz}></img>
                          </span>
                          Add to quiz
                        </button>
                      </div>
                    </div>
                    <div
                      className={"sm:mt-[150px] md:mt-[100px]"}
                      style={{
                        flex: 1,
                        height: "1px",
                        backgroundColor: "#B3B3B3",
                        // marginTop: "100px",
                        marginBottom: "10px",
                      }}
                    />
                  </Fragment>
                );
              })}
          </DialogContent>
        ) : (
          <DialogContent>
            <div className="h-[100%] mt-[50px]">
              <center>
                <img src={EmptyBox} width={"200px"}></img>
                <div className="pb-[30px]">
                  {props.countForReplacedQuestionGeneration !== 0
                    ? "All Replacement options have been explored"
                    : "We are all out of replacement questions!"}
                </div>
                <button
                  onClick={props.countForReplacedQuestionGeneration !== 0 ? handleClickOpenBlacklistedReplacementQuestionDialogue : props.handleClose}
                  className=" text-[#6750A4] font-semibold "
                  style={{ textDecoration: "underline" }}
                >
                  {props.countForReplacedQuestionGeneration !== 0
                    ? " Go back to select from last options"
                    : "Go back to create replacement from another subtopic."}
                </button>
              </center>
            </div>
            <BlacklistedQuestionDialogue
              open={openBlacklistedReplaceQuestionDialogue}
              handleClose={handleCloseBlackListedReplacementQuestionDialogue}
              handleOpen={handleClickOpenBlacklistedReplacementQuestionDialogue}
              response_data={props.blackListedQuestionArrayToShow}
              selectedQuizType={props.selectedQuizType}
              mock_exam={props.mock_exam}
              questionIndex={props.questionIndex}
              replaceQuestion={props.replaceQuestion}
              blackListedQuestionsToAvoid={props.blackListedQuestionsToAvoid}
              handleCloseParentDialogue={props.handleCloseParentDialogue}
              handleRawResponseClick={props.handleRawResponseClick}
              quiz_generation_ids_list={props.quiz_generation_ids_list}
              handleCloseReplaceQuestionDialogue={props.handleClose}
              handleCloseNoQuestionDialogue={props.handleClose}
              selectedQuestionForReplacement={props.selectedQuestionForReplacement}
              isAnswerRubric={props.isAnswerRubric}
            ></BlacklistedQuestionDialogue>
          </DialogContent>
        )}

        {userType === "2" ? (
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "start",
              marginLeft: "20px",
              marginBottom: "20px",
            }}
          >
            <div className="mr-[10px]">
              <img width={"20px"} src={Setting}></img>
            </div>
            <button style={{ width: "120px" }} className=" sm:w-[44%] md:w-[46%]" onClick={handleRawResponseClick}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  float: "left",
                }}
              >
                <div>View logs</div>
              </div>
            </button>{" "}
          </DialogActions>
        ) : (
          ""
        )}
      </Dialog>
      {/* <BlacklistedQuestionDialogue
        open={openBlacklistedReplaceQuestionDialogue}
        handleClose={handleCloseBlackListedReplacementQuestionDialogue}
        handleOpen={handleClickOpenBlacklistedReplacementQuestionDialogue}
        response_data={props.blackListedQuestionArray}
        selectedQuizType={props.selectedQuizType}
        mock_exam={props.mock_exam}
        questionIndex={props.questionIndex}
        replaceQuestion={props.replaceQuestion}
      ></BlacklistedQuestionDialogue> */}
    </div>
  );
}

export default ReplaceQuestionDialogue;
