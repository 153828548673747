import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import animation from "../../../images/no_students.json";
import LoadingIndicator from "../../Student/widget/mockbot/LoadingIndicator";

function PractiseAttemptTable({ dataList, handleViewPracticeSession }) {
  const [isloading, setIsLoading] = useState(true);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  }, []);

  return (
    <div className="p-12">
      {!isloading ? (
        dataList && dataList.length > 0 ? (
          <table className="w-full text-sm text-left text-white ">
            <thead className="text-xs text-white bg-[#6750A4]" style={{ height: "3.5rem" }}>
              <tr>
                <th scope="col" className="px-6" style={{ height: "2.5rem" }}>
                  ID
                </th>
                <th scope="col" className="px-6" style={{ height: "2.5rem" }}>
                  Name
                </th>
                <th scope="col" className="px-6" style={{ height: "2.5rem" }}>
                  Email
                </th>
                <th scope="col" className="pl-6" style={{ height: "2.5rem" }}>
                  Practice Attempts
                </th>
              </tr>
            </thead>
            <tbody>
              {/* Static data rows */}
              {dataList.map((student, index) => (
                <tr key={index} className="bg-white border-b text-black">
                  <td className="px-6 py-4">{index + 1}</td>
                  <td className="px-6 py-4">{student.student_name}</td>
                  <td className="px-6 py-4">{student.student_email}</td>
                  <td className="px-6 py-4">
                    <button
                      className="border border-[#6750A4] text-[#6750A4] py-2 px-4 rounded hover:bg-[#6750A4] hover:text-white transition"
                      onClick={() => {
                        handleViewPracticeSession(student.student_email);
                      }}
                    >
                      View Practice Sessions
                    </button>
                  </td>
                </tr>
              ))}

              {/* END HERE */}
            </tbody>
          </table>
        ) : (
          <div className=" text-center md:mt-12 ">
            {" "}
            <Lottie options={defaultOptions} height={180} width={150} />
            <h3 class="title md:mt-12">No Students Yet!</h3>
            <p class="subtitle">You haven’t added any students to your class.</p>
          </div>
        )
      ) : (
        <LoadingIndicator text={"Loading..."} />
      )}
    </div>
  );
}

export default PractiseAttemptTable;
