import React from "react";
import { useNavigate } from "react-router-dom";

function DashboardPracticeAttemptCard(props) {
  const navigate = useNavigate();

  const handleViewDetails = () => {
    navigate("/practise-attempt-details", {
      state: { subjectId: props.subjectId, subjectName: props.subjectName, accountStatus: props.accountStatus },
    });
  };

  return (
    <div className="border border-[#79747E] rounded-lg shadow-sm p-4 mb-4 w-[400px]">
      <h3 className="text-lg font-bold text-[#49454F] mb-2">{props.subjectName}</h3>
      <div className="flex items-center text-[#49454F] text-sm mb-2">
        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24" fill="currentColor" className="mr-2">
          <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
        </svg>
        {props.enrolledStudents + " Students"}
      </div>
      <p className="text-[#49454F] text-sm mb-4">View a detailed summary of your student's practise exam attempts.</p>
      <div>
        <button className="bg-[#6750A4] text-white py-2 px-4 rounded hover:bg-purple-700 w-full" onClick={handleViewDetails}>
          View Details
        </button>
      </div>
    </div>
  );
}

export default DashboardPracticeAttemptCard;
