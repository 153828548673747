import { useContext } from "react";
import TeepeeIcon from "../images/avatar4.png";
import Background from "../images/Rectangle.png";
import appContext from "../context/appContext";
import Footer from "./Footer";

function LoginPageLeftSide(props) {
  const state = useContext(appContext);

  return (
    <div
      className="centered w-full h-full bg-white md:h-screen p-10 md:p-30 sm:p-5 md:text-left items-center mt-0 md:mt-10 sm:pt-20 relative"
      style={{ backgroundImage: `url(${Background})` }}
    >
      <div className="jess_icon left-[35px] md:left-[35px] sm:left-[30px]">
        <img src={TeepeeIcon} alt="" />
      </div>

      <div className="main-content-div w-[495px] sm:w-[360px] md:w-[495px]">
        <div className="teepee-heading" style={{ textAlign: "center" }}>
          <p>{state.loginState === 1 ? "Hey, it's Teepee AI" : "Unlimited Exam Practice with Instant AI Marking and Feedback"}</p>
          {/* <p>
            {state.loginState === 1 ? (
              "Hey, it's Teepee AI"
            ) : (
              <>
                Unlimited Exam Practice with Instant <br /> AI Marking and Feedback
              </>
            )}
          </p> */}
        </div>
        <div className="teepee-sub-heading mt-[21px]" style={{ textAlign: state.loginState === 1 && "center" }}>
          <p>
            {state.loginState === 1 ? (
              "Join your class to start learning"
            ) : (
              <div class="">
                <p className="mb-4">
                  With Teepee.ai’s extensive bank of <strong>topic-specific exam questions,</strong> you can prepare students for success under{" "}
                  <strong>exam conditions, reduce your workload,</strong> and gain valuable<strong> real-time insights</strong> into their
                  performance.
                </p>
              </div>
            )}
            <br />
          </p>
        </div>
        {/* <div
          className="teepee-disclaimer"
          style={{
            fontSize: "12px",
            display: state.loginState === 1 ? "none" : "block",
          }}
        >
          <p>
            ⚠️ Please note: Teepee.ai is in early Beta. We’re refining our AI’s task generation and marking, so occasional inaccuracies may occur.
          </p>
        </div> */}
      </div>
      <div className="jess_footer absolute bottom-0 md:block sm:hidden">
        <Footer></Footer>
      </div>
    </div>
  );
}

export default LoginPageLeftSide;
