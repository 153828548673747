import React, { useState, useRef, useEffect } from "react";
import { Select, MenuItem, Checkbox, ListItemText } from "@mui/material";

function ReplaceQuestionChapterDropdown(props) {
  const [chapters, setChapters] = useState(props.chapterList);
  const [selectedChapter, setSelectedChapter] = useState("");
  const selectRef = useRef(null);
  const [dropdownWidth, setDropdownWidth] = useState(0);

  useEffect(() => {
    if (selectRef.current) {
      setDropdownWidth(selectRef.current.offsetWidth);
    }
  }, []);

  const handleChange = (event) => {
    setSelectedChapter(event.target.value);
    props.handleOnChange(event);
  };

  return (
    <div style={{ paddingTop: "10px" }}>
      <Select
        id="modules_option"
        name="chapter"
        value={selectedChapter}
        onChange={handleChange}
        displayEmpty
        inputRef={selectRef}
        renderValue={(selected) => selected || "Select topic"}
        style={{
          height: "50px",
          width: "100%",
          fontWeight: 100,
          fontSize: 20,
          color: "#7C7C7C",
          paddingLeft: "16px"
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
              width: dropdownWidth || 300,
            }
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
      >
        <MenuItem disabled value="">
          Select topic
        </MenuItem>
        {chapters.map((chapter) => (
          <MenuItem
            key={chapter.chapter_id}
            value={chapter.chapter_name}
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "100%",
              display: "block"
            }}
          >
            {chapter.chapter_name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export default ReplaceQuestionChapterDropdown;
