import React, { useState, useEffect, useContext } from "react";
import TeepeeIcon from "../images/avatar4.png";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import { Link, useNavigate } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Footer from "./Footer";
import amplitude from "amplitude-js";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import GoogleButton from "react-google-button";
import { MuiOtpInput } from "mui-one-time-password-input";
import TextField from "@material-ui/core/TextField";
import google_icon from "../images/ic_google.svg";
import { useMsal } from "@azure/msal-react";
import microsoft_icon from "../images/ic_microsoft.svg";
import Background from "../images/Rectangle.png";
import appContext from "../context/appContext";
import validator from "validator";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
const PurpleCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.getContrastText("#6750A4"), // Color for unchecked state
  "&.Mui-checked": {
    color: "#6750A4", // Custom color for checked state
  },
  "&.MuiCheckbox-root": {
    color: "#6750A4", // Ensures the checkbox has a visible color when unchecked
    // You can adjust the opacity to make the checkbox visible but distinct from the checked state
  },
}));

function Register() {
  React.useEffect(() => {
    try {
      if (state.loginState === 1) {
        amplitude.getInstance().setUserId(null);
        amplitude.getInstance().logEvent("SU-Page View-Student");
      } else {
        amplitude.getInstance().setUserId(null);
        amplitude.getInstance().logEvent("SU-Page View-Teacher");
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const { instance } = useMsal();
  const [otp, setOtp] = React.useState("");
  const [update, setUpdate] = useState(true);
  const [update2, setUpdate2] = useState(true);
  const [passwordCriteraMatch, setPasswordCriteraMatch] = React.useState(true);
  const [confirmPasswordCriteraMatch, setConfirmPasswordCriteraMatch] = React.useState(true);
  const [passwordHelperText, setPasswordHelperText] = React.useState("");
  const [confirmPasswordHelperText, setConfirmPasswordHelperText] = React.useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [emailError, setEmailError] = useState("");

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const [credentials, setCredentials] = useState({
    name: "",
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirm_password: "",
    one_time_password: "",
  });
  const isSubmitting = React.useRef(false);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [callState, setCallState] = useState(false);
  const [user, setUser] = useState(null);
  const [signUpStep, setSignUpStep] = useState(0);
  const state = useContext(appContext);
  const sendEarlyAccessRequest = async (e) => {
    var input_field = document.getElementById("early_access_email_input");
    if (input_field.value.length <= 0) {
      alert("Enter valid Email for early access");
      return;
    }

    // if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(input_field.value)) {
    //   alert("Please enter valid email address");
    //   return;
    // }

    if (callState) {
      return;
    }
    isSubmitting.current = true;
    setCallState(true);
    e.preventDefault();
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/early_access_request",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          email: input_field.value,
        }),
      })
      .then((res) => {
        setOpen(true);
        amplitude.getInstance().setUserId(input_field.value);
        amplitude.getInstance().logEvent("GetEarlyAccess", "user has submitted early access request");
        input_field.value = "";
        setCallState(false);
        isSubmitting.current = false;
      })
      .catch((err) => {
        setCallState(false);
        isSubmitting.current = false;
      });
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
    },
    onError: (error) => {
      console.log("Login Failed:", error);
    },
  });

  useEffect(() => {
    if (user != null) {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: "application/json",
          },
        })
        .then((res) => {
          checkUserAccountAndThenLogin(res.data, 2, res.data);
        })
        .catch((err) => {
          console.log(err);
          if (state.loginState === 1) {
            amplitude.getInstance().setUserId(null);
            amplitude.getInstance().logEvent("SU-CwithGoogle-Unsuccessful-Student");
          } else {
            amplitude.getInstance().setUserId(null);
            amplitude.getInstance().logEvent("SU-CwithGoogle-Unsuccessful-Teacher");
          }
        });
    }
  }, [user]);

  const createUserAccount = (email, password, auth_type, name, firstname, lastname) => {
    const url = state.loginState === 1 ? "/create_new_user_by_otp_authentication_student" : "/create_new_user_by_otp_authentication";
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + url,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          name: name,
          first_name: firstname,
          last_name: lastname,
          email: email,
          password: password,
        }),
      })
      .then((res) => {
        console.log(res);

        setSignUpStep(2);
        if (state.loginState === 1) {
          localStorage.setItem("student_name", name);
          amplitude.getInstance().setUserId(email);
          amplitude.getInstance().logEvent("SU-CwithWorkEmail-Student");
        } else {
          amplitude.getInstance().setUserId(email);
          amplitude.getInstance().logEvent("SU-CwithWorkEmail-Teacher");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 409) {
          alert("Account with this email already exist");
          return;
        }
        if (state.loginState === 1) {
          amplitude.getInstance().setUserId(email);
          amplitude.getInstance().logEvent("SU-CwithWorkEmail-Unsuccessful-Student");
        } else {
          amplitude.getInstance().setUserId(email);
          amplitude.getInstance().logEvent("SU-CwithWorkEmail-Unsuccessful-Teacher");
        }
      });
  };

  const verifyOtp = (email, one_time_password, e) => {
    var classCode = localStorage.getItem("class_code");
    e.preventDefault();
    const url = state.loginState === 1 ? "/verify_otp_student" : "/verify_otp";
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + url,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          email_address: email,
          one_time_password: one_time_password,
          class_code: classCode !== undefined ? classCode : null,
        }),
      })
      .then((res) => {
        console.log(res);
        if (state.loginState === 1) {
          amplitude.getInstance().setUserId(email);
          amplitude.getInstance().logEvent("SU-CwithWorkEmail-OTP-Validated-Student");
        } else {
          amplitude.getInstance().setUserId(email);
          amplitude.getInstance().logEvent("SU-CwithWorkEmail-OTP-Validated-Teacher");
        }

        localStorage.setItem("access_token", res.data.access_token);
        if (state.loginState === 1) {
          localStorage.setItem("user_type", "3");
        } else {
          localStorage.setItem("user_type", res.data.user_type);
        }
        localStorage.setItem("email_address", email);
        console.log("DATA:", res.data);
        if (state.loginState === 1) {
          navigate("/student-dashboard");
        } else {
          if (res.data.new_user) {
            navigate("/onboarding");
            return;
          }
          navigate("/");
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          alert("Invalid OTP entered");
        }
        if (state.loginState === 1) {
          amplitude.getInstance().setUserId(email);
          amplitude.getInstance().logEvent("SU-CwithWorkEmail-OTP-Invalid-Student");
        } else {
          amplitude.getInstance().setUserId(email);
          amplitude.getInstance().logEvent("SU-CwithWorkEmail-OTP-Invalid-Teacher");
        }

        console.log(err);
        //amplitude.getInstance().setUserId(credentials.email);
        //amplitude.getInstance().logEvent('LoginFailed', "error in login api call for email: " + credentials.email);
      });
  };

  const loginWithMicrosoft = () => {
    instance
      .loginPopup({
        scopes: ["email"],
      })
      .then((result) => {
        return result;
      })
      .then((result) => {
        checkUserAccountAndThenLogin(result.account, 3);
      })
      .catch((err) => {
        console.log(err.errName, err.errType);
        if (state.loginState === 1) {
          amplitude.getInstance().setUserId(null);
          amplitude.getInstance().logEvent("SU-CwithMicrosoft-Unsuccessful-Student");
        } else {
          amplitude.getInstance().setUserId(null);
          amplitude.getInstance().logEvent("SU-CwithMicrosoft-Unsuccessful-Teacher");
        }
      });
  };

  function checkUserAccountAndThenLogin(email, auth_type, data) {
    const nameParts = email.name.split(" ");

    // Extract the first name (assuming the first part is the first name)
    const firstName = nameParts[0];

    // Join the remaining parts as the last name
    const lastName = nameParts.slice(1).join(" ");
    console.log(data);
    var classCode = localStorage.getItem("class_code");
    const url = state.loginState === 1 ? "/student_login_with_google_or_ms_verified_email" : "/login_with_google_or_ms_verified_email";
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + url,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          email: auth_type === 3 ? email.username : email.email,
          auth_type: auth_type,
          classCode: classCode !== undefined ? classCode : null,
          name: email.name,
          first_name: firstName,
          last_name: lastName,
        }),
      })
      .then((res) => {
        console.log(res);
        localStorage.setItem("access_token", res.data.access_token);
        if (state.loginState === 1) {
          localStorage.setItem("user_type", "3");
          localStorage.setItem("student_name", email.name);
        } else {
          localStorage.setItem("user_type", res.data.user_type);
        }
        localStorage.setItem("email_address", auth_type === 3 ? email.username : email.email);

        if (auth_type === 2) {
          if (state.loginState === 1) {
            amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
            amplitude.getInstance().logEvent("SU-CwithGoogle-Student");
          } else {
            amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
            amplitude.getInstance().logEvent("SU-CwithGoogle-Teacher");
          }
        } else {
          if (state.loginState === 1) {
            amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
            amplitude.getInstance().logEvent("SU-CwithMicrosoft-Student");
          } else {
            amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
            amplitude.getInstance().logEvent("SU-CwithMicrosoft-Teacher");
          }
        }
        if (state.loginState === 1) {
          navigate("/student-dashboard");
        } else {
          if (res.data.new_user) {
            navigate("/onboarding");
            return;
          }
          navigate("/");
        }
      })
      .catch((err) => {
        console.log(err);
        if (auth_type === 2) {
          if (state.loginState === 1) {
            amplitude.getInstance().setUserId(email);
            amplitude.getInstance().logEvent("SU-CwithGoogle-Unsuccessful-Student");
          } else {
            amplitude.getInstance().setUserId(email);
            amplitude.getInstance().logEvent("SU-CwithGoogle-Unsuccessful-Teacher");
          }
        } else {
          if (state.loginState === 1) {
            amplitude.getInstance().setUserId(email);
            amplitude.getInstance().logEvent("SU-CwithMicrosoft-Unsuccessful-Student");
          } else {
            amplitude.getInstance().setUserId(email);
            amplitude.getInstance().logEvent("SU-CwithMicrosoft-Unsuccessful-Teacher");
          }
        }
      });
  }

  const signUpUser = (e) => {
    e.preventDefault();
    if (credentials.email.length <= 0) {
      alert("Enter valid Email or password");
      return;
    }
    if (validator.isEmail(credentials.email)) {
    } else {
      setEmailError("Enter valid Email!");
      setIsValidEmail(true);
      return;
    }

    if (!credentials.password.match("^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$")) {
      setPasswordHelperText("Password must have atleast 6 characters including alphabets and numbers.");
      setPasswordCriteraMatch(false);
      return;
    }
    setPasswordHelperText("");
    setPasswordCriteraMatch(true);

    if (credentials.password !== credentials.confirm_password) {
      setConfirmPasswordHelperText("Confirm password did not match with the password entered");
      setConfirmPasswordCriteraMatch(false);
      return;
    }

    setConfirmPasswordHelperText("");
    setConfirmPasswordCriteraMatch(true);

    createUserAccount(credentials.email, credentials.password, 1, credentials.name, credentials.firstname, credentials.lastname);
    // axios
    //   .request({
    //     method: "POST",
    //     url: process.env.REACT_APP_REST_API_BASE_URL + "/login",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     data: JSON.stringify({
    //       email: credentials.email,
    //       password: credentials.password,
    //     }),
    //   })
    //   .then((res) => {
    //     console.log(res);
    //     setSignUpStep(2)
    // if (res.data.access_token === undefined) {
    //   alert("Enter valid Email or password");
    //   amplitude.getInstance().setUserId(credentials.email)
    //   amplitude.getInstance().logEvent('LoginUnSuccessful', "user credentials does not match");
    //   // invalid username or password
    //   return;
    // }
    // localStorage.setItem("access_token", res.data.access_token);
    // localStorage.setItem("user_type", res.data.user_type);
    // localStorage.setItem("email_address", credentials.email);
    // console.log("DATA:", res.data);
    // amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
    // amplitude.getInstance().logEvent('LoginSuccessful', "user successfully logged in with email: " + credentials.email);
    // navigate("/dashboard");

    // })
    // .catch((err) => {
    //   console.log(err);
    //   amplitude.getInstance().setUserId(credentials.email);
    //   amplitude.getInstance().logEvent('LoginFailed', "error in login api call for email: " + credentials.email);
    // });
  };
  const onChange = (e) => {
    setIsValidEmail(false);
    setEmailError("");
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" titleStyle={{ textAlign: "center" }} textAlign="center">
          <b>Thank you!</b>
        </DialogTitle>
        <DialogContent titleStyle={{ textAlign: "center" }} textAlign="center">
          <DialogContentText id="alert-dialog-description" titleStyle={{ textAlign: "center" }} textAlign="center">
            Someone will get in touch shortly
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyItems: "center", justifyContent: "center" }}>
          <button
            fullWidth
            type="submit"
            style={{ width: "180px", height: "45px" }}
            className="early_access__dialog_close_button  hover:text-white font-bold py-2 px-4 "
            onClick={handleClose}
          >
            Close
          </button>
          {/* <Button onClick={handleClose}>Close</Button> */}
        </DialogActions>
      </Dialog>
      <section>
        <div className="w-auto h-auto grid  text-white text-4xl md:grid-cols-2 sm:grid-cols-1 overflow-hidden">
          <div
            className="centered w-full h-full bg-white md:min-h-screen p-10  sm:p-5 md:text-left items-center mt-0 md:mt-10 sm:pt-20 relative"
            style={{ backgroundImage: `url(${Background})` }}
          >
            <div className="jess_icon left-[35px] md:left-[35px] sm:left-[30px]">
              <img src={TeepeeIcon} alt="" />
            </div>

            <div className="main-content-div w-[495px] sm:w-[360px] md:w-[495px]">
              <div className="teepee-heading  " style={{ textAlign: "center" }}>
                <p>{state.loginState === 1 ? "Hey, it's Teepee AI" : "Unlimited Exam Practice with Instant AI Marking and Feedback"}</p>
                {/* <p>
                  {state.loginState === 1 ? (
                    "Hey, it's Teepee AI"
                  ) : (
                    <>
                      Unlimited Exam Practice with Instant <br /> AI Marking and Feedback
                    </>
                  )}
                </p> */}
              </div>
              <div className="teepee-sub-heading mt-[21px]" style={{ textAlign: state.loginState === 1 && "center" }}>
                <p>
                  {state.loginState === 1 ? (
                    "Join your class to start learning"
                  ) : (
                    <div class="">
                      <p className="mb-4">
                        With Teepee.ai’s extensive bank of <strong>topic-specific exam questions,</strong> you can prepare students for success under{" "}
                        <strong>exam conditions, reduce your workload,</strong> and gain valuable <strong>real-time insights</strong> into their
                        performance.
                      </p>
                      {/* <ul className="list-disc">
                      <li className="mb-4 text-[16px]">
                        <strong>Automated & Personalised Spaced Retrieval Practice:</strong> Helps students retain and recall information when it matters
                        most.
                      </li>
                      <li className="text-[16px]">
                        <strong>Exam-Style Question Practice with AI Feedback:</strong> Provides instant, detailed feedback to help students understand
                        mistakes and improve performance.
                      </li>
                    </ul> */}
                    </div>
                  )}

                  <br />
                </p>
              </div>
              {/* <div
                className="teepee-disclaimer"
                style={{
                  fontSize: "12px",
                  display: state.loginState === 1 ? "none" : "block",
                }}
              >
                <p>
                  ⚠️ Please note: Teepee.ai is in early Beta. We’re refining our AI’s task generation and marking, so occasional inaccuracies may
                  occur.
                </p>
              </div> */}

              {/* <div className="teepee-disclaimer" style={{ fontSize: "14px" }}>
          <p>
            ⚠️ Please note: Teepee.ai is in early Beta. We're refining our AI's
            quiz generation and marking, and occasional inaccuracies may occur.
          </p>
        </div> */}
            </div>
            <div className="jess_footer absolute bottom-0 md:block sm:hidden">
              <Footer></Footer>
            </div>
          </div>

          {/* page 2 */}
          {signUpStep === 0 ? (
            <div className="w-full h-full bg-white centered md:h-screen p-10 md:p-36 sm:p-5">
              <div className="main-content-div w-[500px] sm:w-[360px] md:w-[520px]">
                <div className="sign-in-label ">
                  <p>Welcome to Teepee.ai</p>
                </div>
                <div className="sign-in-sub-label mt-[16px]">
                  <p>
                    Choose how would you like to create your
                    <br />
                    Teepee.ai account
                  </p>
                </div>

                <div>
                  <div className="w-full mt-[20px]">
                    <button
                      type="submit"
                      className="create-new-account-button border-[1px] border-[#474747] rounded-md hover:bg-black text-[black] hover:text-white font-bold py-2 px-4 w-full h-[45px]"
                      onClick={() => {
                        setSignUpStep(1);
                        if (state.loginState === 1) {
                          amplitude.getInstance().setUserId(null);
                          amplitude.getInstance().logEvent("MAR-Page View-Student");
                        } else {
                          amplitude.getInstance().setUserId(null);
                          amplitude.getInstance().logEvent("MAR-Page View-Teacher");
                        }
                      }}
                    >
                      Continue with work email
                    </button>
                    <div
                      className="mt-[20px]"
                      style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* <GoogleButton label="Sign in with Google 🚀 " onClick={() => login()}/> */}
                      <button
                        type="submit"
                        className="create-new-account-button border-[1px] border-[#474747] rounded-md hover:bg-black text-[black] hover:text-white font-bold py-2 px-4 w-full h-[45px]"
                        onClick={() => login()}
                      >
                        <div style={{ display: "inline-block" }}>
                          <div className="flex">
                            <img alt="" className="float-left w-[26px] mr-[10px]" src={google_icon}></img>
                            Continue with Google
                            <div style={{ visibility: "hidden" }}>ds</div>
                          </div>
                        </div>
                      </button>
                    </div>
                    <div
                      className="mt-[20px]"
                      style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        type="submit"
                        className="create-new-account-button border-[1px] border-[#474747] rounded-md hover:bg-black text-[black] hover:text-white font-bold py-2 px-4 w-full h-[45px]"
                        onClick={() => loginWithMicrosoft()}
                      >
                        <div style={{ display: "inline-block" }}>
                          <div className="flex">
                            <img alt="" className="float-left w-[26px] mr-[10px]" src={microsoft_icon}></img>
                            Continue with Microsoft
                          </div>
                        </div>
                      </button>

                      {/* <MicrosoftLogin
                  buttonTheme="light"
                  className="bg-center"
                  clientId={"82c15ec2-c4b6-47e0-b520-6f3839d026c4"}
                  authCallback={authHandler}
                /> */}
                    </div>
                  </div>
                </div>

                <div className="mt-[12px]">
                  <div className="mb-[20px]   text-dark">
                    {" "}
                    <FormControlLabel
                      control={<PurpleCheckbox checked={update2} onChange={() => setUpdate2(!update2)} />}
                      label="I want to receive updates about Teepee"
                    />
                  </div>
                  <p
                    style={{
                      color: "black",
                      fontSize: "14px",
                      marginBottom: "0px",
                      float: "left",
                      marginRight: "10px",
                    }}
                  >
                    Already have an account?{" "}
                    <Link
                      className="sign-in-text"
                      style={{ fontSize: "14px", marginTop: "0px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/login");
                        if (state.loginState === 1) {
                          amplitude.getInstance().setUserId(null);
                          amplitude.getInstance().logEvent("SU- ClickSign in-Student");
                        } else {
                          amplitude.getInstance().setUserId(null);
                          amplitude.getInstance().logEvent("SU- ClickSign in-Teacher");
                        }
                      }}
                    >
                      {" "}
                      Sign in
                    </Link>
                  </p>
                </div>

                <div className="jess_footer_sm left-[5px] md:hidden sm:block mb-[20px]">
                  <div style={{ color: "black" }}>© Teepee.ai. All rights reserved</div>
                  <div>
                    <a href="/about_us" target="_blank" rel="noopener noreferrer" className="text-black" style={{ float: "left" }}>
                      About
                    </a>
                  </div>
                  {/* <a
                    href="https://teepee.ai/privacy-policy-for-teepee-ai/"
                    target="_blank"
                    className="text-black ml-[30px]"
                    style={{ float: "left" }}
                  >
                    Privacy Policy
                  </a> */}
                </div>
              </div>
            </div>
          ) : signUpStep === 1 ? (
            <div className="w-full h-full bg-white centered md:min-h-screen  p-10  sm:p-5">
              <div className="main-content-div w-[500px] sm:w-[360px] md:w-[520px]">
                <div className="sign-in-label ">
                  <p>Welcome to Teepee.ai</p>
                </div>

                <div>
                  <div className="w-full">
                    <form id="signup-form">
                      {state.loginState === 1 && (
                        <div className="mt-[2px]">
                          <label className="email-label">Enter your name:</label>
                          <TextField
                            type="text"
                            name="name"
                            style={{
                              height: "45px",
                            }}
                            className="textfield-text border-[#8B8B90] border-[1px] rounded-md w-full text-black leading-tight  focus:shadow-blue-900 "
                            id="username"
                            placeholder="Enter your name"
                            value={credentials.name}
                            onChange={onChange}
                          />
                        </div>
                      )}
                      <div className="mt-[2px]">
                        <label className="email-label">Enter your first name:</label>
                        <TextField
                          type="text"
                          name="firstname"
                          style={{
                            height: "45px",
                          }}
                          className="textfield-text border-[#8B8B90] border-[1px] rounded-md w-full text-black leading-tight  focus:shadow-blue-900 "
                          id="username"
                          placeholder="Enter your first name"
                          value={credentials.firstname}
                          onChange={onChange}
                        />
                      </div>
                      <div className="mt-[2px]">
                        <label className="email-label">Enter your last name:</label>
                        <TextField
                          type="text"
                          name="lastname"
                          style={{
                            height: "45px",
                          }}
                          className="textfield-text border-[#8B8B90] border-[1px] rounded-md w-full text-black leading-tight  focus:shadow-blue-900 "
                          id="username"
                          placeholder="Enter your last name"
                          value={credentials.lastname}
                          onChange={onChange}
                        />
                      </div>
                      <div className="mt-[2px]">
                        <label className="email-label">Enter your email address:</label>
                        <TextField
                          type="email"
                          name="email"
                          style={{
                            height: "45px",
                            marginBottom: isValidEmail ? "10px" : null,
                          }}
                          className="textfield-text border-[#8B8B90] border-[1px] rounded-md w-full text-black leading-tight  focus:shadow-blue-900 "
                          id="username"
                          placeholder="Enter your Username"
                          value={credentials.email}
                          onChange={onChange}
                          error={isValidEmail}
                          helperText={emailError}
                        />
                      </div>
                      <div>
                        <label className="email-label mt-[13px] ">Password:</label>
                        <TextField
                          type="password"
                          name="password"
                          style={{ height: "45px" }}
                          className=" textfield-text border-[#8B8B90] border-[1px] rounded-md w-full text-black leading-tight  focus:shadow-blue-900  "
                          id="password"
                          error={!passwordCriteraMatch}
                          helperText={passwordHelperText}
                          placeholder="Enter password"
                          value={credentials.password}
                          onChange={onChange}
                        />
                      </div>

                      <div>
                        <label className="email-label mt-[12px] ">Confirm Password:</label>
                        <TextField
                          type="password"
                          name="confirm_password"
                          style={{ height: "45px" }}
                          className=" textfield-text border-[#8B8B90] border-[1px] rounded-md w-full text-black leading-tight  focus:shadow-blue-900  "
                          id="confirm_password"
                          error={!confirmPasswordCriteraMatch}
                          helperText={confirmPasswordHelperText}
                          placeholder="Enter password again"
                          value={credentials.confirm_password}
                          onChange={onChange}
                        />
                      </div>

                      <div className="mt-[4px] ">
                        <div className="mb-[20px] text-dark">
                          {" "}
                          <FormControlLabel
                            control={<PurpleCheckbox checked={update} onChange={() => setUpdate(!update)} />}
                            label="I want to receive updates about Teepee"
                          />
                        </div>
                        <button
                          type="submit"
                          className="sign-in-button bg-[#A07BEC]  rounded-md hover:bg-black text-[white] hover:text-white font-bold py-2 px-4 w-full h-[45px]"
                          onClick={(e) => signUpUser(e)}
                        >
                          Sign Up
                        </button>

                        <div className="mt-[0px]">
                          <p
                            style={{
                              color: "black",
                              fontSize: "14px",
                              marginBottom: "0px",
                              float: "left",
                              marginRight: "10px",
                            }}
                          >
                            Already have an account?
                            <Link
                              className="sign-in-text"
                              style={{ fontSize: "14px", marginTop: "0px" }}
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/login");
                                amplitude.getInstance().setUserId(null);
                                amplitude.getInstance().logEvent("SU- ClickSign in");
                              }}
                            >
                              {" "}
                              Sign in
                            </Link>
                          </p>
                        </div>

                        <div className="jess_footer_sm left-[5px] md:hidden sm:block mb-[20px]">
                          <div style={{ color: "black" }}>© Teepee.ai. All rights reserved</div>
                          <div>
                            <a href="/about_us" target="_blank" rel="noopener noreferrer" className="text-black" style={{ float: "left" }}>
                              About
                            </a>
                          </div>
                          {/* <a
                            href="https://teepee.ai/privacy-policy-for-teepee-ai/"
                            target="_blank"
                            className="text-black ml-[30px]"
                            style={{ float: "left" }}
                          >
                            Privacy Policy
                          </a> */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            signUpStep === 2 && (
              <div className="w-full h-full bg-white centered md:h-screen p-10 md:p-36 sm:p-5">
                <div className="main-content-div w-[500px] sm:w-[360px] md:w-[520px]">
                  <div className="sign-in-label ">
                    <p>Welcome to Teepee.ai</p>
                  </div>
                  <div className="sign-in-sub-label ">
                    <p>
                      Please enter the code sent to <br />
                      <b>{credentials.email}</b>
                    </p>
                  </div>
                  <div>
                    <div className="w-full">
                      <form>
                        <div className="mt-[2px]">
                          {/* <input
                    name="one_time_password"
                    style={{ height: "65px" }}
                    className="textfield-text border-[1px] border-[#8B8B90] rounded-md w-full py-7 px-4 text-black leading-tight  "
                    id="verification_code"
                    placeholder="Enter your verification"
                    value={credentials.one_time_password}
                    onChange={onChange}
                  /> */}
                          <MuiOtpInput length={5} value={otp} onChange={handleChange} />
                        </div>

                        <div className="mt-[20px] ">
                          <button
                            type="submit"
                            className="sign-in-button bg-[#A07BEC]  rounded-md hover:bg-black text-[white] hover:text-white font-bold py-2 px-4 w-full h-[45px]"
                            onClick={(e) => verifyOtp(credentials.email, otp, e)}
                          >
                            Verify
                          </button>

                          <div
                            style={{
                              fontWeight: "700",
                              fontSize: "14px",
                              textAlign: "center",
                              color: "#101010",
                            }}
                          >
                            <p className="mb-[0px]">Or</p>
                          </div>

                          <div className="mt-[0px]">
                            <p
                              style={{
                                color: "black",
                                fontSize: "14px",
                                marginBottom: "0px",
                                float: "left",
                                marginRight: "10px",
                              }}
                            >
                              Already have an account?
                              <Link
                                className="sign-in-text"
                                style={{ fontSize: "14px", marginTop: "0px" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate("/login");
                                  amplitude.getInstance().setUserId(null);
                                  amplitude.getInstance().logEvent("SU- ClickSign in");
                                }}
                              >
                                {" "}
                                Sign in
                              </Link>
                            </p>
                          </div>

                          <div className="jess_footer_sm left-[5px] md:hidden sm:block mb-[20px]">
                            <div style={{ color: "black" }}>© Teepee.ai. All rights reserved</div>
                            <div>
                              <a href="/about_us" target="_blank" rel="noopener noreferrer" className="text-black" style={{ float: "left" }}>
                                About
                              </a>
                            </div>
                            {/* <a
                              href="https://teepee.ai/privacy-policy-for-teepee-ai/"
                              target="_blank"
                              className="text-black ml-[30px]"
                              style={{ float: "left" }}
                            >
                              Privacy Policy
                            </a> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </section>
    </React.Fragment>
  );
}

export default Register;
