import { useState, useEffect } from "react";
import axios from "axios";

const useGetApi = () => {
  const [apiData, setApiData] = useState({
    loading: false,
    data: null,
    error: null,
  });

  const getApiCall = async (url) => {
    setApiData({ ...apiData, loading: true });

    try {
      const token = "Bearer " + localStorage.getItem("access_token");
      axios
        .get(process.env.REACT_APP_REST_API_BASE_URL + url, {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            authorization: token,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => {
          setApiData({ ...apiData, data: response.data });
        });
    } catch (err) {
      setApiData({ ...apiData, error: err.message }); // Handle error
    } finally {
      setApiData({ ...apiData, loading: false }); // Always set loading to false in the end
      return apiData;
    }
  };

  return getApiCall;
};

export default useGetApi;
