import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function TeacherAnalyticsTabBody({
  classroomAnalyticsData,
  typeFilter,
  onChangeFilter,
  startDateFilter,
  endDateFilter,
  onChangeStartDateFilter,
  onChangeEndDateFilter,
  quizTypeAnalyticsSelection,
  handleCloseDateDialogue,
  open,
  handleOpenDateDialogue,
  handleCloseWithConfirm,
}) {
  const selectFieldStyles = {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid",
      borderColor: "black",
      // Change the border color to your desired focus color
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#79747e", // Change the normal border color here
    },
    "& .MuiSelect-select.MuiSelect-select": {
      lineHeight: "0.44em",
      height: "5px",
      minHeight: "5px",
      marginLeft: "13px",
      color: "#474747", // Change the color of the selected option(s)
      fontSize: "12px",
      fontWeight: "399",
      fontFamily:
        'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"', // Change the font size of the selected option(s)
    },
  };

  const handleColor = (percentage) => {
    if (percentage >= 80) {
      return "#55CE98";
    } else if (percentage >= 70) {
      return "#BBEABB";
    } else if (percentage >= 50) {
      return "#FEF1CE";
    } else {
      return "#FAAD98";
    }
  };

  const handleMapOfTypeFilter = (mapType) => {
    if (mapType === 1) {
      if (typeFilter === 1) {
        return classroomAnalyticsData.area_name_list.map((value, index) => (
          <th scope="col" class="px-4 py-3 tracking_table">
            {value} ({classroomAnalyticsData.area_total_marks_list[index]})
          </th>
        ));
      } else if (typeFilter === 2) {
        return classroomAnalyticsData.sub_topic_name_list.map((value, index) => (
          <th scope="col" class="px-4 py-3 tracking_table">
            {value} ({classroomAnalyticsData.sub_topic_total_marks_list[index]})
          </th>
        ));
      } else if (typeFilter === 3) {
        return classroomAnalyticsData?.topic_name_list.map((value, index) => (
          <th scope="col" class="px-4 py-3 tracking_table">
            {value} ({classroomAnalyticsData.topic_total_marks_list[index]})
          </th>
        ));
      }
    }
    if (mapType === 2) {
      if (typeFilter === 1) {
        return classroomAnalyticsData.area_student_table.map((value) => (
          <tr className="tracking_table">
            <td scope="col" class="px-4 py-3  tracking_table">
              {value.student_name}
            </td>
            {value.marks.map((values, index) => (
              <td
                scope="col"
                class="px-4 py-3  tracking_table"
                style={{
                  backgroundColor: handleColor(value.percentage[index]),
                }}
              >
                {values}
              </td>
            ))}
          </tr>
        ));
      } else if (typeFilter === 2) {
        return classroomAnalyticsData.sub_topic_student_table.map((value) => (
          <tr className="tracking_table">
            <td scope="col" class="px-4 py-3  tracking_table">
              {value.student_name}
            </td>
            {value.marks.map((values, index) => (
              <td
                scope="col"
                class="px-4 py-3  tracking_table"
                style={{
                  backgroundColor: handleColor(value.percentage[index]),
                }}
              >
                {values}
              </td>
            ))}
          </tr>
        ));
      } else if (typeFilter === 3) {
        return classroomAnalyticsData?.topic_student_table.map((value) => (
          <tr className="tracking_table">
            <td scope="col" class="px-4 py-3  tracking_table">
              {value.student_name}
            </td>
            {value.marks.map((values, index) => (
              <td
                scope="col"
                class="px-4 py-3  tracking_table"
                style={{
                  backgroundColor: handleColor(value.percentage[index]),
                }}
              >
                {values}
              </td>
            ))}
          </tr>
        ));
      }
    }
    if (mapType === 3) {
      if (typeFilter === 1) {
        return classroomAnalyticsData.area_average_list.map((value, index) => (
          <td
            scope="col"
            class="px-4 py-3  tracking_table"
            style={{
              backgroundColor: handleColor(classroomAnalyticsData.area_percentage_list[index]),
            }}
          >
            {value.toFixed(2)}
          </td>
        ));
      } else if (typeFilter === 2) {
        return classroomAnalyticsData.sub_topic_average_list.map((value, index) => (
          <td
            scope="col"
            class="px-4 py-3  tracking_table"
            style={{
              backgroundColor: handleColor(classroomAnalyticsData.sub_topic_percentage_list[index]),
            }}
          >
            {value.toFixed(2)}
          </td>
        ));
      } else if (typeFilter === 3) {
        return classroomAnalyticsData?.topic_average_list.map((value, index) => (
          <td
            scope="col"
            class="px-4 py-3  tracking_table"
            style={{
              backgroundColor: handleColor(classroomAnalyticsData?.topic_percentage_list[index]),
            }}
          >
            {value.toFixed(2)}
          </td>
        ));
      }
    }
    if (mapType === 4) {
      if (typeFilter === 1) {
        return classroomAnalyticsData?.area_percentage_list.map((value) => (
          <td
            scope="col"
            class="px-4 py-3  tracking_table"
            style={{
              backgroundColor: handleColor(value),
            }}
          >
            {value.toFixed(2)}
          </td>
        ));
      } else if (typeFilter === 2) {
        return classroomAnalyticsData?.sub_topic_percentage_list.map((value) => (
          <td
            scope="col"
            class="px-4 py-3  tracking_table"
            style={{
              backgroundColor: handleColor(value),
            }}
          >
            {value.toFixed(2)}
          </td>
        ));
      } else if (typeFilter === 3) {
        return classroomAnalyticsData?.topic_percentage_list.map((value) => (
          <td
            scope="col"
            class="px-4 py-3  tracking_table"
            style={{
              backgroundColor: handleColor(value),
            }}
          >
            {value.toFixed(2)}
          </td>
        ));
      }
    }
  };
  return (
    <>
      <div className="mb-[3px] class_analytics_text_bold">
        The data and statistics shown here are exclusive to this particular task format (Exam Style).
      </div>
      <div className="class_analytics_text_second">
        View detailed analytics for the tasks you've created. Analyze class performance trends and identify areas for improvement based on each
        individual task.
      </div>
      <div>
        <center>
          <div>
            {/* <ComingSoon /> */}
            <div>
              <FormControl sx={selectFieldStyles} className="md:w-[10%] float-right " style={{ marginTop: "10px", marginRight: "20px" }}>
                <InputLabel shrink={false} id="demo-simple-select-label" style={{ marginBottom: "10px" }}></InputLabel>
                <Select
                  name="typeFilter"
                  defaultValue={3}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={typeFilter}
                  onChange={onChangeFilter}
                >
                  {/* <MenuItem value={1}>Area Level</MenuItem> */}
                  <MenuItem value={2}>Subtopic Level</MenuItem>
                  <MenuItem value={3}>Topic Level</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl style={{ marginTop: "10px", marginRight: "20px" }} sx={selectFieldStyles} className="md:w-[10%] float-right ">
                <InputLabel
                  shrink={false}
                  id="demo-simple-select-label"
                  style={{
                    marginBottom: "10px",
                    color: "#474747",
                    fontSize: "12px",
                    textAlign: "center",
                    paddingLeft: "20%",
                    lineHeight: "0.9em",
                  }}
                >
                  Date
                </InputLabel>
                <Select
                  open={open}
                  onClose={handleCloseDateDialogue}
                  onOpen={handleOpenDateDialogue}
                  className="dateAnalytics"
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  name="dateFilter"
                  value={10}
                >
                  <MenuItem value={10} className="bg-white"></MenuItem>

                  <div className="pr-[35px] pl-[35px] pt-[12px]">
                    <div>
                      <div className="mb-[8px]">Start Date</div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker value={startDateFilter} onChange={onChangeStartDateFilter} format="YYYY-MM-DD" />
                      </LocalizationProvider>
                    </div>
                    <div className="pt-[8px]">End Date</div>
                    <div className="mt-[8px]">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker value={endDateFilter} onChange={onChangeEndDateFilter} format="YYYY-MM-DD" />
                      </LocalizationProvider>
                    </div>
                    <div className="">
                      <button className="dialogue-button-text mt-[30px]" onClick={() => handleCloseDateDialogue()}>
                        Cancel
                      </button>
                      <button className="teepee-button  mt-[20px] mb-[17px]" onClick={() => handleCloseWithConfirm()}>
                        Confirm
                      </button>
                    </div>
                  </div>
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl
                sx={selectFieldStyles}
                className="md:w-[10%] float-right "
                style={{
                  marginTop: "10px",
                  marginRight: "20px",
                  marginBottom: "1%",
                }}
              >
                <InputLabel shrink={false} id="demo-simple-select-label"></InputLabel>
                <Select
                  name="quiztypeFilter"
                  defaultValue={1}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={quizTypeAnalyticsSelection}
                  onChange={onChangeFilter}
                >
                  {/* <MenuItem value={1}>Formative</MenuItem> */}
                  {/* <MenuItem value={2}>Minomock</MenuItem> */}
                  <MenuItem value={3}>Exam Style</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </center>
      </div>
      <div className="w-[100%] overflow-x-auto">
        {!classroomAnalyticsData?.msg && (
          <div id="">
            <table
              style={{
                width: "100%",
                border: "1px solid black",
                borderCollapse: "collapse",
              }}
            >
              <thead class="text-xs text-black dark:bg-white dark:text-white ">
                <tr className="tracking_table">
                  <th scope="col" class="px-6 py-3  tracking_table">
                    Student Name
                  </th>
                  {handleMapOfTypeFilter(1)}
                </tr>
              </thead>
              <tbody>
                {handleMapOfTypeFilter(2)}
                <tr
                  className="tracking_table"
                  style={{
                    display: localStorage.getItem("student") !== null && "none",
                  }}
                >
                  <td scope="col" class="px-4 py-3  tracking_table">
                    Class Avg. Score
                  </td>
                  {handleMapOfTypeFilter(3)}
                </tr>
                <tr
                  className="tracking_table"
                  style={{
                    display: localStorage.getItem("student") !== null && "none",
                  }}
                >
                  <td scope="col" class="px-4 py-3  tracking_table">
                    Class Avg. Score %age
                  </td>
                  {handleMapOfTypeFilter(4)}
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>

      <div className="pt-[10%]">{classroomAnalyticsData?.msg && <div className="text-center">No Analytics Available</div>}</div>
    </>
  );
}

export default TeacherAnalyticsTabBody;
