import React from "react";
import Inventory from "../../../images/menu_book.svg";
import quiz_icon from "../../../images/assignment.svg";

function StudentDoneThemCard(props) {
  const doneThem = props.doneThem;
  const doneThemRetrieval = props.doneThemRetrieval;
  console.log("DONE THEM: ", doneThemRetrieval);
  return (
    <div class="grid grid-rows-1 grid-flow-col gap-4 overflow-auto">
      {doneThem.map((item, index) => (
        <div
          onClick={() => props.handleOnClickDoneThemQuiz(index)}
          key={index}
          className="quiz-div w-[360px] h-[196px] bg-gradient-to-r from-[#937CFE] to-[#B58DFE] rounded-[12px] mt-[24px] p-4 shadow-md text-white"
        >
          <div className="flex justify-between items-center mb-4">
            <div className="font-bold text-xl overflow-hidden text-ellipsis whitespace-nowrap">{item.quiz_title}</div>
            <div className="bg-white text-black px-3 py-1 rounded-lg font-semibold text-[14px] whitespace-nowrap">
              <span className="text-[10px]">MARKS:</span> {Math.round((item.quiz_total_marks / 100) * item.overall_score)}{" "}
              <span className="text-[10px]">/{item.quiz_total_marks}</span>
            </div>
          </div>

          <div className="mb-6">
            <div className="flex items-center mb-2">
              <img src={Inventory} alt="Icon" className="mr-2" />
              <span className="text-sm">{item.subject_name}</span>
            </div>
            <div className="flex items-center">
              <img src={quiz_icon} alt="Icon" className="mr-2" />
              <span className="text-sm">Exam style Questions</span>
            </div>

            <button className="bg-white text-[#424242] text-[14px] leading-[20px] w-full py-2 mt-3 rounded-md font-bold ">VIEW FEEDBACK</button>
          </div>
        </div>
      ))}
      {doneThemRetrieval.map((item, index) => (
        <div
          onClick={() => props.viewQuizRetrieval(item, 2)}
          key={index}
          className="quiz-div w-[360px] h-[196px]  bg-gradient-to-r from-[#91AEF4] to-[#95C6F4] rounded-[12px] mt-[24px] p-4 shadow-md text-white cursor-pointer"
        >
          <div className="flex  justify-between items-center mb-4">
            <div className="font-bold text-xl">{item.student_quiz_title}</div>
            <div className="bg-white text-black px-3 py-1 rounded-lg font-semibold text-[14px]">
              <span className="text-[12px]">MARKS:</span> {item.obtained_score}
              <span className="text-[12px]">/{item.quiz_marks}</span>
            </div>
          </div>
          <div className="flex justify-between items-start w-[67%]">
            <div>
              <div className="flex items-center mb-2">
                <img src={Inventory} alt="Icon" className="mr-2" />
                <span className="text-sm whitespace-nowrap overflow-hidden text-ellipsis">{item.subject_name}</span>
              </div>
            </div>
          </div>

          <div className="flex items-center mt-1">
            <img src={quiz_icon} alt="Icon" className="mr-2" />
            <span className="text-sm">Retrieval Quiz</span>
          </div>
          <button className="bg-white text-[#424242] text-[14px] leading-[20px] w-full py-2 mt-3 rounded-md font-bold ">REVIEW QUIZ</button>
        </div>
      ))}
    </div>
  );
}

export default StudentDoneThemCard;
