import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function AddOwnQuestionDialogue({
  handleSecondaryBtnSelected,
  handlePrimaryBtnSelected,
  open,
  secondaryBtnText,
  PrimaryBtnText,
  description,
  heading,
}) {
  return (
    <React.Fragment>
      <Dialog open={open} onClose={() => {}} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{heading}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {secondaryBtnText && (
            <div className="teepee-back-button hover: cursor-pointer " onClick={handleSecondaryBtnSelected}>
              {secondaryBtnText}
            </div>
          )}
          {PrimaryBtnText && (
            <div className="teepee-button cursor-pointer " onClick={handlePrimaryBtnSelected} autoFocus>
              {PrimaryBtnText}
            </div>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
