import axios from "axios";
import { REACT_APP_REST_API_BASE_URL, REACT_APP_CREATE_NOTIFICATION_END_POINT } from "../../Constants/ApiConstant";

const useCreateNotification = () => {
  const createNotification = (notification_text) => {
    const access_token = "Bearer " + localStorage.getItem("access_token");

    axios
      .post(
        REACT_APP_REST_API_BASE_URL + REACT_APP_CREATE_NOTIFICATION_END_POINT,
        {
          notification_text: notification_text,
        },
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: access_token,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        // console.log("data here ", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return createNotification;
};

export default useCreateNotification;
