import { Dialog, DialogContent, DialogTitle, IconButton, Snackbar, Alert, Typography } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close"; // Material-UI Close Icon
import shareQuizIcon from "../../../images/share_quiz_icon.svg";

function ShareQuizUrlDialogue(props) {
  const { title, quizUrl, handleCopy, open, handleClose } = props;
  const [toastOpen, setToastOpen] = useState(false);

  // Handle copy and show toast
  const handleCopyAndToast = () => {
    handleCopy();
    setToastOpen(true);
  };

  // Close toast
  const handleToastClose = (event, reason) => {
    if (reason === "clickaway") return;
    setToastOpen(false);
  };

  return (
    <>
      <Dialog
        PaperProps={{
          sx: {
            width: "500px",
            padding: "12px",
            borderRadius: "8px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
          },
        }}
        open={open}
        // onClose={handleClose}
        aria-labelledby="dialog-title"
      >
        <DialogTitle
          id="dialog-title"
          sx={{
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "600",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            position: "relative", // Ensure relative positioning for the close button
          }}
        >
          {/* Close Button */}
          {/* <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: "8px",
              top: "8px",
              color: "#49454F",
            }}
          >
            <CloseIcon />
          </IconButton> */}

          {/* Icon */}
          <img src={shareQuizIcon} alt="Share Icon" style={{ width: "124px", height: "124px", marginBottom: "10px" }} />
          {title}
        </DialogTitle>

        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "15px",
            marginTop: "10px",
          }}
        >
          {/* URL and Copy Button Container */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <input
              type="text"
              value={quizUrl}
              readOnly
              style={{
                width: "80%",
                padding: "8px",
                textAlign: "center",
                border: "1px solid #49454F",
                borderRadius: "4px",
                fontSize: "16px",
                backgroundColor: "#F5F5F5",
              }}
            />
            <button
              className="teepee-button"
              onClick={handleCopyAndToast}
              style={{
                backgroundColor: "#6a1b9a",
                color: "#fff",
                border: "none",
                borderRadius: "6px",
                padding: "10px 20px",
                fontSize: "16px",
                fontWeight: "500",
                cursor: "pointer",
                boxShadow: "0px 2px 10px rgba(106, 27, 154, 0.2)",
              }}
            >
              Copy
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Snackbar open={toastOpen} autoHideDuration={3000} onClose={handleToastClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert onClose={handleToastClose} severity="success" sx={{ width: "100%" }}>
          <strong>Link Copied Successfully!</strong>
          <Typography variant="body2" sx={{ mt: 0.5 }}>
            Share this link with your students to let them attempt the quiz.
          </Typography>
        </Alert>
      </Snackbar>
    </>
  );
}

export default ShareQuizUrlDialogue;
