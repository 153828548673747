import React from "react";
import CheckCircle from "../../../../images/check_circle.svg";
function SlideOneBullets({ text }) {
  return (
    <div>
      <li className="flex mt-[0.5rem] text-[1rem] leading-[1.75rem] tracking-[0.009rem]">
        <img src={CheckCircle} className="w-[4%] mr-[0.375rem]" />
        <div>{text}</div>
      </li>
    </div>
  );
}

export default SlideOneBullets;
