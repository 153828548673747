import React, { useEffect, useState } from "react";
import close_icon from "../../images/closed.svg";
import checked from "../../images/checked.svg";
import bg_image from "../../images/pop_bg.png";
import useGetPricing from "../PricingApiCall/GetPricing";

const Pricing = () => {
  const [students, setStudents] = useState();
  const [noOfStudents, setNoOfStudents] = useState("");
  const [price, setPrice] = useState(0); // default to 0 for initial state
  const [paymentOption, setPaymentOption] = useState("annually");
  const [costPerStudent, setCostPerStudent] = useState(price); // cost per student updated according to month/year selection
  const [updatedPrice, setUpdatedPrice] = useState(0); // defaulting to 0

  const getPricing = useGetPricing();

  useEffect(() => {
    getPricing(setPrice, setStudents);
  }, []);

  useEffect(() => {
    // Update cost per student based on payment option
    if (paymentOption === "monthly") {
      setCostPerStudent(price / 12); // Calculate the monthly price per student
    } else {
      setCostPerStudent(price); // Use the annual price per student
    }
  }, [paymentOption, price]);

  useEffect(() => {
    // Update the total price based on the number of students and payment option
    if (noOfStudents) {
      setUpdatedPrice(costPerStudent * noOfStudents); // Calculate the total price
    } else {
      setUpdatedPrice(0); // Reset the price if noOfStudents is empty
      setPaymentOption("annually"); // Reset payment option to 'annually' if noOfStudents is empty

      // Use document.getElementById to set the annually radio button checked
      const annuallyRadio = document.getElementById("annually");
      const monthlyRadio = document.getElementById("monthly");

      if (annuallyRadio) {
        annuallyRadio.checked = true;
      }
      if (monthlyRadio) {
        monthlyRadio.checked = false;
      }
    }
  }, [noOfStudents, costPerStudent]);

  const handleOnClickBuy = () => {
    window.open("https://app.teepee.ai/", "_blank");
  };

  return (
    <div id="pricing-form" className="mt-1 mr-1 ml-1 bg-white shadow-md rounded-lg overflow-none">
      <br />
      <div className="flex items-center justify-center md:p-3 md:m-3 sm:p-1 sm:m-1 mt-0 mb-1 bg-[#6750A4] text-[#FFFFFF] rounded-lg">
        <div>
          <p className="font-bold text-center text-[40px] md:text-[56px] sm:text-[40px] leading-[64px]">£{price.toFixed(2)}</p>
          <p>{"per student, per subject, per year"}</p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row" style={{ fontFamily: "Roboto" }}>
        <div className="flex-1 md:p-2 sm:p-2">
          <div className="p-2 rounded-lg">
            <p className="sm:text-[12px] md:text-[16px] font-semibold mb-2">How many students would you like to enrol?</p>
            <input
              type="text"
              value={noOfStudents}
              placeholder="Enter number of students"
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  setNoOfStudents(value);
                }
              }}
              className="w-full lg:w-[390px] h-[48px] p-2 border border-gray-300 rounded mb-2"
            />
            <p className="text-xs text-gray-600 mb-4 italic">
              Note: If you enrol {students} or more students, you'll have the option to pay monthly.
            </p>
            <div className="p-3 rounded-[12px] border-[1px] border-gray-200 mb-4">
              <div className="flex justify-between mb-1">
                <span className="font-bold">Cost (per student):</span>
                <span>
                  £{costPerStudent.toFixed(2)} {paymentOption === "annually" ? "per year" : "per month"}
                </span>
              </div>
              <div className="flex justify-between mb-1">
                <span className="font-bold">Students:</span>
                <span>{noOfStudents ? noOfStudents : "0"}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-bold">Total cost:</span>
                <span>
                  £{updatedPrice.toFixed(2)} {paymentOption === "annually" ? "per year" : "per month"}
                </span>
              </div>
            </div>
            <span style={{ display: noOfStudents < students ? "none" : "" }}>
              <p className="font-semibold mb-2 text-[16px] leading-[20px]">You Qualify for Monthly Payments!</p>
              <div className="mb-4">
                <label className="flex items-center mb-2">
                  <input
                    id="annually"
                    type="radio"
                    name="payment"
                    value="annually"
                    onChange={(e) => setPaymentOption(e.target.value)}
                    className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out mr-2"
                    defaultChecked
                  />
                  <b> Pay Annually: £{price * noOfStudents} per year </b>
                  <span className="text-gray-500 underline font-roboto  text-[12px] ml-1">cancel anytime</span>
                </label>
                <label className="flex items-center">
                  <input
                    id="monthly"
                    type="radio"
                    name="payment"
                    value="monthly"
                    className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out mr-2"
                    onChange={(e) => setPaymentOption(e.target.value)}
                  />
                  <span>
                    <b>Pay Monthly: £{((price * noOfStudents) / 12).toFixed(2)} per month </b>
                    <span className="text-gray-500 underline font-roboto  text-[12px] ml-1">cancel anytime</span>
                  </span>
                </label>
              </div>
            </span>
            <button
              disabled={!noOfStudents || noOfStudents === "0"}
              className={`w-full ${
                !noOfStudents || noOfStudents === "0" ? "disable-button" : "bg-[#6750A4]"
              } text-white p-2 rounded-[4px] transition duration-150 ease-in-out`}
              onClick={handleOnClickBuy}
            >
              Get Started
            </button>
            {/* <p className="text-xs text-center text-gray-500 mt-2">
              By subscribing, you agree to Teepee's{" "}
              <a href="#" className="underline">
                Terms of Service
              </a>{" "}
              and<br></br>
              <a href="https://teepee.ai/privacy-policy-for-teepee-ai/" target="_blank" className="underline ml-2">
                Privacy Policy
              </a>
              .
            </p> */}
          </div>
        </div>
        <div className="flex-1 p-4 mt-8 md:mt-0" style={{ fontFamily: "Roboto" }}>
          <p className="font-bold text-center text-[18px] mb-3">
            What's included?
            <br /> Everything!
          </p>
          <ul className="md:pl-5 sm:pl-0 text-[#49454F] text-[14px] leading-[24px]">
            <li className="mb-3 flex items-start">
              <span className="flex-shrink-0">
                <img src={checked} className="mt-0 mr-2" alt="checked" />
              </span>
              <span>Create Multiple Year Groups Under One Subject (e.g., Year 10, Year 11)</span>
            </li>
            <li className="mb-3 flex items-start">
              <span className="flex-shrink-0">
                <img src={checked} className="mt-0 mr-2" alt="checked" />
              </span>
              <span>Instant AI Marking & Feedback for Students</span>
            </li>

            <li className="mb-3 flex items-start">
              <span className="flex-shrink-0">
                <img src={checked} className="mt-0 mr-2" alt="checked" />
              </span>
              <span>Access to Exam-Style Question Database</span>
            </li>

            <li className="mb-3 flex items-start">
              <span className="flex-shrink-0">
                <img src={checked} className="mt-0 mr-2" alt="checked" />
              </span>
              <span>Reports & Dashboard for Teachers</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
