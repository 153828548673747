import React, { useContext, useEffect, useState } from "react";
import Joyride from "react-joyride";
import Modal from "react-modal";
import "../../../../src/OnboardingTour.css";
import CheckCircle from "../../../images/check_circle.svg";
import { useNavigate } from "react-router-dom";
import appContext from "../../../context/appContext";

Modal.setAppElement("#root"); // Required for accessibility

const OnboardingTour = () => {
  const [runTour, setRunTour] = useState(false);
  const navigate = useNavigate();
  const state = useContext(appContext);

  useEffect(() => {
    if (sessionStorage.getItem("tourStarted") && sessionStorage.getItem("tourStarted") !== "false") {
      setRunTour(true);
    }
  }, []);

  const onClickRestart = () => {
    sessionStorage.removeItem("tourStarted");
    navigate("/");
  };

  const onClickFinishTour = () => {
    setRunTour(false);
    sessionStorage.setItem("tourStarted", false);
    state.updateTourState(5);
  };

  const handleJoyrideCallback = (data) => {
    const { action } = data;

    if (action === "close") {
      state.updateTourState(5);
      sessionStorage.setItem("tourStarted", false);
    }
  };

  const steps = [
    {
      target: ".share-task", // Final step
      title: "",
      content: (
        <div>
          <div className="pt-[1.375rem] pr-[0.75rem] pl-[0.75rem]">
            <div className="my-[1.625rem]">
              <div className="flex text-[1rem] leading-[1.75rem] tracking-[0.009rem] font-semibold">
                <img src={CheckCircle} className="w-[5%] mr-[0.375rem]" />
                <div>Final Step: Share or Preview Task</div>
              </div>
              <div className="text-start pl-[1.25rem] pr-[1.25rem]">
                <div className="mt-[0.25rem] leading-[1.5rem] tracking-[0.006rem] text-[0.875rem]">
                  <strong>💡 Tip:</strong> You have two options now:
                </div>
                <div className="  mt-[1.25rem] leading-[1.25rem] tracking-[0.006rem] text-[0.875rem]">
                  <strong>🔴 Share with Students: </strong> – Copy the link and send it to students.
                </div>

                <div className="mt-[1.25rem] leading-[1.25rem] tracking-[0.006rem] text-[0.875rem]">
                  <strong>🔴 Preview as Student: </strong> See the task and experience AI marking & feedback as students do.
                </div>
              </div>
              <div className="text-start mt-[1.375rem] ml-[0.5rem] leading-[1.5rem] tracking-[0.006rem] text-[0.875rem]">
                👉 Action: Click <strong>"Share via Link"</strong> or <strong>"Preview as Student".</strong>
              </div>
            </div>
          </div>
          <hr className="!m-0"></hr>
          <div className="modal-buttons pl-[1.5rem] pr-[1.5rem] pt-[0.5rem]  mt-0">
            <button className="skip-btn" onClick={() => onClickRestart()}>
              Restart
            </button>
            <p className="step-count">5 of 5</p>
            <button className="teepee-button !font-medium !text-[0.875rem]" onClick={() => onClickFinishTour()}>
              Finish Tour
            </button>
          </div>
        </div>
      ),
      placement: "bottom",
      disableBeacon: true,
      styles: {
        options: {
          width: "24vw",
        },
      },
    },
  ];

  return (
    <>
      <Joyride
        steps={steps}
        run={runTour}
        continuous={true}
        showProgress={false}
        showSkipButton={false}
        hideBackButton={true}
        disableOverlayClose={true}
        disableBeacon={true}
        hideFooter={true}
        callback={handleJoyrideCallback}
        styles={{
          buttonClose: { width: "70%" },
          tooltipTitle: { padding: "1.5rem", textAlign: "start" },
          tooltipContent: { padding: "0px" },
          buttonNext: {
            display: "none",
          },
          options: {
            zIndex: 10000,
          },
        }}
      />
    </>
  );
};

export default OnboardingTour;
