import axios from "axios";

const callClickCounts = (quizClick, classClick) => {
  const accessToken = localStorage.getItem("access_token");
  if (!accessToken) {
    console.error("No access token found");
    return;
  }

  const token = `Bearer ${accessToken}`;

  axios
    .post(
      `${process.env.REACT_APP_REST_API_BASE_URL}/get_clicks_count`,
      { quiz_click: quizClick, class_click: classClick },
      {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: token, // Correct capitalization
        },
      }
    )
    .then((response) => {
      console.log(response.data); // Only log useful data
    })
    .catch((error) => {
      console.error("Error posting click counts:", error.response?.data || error.message);
    });
};

export default callClickCounts;
