import React, { Fragment, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import TopicAccordions from "./TopicAccordian";

import Button from "@mui/material/Button";
import YesNoDialog from "./YesNoDialog";
function ViewSubjectDetails(props) {
  const [deletingObject, setDeletingObject] = useState([null, null]);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const onDeleteClick = (object, type) => {
    setOpenConfirmationDialog(true);
    setDeletingObject([object, type]);
  };

  const handleCloseWithNo = () => {
    setOpenConfirmationDialog(false);
  };

  const handleCloseWithYes = () => {
    setOpenConfirmationDialog(false);
    var type = deletingObject[1];
    var id = null;
    if (type === process.env.REACT_APP_DELETING_OBJECT_TYPE_CHAPTER) {
      id = deletingObject[0].chapter_id;
    } else if (type === process.env.REACT_APP_DELETING_OBJECT_TYPE_TOPIC) {
      id = deletingObject[0].topic_id;
    } else if (type === process.env.REACT_APP_DELETING_OBJECT_TYPE_SUBTOPIC) {
      id = deletingObject[0].sub_topic_id;
    }

    sendDeleteRequest(type, id);
  };

  function sendDeleteRequest(type, object_id) {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/delete_content", {
        method: "POST",
        type: type,
        id: object_id,
      })
      .then((response) => {
        alert("successfully deleted");
        props.addTodo();
      })
      .catch((error) => {
        console.log(error);
        alert("unable to delete the content");
      });
  }

  function addNewChapter(subject_id) {
    // alert(subject.subject_name);

    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/create_new_chapter", {
        method: "POST",
        subject_id: subject_id,
      })

      .then((response) => {
        props.addTodo();
      })
      .catch((error) => {
        console.log(error);
        alert("unable to add new module");
      });
  }

  return (
    <div>
      <YesNoDialog
        handleCloseWithNo={handleCloseWithNo}
        handleCloseWithYes={handleCloseWithYes}
        open={openConfirmationDialog}
        title={"Confirm"}
        description={"Are you sure you want to delete this record?"}
      />
      <div className="form-div">
        <div className="ml-[30px] mr-[30px] w-full">
          {props.subject_data?.map((subject, subjectIndex) => (
            <Fragment key={subjectIndex}>
              <div style={{}}>
                <div>
                  <p
                    style={{
                      fontSize: "14px",
                      fontFamily: "Roboto",
                      color: "#49454F",
                    }}
                  >
                    {JSON.parse(subject["chapters_list"])?.map((chapter, chapterIndex) => (
                      <Fragment key={chapterIndex}>
                        <div style={{}}>
                          <div
                            style={{
                              padding: "10px",
                              borderRadius: "5px",
                              width: "100%",
                              marginBottom: "30px",
                              backgroundColor: "#F0F0F0",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "16px",
                                fontFamily: "Roboto",
                                color: "#49454F",
                              }}
                            >
                              <b>Topic Name: </b>
                            </p>
                            <p>
                              <TopicAccordions
                                addTodo={props.addTodo}
                                title={chapter}
                                subject={subject}
                                has_sub_topics={subject.has_sub_topics}
                                onDeleteClick={onDeleteClick}
                                topics_list={chapter["topics_list"]}
                                predefined_question_answer_status={props.predefined_question_answer_status}
                                predefinedQuestionsPromptList={props.predefinedQuestionsPromptList}
                                teacherFlow={props.teacherFlow && props.teacherFlow}
                              />
                            </p>
                          </div>
                        </div>

                        <div
                          style={{
                            flex: 1,
                            height: "1px",
                            backgroundColor: "#B3B3B3",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        />
                      </Fragment>
                    ))}
                  </p>
                </div>
              </div>
              <Button
                onClick={() => {
                  addNewChapter(subject.subject_id);
                }}
                className="delete-button"
                style={{ color: "#1976d2", padding: "6px 8px" }}
              >
                Add New topic
              </Button>
              <div
                style={{
                  flex: 1,
                  height: "1px",
                  backgroundColor: "#B3B3B3",
                  marginTop: "100px",
                  marginBottom: "10px",
                }}
              />
            </Fragment>
          ))}

          <div>
            {/* <form onSubmit={handleSubmit(submitForm)}>
            <div className="mt-2">
                <button disabled={isSubmitting} className="btn btn-danger">
                  {isSubmitting && (
                    <span className="spinner-grow spinner-grow-sm"></span>
                  )}
                  Submit
                </button>
            </div>
        </form> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewSubjectDetails;
