import React, { useEffect, useState } from "react";
import PlusIcon from "../../images/plus.svg";
import PlusIconDisabled from "../../images/plus-icon-disabled .png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import amplitude from "amplitude-js";
import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from "@material-tailwind/react";
import TaskDashboardTabBody from "./widgets/TaskDashboardTabBody";
import TeacherAnalyticsTabBody from "./TeacherAnalyticsTabBody";
import TeacherMarkbookTabBody from "./TeacherMarkbookTabBody";
import moment from "moment";
import callClickCounts from "../Dashboard/DashboardApiCall/ClicksApi";
import { useTab } from "./context/TabContext";

function TeacherDashboardTabBody(props) {
  // console.log("IN TEACHER DASHBOARD: ", props.subjectData);
  const [assignedQuizzes, setAssignedQuizzes] = useState([]);
  const is_parent = sessionStorage.getItem("is_parent");
  const [selectedTable, setSelectedTable] = useState(parseInt(localStorage.getItem("teacher_table_state")));
  const [draftQuizzes, setDraftQuizzes] = useState([]);
  // const [tabState, setTabState] = useState(props.tabState !== null ? props.tabState : "1");
  const { tabState, setTabState } = useTab();
  console.log("TabState: ", tabState);
  // const [tabState, setTabState] = useState("1");
  const requiredUrl = window.location.href;
  const navigate = useNavigate();
  const classroomData = props.classroomData;

  // var { accountStatus, enrolledStudents } = location.state;
  const handleClick = () => {
    callClickCounts(1, null);
    navigate(`/add-details?id=${props.subjectData.subject.subject_id}`, {
      state: {
        classroomData: classroomData !== null ? classroomData : null,
        url: requiredUrl,
        bannerObject: props.bannerObject,
        subjectData: props.subjectData.subject,
        is_code: props.subjectData.subject.is_code,
      },
    });
  };

  useEffect(() => {
    if (props.classroomData.classroom_id) {
      setSelectedTable(parseInt(localStorage.getItem("teacher_table_state")));
      var token = "Bearer " + localStorage.getItem("access_token");
      axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
      axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
      axios.defaults.headers.post["authorization"] = token;
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

      axios
        .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_quizzes", {
          method: "POST",
          class_id: props.classroomData.classroom_id,
          folder_id: null,
          subject_id: null,
          current_date: moment().format("Y-MM-DD HH:mm:ss"),
        })

        .then((response) => {
          console.log("QUIZZES DATA: ", response.data);
          setAssignedQuizzes(response.data.assigned_quizzes);
          setDraftQuizzes(response.data.draft_quizzes);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props.classroomData, tabState]);

  const showAssignedQuizzesTable = (value) => {
    if (value === 1) {
      amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
      amplitude.getInstance().logEvent("AssignedQuizzes");
    } else {
      amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
      amplitude.getInstance().logEvent("Drafts");
    }
    localStorage.setItem("teacher_table_state", value);
    setSelectedTable(value);
  };
  const handleTotalNumberOfQuizzes = () => {
    if (localStorage.getItem("email_address") === process.env.REACT_APP_REQUESTED_USER && props.subjectData.subject_id) {
      return 20;
    } else {
      return process.env.REACT_APP_TRIAL_QUIZ;
    }
  };

  const data = [
    {
      label: "Dashboard",
      value: "1",
      body: (
        <>
          {tabState === "1" && (
            <TaskDashboardTabBody
              showAssignedQuizzesTable={showAssignedQuizzesTable}
              assignedQuizzes={assignedQuizzes}
              selectedTable={selectedTable}
              draftQuizzes={draftQuizzes}
              getClassData={props.getClassData}
              bannerObject={props.bannerObject}
              classroomData={classroomData}
              requiredUrl={requiredUrl}
              is_code={props.subjectData.subject.is_code}
            />
          )}
        </>
      ),
    },
    {
      label: "Class Analytics",
      value: "2",
      body: (
        <>
          {tabState === "2" && (
            <TeacherAnalyticsTabBody
              quizTypeAnalyticsSelection={props.quizTypeAnalyticsSelection}
              classroomAnalyticsData={props.classroomAnalyticsData}
              typeFilter={props.typeFilter}
              onChangeFilter={props.onChangeFilter}
              startDateFilter={props.startDateFilter}
              endDateFilter={props.endDateFilter}
              handleCloseDateDialogue={props.handleCloseDateDialogue}
              handleOpenDateDialogue={props.handleOpenDateDialogue}
              handleCloseWithConfirm={props.handleCloseWithConfirm}
              open={props.open}
              onChangeEndDateFilter={props.onChangeEndDateFilter}
              onChangeStartDateFilter={props.onChangeStartDateFilter}
              handleDatePickerClick={props.handleDatePickerClick}
            />
          )}
        </>
      ),
    },
    {
      label: "Markbook",
      value: "3",
      body: <>{tabState === "3" && <TeacherMarkbookTabBody />}</>,
    },
  ];
  return (
    <div>
      <div className="home sm:ml-[20px] !text-[#6750A4]  underline teacher_navigation_text">
        <button
          className=""
          onClick={() => {
            console.log("IS PARENT: ", is_parent);
            is_parent === "true"
              ? navigate(`/teacher-subject-home?id=${props.subjectData.subject.subject_id}`)
              : //  navigate(`/teacher-subject-home?id=${props.subjectData.subject.subject_id}`, {
                //     state: { accountStatus: accountStatus, enrolledStudents: enrolledStudents },
                //   })
                navigate("/");
          }}
        >
          {"Back"}
        </button>
      </div>
      <div className="home md:ml-[20px]">
        <div>
          <label className="dashboard-label">Get Started</label>
          <div className="teepee-divider"></div>
        </div>
      </div>
      <div
        className="create_quiz_div-main body md:ml-[20px] tour-inside-2nd"
        onClick={
          props.bannerObject &&
          ((!props.bannerObject.subscription_days_left && props.bannerObject.quiz_count >= handleTotalNumberOfQuizzes()) ||
            props.bannerObject.account_status === 2 ||
            (props.bannerObject.account_status === 5 && props.bannerObject.days_left <= 0 && !props.bannerObject.subscription_days_left) ||
            (props.bannerObject.account_status === 5 && props.bannerObject.days_left <= 0 && props.bannerObject.subscription_days_left < 0) ||
            props.bannerObject.account_status === 4 ||
            props.bannerObject.quiz_count_subscribed >= process.env.REACT_APP_SUBSCRIPTION_QUIZ)
            ? () => {
                console.log("HANDLE CLICK IS NOT CALLED 1");
              }
            : handleClick
        }
      >
        <button
          disabled={
            props.bannerObject &&
            ((!props.bannerObject.subscription_days_left && props.bannerObject.quiz_count >= handleTotalNumberOfQuizzes()) ||
              props.bannerObject.account_status === 2 ||
              (props.bannerObject.account_status === 5 && props.bannerObject.days_left <= 0 && !props.bannerObject.subscription_days_left) ||
              (props.bannerObject.account_status === 5 && props.bannerObject.days_left <= 0 && props.bannerObject.subscription_days_left < 0) ||
              props.bannerObject.account_status === 4 ||
              props.bannerObject.quiz_count_subscribed >= process.env.REACT_APP_SUBSCRIPTION_QUIZ)
          }
          style={{ width: "100%" }}
          type="button"
          className={`create_quiz_button_dashboard ${
            props.bannerObject &&
            ((!props.bannerObject.subscription_days_left && props.bannerObject.quiz_count >= handleTotalNumberOfQuizzes()) ||
              props.bannerObject.account_status === 2 ||
              (props.bannerObject.account_status === 5 && props.bannerObject.days_left <= 0 && !props.bannerObject.subscription_days_left) ||
              (props.bannerObject.account_status === 5 && props.bannerObject.days_left <= 0 && props.bannerObject.subscription_days_left < 0) ||
              props.bannerObject.account_status === 4 ||
              props.bannerObject.quiz_count_subscribed >= process.env.REACT_APP_SUBSCRIPTION_QUIZ)
              ? "!text-gray-400"
              : ""
          }`}
          onClick={
            props.bannerObject &&
            ((!props.bannerObject.subscription_days_left && props.bannerObject.quiz_count >= handleTotalNumberOfQuizzes()) ||
              props.bannerObject.account_status === 2 ||
              (props.bannerObject.account_status === 5 && props.bannerObject.days_left <= 0 && !props.bannerObject.subscription_days_left) ||
              (props.bannerObject.account_status === 5 && props.bannerObject.days_left <= 0 && props.bannerObject.subscription_days_left < 0) ||
              props.bannerObject.account_status === 4 ||
              props.bannerObject.quiz_count_subscribed >= process.env.REACT_APP_SUBSCRIPTION_QUIZ)
              ? () => {
                  console.log("HANDLE CLICK IS NOT CALLED 2");
                }
              : handleClick
          }
        >
          <div className="inline-flex">
            <img
              className="create-quiz-button-icon"
              src={
                props.bannerObject &&
                ((!props.bannerObject.subscription_days_left && props.bannerObject.quiz_count >= handleTotalNumberOfQuizzes()) ||
                  props.bannerObject.account_status === 2 ||
                  (props.bannerObject.account_status === 5 && props.bannerObject.days_left <= 0 && !props.bannerObject.subscription_days_left) ||
                  (props.bannerObject.account_status === 5 && props.bannerObject.days_left <= 0 && props.bannerObject.subscription_days_left < 0) ||
                  props.bannerObject.account_status === 4 ||
                  props.bannerObject.quiz_count_subscribed >= process.env.REACT_APP_SUBSCRIPTION_QUIZ)
                  ? PlusIconDisabled
                  : PlusIcon
              }
              alt=""
            />{" "}
            <span>{assignedQuizzes.length > 0 || draftQuizzes.length > 0 ? "Create Tasks" : "Create your first task now!"}</span>
          </div>
        </button>
      </div>
      <div>
        <Tabs key={tabState} value={tabState} className="w-[100%] sm:mt-[20px] md:mt-0">
          <div>
            <center>
              <div className="flex relative !z-0">
                <div className="ml-[auto]">
                  <TabsHeader
                    className="w-[100%] h-[58px] justify-center bg-[#6750A414]"
                    indicatorProps={{
                      className: "bg-[#6750A414] shadow-none !text-gray-900",
                    }}
                  >
                    {data.map(({ label, value, icon }) => (
                      <div
                        onClick={() => {
                          setTabState(value);
                        }}
                      >
                        <Tab
                          id="ABC"
                          key={value}
                          value={value}
                          icon={icon}
                          className={`w-[100%] mr-[20px] ${value === "2" && "class-analytics-tour"}`}
                        >
                          <div>{label}</div>
                        </Tab>
                      </div>
                    ))}
                  </TabsHeader>
                </div>
              </div>
            </center>
          </div>
          <div className="mt-[2%]">
            <TabsBody animate={{}}>
              {data.map(({ value, body }) => (
                <TabPanel key={value} value={value} className="p-0 overflow-auto">
                  {body}
                </TabPanel>
              ))}
            </TabsBody>
          </div>
        </Tabs>
      </div>
    </div>
  );
}

export default TeacherDashboardTabBody;
