import React, { useEffect, useRef, useState } from "react";
import Sidebar from "./Sidebar";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import Button from "@mui/material/Button";

const EmailUs = () => {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const isSubmitting = useRef(false);

  const submitForm = () => {
    const token = "Bearer " + localStorage.getItem("access_token");
    const userName = document.getElementById("userName").value;
    const userMessage = document.getElementById("user_message").value;

    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/submit_message", {
        method: "POST",
        name: userName,
        user_feedback: userMessage,
      })
      .then((response) => {
        console.log(response);
        isSubmitting.current = false;
        setShow(true);
        document.getElementById("user_message").value = "";
        document.getElementById("userName").value = "";
        var submitButton = document.getElementById("submit_button");
        submitButton.classList.add("disable-button");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCloseWithCancel = () => {
    setOpen(false);
    isSubmitting.current = false;
  };

  const handleCloseWithYes = () => {
    setOpen(false);
    isSubmitting.current = true;
    submitForm();
  };

  const handleDeleteOptionClick = (event) => {
    var userFeedback = document.getElementById("user_message").value;
    var userName = document.getElementById("userName").value;
    if (userFeedback.length <= 0 || userName.length <= 0) {
      return;
    }
    setOpen(true);
  };
  useEffect(() => {
    var submitButton = document.getElementById("submit_button");
    var userFeedback = document.getElementById("user_message").value;
    var userName = document.getElementById("userName").value;
    if (userFeedback.length <= 0 || userName.length <= 0) {
      submitButton.classList.add("disable-button");
      return;
    }
    submitButton.classList.remove("disable-button");
  }, []);
  const onUserChange = (e) => {
    var submitButton = document.getElementById("submit_button");
    var userFeedback = document.getElementById("user_message").value;
    var userName = document.getElementById("userName").value;
    if (userFeedback.length <= 0 || userName.length <= 0) {
      submitButton.classList.add("disable-button");
      return;
    }
    submitButton.classList.remove("disable-button");
  };

  return (
    <>
      <Sidebar item_id="contact_us" />
      <ToastContainer className="p-3 position-fixed top-12 right-0">
        <Toast style={{ backgroundColor: "#f7f2f9" }} onClose={() => setShow(false)} show={show} delay={5000} autohide>
          <Toast.Header>
            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
            <strong className="me-auto">Thank you!</strong>
          </Toast.Header>
          <Toast.Body>We will be in touch shortly.</Toast.Body>
        </Toast>
      </ToastContainer>
      <Dialog open={open} onClose={handleCloseWithCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <b>Confirm</b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to submit your Message?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWithCancel}>NO</Button>
          <Button className="delete-button" onClick={handleCloseWithYes} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px]">
        <div className="home sm:ml[25px] md:ml-[3.75rem]">
          <div>
            <label className="dashboard-label">Contact Us</label>
            <div className="teepee-divider"></div>
          </div>
        </div>
        <div className="flex flex-row md:justify-left md:items-center sm:ml-0 md:ml-[3.75rem] mt-[20px]">
          <div className=""></div>
          <div className="selectionDiv sm:w-[100%] md:w-[600px] ">
            <div className="form-div sm:w-[100%] md:w-[600px]">
              <div className="sm:w-[100%] md:w-[600px] ">
                <div>
                  <label className="create_quiz_label">Enter Your Name</label>
                </div>
                <div>
                  <textarea
                    type="text"
                    name="user_name"
                    required={true}
                    maxLength={45}
                    className="teepee-input-2 sm:w-[100%] md:w-full "
                    placeholder="Enter your Name"
                    id="userName"
                    onChange={(e) => {
                      onUserChange(e);
                    }}
                  />
                </div>
                <div>
                  <label className="character-length-label float-right mr-[28px] sm:pb-[10px]">Max. 45 characters</label>
                </div>
                <div>
                  <label className="create_quiz_label">Drop Us a Line</label>
                </div>
                <div>
                  <textarea
                    type="text"
                    name="user_feedback"
                    maxLength={500}
                    minLength={100}
                    required={true}
                    id="user_message"
                    className="teepee-textarea sm:w-[100%] md:w-full"
                    placeholder=" Send us your message"
                    onChange={(e) => {
                      onUserChange(e);
                    }}
                  />
                </div>
                <div className="">
                  <button
                    onClick={handleDeleteOptionClick}
                    id="submit_button"
                    className={`teepee-button hover:bg-black ${isSubmitting.current ? "disable-button" : ""} mb-[36px]`}
                    style={{ width: "46%" }}
                  >
                    {isSubmitting.current && (
                      <div className="spinner-border spinner-border-m float-left" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                    Submit
                  </button>
                  <div className="text-center ">
                    <label className="create_quiz_label-1 w-full">
                      Or you can email us at{" "}
                      <span
                        style={{
                          color: "#6750A4",
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "24px",
                          letterSpacing: "0.5px",
                          textDecorationLine: "underline",
                        }}
                      >
                        support@teepee.ai
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailUs;
