import React from "react";

import Sidebar from "../Sidebar";
import QuestionForm from "./Widgets/QuestionForm";

function TeacherAddOwnQuestion() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get("id");
  console.log("TeacherAddOwnQuestion");

  return (
    <div>
      <Sidebar />
      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[19.75rem] ">
        {/* <ViewSubjectDetails
          addTodo={addTodo}
          subject_data={subjectData}
          predefined_question_answer_status={2}
          predefinedQuestionsPromptList={predefinedQuestionsPromptList}
          teacherFlow
        /> */}
        <div className="md:p-[2rem]">
          <QuestionForm subjectId={id} />
        </div>
      </div>
    </div>
  );
}

export default TeacherAddOwnQuestion;
