import React, { createContext, useState } from "react";

export const TourContext = createContext();

export const TourProvider = ({ children }) => {
  // Check localStorage to see if the tour has already been completed
  const [runTour, setRunTour] = useState(() => {
    return localStorage.getItem("tourCompleted") === "true" ? false : true;
  });

  const endTour = () => {
    setRunTour(false);
    localStorage.setItem("tourCompleted", "true"); // Save to prevent repeating
  };

  return <TourContext.Provider value={{ runTour, setRunTour, endTour }}>{children}</TourContext.Provider>;
};
