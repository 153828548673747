import React, { useContext, useState } from "react";
import LoginTabs from "./LoginTabs";
import studentIcon from "../images/student-icon.svg";
import teacherIcon from "../images/teacher-icon.svg";
import TeepeeIcon from "../images/teepee-icon.svg";
import appContext from "../context/appContext";
function LoginPageRightSide(props) {
  const [landed, setLanded] = useState(true);
  const state = useContext(appContext);

  const handleStudentCardClicked = () => {
    state.setLoginState(1);
    setLanded(false);
  };
  const handleTeacherCardClicked = () => {
    state.setLoginState(2);
    setLanded(false);
  };

  return (
    <>
      {!landed ? (
        <div>
          <div className="w-full h-full bg-white centered md:h-screen p-10 md:p-30 sm:p-5 overflow-y-auto">
            <div className="main-content-div w-[500px] sm:w-[360px] md:w-[520px]">
              <div className="sign-in-label mb-[20px]">
                <p>Join Teepee as a</p>
              </div>
              <div className="w-[100%] flex justify-center">
                <LoginTabs />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center min-h-screen bg-white">
          <div className="w-full max-w-md p-6 flex flex-col items-center">
            {/* Logo */}
            <img src={TeepeeIcon} alt="Teepee logo" className="w-20 h-20 mb-3" />
            {/* Heading */}
            {/* <p className="text-xl font-semibold text-gray-800 mb-8">Join Teepee as a</p> */}
            <div className="sign-in-label mb-[20px]">
              <p>Join Teepee as a</p>{" "}
            </div>
            {/* Role Selection */}
            <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-12 mb-6">
              {/* Student */}
              <button
                onClick={handleStudentCardClicked}
                className="border border-gray-200 bg-[#EFB3A5] bg-opacity-50 rounded-lg p-6 flex flex-col items-center justify-center hover:shadow-md transition-shadow w-[12rem]"
              >
                <span className="text-xl font-medium text-black">Student</span>
                <div className="my-2 rounded-full flex items-center justify-center">
                  <img src={studentIcon} alt="Student" className="h-[8rem]" />
                </div>
              </button>
              {/* Teacher */}
              <button
                onClick={handleTeacherCardClicked}
                className="border border-gray-200 bg-[#B89FD6] bg-opacity-50 rounded-lg p-6 flex flex-col items-center justify-center hover:shadow-md transition-shadow w-[12rem]"
              >
                <span className="text-xl font-medium text-black">Teacher</span>
                <div className="my-2 rounded-full flex items-center justify-center">
                  <img src={teacherIcon} alt="Teacher" className="h-[8rem]" />
                </div>
              </button>
            </div>
            {/* Trial Info */}
            <p className="text-sm text-black">
              <span className="font-bold">14-Day Free Trial</span> — No Credit Card Required!
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default LoginPageRightSide;

// import React from "react";

// function LoginPageRightSide() {
//   return (
//     <div className="flex items-center justify-center min-h-screen bg-white">
//       <div className="w-full max-w-md p-6 flex flex-col items-center">
//         {/* Logo */}
//         <img src={TeepeeIcon} alt="Teepee logo" className="w-16 h-16 mb-3" />
//         {/* Heading */}
//         {/* <p className="text-xl font-semibold text-gray-800 mb-8">Join Teepee as a</p> */}
//         <div className="sign-in-label mb-[20px]">
//           <p>Join Teepee as a</p>{" "}
//         </div>
//         {/* Role Selection */}
//         <div className="grid grid-cols-2 gap-6 mb-6">
//           {/* Student */}
//           <button className="border border-gray-200 bg-[#EFB3A5] rounded-lg pt[2rem] flex flex-col items-center justify-center hover:shadow-md transition-shadow">
//             <span className="text-xl font-medium text-black">Student</span>
//             <div className="my-2 rounded-full flex items-center justify-center">
//               <img src={studentIcon} alt="Student" className="h-[5rem]" />
//             </div>
//           </button>
//           {/* Teacher */}
//           <button className="border border-gray-200 bg-[#B89FD6] rounded-lg p-6 flex flex-col items-center justify-center hover:shadow-md transition-shadow">
//             <span className="text-xl font-medium text-black">Teacher</span>
//             <div className="my-2 rounded-full flex items-center justify-center">
//               <img src={teacherIcon} alt="Teacher" className="h-[5rem]" />
//             </div>
//           </button>
//         </div>
//         {/* Trial Info */}
//         <p className="text-sm text-black">
//           <span className="font-bold">14-Day Free Trial</span> — No Credit Card Required!
//         </p>
//       </div>
//     </div>
//   );
// }
// export default LoginPageRightSide;
