import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { Backdrop, Box, Tooltip } from "@mui/material";
import ReplaceQuestionChapterDropdown from "./ReplaceQuestionChapterDropdown";
import { useLocation } from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import amplitude from "amplitude-js";
import { useForm } from "react-hook-form";
import magicianImage from "../../images/magician.gif";
import ReplaceQuestionDialogue from "./ReplaceQuestionDialogue";
import Setting from "../../images/settings.png";
import Cross from "../../images/cross.png";
import QuizRawResponseAccordian from "../QuizRawResponseAccordian";

function ReplaceDialogue(props) {
  const [chapters, setChapters] = useState(props.chapterList);
  var topic = [];
  const [topics, setTopics] = useState([]);
  const [checkedTopicList, setCheckedTopicList] = useState([]);
  const [subTopics, setSubTopics] = useState([]);
  const question_answers = useRef([]);
  const sub_topic_ids_csv = useRef([]);
  const creation_tokens = useRef(0);
  const quiz_generation_ids_list = useRef([]);
  const [openViewLogsDialog, setOpenViewLogsDialog] = React.useState(false);

  const [response_data, setResponseData] = useState([]);
  const [mock_exam, setMockExam] = useState(null);
  const [quizDuration, setQuizDuration] = useState(1);
  const sub_topic_name = useRef([]);
  const [open, setOpen] = React.useState(false);
  const checkListLength = useRef(0);
  const [openReplaceQuestionDialogue, setOpenReplaceQuestionDialogue] = React.useState(false);
  const [oneMark, setOneMark] = useState(0);
  const [fourMark, setFourMark] = useState(0);
  const [eighteMark, setEightMarks] = useState(0);
  const userType = localStorage.getItem("user_type");

  const quizTypesList = useRef([]);
  const { handleSubmit, formState } = useForm();

  const location = useLocation();
  let { formativeQuizTypeStatus } = location.state !== null ? location.state : "";
  let { hasMockExams } = location.state !== null ? location.state : "";
  let { hasExamStylePrompt } = location.state !== null ? location.state : "";
  const selectedContentCount = useRef(0);
  const countForReplacedQuestionGeneration = useRef(0);
  const [selectedQuizType, setSelectedQuizType] = useState(formativeQuizTypeStatus ? 1 : hasMockExams ? 2 : hasExamStylePrompt ? 3 : null);
  const numberOfQuestionSelectionCount = useRef(0);
  const checkedSubTopicList = useRef([]);
  const selectedTopicId = useRef(-1);

  const [blackListedQuestionArrayToShow, setBlackListedQuestionArrayToShow] = useState([]);
  const selectFieldStyles = {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid",
      borderColor: "black",
      // Change the border color to your desired focus color
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#79747e", // Change the normal border color here
    },
    "& .MuiSelect-select.MuiSelect-select": {
      marginTop: "5px",
      marginLeft: "13px",
      color: "#474747", // Change the color of the selected option(s)
      fontSize: "18px",
      fontWeight: "399",
      fontFamily:
        'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"', // Change the font size of the selected option(s)
    },
  };

  const [quizDetails, setQuizDetails] = useState({
    chapter_id: -1,
    topic_id: [],
    subTopic: [],
    numOfQuestions: 0,
    quizType: props.quizType,
  });
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const aiEnabled = useRef(false);
  const handleOnChange = (e) => {
    if (e.target.name === "chapter") {
      setCheckedTopicList([]);
      setNumberOfQuestions(0);
      setSubTopics([]);
      quizDetails.subTopic = [];
      quizDetails.topic_id = [];
      const selectedChapter = chapters.filter((chapter) => chapter.chapter_name === e.target.value);
      if (selectedChapter.length === 0) {
        return;
      }
      if (props.quizType === 1) {
        aiEnabled.current = selectedChapter[0].ai_enabled_formative;
      } else {
        aiEnabled.current = selectedChapter[0].ai_enabled;
      }
      setTopics(topic);
      console.log("Selected Component :", selectedChapter[0].chapter_id);
      axios
        .request({
          method: "POST",
          url: process.env.REACT_APP_REST_API_BASE_URL + "/get_topics",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
          data: JSON.stringify({
            chapterId: selectedChapter[0].chapter_id,
            chapterName: selectedChapter[0].chapter_name,
            selected_quiz_type: quizDetails.quizType,
          }),
        })
        .then((res) => {
          console.log(res.data);
          topic = topic.concat(res.data);
          console.log(topic);
          setTopics(topic);
          if (props?.is_code === 1) {
            setNumberOfQuestions(3);
            quizDetails.numOfQuestions = 1;
          }
        });
      return;
    }

    if (e.target.name === "topic") {
      const {
        target: { value },
      } = e;
      setCheckedTopicList(typeof value === "string" ? value.split(",") : value);

      const selectedTopicIds = topics
        .filter((topic) => value.includes(topic.topic_name))
        .map((topic) => topic.topic_id)
        .join(",");
      if (!selectedTopicIds) {
        setSubTopics([]);
        quizDetails.subTopic = [];
        quizDetails.topic_id = [];
        setNumberOfQuestions(0);
        quizDetails.numOfQuestions = 0;
        return; // Exit the function as there's no need to make an API call
      }
      axios
        .request({
          method: "POST",
          url: process.env.REACT_APP_REST_API_BASE_URL + "/get_sub_topics",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
          data: JSON.stringify({
            topic_id: selectedTopicIds,
            selected_quiz_type: quizDetails.quizType,
          }),
        })
        .then((res) => {
          const subtopics = JSON.parse(res.data.sub_topic_list);
          setSubTopics(subtopics);
          quizDetails.subTopic = subtopics.map((sub) => sub.sub_topic_id);
          quizDetails.topic_id = selectedTopicIds;

          const totalQuestions = subtopics.reduce((acc, sub) => acc + sub.number_of_predefined_questions, 0);
          setNumberOfQuestions(totalQuestions);
          quizDetails.numOfQuestions = 1;
        });
    }

    if (e.target.name === "subTopicCheckBox") {
      if (e.target.checked === true) {
        if (selectedContentCount.current + parseInt(e.target.alt) > 9000) {
          e.target.checked = false;
          document.getElementById("sub_topics_container").style.borderColor = "red";
          return;
        }

        selectedContentCount.current += parseInt(e.target.alt);

        quizDetails.subTopic.push(e.target.value);

        var subTopicObj = null;

        for (let index = 0; index < subTopics.length; index++) {
          if (subTopics[index].sub_topic_id === parseInt(e.target.value)) {
            subTopicObj = subTopics[index];
            break;
          }
        }

        if ((!aiEnabled.current && props.quizType === 1) || (!aiEnabled.current && props.quizType === 3)) {
          setNumberOfQuestions(numberOfQuestions + subTopicObj.number_of_predefined_questions);
        } else {
          setNumberOfQuestions(3);
        }
      } else {
        var subTopicIndex = quizDetails.subTopic.indexOf(e.target.value);
        selectedContentCount.current -= parseInt(e.target.alt);

        var subTopicObj = null;

        for (let index = 0; index < subTopics.length; index++) {
          if (subTopics[index].sub_topic_id === parseInt(e.target.value)) {
            subTopicObj = subTopics[index];
            break;
          }
        }
        setNumberOfQuestions(numberOfQuestions - subTopicObj.number_of_predefined_questions);

        if (subTopicIndex !== -1) {
          quizDetails.subTopic.splice(subTopicIndex, 1);
        }
      }

      console.log("Characters count of selected sub-topic is: " + selectedContentCount.current);
      return;
    }

    if (e.target.name === "numOfQuestions") {
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        quizDetails.numOfQuestions = e.target.value;
        setQuizDetails({ ...quizDetails, [e.target.name]: e.target.value });
      }
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const topicList = topics.map((topic) => (
    <MenuItem key={topic.topic_id} value={topic.topic_name}>
      <Checkbox checked={checkedTopicList.indexOf(topic.topic_name) > -1} />
      <Tooltip title={topic.topic_name}>
        <ListItemText
          primary={
            <Box
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%", // Adjust maxWidth as per your design
              }}
            >
              {topic.topic_name}
            </Box>
          }
        />
      </Tooltip>
    </MenuItem>
  ));

  const subTopicList = subTopics.map((subTopic, index) => (
    <li key={index}>
      <div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <input
          id={index}
          type="checkbox"
          name="subTopicCheckBox"
          alt=""
          onChange={handleOnChange}
          value={subTopic.sub_topic_id}
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
        />

        <label htmlFor={index} className="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-900">
          {subTopic.sub_topic_name}
        </label>
      </div>
    </li>
  ));

  const { isSubmitting } = formState;

  const AccountStatus = {
    TRIAL_ACTIVE: 1,
    TRIAL_EXPIRED: 2,
    SUBSCRIPTION_ACTIVE: 3,
    SUBSCRIPTION_EXPIRED: 4,
    SPECIAL_CONDITION: 5,
  };

  const handleAccontStatus = (status, object) => {
    if (AccountStatus.TRIAL_ACTIVE === status) {
      return 1;
    } else if (AccountStatus.TRIAL_EXPIRED === status) {
      return 1;
    } else if (AccountStatus.SUBSCRIPTION_ACTIVE === status) {
      return 3;
    } else if (AccountStatus.SPECIAL_CONDITION === status) {
      if (object.subscription_days_left) {
        return 3;
      } else {
        return 1;
      }
    }
  };
  const handleQuestionCategory = (category) => {
    if (props?.is_code === 0) {
      if (props.selectedQuestionForReplacement.question_marks >= 8 && category === 3) {
        return parseInt(quizDetails.numOfQuestions);
      } else if (
        props.selectedQuestionForReplacement.question_marks >= 4 &&
        props.selectedQuestionForReplacement.question_marks < 8 &&
        category === 2
      ) {
        return parseInt(quizDetails.numOfQuestions);
      } else if (
        props.selectedQuestionForReplacement.question_marks >= 1 &&
        props.selectedQuestionForReplacement.question_marks < 4 &&
        category === 1
      ) {
        return parseInt(quizDetails.numOfQuestions);
      } else {
        return 0;
      }
    } else {
      if (props.selectedQuestionForReplacement.question_marks === 7 && category === 1) {
        return parseInt(quizDetails.numOfQuestions);
      } else if (props.selectedQuestionForReplacement.question_marks === 8 && category === 2) {
        return parseInt(quizDetails.numOfQuestions);
      } else if (props.selectedQuestionForReplacement.question_marks === 15 && category === 3) {
        return parseInt(quizDetails.numOfQuestions);
      } else {
        return 0;
      }
    }
  };
  function submitForm(data) {
    question_answers.current = [];
    sub_topic_ids_csv.current = "";
    creation_tokens.current = "";
    quiz_generation_ids_list.current = [];
    setResponseData(question_answers.current);
    setMockExam(null);
    var blackListedQuestionsId = [];
    var blackListedQuestionsToAvoid = [];
    if ((props.quizType === 3 && !aiEnabled.current) || (props.quizType === 1 && !aiEnabled.current)) {
      for (let index = 0; index < props.blackListedQuestionArray.current.length; index++) {
        const element = props.blackListedQuestionArray.current[index];
        blackListedQuestionsId.push(element.predefined_question_answer_id.toString());
      }
      console.log("BLACKLISTED QUESTIONS ARE: ", blackListedQuestionsId);
    }
    let condition_1 = quizDetails.subTopic.length > 0 && quizDetails.numOfQuestions > 0 && quizDetails.numOfQuestions <= 10;
    let condition_2 = quizDetails.numOfQuestions > 0 && quizDetails.numOfQuestions <= 10;
    // handleClickScroll();
    return new Promise((resolve) => {
      if (props.is_code === 0 ? condition_1 : condition_2) {
      } else {
        if (props.quizType === 1 || props.quizType === 3) {
          var msg = "Please enter all details";
          if (quizDetails.numOfQuestions > 0) {
            msg = "Number of questions must be less than or equal to 1 ";
          }
          alert(msg);
          resolve();
          return;
        }
      }
      const topicIdsArray = Object.values(topics).map((topic) => topic.topic_id);
      console.log("SUBTOPIS ARE: ", quizDetails.topic_id, props.selectedQuestionForReplacement);
      //const sub_topic_id_list = [1, 2]
      var token = "Bearer " + localStorage.getItem("access_token");
      axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
      axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.post["authorization"] = token;
      // lockFields.current = true;
      axios
        .post(process.env.REACT_APP_REST_API_BASE_URL + "/generate_quiz", {
          method: "POST",
          subject_id: localStorage.getItem("selected_subject_id"),
          sub_topic_id_list: props?.is_code === 0 ? quizDetails.topic_id.split(",").map(Number) : topicIdsArray,
          number_of_questions: quizDetails.numOfQuestions,
          quiz_type: props.quizType,
          question_ids: blackListedQuestionsId,
          quiz_status: 3,
          exam_paper_quiz_status: 0,
          account_status: props.bannerObject ? handleAccontStatus(props.bannerObject.account_status, props.bannerObject) : null,
          is_task: true,
          one_to_three: handleQuestionCategory(1),
          four_to_seven: handleQuestionCategory(2),
          eight_plus: handleQuestionCategory(3),
          code_status: props?.is_code,
        })
        .then((response) => {
          handleClickOpenReplacementQuestionDialogue();
          console.log(response);
          amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
          amplitude
            .getInstance()
            .logEvent(
              "Generate Quiz [" + (quizDetails.quizType === 2 ? "mini-mocks (beta)]" : quizDetails.quizType === 3 ? "Exam Style]" : "Formative]")
            );

          if (props.quizType === 2) {
            var mock_exam = JSON.parse(response.data["mock_exam"]);
            setMockExam(mock_exam);
            setQuizDuration(mock_exam.mock_exam_duration);
            setResponseData([]);
          } else if (props.quizType === 1 || props.quizType === 3) {
            if (response.data["question_answers"].length !== 0) {
              var question_answers_list = JSON.parse(response.data["question_answers"]);

              var sub_topics = response.data["sub_topic_ids_csv"];
              console.log(question_answers_list);
              question_answers.current = question_answers_list;
              if (question_answers.current.length !== 0) {
                countForReplacedQuestionGeneration.current += 1;
              }
              setBlackListedQuestionArrayToShow(blackListedQuestionArrayToShow.concat(question_answers.current));
              console.log("BLACK LISTED QUESTION TO SHOW: ", blackListedQuestionArrayToShow);
              props.blackListedQuestionArray.current = props.blackListedQuestionArray.current.concat(question_answers.current);
              console.log("AFTER APPENDING: ", props.blackListedQuestionArray.current);
              sub_topic_ids_csv.current = sub_topics;
              creation_tokens.current = response.data["creation_tokens"];
              quiz_generation_ids_list.current = JSON.parse(response.data["quiz_generation_ids_list"]);
              // sub_topic_name.current = response.data["sub_topic_name"];
              // console.log("SUBTOPIC NAMES ARE: ", sub_topic_name.current);

              setResponseData(question_answers.current);
            }
          }

          //quizType.current=quizDetails.quizType;

          //question_answers.current = response.data['question_answers'];
          //setResponseData(question_answers.current)

          resolve();
          // handleClickScroll();
          // lockFields.current = false;
        })
        .catch((error) => {
          resolve();
          // lockFields.current = false;
          console.log(error);
          if (error.code === "ERR_BAD_RESPONSE") {
            setOpen(true);
          }
        });
    });
  }

  const styles = {
    dialogPaper: {
      minHeight: "80vh",
      maxHeight: "80vh",
    },
  };

  const handleClickOpenReplacementQuestionDialogue = () => {
    setOpenReplaceQuestionDialogue(true);
  };

  const handleCloseReplacementQuestionDialogue = () => {
    setOpenReplaceQuestionDialogue(false);
  };

  useEffect(() => {
    if (props.openReplaceDialogue === false) {
      setTopics([]);
      setCheckedTopicList([]);
      setSubTopics([]);
      setNumberOfQuestions(0);
    }
  }, [props.openReplaceDialogue]);

  const handleViewLogDialogCloseWithCancel = () => {
    setOpenViewLogsDialog(false);
  };
  const handleRawResponseClick = () => {
    setOpenViewLogsDialog(true);
  };

  useEffect(() => {
    if (openReplaceQuestionDialogue === false) {
      if (props.is_code === 0) {
        setCheckedTopicList([]);
        setSubTopics([]);
        setNumberOfQuestions(0);
        quizDetails.subTopic = [];
      } else {
        setCheckedTopicList([]);
        setSubTopics([]);
        quizDetails.subTopic = [];
      }
    }
  }, [openReplaceQuestionDialogue]);

  const handleClose = () => {
    setCheckedTopicList([]);
    setSubTopics([]);
    setNumberOfQuestions(0);
    quizDetails.subTopic = [];
    props.handleClose();
  };

  return (
    <div id="replaceDialogue">
      <Dialog
        className="overflow-y-hidden"
        open={openViewLogsDialog}
        onClose={handleViewLogDialogCloseWithCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <b>Raw Response</b>
        </DialogTitle>
        <DialogContent className="overflow-y-hidden">
          <QuizRawResponseAccordian quizEvaluationLog={quiz_generation_ids_list.current} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewLogDialogCloseWithCancel}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        // PaperProps={{
        //   sx: {
        //     width: "800px",
        //     maxWidth: "800px",
        //     height: "690px",
        //     maxHeight: "700px",
        //     padding: "20px",
        //   },
        // }}
        PaperProps={{
          sx: {
            width: {
              xs: "95vw",  // On smaller viewports, occupy most of the screen
              sm: "80vw",
              md: "70vw",
              lg: "800px"  // Only use 800px max on larger screens
            },
            maxWidth: "95vw",
            height: "85vh",
            // maxHeight: "95vh",  // Take up to 95% of viewport height
            padding: "20px",
            overflowY: "auto",  // Scroll if content exceeds height
          },
        }}
        
        open={props.openReplaceDialogue}
        onClose={props.handleClose}
        // BackdropProps={{ style: { backgroundColor: "black" } }}
      >
        <DialogTitle className="flex">
          {!isSubmitting && <div className="text-center w-[100%]">Create Replacement Question</div>}
          <button onClick={() => handleClose()} style={{ marginLeft: "auto" }}>
            <img src={Cross}></img>
          </button>
        </DialogTitle>
        <hr className="mr-[20px] ml-[20px]"></hr>
        <DialogContent>
          <div style={{ display: isSubmitting ? "none" : "block" }}>
            <div>
              <label className="create_quiz_label">1. Select the topic of your choice.</label>
            </div>
            <ReplaceQuestionChapterDropdown chapterList={chapters} handleOnChange={handleOnChange} />
            {props?.is_code === 0 && (
              <div>
                <label className="create_quiz_label mt-[10px]">2. Select your preferred subtopic.</label>
              </div>
            )}
            <div>
              <FormControl
                // style={{ width: "100%", margin: "10px 10px 20px 0px" }}
                sx={selectFieldStyles}
                className="w-[100%] pb-[10px]"
              >
                {checkedTopicList.length === 0 && props?.is_code === 0 ? (
                  <InputLabel
                    shrink={false}
                    style={{
                      marginTop: "-4px",
                      fontSize: "20px",
                      marginLeft: "14px",
                      color: "#474747",
                      fontWeight: "300",
                      fontFamily:
                        'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
                    }}
                  >
                    Select subtopic
                  </InputLabel>
                ) : null}

                <Select
                  style={{ height: "50px", display: props?.is_code === 0 ? "" : "none" }}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  name="topic"
                  value={checkedTopicList}
                  onChange={handleOnChange}
                  // input={<OutlinedInput label="Select Topic" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {topicList}
                </Select>
              </FormControl>
            </div>
            {/* <div>
              <label className="create_quiz_label mt-[13px]">3. Choose your desired areas.</label>
            </div> */}
            {/* <ReplaceQuestionSubTopicCheckbox subTopicList={subTopics} /> */}
            <div>
              {/* <div className="subtopic_checkbox">
                <div
                  id="sub_topics_container"
                  className="p-2.5 bg-white shadow-sm outline-none rounded-[8px] border-[1px] border-[#474747] w-full h-[7srem] text-[#474747] leading-[30px] text-[20px] font-[500] mb-1"
                >
                  <ul className="h-24 px-3 overflow-y-auto text-sm text-black dark:text-black" aria-labelledby="dropdownSearchButton">
                    {subTopicList}
                  </ul>
                </div>
              </div> */}
            </div>

            <div className="mb-5">
              <div>
                <label className="create_quiz_label mt-[10px]">
                  {props?.is_code === 0 ? "3." : "2."} Select the number of substitute questions you'd like to review
                </label>
              </div>

              <div>
                <div>
                  <select
                    id="number_of_questions"
                    value={quizDetails.numOfQuestions}
                    name="numOfQuestions"
                    onChange={handleOnChange}
                    className="p-2.5 bg-white shadow-sm outline-none rounded-[5px] border-[1px] border-[#474747] w-full h-[50px] text-[#474747] leading-[30px] text-[20px] font-[500] px-4"
                  >
                    {[...Array(numberOfQuestions > 3 ? 3 : numberOfQuestions)]
                      .map((_, i) => i + 1)
                      .map((i) => (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          {isSubmitting && (
            <div id="quiz-section" className="w-[100%] sm:pt-[30px] ">
              <img alt="" src={magicianImage} style={{ margin: "auto", width: "180px" }} className="sm:pt-[30px] md:pt-0"></img>
              <p
                style={{
                  fontSize: "21px",
                  fontFamily: "Roboto",
                  fontStyle: "Bold",
                  fontWeight: "500",
                  textAlign: "center",
                  marginTop: "10px",
                  marginBottom: "5px",
                }}
              >
                Magically generating task
              </p>

              <p
                style={{
                  fontSize: "16px",
                  marginBottom: "100px",
                  fontFamily: "Roboto",
                  textAlign: "center",
                  marginTop: "1px",
                }}
              >
                Our AI can take up to 60 seconds…
              </p>
            </div>
          )}
          <ReplaceQuestionDialogue
            open={openReplaceQuestionDialogue}
            handleClose={handleCloseReplacementQuestionDialogue}
            handleOpen={handleClickOpenReplacementQuestionDialogue}
            response_data={response_data}
            selectedQuizType={props.quizType}
            mock_exam={mock_exam}
            // sub_topic_name={sub_topic_name}
            questionIndex={props.questionIndex}
            replaceQuestion={props.replaceQuestion}
            blackListedQuestionArray={props.blackListedQuestionArray.current}
            handleRawResponseClick={handleRawResponseClick}
            blackListedQuestionArrayToShow={blackListedQuestionArrayToShow}
            handleCloseParentDialogue={props.handleClose}
            quiz_generation_ids_list={quiz_generation_ids_list}
            countForReplacedQuestionGeneration={countForReplacedQuestionGeneration.current}
            selectedQuestionForReplacement={props.selectedQuestionForReplacement}
            isAnswerRubric={props.isAnswerRubric}
          />
        </DialogContent>
        {!isSubmitting ? (
          <DialogActions
            style={{
              justifyContent: "space-between",
              marginLeft: "15px",
              marginRight: "15px",
            }}
          >
            <button style={{ width: "120px" }} className="teepee-back-button sm:w-[44%] md:w-[46%]" onClick={props.handleClose}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div>Cancel</div>
              </div>
            </button>
            <button
              style={{ width: "110px" }}
              id="button"
              onClick={handleSubmit(submitForm)}
              className="teepee-button md:ml-12 hover:bg-black sm:w-[50%] md:w-[46%]"
            >
              Generate
            </button>{" "}
          </DialogActions>
        ) : (
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "start",
              marginLeft: "20px",
            }}
          >
            {userType === "2" ? (
              <>
                <div className="mr-[10px]">
                  <img width={"20px"} src={Setting}></img>
                </div>
                <button style={{ width: "120px" }} className=" sm:w-[44%] md:w-[46%]" onClick={handleRawResponseClick}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      float: "left",
                    }}
                  >
                    <div>View logs</div>
                  </div>
                </button>
              </>
            ) : (
              ""
            )}
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}

export default ReplaceDialogue;
