import React, { useContext, useState } from "react";
import Joyride from "react-joyride";
import Modal from "react-modal";
import "../../../OnboardingTour.css";
import VideoPlayer from "../../widgets/VideoPlayer";
import AssignedTaskAndDrafts from "../../../images/videos/TasksDashboardAndAssignedTasks.mp4";
import ClassAnalyticsVideo from "../../../images/videos/ClassAnalytics.mp4";
import PupilsAndStaff from "../../../images/videos/PupilsAndStaff.mp4";
import CheckCircle from "../../../images/check_circle.svg";
import { useTab } from "../../Teacher/context/TabContext";
import appContext from "../../../context/appContext";

Modal.setAppElement("#root"); // Required for accessibility
const OnboardingTourInside = ({ disableTour }) => {
  console.log("OnboardingTourInside Rendered");
  const [stepIndex, setStepIndex] = useState(0);
  const [runTour, setRunTour] = useState(true);
  const { setTabState } = useTab();
  const state = useContext(appContext);

  // const startTour = () => {
  //   setModalIsOpen(false);
  //   setRunTour(true);
  // };

  const onClickRestart = () => {
    setStepIndex(0);
    setTabState("1");
    state.setDashboardTabsState(1);
  };

  const steps = [
    {
      target: ".start-tour-inside",
      // placement: "center",
      content: (
        <div className="">
          <div className="pt-[1rem] pr-[0.75rem] pl-[0.75rem]">
            <div className="my-[1.625rem]">
              <div className="flex text-[1rem] leading-[1.75rem] tracking-[0.009rem] font-semibold text-start mr-[2rem]">
                <img src={CheckCircle} className="w-[1.125rem] h-[1.125rem] mr-[0.375rem] self-start mt-[0.4rem]" />
                <div>Step 1: Copy & Share Your Class Code</div>
              </div>
              <div className="text-start pl-[1.25rem] pr-[1.25rem]">
                <div className="flex mt-[1.25rem]">
                  <div className="leading-[1.25rem] tracking-[0.006rem] text-[0.875rem]">
                    <strong>📌 Your Class Code: </strong> Every class has a unique code that students can use to join.
                  </div>
                </div>
                <div className="flex mt-[1.25rem]">
                  <div className="leading-[1.25rem] tracking-[0.006rem] text-[0.875rem]">
                    <strong>🔹 Action: </strong>Click the copy icon 📋 next to the class code.
                  </div>
                </div>
                <div className="flex mt-[1.25rem]">
                  <div className="leading-[1.25rem] tracking-[0.006rem] text-[0.875rem]">
                    <strong>🔹 Share with Students: </strong> Send the copied code to your students so they can join your class instantly.
                  </div>
                </div>
                <div className="text-start mt-[1.375rem] leading-[1.5rem] tracking-[0.006rem] text-[0.875rem]">
                  <strong>💡 Tip: </strong> Students can enter this code when signing up to get added to your class automatically!
                </div>
              </div>
            </div>
          </div>
          <hr className="!m-0"></hr>
          <div className="modal-buttons pl-[1.5rem] pr-[1.5rem] pt-[0.5rem] mt-2">
            <button
              className="skip-btn"
              onClick={() => {
                setRunTour(false);
                sessionStorage.setItem("insideTourStatus", true);
              }}
            >
              Skip for Now
            </button>
            <p className="step-count">1 of 6</p>
            <button
              disabled={disableTour}
              className={`${
                disableTour
                  ? "text-[#1C1B1F] text-opacity-50 !font-medium !text-[0.875rem] pt-[0.5rem] pb-[0.5rem] pl-[1rem] pr-[1rem] rounded-[5px] bg-[rgba(28,27,31,0.12)]"
                  : "teepee-button !font-medium !text-[0.875rem]"
              }`}
              onClick={() => {
                setStepIndex(1);
              }}
            >
              Next
            </button>
          </div>
        </div>
      ),
      disableBeacon: true,
      spotlightClicks: false,
      placement: "top",
    },
    {
      target: ".Tasks-tour-step",
      placement: "top",
      disableBeacon: true,
      spotlightClicks: true,
      content: (
        <div>
          <div className="pt-[1rem] pr-[0.75rem] pl-[0.75rem]">
            <div className="my-[1.625rem]">
              <div className="flex text-[1rem] leading-[1.75rem] tracking-[0.009rem] font-semibold">
                <img src={CheckCircle} className="w-[1.125rem] h-[1.125rem] mr-[0.375rem] mt-[0.4rem]" />
                <div>Step 2: Explore the Tasks Section</div>
              </div>
              <div className="text-start pl-[1.25rem] pr-[1.25rem]">
                <div className="mt-[0.25rem] leading-[1.5rem] tracking-[0.006rem] text-[0.875rem]">📌  Your central hub for managing tasks!</div>
                <div className="flex mt-[1.25rem]">
                  <div className="leading-[1.25rem] tracking-[0.006rem] text-[0.875rem]">Inside the Tasks section, you’ll find:</div>
                </div>
                <div className="flex mt-[1.25rem]">
                  <div className="leading-[1.25rem] tracking-[0.006rem] text-[0.875rem]">
                    <strong>🔹 Assigned Tasks</strong> – View tasks already given to students.
                  </div>
                </div>
                <div className="flex mt-[1.25rem]">
                  <div className="leading-[1.25rem] tracking-[0.006rem] text-[0.875rem]">
                    <strong>🔹 Drafts </strong>– Access unfinished tasks that you can edit later.
                  </div>
                </div>
                <div className="flex mt-[1.25rem]">
                  <div className="leading-[1.25rem] tracking-[0.006rem] text-[0.875rem]">
                    <strong>🔹 Class Analytics</strong> – Track student performance and progress.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="!m-0"></hr>
          <div className="modal-buttons pl-[1.5rem] pr-[1.5rem] pt-[0.5rem]  mt-2">
            <button className="skip-btn" onClick={onClickRestart}>
              Restart
            </button>
            <p className="step-count">2 of 6</p>
            <button
              disabled={disableTour}
              className={`${
                disableTour
                  ? "text-[#1C1B1F] text-opacity-50 !font-medium !text-[0.875rem] pt-[0.5rem] pb-[0.5rem] pl-[1rem] pr-[1rem] rounded-[5px] bg-[rgba(28,27,31,0.12)]"
                  : "teepee-button !font-medium !text-[0.875rem]"
              }`}
              onClick={() => {
                setStepIndex(2);
              }}
            >
              Next
            </button>
          </div>
        </div>
      ),
    },
    {
      target: ".assigned-task-and-draft",
      placement: "bottom",
      disableBeacon: true,
      spotlightClicks: true,
      content: (
        <div>
          <div className="pt-[2.375rem] pr-[0.75rem] pl-[0.75rem]">
            <div>
              <VideoPlayer source={AssignedTaskAndDrafts} />
            </div>
            <div className="my-[1.625rem]">
              <div className="flex text-[1rem] leading-[1.75rem] tracking-[0.009rem] font-semibold text-start">
                <img src={CheckCircle} className="w-[1.125rem] h-[1.125rem] mr-[0.375rem] self-start mt-[0.4rem]" />
                <div>Step 3: Understanding Assigned Tasks & Drafts</div>
              </div>
              <div className="text-start pl-[1.25rem] pr-[1.25rem]">
                <div className="mt-[0.25rem] leading-[1.5rem] tracking-[0.006rem] text-[0.875rem]">📌  Manage all your tasks in one place!</div>
                <div className="flex mt-[1.25rem]">
                  <div className="leading-[1.25rem] tracking-[0.006rem] text-[0.875rem]">
                    <strong>🔹 Assigned Tasks</strong> - Track student progress and results.
                  </div>
                </div>
                <div className="flex mt-[1.25rem]">
                  <div className="leading-[1.25rem] tracking-[0.006rem] text-[0.875rem]">
                    <strong>🔹 Drafts </strong>– Delete and assign saved tasks when ready.
                  </div>
                </div>
                <div className="flex mt-[1.25rem]">
                  <div className="text-start leading-[1.5rem] tracking-[0.006rem] text-[0.875rem]">
                    <strong>💡 Tip: </strong> Keep tasks organized for easy management! 🚀
                  </div>
                </div>
                {/* <div className="flex mt-[1.25rem]">
                  <div className="leading-[1.25rem] tracking-[0.006rem] text-[0.875rem]">
                    <strong>👉 Action:</strong> Click "Assigned Tasks" to monitor students or "Drafts" to assign tasks.
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <hr className="!m-0"></hr>
          <div className="modal-buttons pl-[1.5rem] pr-[1.5rem] pt-[0.5rem] mt-2">
            <button className="skip-btn" onClick={onClickRestart}>
              Restart
            </button>
            <p className="step-count">3 of 6</p>
            <button
              disabled={disableTour}
              className={`${
                disableTour
                  ? "text-[#1C1B1F] text-opacity-50 !font-medium !text-[0.875rem] pt-[0.5rem] pb-[0.5rem] pl-[1rem] pr-[1rem] rounded-[5px] bg-[rgba(28,27,31,0.12)]"
                  : "teepee-button !font-medium !text-[0.875rem]"
              }`}
              onClick={() => {
                setTabState("2");
                setTimeout(() => {
                  setStepIndex(3);
                }, 20);
              }}
            >
              Next
            </button>
          </div>
        </div>
      ),
    },
    {
      target: ".class-analytics-tour",
      placement: "bottom",
      disableBeacon: true,
      spotlightClicks: true,
      content: (
        <div>
          <div className="pt-[2.375rem] pr-[0.75rem] pl-[0.75rem]">
            <div>
              <VideoPlayer source={ClassAnalyticsVideo} />
            </div>
            <div className="my-[1.625rem]">
              <div className="flex text-[1rem] leading-[1.75rem] tracking-[0.009rem] font-semibold text-start">
                <img src={CheckCircle} className="w-[1.125rem] h-[1.125rem] mr-[0.375rem] self-start mt-[0.4rem]" />
                <div>Step 4: Analyze Class Performance</div>
              </div>
              <div className="text-start pl-[1.25rem] pr-[1.25rem]">
                <div className="mt-[0.325rem] leading-[1.5rem] tracking-[0.006rem] text-[0.875rem]">📌 Gain insights into student progress!</div>
                {/* <div className="flex mt-[1.25rem]">
                  <div className="leading-[1.25rem] tracking-[0.006rem] text-[0.875rem]">
                    <strong>🔹 Filters Available:</strong> Adjust analytics by<strong> exam style, date, topic, </strong> or subtopic to track student
                    performance.
                  </div>
                </div> */}
                <div className="flex mt-[1.25rem]">
                  <div className="leading-[1.25rem] tracking-[0.006rem] text-[0.875rem]">
                    <strong>👉 Action:</strong> Use the filters to refine your data.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="!m-0"></hr>
          <div className="modal-buttons pl-[1.5rem] pr-[1.5rem] pt-[0.5rem] mt-2">
            <button className="skip-btn" onClick={onClickRestart}>
              Restart
            </button>
            <p className="step-count">4 of 6</p>
            <button
              disabled={disableTour}
              className={`${
                disableTour
                  ? "text-[#1C1B1F] text-opacity-50 !font-medium !text-[0.875rem] pt-[0.5rem] pb-[0.5rem] pl-[1rem] pr-[1rem] rounded-[5px] bg-[rgba(28,27,31,0.12)]"
                  : "teepee-button !font-medium !text-[0.875rem]"
              }`}
              onClick={() => {
                state.setDashboardTabsState(2);
                setTimeout(() => {
                  setStepIndex(4);
                }, 20);
              }}
            >
              Next
            </button>
          </div>
        </div>
      ),
    },
    {
      target: ".Pupils-tour-step",
      placement: "center-right",
      disableBeacon: true,
      spotlightClicks: true,
      content: (
        <div>
          <div className="pt-[1rem] pr-[0.75rem] pl-[0.75rem]">
            <div className="my-[1.625rem]">
              <div className="flex text-[1rem] leading-[1.75rem] tracking-[0.009rem] font-semibold">
                <img src={CheckCircle} className="w-[1.125rem] h-[1.125rem] mr-[0.375rem] mt-[0.4rem]" />
                <div>Step 5: Pupils & Staff</div>
              </div>
              <div className="text-start pl-[1.25rem] pr-[1.25rem]">
                <div className="mt-[0.25rem] leading-[1.5rem] tracking-[0.006rem] text-[0.875rem]">📌  Simplify classroom management!</div>
                <div className="flex mt-[1.25rem]">
                  <div className="leading-[1.25rem] tracking-[0.006rem] text-[0.875rem]">
                    <strong>👩‍🏫 Manage Students –</strong> Add or remove students and adjust their access.
                  </div>
                </div>
                <div className="flex mt-[1.25rem]">
                  <div className="leading-[1.25rem] tracking-[0.006rem] text-[0.875rem]">
                    <strong>👨‍🏫 Manage Teachers –</strong> Add co-teachers so they can help manage the classroom too.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="!m-0"></hr>
          <div className="modal-buttons pl-[1.5rem] pr-[1.5rem] pt-[0.5rem] mt-2">
            <button className="skip-btn" onClick={onClickRestart}>
              Restart
            </button>
            <p className="step-count">5 of 6</p>
            <button
              disabled={disableTour}
              className={`${
                disableTour
                  ? "text-[#1C1B1F] text-opacity-50 !font-medium !text-[0.875rem] pt-[0.5rem] pb-[0.5rem] pl-[1rem] pr-[1rem] rounded-[5px] bg-[rgba(28,27,31,0.12)]"
                  : "teepee-button !font-medium !text-[0.875rem]"
              }`}
              onClick={() => {
                setStepIndex(5);
              }}
            >
              Next
            </button>
          </div>
        </div>
      ),
    },
    {
      target: ".manage-tour-step",
      placement: "right",
      disableBeacon: true,
      spotlightClicks: true,
      content: (
        <div>
          <div className="pt-[2.375rem] pr-[0.75rem] pl-[0.75rem]">
            <div>
              <VideoPlayer source={PupilsAndStaff} />
            </div>
            <div className="my-[1.625rem]">
              <div className="flex text-[1rem] leading-[1.75rem] tracking-[0.009rem] font-semibold text-start">
                <img src={CheckCircle} className="w-[1.125rem] h-[1.125rem] mr-[0.375rem] self-start mt-[0.4rem]" />
                <div>Step 6: Manage Pupils & Staff</div>
              </div>
              <div className="text-start pl-[1.25rem] pr-[1.25rem]">
                <div className="mt-[0.25rem] leading-[1.5rem] tracking-[0.006rem] text-[0.875rem]">📌 Simplify classroom management!</div>
                <div className="flex mt-[1.25rem]">
                  <div className="leading-[1.25rem] tracking-[0.006rem] text-[0.875rem]">
                    💡 Control who can join your classroom and collaborate with co-teachers.
                  </div>
                </div>
                <div className="flex mt-[1.25rem]">
                  <div className="leading-[1.25rem] tracking-[0.006rem] text-[0.875rem]">
                    <strong>👩‍🏫 Manage Students –</strong> Add or remove students and adjust their access.
                  </div>
                </div>
                <div className="flex mt-[1.25rem]">
                  <div className="leading-[1.25rem] tracking-[0.006rem] text-[0.875rem]">
                    <strong>👨‍🏫 Manage Teachers –</strong> Add co-teachers so they can help manage the classroom too.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="!m-0"></hr>
          <div className="modal-buttons pl-[1.5rem] pr-[1.5rem] pt-[0.5rem] mt-2">
            <button className="skip-btn" onClick={onClickRestart}>
              Restart
            </button>
            <p className="step-count">6 of 6</p>
            <button
              disabled={disableTour}
              className={`${
                disableTour
                  ? "text-[#1C1B1F] text-opacity-50 !font-medium !text-[0.875rem] pt-[0.5rem] pb-[0.5rem] pl-[1rem] pr-[1rem] rounded-[5px] bg-[rgba(28,27,31,0.12)]"
                  : "teepee-button !font-medium !text-[0.875rem]"
              }`}
              onClick={() => {
                setRunTour(false);
                sessionStorage.setItem("insideTourStatus", true);
                state.setDashboardTabsState(1);
                setTabState("1");
              }}
            >
              Finish Tour
            </button>
          </div>
        </div>
      ),
    },
  ];

  const handleJoyrideCallback = (data) => {
    const { action, index, status } = data;
    if (action === "close") {
      setRunTour(false);
      sessionStorage.setItem("insideTourStatus", true);
    }
    // if (action === "next" && stepIndex === 3) {
    //   setTabState("2");
    // }
    // if (action === "next" && stepIndex === 4) {
    //   state.setDashboardTabsState(2);
    // }
  };
  return (
    <>
      <Joyride
        steps={steps}
        stepIndex={stepIndex}
        run={runTour}
        continuous={true}
        showProgress={false}
        showSkipButton={false}
        hideBackButton={true}
        disableOverlayClose={true}
        disableBeacon={true}
        hideFooter={true}
        spotlightClicks={false}
        disableScrollParentFix={true}
        callback={handleJoyrideCallback}
        styles={{
          buttonClose: { width: "70%" },
          tooltipTitle: { padding: "1.5rem", textAlign: "start" },
          tooltipContent: { padding: "0px" },
          tooltip: {
            width: "22.75rem",
          },
          buttonNext: {
            display: "none",
          },
          options: {
            zIndex: 10000,
          },
          overlay: {
            pointerEvents: "auto", // :white_tick: Allows clicking on the shaded area
            background: "#0000005C", // :white_tick: Removes the shading effect
          },
        }}
      />
    </>
  );
};
export default OnboardingTourInside;
