import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircle from "../../../images/check_circle.svg";
import Pin from "../../../images/pin.svg";
import { FiArrowRight, FiEdit, FiEye, FiShare2 } from "react-icons/fi";

function AcknowledgementDialogue(props) {
  const title = props.title;
  const dialogueText = props.text;
  const handleCloseWithConfirm = props.handleCloseWithConfirm;
  const open = props.open;
  const showNewDialogue = props.showNewDialogue && props.showNewDialogue;
  const isDraft = props.isDraft && props.isDraft;
  const handlePreviewClicked = props.handlePreviewClicked && props.handlePreviewClicked;

  return !showNewDialogue ? (
    <Dialog
      PaperProps={{
        sx: {
          width: "370px",
          height: "224px",
        },
      }}
      open={open}
      // onClose={handleCloseWithConfirm}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{dialogueText}</DialogContentText>
      </DialogContent>
      <DialogActions className="mb-[20px]">
        <button className="teepee-button mr-[20px] " onClick={() => handleCloseWithConfirm()}>
          Okay
        </button>
      </DialogActions>
    </Dialog>
  ) : (
    <Dialog
      PaperProps={{
        sx: {
          width: "500px",
          borderRadius: "12px",
          padding: "36px",
          paddingTop: "0px",
        },
      }}
      open={open}
      // onClose={() => {
      //   handleCloseWithConfirm();
      // }}
      aria-labelledby="task-saved-dialog-title"
    >
      <DialogTitle>
        {/* <div className="flex justify-end items-end">
          <button
            onClick={() => {
              handleCloseWithConfirm();
            }}
            className="absolute top-4 right-8"
          >
            <CloseIcon style={{ fontSize: 20 }} />
          </button>
        </div> */}
      </DialogTitle>
      <DialogContent className="text-center">
        <div className="flex justify-center">
          <img src={isDraft ? Pin : CheckCircle} />
        </div>
        <div className="text-[24px] leading-[2rem] font-bold text-gray-900 mb-[0.25rem]">{title}</div>
        {isDraft ? (
          <p className="text-[16px] leading-[1.5rem] tracking-[0.15px] text-[#49454F]">
            You can find it under the <strong>'Drafts' tab</strong> on your dashboard.
          </p>
        ) : (
          <p className="text-[16px] leading-[1.5rem] tracking-[0.15px] text-[#49454F]">
            You can find it under the <strong>'Assigned' tab</strong> on your dashboard.
          </p>
        )}
      </DialogContent>
      <DialogActions className="flex flex-col gap-y-[0.5rem]">
        <button
          onClick={() => {
            handleCloseWithConfirm();
          }}
          className="teepee-button flex items-center gap-2 hover:bg-black w-full sm:w-[100%] justify-center"
        >
          <span className="flex items-center justify-center gap-2">
            {isDraft ? <FiEdit size={20} /> : <FiArrowRight size={20} />}
            {isDraft ? "Go to Drafts" : "Go to Assigned Tasks"}
          </span>
        </button>

        <button onClick={() => handlePreviewClicked()} className="teepee-back-button !ml-0 hover:bg-black hover:text-white w-[full] sm:w-[100%]">
          <span className="flex items-center justify-center gap-2">
            <FiEye size={20} />
            Preview Task as a Student
          </span>
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default AcknowledgementDialogue;
