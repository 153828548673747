import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle, memo } from "react";

const EditableTable = memo(
  forwardRef(({ columnList, rowList, isEditable, isAdmin }, ref) => {
    const [columns, setColumns] = useState(() =>
      columnList && columnList.length > 0
        ? [...columnList]
        : [
            { id: "column1", label: "Column 1" },
            { id: "column2", label: "Column 2" },
          ]
    );
    const [rows, setRows] = useState(() => (rowList && rowList.length > 0 ? rowList : [{ id: "1", column1: "", column2: "" }]));
    const inputRef = useRef(null);
    const columnInputRef = useRef(null);
    const isInputEditable = useRef([]);

    // Update state when props change
    useEffect(() => {
      console.log("Col List: ", columnList);
      console.log("Row List: ", rowList);
      if (columnList && columnList.length > 0) {
        setColumns([...columnList]);
      }
      if (rowList && rowList.length > 0) {
        setRows(rowList);
      }
      rowList.map((row, rowIndex) => {
        columnList.map((col, colIndex) => {
          let canEdit = row[col.id] === "";
          // console.log("test: ", test);
          if (!isInputEditable.current[rowIndex]) {
            isInputEditable.current[rowIndex] = [];
          }
          isInputEditable.current[rowIndex][colIndex] = canEdit;
        });
      });
      console.log("Testing: ", isInputEditable.current);
    }, [columnList, rowList]);

    const handleColumnChange = (e, columnId) => {
      const { value } = e.target;
      // Track the currently focused input
      columnInputRef.current = e.target;
      setColumns((prevColumns) => prevColumns.map((col) => (col.id === columnId ? { ...col, label: value } : col)));
    };

    useEffect(() => {
      // Restore focus to the column input after re-render
      if (columnInputRef.current) {
        columnInputRef.current.focus();
      }
    }, [columns]);

    const handleInputChange = (e, rowId, columnId) => {
      const { value } = e.target;
      setRows((prevRows) => prevRows.map((row) => (row.id === rowId ? { ...row, [columnId]: value } : row)));
      inputRef.current = e.target;
    };

    const addRow = () => {
      const newRow = {
        id: Date.now().toString(),
        ...columns.reduce((acc, col) => (col.id !== "sr" ? { ...acc, [col.id]: "" } : acc), {}),
      };
      setRows((prevRows) => [...prevRows, newRow]);
    };

    const removeRow = (rowId) => {
      setRows((prevRows) => prevRows.filter((row) => row.id !== rowId));
    };

    const addColumn = () => {
      const newColumn = { id: `col_${Date.now()}`, label: `Column ${columns.length + 1}` };
      setColumns((prevColumns) => [...prevColumns, newColumn]);
      setRows((prevRows) => prevRows.map((row) => ({ ...row, [newColumn.id]: "" })));
    };

    const removeColumn = (columnId) => {
      if (columns.length > 1) {
        setColumns((prevColumns) => prevColumns.filter((col) => col.id !== columnId));
        setRows((prevRows) =>
          prevRows.map((row) => {
            const updatedRow = { ...row };
            delete updatedRow[columnId];
            return updatedRow;
          })
        );
      }
    };

    // Expose a method to get the current table data
    useImperativeHandle(ref, () => ({
      getTableData: () => ({
        columns: columns,
        rows: rows,
      }),
    }));

    return (
      <div className="mb-4">
        <table className="border-collapse border border-black">
          <thead>
            <tr className="bg-[#E5E5E5]">
              {columns.map((col) => (
                <th key={col.id} className="text-lg p-2 w-[150px] max-w-[150px] border border-black text-left align-top">
                  <div className="flex flex-col w-full">
                    <textarea
                      ref={(el) => {
                        if (el) {
                          el.style.height = "auto"; // reset first
                          el.style.height = el.scrollHeight + "px"; // then grow
                        }
                      }}
                      disabled={!isEditable || !isAdmin}
                      value={col.label}
                      onChange={(e) => {
                        handleColumnChange(e, col.id);

                        // auto-resize logic
                        const el = e.target;
                        el.style.height = "auto"; // reset
                        el.style.height = el.scrollHeight + "px"; // grow to fit
                      }}
                      className="bg-transparent border-none outline-none w-full p-1 resize-none break-words whitespace-pre-wrap overflow-hidden leading-snug text-base"
                      rows={1}
                    />
                    {isEditable && isAdmin && columns.length > 1 && (
                      <button onClick={() => removeColumn(col.id)} className="mt-1 text-red-600 self-end text-sm">
                        ✖
                      </button>
                    )}
                  </div>
                </th>
              ))}
              {isEditable && isAdmin && <th className="text-lg p-3 border border-black">Actions</th>}
            </tr>
          </thead>

          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={row.id} className="even:bg-gray-50">
                {columns.map((col, colIndex) => (
                  <td key={col.id} className="border border-black align-top">
                    {isEditable && (isAdmin || isInputEditable.current[rowIndex]?.[colIndex]) ? (
                      <textarea
                        name={col.id}
                        value={row[col.id] || ""}
                        onChange={(e) => {
                          handleInputChange(e, row.id, col.id);

                          // Auto-resize textarea height
                          const el = e.target;
                          el.style.height = "auto";
                          el.style.height = el.scrollHeight + "px";
                        }}
                        // className="w-full p-2 text-base font-normal bg-transparent border border-black outline-none hover:bg-yellow-50 focus:ring-1 focus:ring-gray-400 resize-none break-words whitespace-pre-wrap overflow-hidden leading-snug"
                        className="w-full p-1 bg-transparent font-normal border-none outline-none resize-none break-words whitespace-pre-wrap overflow-hidden leading-snug text-base"
                        placeholder="Enter Text Here..."
                        rows={1}
                      />
                    ) : (
                      <div className="w-full p-2 text-base font-normal whitespace-pre-wrap break-words min-h-[2.5rem]">{row[col.id] || ""}</div>
                    )}
                  </td>
                ))}
                {isEditable && isAdmin && (
                  <td className="border border-black text-center">
                    <button onClick={() => removeRow(row.id)} className="text-red-600">
                      ✖
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>

        {isEditable && isAdmin && (
          <div className="flex max-h-[3rem] gap-[1rem] mt-4">
            <button onClick={addRow} className="bg-green-500 text-[1.25rem] text-white px-2 py-2 rounded">
              Add Row
            </button>
            <button onClick={addColumn} className="bg-[#3288E5] text-[1.25rem] text-white px-2 py-2 rounded">
              Add Column
            </button>
          </div>
        )}
      </div>
    );
  })
);

export default EditableTable;
