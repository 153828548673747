import React from "react";
import image1 from "../../../images/CS.png";
import image2 from "../../../images/GEO.png";
import image3 from "../../../images/PSY.png";

function SubjectCard({ index, subject, handleOnClick }) {
  const images = [image1, image2, image3];
  const getImageIndex = (index) => {
    const pattern = [0, 1, 2, 1, 2, 0];
    return pattern[index % pattern.length];
  };

  return (
    <div
      key={index}
      className={"relative rounded-lg overflow-hidden flex justify-center items-center cursor-pointer p-2 md:w-[20rem] sm:w-[10rem]"}
      onClick={() => {
        handleOnClick(subject.subject_id);
      }}
      style={{
        backgroundImage: `url(${images[getImageIndex(index)]})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="z-8 mb-[160px] text-center font-bold text-2xl text-[#F2F2F2]">{subject.subject_name}</div>
    </div>
  );
}

export default SubjectCard;
